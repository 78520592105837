let chartPanel;

function initChart(chartContainer, chartId, patientId, chartName = "") {
    const container = $(`#chartContainer-${chartContainer}`);
    const error = container.find(".error");
    error.hide();

    $.ajax({
        url: `/${appLocation}chart/getLineChart/${patientId}/${chartId}`,
        method: 'get', context: document.body
    }).done(function (data) {
        new Chart(
            container.find("canvas")[0],
            JSON.parse(data) // the backend-library generates the settings, we just need to parse the json
        );
    }).fail(function () { error.show() });
}

function loadChartPanel(chartId) {
    $("#chartSettings" + chartId).load("/" + appLocation + "getChartSettings/" + chartId)
}

function createChartScale(chartId) {
    $.ajax({
        url: "/" + appLocation + "chart/createScale",
        type: "post", context: document.body,
        data: { chartId }
    }).done(([chart, scale]) => {
        loadChartPanel(chartId);
    }).fail(() => actionFail())
}
function createChartAnnotation(chartId, scaleId) {
    $.ajax({
        url: "/" + appLocation + "chart/createAnnotation",
        type: "post", context: document.body,
        data: { chartId, scaleId }
    }).done(([chart, scale]) => {
        loadChartPanel(chartId);
    }).fail(() => actionFail())
}

function deleteChartScale(chartId, scaleId) {
    customConfirm(
        "Bitte bestätigen",
        "Sind Sie sicher, dass Sie die Skala löschen möchten?",
        () => {
            $.ajax({
                url: "/" + appLocation + "chart/deleteScale",
                type: "post", context: document.body,
                data: { scaleId }
            }).done((chart) => {
                loadChartPanel(chartId);
            }).fail(() => actionFail())
        }
    )
}
function deleteChartAnnotation(chartId, annotationId) {
    customConfirm(
        "Bitte bestätigen",
        "Sind Sie sicher, dass Sie die Markierung löschen möchten?",
        () => {
            $.ajax({
                url: "/" + appLocation + "chart/deleteAnnotation",
                type: "post", context: document.body,
                data: { annotationId }
            }).done(() => {
                loadChartPanel(chartId);
            }).fail(() => actionFail())
        }
    )
}

function setChartScaleSetting(scaleId, setting, value) {
    $.ajax({
        url: "/" + appLocation + "chart/setScaleSetting",
        type: "post", context: document.body,
        data: { scaleId, setting, value }
    })
    .done(() => actionSuccess())
    .fail(() => actionFail())
}
function setChartSetting(chartId, setting, value) {
    $.ajax({
        url: "/" + appLocation + "chart/setSetting",
        type: "post", context: document.body,
        data: { chartId, setting, value }
    })
    .done(() => actionSuccess())
    .fail(() => actionFail())
}
function setChartAnnotationSetting(annotationId, setting, value) {
    $.ajax({
        url: "/" + appLocation + "chart/setAnnotationSetting",
        type: "post", context: document.body,
        data: { annotationId, setting, value }
    })
        .done(() => actionSuccess())
        .fail(() => actionFail())
}

function manageChart(chartId = -1, page) {
    jsPanel.create({
        headerTitle: "Grafiken",
        closeOnEscape: true,
        headerLogo: '<span style="padding:10px" class="glyphicon glyphicon-picture" title="Grafiken" aria-hidden="true"></span>',
        contentSize: { width: "75%", height: "75%" },
        borderRadius: 3,
        onbeforeclose: (panel) => { chartPanel = null; return true },
        content: function () {
            chartPanel = this;
            $(chartPanel.content).load("/" + appLocation + `getChartSettings/${chartId}`, function() {
                if (chartId < 1) { loadChartAdministration(page || 0); }
            });
        },
        theme: "#f2f2f2",
        footerToolbar: '<span class="pull-right">Änderungen werden live übernommen.</span>',
    });
}
function manageScales(abstractQuestionId) {
    $.ajax("/" + appLocation + `getChartScaleSettings/${abstractQuestionId}`)
    .done((response) => {
        bootbox.dialog({
            message: response,
            title: "Skala wählen"
        })
    }).fail((error) => {
        actionFail(error)
    })
}

function saveQuestionScales(questionId) {
    $.ajax({
        url: "/" + appLocation + "setQuestionScales",
        method: "post", context: document.body,
        data: { questionId, scales: $("#selectedScales-" + questionId).val() }
    })
    .done(() => actionSuccess())
    .fail(() => actionFail())
}

function showChartsPopup(patientId, chartId) {
    jsPanel.create({
        headerTitle: "Grafik",
        closeOnEscape: true,
        headerLogo: '<span style="padding:10px" class="glyphicon glyphicon-picture" aria-hidden="true"></span>',
        contentSize: { width: "75%", height: "75%" },
        borderRadius: 3,
        contentFetch: {
            resource: "/" + appLocation + `chart/popup/${patientId}/${chartId}`
        },
        theme: "#f2f2f2",
    });
}

// -- Chart administration -- \\
function loadChartAdministration(page = 0) {
    $("#chartsAdministration").load("/" + appLocation + "chart/administation/" + page)
}
function deleteChart(id, page) {
    customConfirm("Wirklich löschen?", "Diese Aktion kann nicht rückgängig gemacht werden", function() {
        $.ajax({
            url: "/" + appLocation + "chart/delete/" + id,
            context: document.body,
            type: "post"
        }).done(function (response) {
            loadChartAdministration(page || 0);
        })
    });
}
