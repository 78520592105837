function registerFeedbackFormSubmitButton() {
    $("#feedbackForm").submit(function (e) {

        //disable button to prevent double submitting
        $("#feedbackCommitButton").prop("disabled", true);
        $("#feedbackCommitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-feedback-form").hide();
        $.ajax({
            url:  $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            actionSuccess();
            router.navigate(appLocation + 'Home', {trigger: true});
        }).fail(function (error) {
            showAjaxError(error, "feedback");
            //disable button to prevent double submitting
            $("#feedbackCommitButton").prop("disabled", false);
            $("#feedbackCommitButton").prop('value', "Senden");
        });
        $("#error-msg-feedback-form").show('fast');
        return false;
    });
}

function iWantThis(feature, alertMessage) {
    if(alertMessage)
        alert(alertMessage);

    $('#feedback' + feature).html('');

    $.ajax({
        url: "/" + appLocation +  "iWantThis",
        context: document.body,
        type: 'post',
        data: {'feature': feature}
    }).done(function () {
        actionSuccess();
        $('#feedback' + feature).html(
            "<div class='alert alert-success alert-dismissible' role='alert'>" +
            "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
            "<strong>Danke für Ihr Feedback!</strong> <br>" +
            "</div>"
        );
    }).fail(function () {
        actionFail();
    })
}

function iDontWantThis(feature, alertMessage) {
    if(alertMessage)
        alert(alertMessage);

    $('#feedback' + feature).html('');
    $.ajax({
        url: "/" + appLocation +  "iDontWantThis",
        context: document.body,
        type: 'post',
        data: {'feature': feature}
    }).done(function () {
        actionSuccess();
        $('#feedback' + feature).html(
            "<div class='alert alert-success alert-dismissible' role='alert'>" +
            "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
            "<strong>Danke für Ihr Feedback!</strong> <br> Wenn wir wissen, worauf unsere Kunden verzichten können, " +
            "haben wir mehr Zeit, um uns auf die wirklich wichtigen Dinge zu konzentrieren." +
            "</div>"
        );
    }).fail(function () {
        actionFail();
    })
}