/* Verification */
function startVerificationProcess(appLocation, containerId) {
    var $container = $("#" + containerId);
    var $iframe = $('<iframe src="/' + appLocation + 'oidc/verify" height="280" width="300" title="Verifikation"></iframe>');
    $container.children().remove();
    $container.append($iframe);
}


function confirmIdentityLink(fname, lname, bday) {
    var msg = "Bitte bestätigen Sie folgende Daten: " +
        fname + " " + lname + ", " + bday + ".";
    customConfirm("Bitte bestätigen", msg, function () {
        $.ajax({
            url: "/" + appLocation + "confirmIdentityLink",
            context: document.body,
            type: 'post'
        }).done(function () {

            showDisablePasswordInformation();

        }).fail(function () {
            actionFail("Daten konnten nicht bestätigt werden. Bitte wenden Sie sich an einen Administrator.");
        })
    });
}

function cancelIdentityLink() {
    var msg = "Wollen Sie den Verifikationsvorgang wirklich abbrechen?";
    customConfirm("Bitte bestätigen", msg, function () {
        $.ajax({
            url: "/" + appLocation + "cancelIdentityLink",
            context: document.body,
            type: 'post'
        }).done(function () {
            reloadCurrentContent(null, function () {
                $('#profileTabList a[href="#identification"]').tab('show');
            });
        }).fail(function () {
            actionFail("Daten konnten nicht gelöscht werden. Bitte wenden Sie sich an einen Administrator.");
        })
    });
}

function showDisablePasswordInformation() {
    var confirmMsg = "Um die Sicherheit Ihres Profils zu erhöhen sollte jetzt der Passwort-Login deaktiviert werden. " +
        "Sie können sich dann nur noch mit der ID Austria oder Handy-Signatur anmelden. Diese Einstellung können Sie jederzeit rückgängig machen. " +
        "Soll der Passwort-Login jetzt deaktiviert werden? ";
    customConfirm("Information", confirmMsg,
        function () {
            router.navigate(appLocation + 'Identification', {trigger: true});
            location.reload();
        },
        function () {
            $.ajax({
                url: "/" + appLocation + "togglePasswordLoginEnabled",
                context: document.body,
                type: 'post'
            }).done(function () {
                router.navigate(appLocation + 'Identification', {trigger: true});
                location.reload();
            }).fail(function () {
                actionFail(failMsg);
            })
        });
}
