function createNewPackageType(button) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var name = $(divInputGroup.find(":input")).val();

    var forDoctorId = $('#packageTypesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "admin/createNewPackageType",
        context: document.body,
        type: 'post',
        data: {'forDoctorId': forDoctorId, 'name': name}
    }).done(function () {
        loadByIdAdmin("PackageTypes", forDoctorId);
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function copyPackageType(button) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var packageTypeId = $(divInputGroup.find(":input")).val();

    var forDoctorId = $('#packageTypesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "admin/copyPackageType",
        context: document.body,
        type: 'post',
        data: {'forDoctorId': forDoctorId, 'packageTypeId': packageTypeId}
    }).done(function () {
        loadByIdAdmin("PackageTypes", forDoctorId);
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function createNewServiceItem(serviceTypeName, packageTypeEntryId) {
    var forDoctorId = $('#packageTypesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "admin/createNewServiceItem",
        context: document.body,
        type: 'post',
        data: {'serviceTypeName': serviceTypeName, 'packageTypeEntryId': packageTypeEntryId}
    }).done(function () {
        loadByIdAdmin("PackageTypes", forDoctorId);
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function createNewPackageTypeEntry(packageTypeId) {
    var forDoctorId = $('#packageTypesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "admin/createNewPackageTypeEntry",
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId}
    }).done(function () {
        loadByIdAdmin("PackageTypes", forDoctorId);
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function createNewPackageTypeItem(packageTypeId) {
    var forDoctorId = $('#packageTypesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "admin/createNewPackageTypeItem",
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId}
    }).done(function () {
        loadByIdAdmin("PackageTypes", forDoctorId);
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

/* default method to set a serviceItem setting */
function setServiceItemSetting(serviceItemId, setting, value, successCallback) {
    $.ajax({
        url: "/" + appLocation + "admin/setServiceItemSetting",
        context: document.body,
        type: 'post',
        data: {'serviceItemId': serviceItemId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('serviceItem' + serviceItemId + setting);
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            actionFail(e.responseText);
        }
        else {
            actionFail();
        }
    })
}

/* default method to set a packageTypeItem setting */
function setPackageTypeItemSetting(packageTypeItemId, setting, value, successCallback) {
    $.ajax({
        url: "/" + appLocation + "admin/setPackageTypeItemSetting",
        context: document.body,
        type: 'post',
        data: {'packageTypeItemId': packageTypeItemId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageTypeItem' + packageTypeItemId + setting);
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            actionFail(e.responseText);
        }
        else {
            actionFail();
        }
    })
}

function updatePackageTypeName(button, packageTypeId) {

    var span = button.parent();
    var divInputGroup = span.parent();
    var newName = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "admin/updatePackageTypeName",
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId, 'newName': newName}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageTypeName' + packageTypeId);
    }).fail(function () {
        actionFail();
    })
}

function updatePackageTypeDescription(packageTypeId, newDescription) {
    $.ajax({
        url: "/" + appLocation + "admin/updatePackageTypeDescription",
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId, 'newDescription': newDescription}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageType' + packageTypeId + 'Description');
    }).fail(function () {
        actionFail();
    })
}

function updatePackageVoucherCode(button, packageId) {

    var span = button.parent();
    var divInputGroup = span.parent();
    var voucherCode = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "admin/updatePackageVoucherCode",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'voucherCode': voucherCode}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageVoucherCode' + packageId);
    }).fail(function () {
        actionFail();
    })
}

function updatePackageTypeEntryPrice(button, packageTypeEntryId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var newPrice = $(divInputGroup.find(":input")).val().replace(",", ".");

    $.ajax({
        url: "/" + appLocation + "admin/updatePackageTypeEntryPrice",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'newPrice': newPrice}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageTypeEntryPrice' + packageTypeEntryId);
    }).fail(function () {
        actionFail();
    })
}


function updatePackageTypeEntryFee(button, packageTypeEntryId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var newFee = $(divInputGroup.find(":input")).val().replace(",", ".");

    $.ajax({
        url: "/" + appLocation + "admin/updatePackageTypeEntryFee",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'newFee': newFee}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageTypeEntryFee' + packageTypeEntryId);
    }).fail(function () {
        actionFail();
    })
}

function updatePackageTypeEntryDescription(button, packageTypeEntryId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var newDescription = $(divInputGroup.find(":input")).val().replace(",", ".");

    $.ajax({
        url: "/" + appLocation + "admin/updatePackageTypeEntryDescription",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'newDescription': newDescription}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageTypeEntryDescription' + packageTypeEntryId);
    }).fail(function () {
        actionFail();
    })
}

function updatePackageTypeEntryCustomServiceDescription(button, packageTypeEntryId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var newCustomServiceDescription = $(divInputGroup.find(":input")).val().replace(",", ".");

    $.ajax({
        url: "/" + appLocation + "admin/updatePackageTypeEntryCustomServiceDescription",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'newCustomServiceDescription': newCustomServiceDescription}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageTypeEntryCustomServiceDescription' + packageTypeEntryId);
    }).fail(function () {
        actionFail();
    })
}

function updatePackageTypeEntryValidityInDays(button, packageTypeEntryId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var validityInDays = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "admin/updatePackageTypeEntryValidityInDays",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'validityInDays': validityInDays}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('packageTypeEntryValidityInDays' + packageTypeEntryId);
    }).fail(function () {
        actionFail();
    })
}

function updatePackageValidFrom(packageId, validFrom) {
    $.ajax({
        url: "/" + appLocation + "admin/updatePackageValidFrom",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'validFrom': validFrom}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updatePackageValidTill(packageId, validTill) {
    $.ajax({
        url: "/" + appLocation + "admin/updatePackageValidTill",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'validTill': validTill}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageValidFromToNull(packageId) {
    $.ajax({
        url: "/" + appLocation + "admin/setPackageValidFromToNull",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId}
    }).done(function () {
        actionSuccess();
        $('#packageValidFrom' + packageId).val("");
    }).fail(function () {
        actionFail();
    })
}

function setPackageValidTillToNull(packageId) {
    $.ajax({
        url: "/" + appLocation + "admin/setPackageValidTillToNull",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId}
    }).done(function () {
        actionSuccess();
        $('#packageValidTill' + packageId).val("");
    }).fail(function () {
        actionFail();
    })
}

function setPackageTypeEntryAutoAssign(input, packageTypeEntryId) {
    var autoAssign = input.prop('checked');

    $.ajax({
        url: "/" + appLocation + "admin/setPackageTypeEntryAutoAssign",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'autoAssign': autoAssign}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageTypeEntryAutoRenewal(input, packageTypeEntryId) {
    var autoRenewal = input.prop('checked');

    $.ajax({
        url: "/" + appLocation + "admin/setPackageTypeEntryAutoRenewal",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'autoRenewal': autoRenewal}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageTypeHiddenInStore(input, packageTypeId) {
    var hiddenInStore = input.prop('checked');

    $.ajax({
        url: "/" + appLocation + "admin/setPackageTypeHiddenInStore",
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId, 'hiddenInStore': hiddenInStore}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageTypeValidForAllManagedPatients(input, packageTypeId) {
    var validForAllManagedPatients = input.prop('checked');

    $.ajax({
        url: "/" + appLocation + "admin/setPackageTypeValidForAllManagedPatients",
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId, 'validForAllManagedPatients': validForAllManagedPatients}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageTypeValidForAllPatients(input, packageTypeId) {
    var validForAllManagedPatients = input.prop('checked');
    $.ajax({
        url: "/" + appLocation + "admin/setPackageTypeValidForAllPatients",
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId, 'publicPackage': validForAllManagedPatients}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageActive(input, packageId) {
    var active = input.prop('checked');

    $.ajax({
        url: "/" + appLocation + "admin/setPackageActive",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'active': active}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageRenew(input, packageId) {
    var renew = input.prop('checked');

    $.ajax({
        url: "/" + appLocation + "admin/setPackageRenew",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'renew': renew}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackagePayed(input, packageId) {
    var payed = input.prop('checked');

    $.ajax({
        url: "/" + appLocation + "admin/setPackagePayed",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'payed': payed}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updatePackageCreatedBy(packageId, createdById) {
    $.ajax({
        url: "/" + appLocation + "admin/updatePackageCreatedBy",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'createdById': createdById}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updatePackageCreatedFor(packageId, createdForId) {
    $.ajax({
        url: "/" + appLocation + "admin/updatePackageCreatedFor",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId, 'createdForId': createdForId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setPackageCreatedByToNull(packageId) {
    var forPatientId = $('#packagesModule #selectAccount').val();
    $.ajax({
        url: "/" + appLocation + "admin/setPackageCreatedByToNull",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId}
    }).done(function () {
        actionSuccess();
        loadByIdAdmin("Packages", forPatientId);
    }).fail(function () {
        actionFail();
    })
}

function setPackageCreatedForToNull(packageId) {
    var forPatientId = $('#packagesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "admin/setPackageCreatedForToNull",
        context: document.body,
        type: 'post',
        data: {'packageId': packageId}
    }).done(function () {
        actionSuccess();
        loadByIdAdmin("Packages", forPatientId);
    }).fail(function () {
        actionFail();
    })
}

function updateServiceItemAmount(button, serviceItemId) {

    var span = button.parent();
    var divInputGroup = span.parent();
    var amount = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "admin/updateServiceItemAmount",
        context: document.body,
        type: 'post',
        data: {'serviceItemId': serviceItemId, 'amount': amount}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('serviceItemAmount' + serviceItemId);
    }).fail(function () {
        actionFail();
    })
}

function updateAvailableServiceItemAvailable(button, availableServiceItemId) {

    var span = button.parent();
    var divInputGroup = span.parent();
    var available = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "admin/updateAvailableServiceItemAvailable",
        context: document.body,
        type: 'post',
        data: {'availableServiceItemId': availableServiceItemId, 'available': available}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('availableServiceItemAvailable' + availableServiceItemId);
    }).fail(function () {
        actionFail();
    })
}

function updateAvailableServiceItemBlocked(button, availableServiceItemId) {

    var span = button.parent();
    var divInputGroup = span.parent();
    var blocked = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "admin/updateAvailableServiceItemBlocked",
        context: document.body,
        type: 'post',
        data: {'availableServiceItemId': availableServiceItemId, 'blocked': blocked}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('availableServiceItemBlocked' + availableServiceItemId);
    }).fail(function () {
        actionFail();
    })
}

function updateAvailableServiceItemConsumed(button, availableServiceItemId) {

    var span = button.parent();
    var divInputGroup = span.parent();
    var consumed = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "admin/updateAvailableServiceItemConsumed",
        context: document.body,
        type: 'post',
        data: {'availableServiceItemId': availableServiceItemId, 'consumed': consumed}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('availableServiceItemConsumed' + availableServiceItemId);
    }).fail(function () {
        actionFail();
    })
}

function assignPackageTypeEntryToPatients(packageTypeEntryId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie dieses Paket allen bestehenden Patienten des Arztes zuteilen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "admin/assignPackageTypeEntryToPatients",
            context: document.body,
            type: 'post',
            data: {'packageTypeEntryId': packageTypeEntryId}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail();
        })
    });
}

function stopAutoAssign(packageTypeEntryId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie die automatische Verlängerung für alle Patienten, die diesen Staffelpreis besitzen, beenden möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "admin/stopAutoAssign",
            context: document.body,
            type: 'post',
            data: {'packageTypeEntryId': packageTypeEntryId}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail();
        })
    });
}

function sendMessageToPatientsOwningPackageTypeEntry(packageTypeEntryId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie eine Nachricht an alle Patienten, die diesen Staffelpreis besitzen, senden möchten?", function () {
        var messageText = prompt("Sender: Admin | Betreff der Nachricht: Info über Änderungen zum Paket: Paketname | Bitte hier Text eingeben (Anrede + Grußformel werden ergänzt)", "Nachricht ohne Anrede");

        if (messageText != null) {
            $.ajax({
                url: "/" + appLocation + "admin/sendMessageToPatientsOwningPackageTypeEntry",
                context: document.body,
                type: 'post',
                data: {'packageTypeEntryId': packageTypeEntryId, 'messageText': messageText}
            }).done(function () {
                actionSuccess();
            }).fail(function () {
                actionFail();
            })
        }
    });
}

function deletePackageTypeItem(packageTypeItemId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?", function () {
        var forDoctorId = $('#packageTypesModule #selectAccount').val();
        $.ajax({
            url: "/" + appLocation + "admin/deletePackageTypeItem",
            context: document.body,
            type: 'post',
            data: {'packageTypeItemId': packageTypeItemId}
        }).done(function () {
            actionSuccess();
            loadByIdAdmin("PackageTypes", forDoctorId);
        }).fail(function () {
            actionFail();
        })
    });
}

function deleteServiceItem(serviceItemId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?", function () {
        var forDoctorId = $('#packageTypesModule #selectAccount').val();

        $.ajax({
            url: "/" + appLocation + "admin/deleteServiceItem",
            context: document.body,
            type: 'post',
            data: {'serviceItemId': serviceItemId}
        }).done(function () {
            actionSuccess();
            loadByIdAdmin("PackageTypes", forDoctorId);
        }).fail(function () {
            actionFail();
        })
    });
}

function deleteAvailableServiceItem(availableServiceItemId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?", function () {
        var forPatientId = $('#packagesModule #selectAccount').val();

        $.ajax({
            url: "/" + appLocation + "admin/deleteAvailableServiceItem",
            context: document.body,
            type: 'post',
            data: {'availableServiceItemId': availableServiceItemId}
        }).done(function () {
            actionSuccess();
            loadByIdAdmin("Packages", forPatientId);
        }).fail(function () {
            actionFail();
        })
    });
}

function deletePackageType(packageTypeId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?", function () {
        var forDoctorId = $('#packageTypesModule #selectAccount').val();

        $.ajax({
            url: "/" + appLocation + "admin/deletePackageType",
            context: document.body,
            type: 'post',
            data: {'packageTypeId': packageTypeId}
        }).done(function () {
            actionSuccess();
            loadByIdAdmin("PackageTypes", forDoctorId);
        }).fail(function () {
            actionFail();
        })
    });
}

function startPayment(packageTypeEntryId) {
    router.navigate(appLocation + "StartPayment/" + packageTypeEntryId, {trigger: true});
}

function preparePayment(packageTypeEntryId, createdFor, paymentMethod) {
    router.navigate(appLocation + "Payment/" + packageTypeEntryId + "/" + createdFor + "/" + paymentMethod, {trigger: true});
}

function paymentSuccess(packageId) {
    router.navigate(appLocation + "PaymentSuccess/" + packageId, {trigger: true});
}

function paymentFail() {
    router.navigate(appLocation + "PaymentFailure", {trigger: true});
}

function activateFreePackage(packageTypeEntryId, createdFor) {
    router.navigate(appLocation + "ActivateFreePackage/" + packageTypeEntryId + "/" + createdFor, {trigger: true});
}

function generateVoucherCodeForPackageTypeEntry(packageTypeEntryId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie dieses Paket jetzt verkaufen möchten? Das Paket ist ab sofort gültig. Eventuell fallen Provisionsgebühren dafür an.", function () {
        $.ajax({
            url: "/" + appLocation + "generateVoucherCodeForPackageTypeEntry",
            context: document.body,
            type: 'post',
            data: {'packageTypeEntryId': packageTypeEntryId}
        }).done(function () {
            actionSuccess();
            routeToPageOrPerformAction(
                "DoctorsPackagesModule",
                function () {
                    reloadCurrentContent();
                }
            );
        }).fail(function () {
            actionFail();
        })
    });
}

function checkVoucherCode() {
    var voucherCode = $('#voucherCode').val();

    $.ajax({
        url: "/" + appLocation + "checkVoucherCode",
        context: document.body,
        type: 'post',
        data: {'voucherCode': voucherCode}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            var profilePicUrl = response.result;
            $('#voucherCodeCheckResult').html('');
            $("#targetDoctorProfilePic").css("background-image", "url(" + profilePicUrl + ")");
            $('#signUpVoucherCodeRestOfForm').show();
        }
        else {
            $('#voucherCodeCheckResult').html('Fehlerhafter Paket-Code. Bitte überprüfen Sie Ihre Eingabe.');
            $('#signUpVoucherCodeRestOfForm').hide();
        }

    }).fail(function (error) {
        actionFail();
    });
}

function redeemVoucherCode() {

    var voucherCode = $('#voucherCodeInput').val();
    var createdForId = $('#createdFor').val();

    $.ajax({
        url: "/" + appLocation + "redeemVoucherCode",
        context: document.body,
        type: 'post',
        data: {'voucherCode': voucherCode, 'createdForId': createdForId}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            router.navigate(appLocation + 'PatientsPackagesModule', {trigger: true});
        }
        else {
            actionFail("Der Paket-Code ist ungültig!");
        }
    }).fail(function () {
        actionFail();
    })
}

function showDonatePackageTypeEntryModal(packageTypeEntryId) {
    $("#DonatePackageTypeEntryModalReplace").load("/" + appLocation + "getDonatePackageTypeEntryModal/" + packageTypeEntryId, function () {
        $("#donatePackageTypeEntryModal").modal({backdrop: 'static', keyboard: false});
    });
}

function donatePackageTypeEntry(packageTypeEntryId) {
    var donateToPatientId = $('#donateToPatientId' + packageTypeEntryId).val();
    var promotionId = $('#promotionId' + packageTypeEntryId).val();

    $.ajax({
        url: "/" + appLocation + "donatePackageTypeEntry",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'patientId': donateToPatientId, 'promotionId': promotionId}
    }).done(function () {
        actionSuccess();
        closeModals();

        setCurrentContentAndBrowserUrl("DoctorsPackagesModule", false);

        reloadCurrentContent(null, function () {
            $('#packagesModuleTabList a[href="#soldPackages"]').tab('show');
        });
    }).fail(function () {
        actionFail();
    })
}

function loadDoctorsPackages(doctorId) {
    //-1 say there is no doctor yet in this list - load all doctors to connect with one of them
    if (doctorId == -1) {
        router.navigate(appLocation + 'Doctors', {trigger: true});
    }

    else {
        loadById("PackageTypes", doctorId);
    }
}

function loadPatientsPackages(active) {
    showLoaderIn('.PatientsPackagesBlock');
    $('.PatientsPackagesBlock').load("/" + appLocation + "getPatients" + active + "Packages", function () {

    });
}

function cancelAutoRenewal(packageId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie die automatische Verlängerung für dieses Paket kündigen möchten? Sie können " +
        "diese Aktion nicht rückgängig machen.", function () {

        $.ajax({
            url: "/" + appLocation + "cancelAutoRenewal",
            context: document.body,
            type: 'post',
            data: {'packageId': packageId}
        }).done(function () {
            actionSuccess();
            reloadCurrentContent();
        }).fail(function () {
            actionFail();
        })
    });
}


/*
* promotions CRUD
* */

function addPromotion() {
    $.ajax({
        url: "/" + appLocation + "addPromotion",
        context: document.body,
        type: 'post'
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            router.navigate(appLocation + 'PromotionSettings/promotion' + response.result, {trigger: true});
        }
        else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function deletePromotion(promotionId) {
    $.ajax({
        url: "/" + appLocation + "deletePromotion",
        context: document.body,
        type: 'post',
        data: {'promotionId': promotionId}
    }).done(function () {
        actionSuccess();
        router.navigate(appLocation + 'PromotionSettings', {trigger: true});
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}


/* default method to set a promotion setting */
function setPromotionSetting(promotionId, setting, value, successCallback) {
    if ($('#error-msg-promotion' + promotionId + '-' + setting).text()) {
        $('#error-msg-promotion' + promotionId + '-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setPromotionSetting",
        context: document.body,
        type: 'post',
        data: {'promotionId': promotionId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-promotion' + promotionId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('promotion' + promotionId + '-' + setting, e.responseText);
            }
            else {
                actionFail(e.responseText);
            }
        }
        else {
            actionFail();
        }
    })
}


function addPromotionToPackageTypeEntry(packageTypeEntryId, promotionId) {
    var forDoctorId = $('#packageTypesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "addPromotionToPackageTypeEntry",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'promotionId': promotionId}
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            loadByIdAdmin("PackageTypes", forDoctorId);
        }
        else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function removePromotionFromPackageTypeEntry(packageTypeEntryId, promotionId) {
    var forDoctorId = $('#packageTypesModule #selectAccount').val();

    $.ajax({
        url: "/" + appLocation + "removePromotionFromPackageTypeEntry",
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'promotionId': promotionId}
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            loadByIdAdmin("PackageTypes", forDoctorId);
        }
        else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}
