//Submit new Vital in Modal
function registerVitalModalSubmitButton(vitalTypeId) {
    $("#newvitalmodal").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-vital-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'vital');
            if (response.status == 'SUCCESS') {
                $('#VitalsModal').modal("hide");
                drawVitalChart(vitalTypeId);
                drawVitalTable(vitalTypeId);
            }
        }).fail(function (error) {
            showAjaxError(error, 'vital');
        });
        $("#error-msg-vital-form").show('fast');
        return false;
    });
    return false;
}

//Update items with id - select correct vitalType
function updateVitals(item, id, vitalTypeId, vitalType) {
    $("#" + item + "ModalReplace").load("/" + appLocation + "get" + item + "Modal/" + id, function () {
        $("#" + item + "Modal").modal({backdrop: 'static', keyboard: false});
        $("#vitalsSelect" + vitalTypeId).attr("selected", true);
        registerModalSubmitButtons();
        registerVitalModalSubmitButton(vitalTypeId);
    });
}

//Delete (set inactive) items with id
function deleteVitalById(item, id, vitalTypeId, vitalType) {
    showLoaderIn('.' + vitalType + 'ChartBlock');
    showLoaderIn('.' + vitalType + 'TableBlock');
    $.ajax({
        url: "/" + appLocation + "delete" + item,
        context: document.body,
        type: 'post',
        data: {'id': id}
    }).done(function () {
        drawVitalChart(vitalTypeId);
        drawVitalTable(vitalTypeId);
    }).fail(function () {
        $("#msg").html("<span class=\"formFieldError\">Server failed to process request</span>");
    })
}

function togglePatientsVitalTypes(vitalTypeId) {
    $.ajax({
        url: "/" + appLocation + "togglePatientsVitalType",
        context: document.body,
        type: 'post',
        data: {'vitalTypeId': vitalTypeId}
    }).done(function () {
        //actionSuccess();
    }).fail(function () {
        //actionFail("Fehler beim Speichern des Abos");
    })
}

function drawVitalTable(type) {
    $('#' + type + 'TableBlock').load("/" + appLocation + "getVitalTableData/" + type);
}

//Draw Vitals
/*function drawVitalChart(type) {
 var jsonData = $.ajax({
 url: "/" + appLocation +  "getVitalChartData",
 data: {'vitalType': type},
 dataType: "json",
 async: false
 }).responseText;

 // Create our data table out of JSON data loaded from server.
 var data = new google.visualization.DataTable(jsonData);

 // Instantiate and draw our chart, passing in some options.
 var chart = new google.visualization.LineChart(document.getElementById(type + 'ChartBlock'));
 var options = {
 curveType: 'function',
 legend: {position: 'top', alignment: 'center'},
 pointSize: 4,
 hAxis: {viewWindowMode: 'pretty'},
 vAxis: {viewWindowMode: 'pretty'},
 chartArea: {
 backgroundColor: {
 fill: 'white',
 opacity: 0.0
 },
 left: '50',
 top: '30'
 },
 backgroundColor: {
 fill: '#e9eaed',
 opacity: 0.0
 }
 };
 chart.draw(data, options);
 }*/

//draw vitals in vis.js graph 2d
function drawVitalChart(type) {

    $('#' + type + 'ChartBlock').html("");//delete previous chart

    var container = document.getElementById(type + 'ChartBlock');

    var jsonItems = $.ajax({
        url: "/" + appLocation + "VitalChartItems",
        data: {'vitalType': type},
        dataType: "json",
        async: false
    }).responseText;

    var jsonGroups = $.ajax({
        url: "/" + appLocation + "getVitalChartGroups",
        data: {'vitalTypeName': type},
        dataType: "json",
        async: false
    }).responseText;

    if (jsonItems != "[]") {
        var items = new vis.DataSet(eval(jsonItems));
        var groups = new vis.DataSet(eval(jsonGroups));
        var options = {
            width: '100%',
            height: '300px'
        };
        var Graph2d = new vis.Graph2d(container, items, groups, options);
    }
    else {
        $('#' + type + 'ChartBlock').html("Keine Daten eingetragen.");//delete previous chart
    }
}

//Draw Vitals
function drawVitalChartForPatient(type, patientId) {
    var jsonData = $.ajax({
        url: "/" + appLocation + "VitalChartDataForPatient",
        data: {'vitalType': type, 'patientId': patientId},
        dataType: "json",
        async: false
    }).responseText;

    // Create our data table out of JSON data loaded from server.
    var data = new google.visualization.DataTable(jsonData);

    // Instantiate and draw our chart, passing in some options.
    var chart = new google.visualization.LineChart(document.getElementById(type + 'ChartBlock'));
    var options = {
        curveType: 'function',
        legend: {position: 'top', alignment: 'center'},
        pointSize: 4,
        hAxis: {viewWindowMode: 'pretty'},
        vAxis: {viewWindowMode: 'pretty'},
        chartArea: {
            backgroundColor: {
                fill: 'white',
                opacity: 0.0
            },
            left: '50',
            top: '30'
        },
        backgroundColor: {
            fill: '#e9eaed',
            opacity: 0.0
        }
    };
    chart.draw(data, options);
}