// function initSummernoteEditors(placeholders) {
//     $(".summernote").each(function (index, editor) {
//         initSummernoteFor(editor, placeholders)
//     });
// }

function destroyEditor(requirementId, markupStr, additionalId) {
    if (!additionalId) {
        additionalId = "";
    }
    var editorId = "#informationRequirementHtmlEditor" + requirementId + "" + additionalId;
    $(editorId).removeClass("isEditor");
    $(editorId).addClass("editor-bg");
    $("#cancelEditor" + requirementId).hide();
    if (!markupStr) {
        markupStr = $(editorId).summernote('code');
    }
    $(editorId).html(markupStr);
    $(editorId).summernote('destroy');
}

function toggleEditor(ctx, requirementId, placeholders, callback, additionalId, isReferenceRequirement) {
    if (!additionalId) {
        additionalId = "";
    }
    var editorId = "#informationRequirementHtmlEditor" + requirementId + "" + additionalId;

    if ($(editorId).hasClass("isEditor")) {
        var markupStr = $(editorId).summernote('code');

        if (callback) {
            //ctx -> this wobei ich das verschmissen hab war nur zum input vom parent holen...
            callback.call(ctx, requirementId, markupStr)
        }
        destroyEditor(requirementId, markupStr, additionalId)
    } else {
        initSummernoteFor(editorId, Array.from(placeholders || []));
        $(editorId).removeClass("editor-bg");
        $("#cancelEditor" + requirementId).show();
    }
}

function getJson(editorId) {
    try {
        return JSON.parse($(editorId).attr('data-json'));
    } catch (ex) {
        return {};
    }
}

function toggleJSONEdit(ctx, requirementId, callback) {
    const editorId = `informationRequirementJSONEditor${requirementId}`;
    const container = document.getElementById(editorId)
    if (!container) return;
    const options = {
        templates: [
            {
                text: 'Example',
                title: 'A full example of possible configurations',
                className: 'jsoneditor-type-object',
                field: 'ExampleTemplate',
                value: {
                    "genericDocumentTypeIds": {
                        "5": {
                            "preSelect": "All",
                            "mostRecent": true,
                            "requirements": {
                                "73": {"requirementStyle": "border:1px solid yellow;"},
                                "74": {
                                    "questions": [
                                        69
                                    ]
                                },
                            }
                        },
                        "6": {
                            "requirements": {
                                "91": {}
                            }
                        }
                    }
                }
            },
        ],
        modes: ["tree", "code", "form", "text", "preview"],
        onChangeText: (text) => {
            $(`#${editorId}TextArea`).attr("value", text);
        }
    }
    const editor = new JSONEditor(container, options)
    editor.set(getJson(`#${editorId}TextArea`))
    const updatedJson = editor.get()
    if (callback) {
        callback.call(ctx, requirementId, updatedJson)
    }
}

function initUserSummernoteEditors(userEditors, textBlocks, extendedTextBlocks) {
    if (!userEditors || userEditors == "") return;
    if (userEditors.charAt(0) != ".") {
        userEditors = "." + userEditors;
    }
    // $(userEditors).each(function (index, editor) {
    initUserSummernote($(userEditors), 150, textBlocks, extendedTextBlocks);
    // });
}


let editor;

function initUserSummernote($editor, minHeight, textBlocks, extendedTextBlocks, value, airMode, additionalButtons = {}) {
    // additional buttons: https://summernote.org/deep-dive/#custom-button
    //   should be constructed via $.summernote.ui.buttonGroup but any jquery-wrapped DOM-Node would work
    if (!$editor) return;
    editor = $editor;
    if (!minHeight) minHeight = 300;
    if (!airMode) airMode = false;

    if ((!textBlocks || textBlocks === "undefined") && extendedTextBlocks) {
        textBlocks = extendedTextBlocks;
        extendedTextBlocks = [];
    }
    if (!textBlocks) textBlocks = [];
    if (!extendedTextBlocks) extendedTextBlocks = [];

    var textBlocksButton = textBlocks && textBlocks.length > 0 ? ['textBlocksButton', ['textBlocksButton']] : undefined;

    var defaultToolbar = [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['fontname','fontsize'/*, 'fontsizeunit'*/]],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
        ['insert', ['link']],
        ['pageBreakButton', ['pageBreakButton']],
        textBlocksButton,
        ['view', ['codeview']],
        ...(Object.keys(additionalButtons).map(it => [it, [it]]))
    ];

    if (textBlocks.length == 0) defaultToolbar.splice(defaultToolbar.indexOf(textBlocksButton), 1);

    const combinedTextBlocks = [];
    combinedTextBlocks.push(...textBlocks);
    combinedTextBlocks.push(...extendedTextBlocks);
    window.editor = $editor;
    $($editor).summernote({
        airMode: airMode,
        lang: 'de-DE',
        toolbar: defaultToolbar,
        minHeight: minHeight,
        maxHeight: 450,
        /*fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '36'],*/
       /* fontSizeUnits: ['pt','px'],*/
        buttons: {
            pageBreakButton: PageBreakButton.bind(this),
            textBlocksButton: TextBlocksButton.bind(this, textBlocks, extendedTextBlocks),
            ...additionalButtons
        },
        hint: combinedTextBlocks.length > 0 ? {
            textBlocks: combinedTextBlocks,
            textBlockNames: combinedTextBlocks.map(block => block.key),
            match: /#(\S+)/,// /#\b(\w{1,})$/,
            search: function (keyword, callback) {
                callback($.grep(this.textBlockNames, function (item) {
                    return item.toLowerCase().indexOf(keyword.toLowerCase()) === 0;
                }));
            },
            content: function (item) {
                for (let i = 0; i < this.textBlocks.length; i++) {
                    const element = this.textBlocks[i];
                    if (element.key === item) {
                        $editor.summernote("pasteHTML", element.value);
                        return "";
                    }
                }
            },
        } : undefined,
        callbacks: {
            onBlur: function (ctx) {
                var that = this;
                setTimeout(function () {
                    if (document.activeElement && $(document.activeElement).parents(".note-editor").length <= 0) {
                        if (that.onblur)
                            that.onblur(ctx);
                    }
                }, 5);

                // fetches the current editor from JQuery and syncs the actual value to be the displayed value
                const me = $(this);
                me.val(me.summernote("code"));
            },
            onKeydown: function (e) {
                if (e.key === '#' && e.ctrlKey) {
                    newTextBlockWithContent($editor.summernote("createRange").toString());
                }
            },
            onChange: function (content, element) {
                setFormHasChanges($(this).parents("form:first")[0], true);
            }
        }
    });

    if (value)
        $($editor).summernote("code", value)

    //this would be using common pt instead of px (like google docs)
    //this would be using users fontSize and fontName from documentStyleInformation
    //but it is causing a lots of problems, e.g. creating 2 paragraphs, remove de linebreak (join to one paragraph), then there is a empty paragraph added, where styling is wrong
    //to avoid problems, we have set fontSize and Name in core.css .note-editable
    //https://github.com/summernote/summernote/issues/3088
    //https://github.com/summernote/summernote/pull/3510
    //https://github.com/JiHong88/suneditor/issues/776
    //https://github.com/summernote/summernote/issues/3645
    //https://github.com/summernote/summernote/issues/3608

    /*$($editor).summernote('fontSizeUnit', 'pt')*/
    //set users default fontSize and fontName
   /* if(fontSize)
        $($editor).summernote('fontSize', fontSize)
    if(fontName)
        $($editor).summernote('fontName', fontName)*/
}

let formsWithChanges = [];
let observedForms = [];
/** if an element is supplied, checks if it's in the list of changed forms, otherwise checks if any form has changed */
function formChangesPending(elem) {
    formsWithChanges = formsWithChanges.filter((node) => document.contains(node));
    observedForms = observedForms.filter((node) => document.contains(node));
    // remove all nodes from the 2 lists, that no longer exist on the DOM
    return elem ? formsWithChanges.indexOf(elem) >= 0 : formsWithChanges.length > 0;
}
navigationPredicates.push(function () { return ! formChangesPending(); })
function observeForm(form, observe = true) {
    formsWithChanges = formsWithChanges.filter((node) => document.contains(node));
    observedForms = observedForms.filter((node) => document.contains(node));
    if (! form) return;
    if (!observe) {
        const observedIndex = observedForms.indexOf(form);
        if (observedIndex >= 0) observedForms.splice(observedIndex, 1);
        const changesIndex = formsWithChanges.indexOf(form);
        if (changesIndex >= 0) formsWithChanges.splice(changesIndex, 1)
    } else {
        observedForms.push(form);
        $(form).on("submit", function (evt) {
            if (! querySessionType()) {
                customModalInfo(
                    "Ihre Sitzung ist abgelaufen",
                    "Die Änderungen können aus Sicherheitsgründen nicht mehr gespeichert werden. " +
                    "Bitte kopieren sie den Inhalt manuell, und fügen sie ihn nach dem neu-anmelden erneut ein.");
                evt.preventDefault();
                evt.stopPropagation();
                evt.stopImmediatePropagation();
                return false;
            }
        });
    }
}
function setFormHasChanges(form, hasChanges = true) {
    if (observedForms.indexOf(form) < 0) return; // check if we ignore the form
    const idx = formsWithChanges.indexOf(form);
    const knownToHaveChanges = idx >= 0;
    if (hasChanges === knownToHaveChanges) return; // state does not change

    if (! hasChanges) formsWithChanges.splice(idx, 1);
    else {
        formsWithChanges.push(form);
        const jQueryForm = $(form);
        jQueryForm.submit(function () {
            setFormHasChanges(form, false)
        })
    }
}

function showTextBlockSummernote(flag) {
    if (flag) {
        initUserSummernote($('#textBlockValue'), 100);
        $('#enabledEditorAction').show();
        $('#disabledEditorAction').hide();
    } else {
        $('#textBlockValue').summernote('destroy');
        $('#enabledEditorAction').hide();
        $('#disabledEditorAction').show();
    }
}

function updateTextBlockFormFields() {
    var primaryType = null;
    document.getElementsByName("primaryType").forEach(type => {
        if (type.checked) primaryType = type.value;
    });
    var generalTypeField = document.getElementById("type-general");
    var documentTypeField = document.getElementById("type-documents");
    var complexTypeDivs = document.querySelectorAll(".complexTypeSelectorClass");

    generalTypeField.style.display = "none";
    documentTypeField.style.display = "none";
    complexTypeDivs.forEach(field => field.style.display = "none");

    switch (primaryType) {
        case "documents":
            documentTypeField.style.display = "initial";
            complexTypeDivs.forEach(div => div.style.display =
                documentTypeField.value.split(";")[1] == div.id.substring(19) // 19 = "complexTypeSelector".length
                    ? "initial"
                    : "none");
        default:
            showTextBlockSummernote(true);
            break;
        case "general":
            generalTypeField.style.display = "initial";
            showTextBlockSummernote(false);
            break;
    }
}



function destroyUserEditors(userEditors) {
    if (!userEditors || userEditors == "") return;
    if (userEditors.charAt(0) != ".") {
        userEditors = "." + userEditors;
    }
    $(userEditors).each(function (index, editor) {
        destroySummernoteFor($(editor));
    });
}

function destroyUserEditor(editor) {
    if (!editor || editor == "") return;
    if (editor.charAt(0) != "#") {
        editor = "#" + editor;
    }
    destroyEditor($(editor));
}

function destroySummernoteFor($editor) {
    if (!$editor) return;
    $($editor).summernote('destroy');
}

function initSummernoteFor(editor, placeholders) {
    var defaultToolbar = [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'hr']],
        ['view', ['codeview']],
        ['placeholderDropDown', ['placeholderDropDown']],
    ];

    var editor = $(editor).summernote({
        toolbar: defaultToolbar,
        lang: 'de-DE',
        buttons: {
            placeholderDropDown: DropDownButton.bind(this, placeholders),
        },
        minHeight: 300
    });
    $(editor).addClass("isEditor");
}

//hint js bind is reverting order of arguments!! how stupid is this?!
var DropDownButton = function (placeholders, editor) {
    var ui = $.summernote.ui;

    // create button
    var event = ui.buttonGroup([
        ui.button({
            contents: 'Placeholders | <i class="fa fa-caret-down" aria-hidden="true"></i>',
            data: {
                toggle: 'dropdown'
            }
        }),
        ui.dropdown({
            items: placeholders,
            callback: function (items) {
                $(items).find('li a').on('click', function () {
                    var listItem = $(this).html()
                    var placeholder = ""
                    if (listItem == 'DYNAMIC_VALUE') {
                        placeholder = "${DYNAMIC_VALUE({\"action\":\"questions\",\"multipleAnswerSeparator\":\",\",\"questionSeparator\":\".\",\"withQuestionText\": true,\"questionIds\":[135,136,137], \"requirementIds\":[135,136,137]})}\n"
                    } else {
                        placeholder = "${" + listItem + "}";
                    }
                    editor.invoke("editor.insertText", placeholder)
                })
            }
        })
    ]);
    return event.render();   // return button as jquery object
};

var PageBreakButton = function (editor) {
    var ui = $.summernote.ui;
    var button = ui.button({
        contents: '<i class="glyphicon glyphicon-scissors"/>',
        tooltip: 'Manueller Seitenumbruch',
        click: function () {
            editor.invoke("editor.pasteHTML", "<p style='font-style: italic; page-break-after: always'>#Manueller Seitenumbruch nach dieser Zeile#</p>")
        }
    });
    return button.render();
};

function buildTextblockListEntry(editor, key, value) {
    var li = $(document.createElement("li"));
    var a = $(document.createElement("a"));
    a.text(key);
    a.attr("href", "javascript: void(0);");
    li.click(function () {
        if(editor.invoke("isEmpty")){
            editor.invoke("code", value);
        }
        else{
            editor.invoke("editor.pasteHTML", `${value}`)
        }
    });

    li.append(a);
    return li;
}

var TextBlocksButton = function (textBlocks, extendedTextblocks, editor) {
    var ui = $.summernote.ui;
    var items = [];

    textBlocks.forEach(textBlock => items.push(buildTextblockListEntry(editor, textBlock.key, textBlock.value)));

    if (extendedTextblocks && extendedTextblocks.length > 0) {
        var separator = $(document.createElement("li"));
        separator.attr("role", "separator");
        separator.attr("class", "divider");
        items.push(separator);
        extendedTextblocks.forEach(textBlock => items.push(buildTextblockListEntry(editor, textBlock.key, textBlock.value)));
    }

    var group = ui.buttonGroup([
        ui.button({
            contents: '<i class="glyphicon glyphicon-indent-left"/>',
            tooltip: 'Texbausteine',
            data: {toggle: 'dropdown'}
        }),
        ui.dropdown({
            className: "dropdown-variable dropdown-maxheight",
            contents: items
        })
    ]);
    return group.render();
};

function initUserSummernoteInAirMode($editor, minHeight) {
    if (!$editor) return;
    if (!minHeight) minHeight = 300;
    var defaultToolbar = [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'hr']],
        ['view', ['codeview']],
        ['placeholderDropDown', ['placeholderDropDown']]
    ];
    $($editor).summernote({
        airMode: true,
        lang: 'de-DE',
        toolbar: defaultToolbar,
        minHeight: minHeight,
        fontname: 'Helvetica',
        callbacks: {
            onBlur: function (ctx) {
                var that = this;
                setTimeout(function () {
                    if (document.activeElement && $(document.activeElement).parents(".note-editor").length <= 0) {
                        if (that.onblur)
                            that.onblur(ctx);
                    }
                }, 5);
            }
        }
    });
}

/* created a own version of CallSummernote because of weird onblur event handling -
did not work when clicking from one cursor in editor to another cursor position in other editor*/
function initExternalCallSummernote($editor, minHeight) {
    if (!$editor) return;
    if (!minHeight) minHeight = 300;
    var defaultToolbar = [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['insert', ['link']],
        ['view', ['codeview']]
    ];
    $($editor).summernote({
        lang: 'de-DE',
        toolbar: defaultToolbar,
        minHeight: minHeight,
        fontname: 'Helvetica',
        callbacks: {
            onBlur: function (ctx) {
                this.onblur(ctx);
            }
        }
    });
}


const getGeneratedPageURL = ({ html, css, js }) => {
    const getBlobURL = (code, type) => {
        const blob = new Blob([code], { type })
        return URL.createObjectURL(blob)
    }

    const cssURL = getBlobURL(css, 'text/css')
    const jsURL = getBlobURL(js, 'text/javascript')

    const source = `
    <html>
      <head>
        ${css && `<link rel="stylesheet" type="text/css" href="${cssURL}" />`}
        ${js && `<script src="${jsURL}"></script>`}
      </head>
      <body>
        ${html || ''}
      </body>
    </html>
  `

    return getBlobURL(source, 'text/html')
}