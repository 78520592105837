//initialize patient search
function searchPatients(inputId, size) {
    if ($('#' + inputId).val().length > 2) {
        loadPatients(size, $('#' + inputId).val());
    } else {
        $('#' + size + 'PatientList').html(' <div class="alert alert-warning alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><span>Bitte geben Sie mind. 3 Buchstaben zur Suche ein. In der Suche können Sie auch mit Hilfe von Platzhaltern nach Patienten suchen.<br/> Sie können <b>*</b> für beliebig viele und <b>_</b> für genau ein beliebiges Zeichen im Namen verwenden.</span></div>');
    }
}

function hideAdminMessage(id) {
    // localStorage.setItem("admin-message", [id]);
    // $("admin-message"+id).hide();
}

function clearMenuPatientSearch() {
    $('#menuPatientSearchInput').val('');
    $('#MenuPatientList').html('');
}

function loadPatients(size, filter) {
    showLoaderIn('#' + size + 'PatientList');
    // there is a problem with encoding / , which are very likely in phone numbers
    // https://github.com/spring-projects/spring-framework/issues/15727
    // just make a simple workaround here and perform special encoding
    filter = filter.replace("/", "#SLASH#")
    $('#' + size + 'PatientList').load('/' + appLocation + 'loadPatients/' + encodeURIComponent(filter) + '/' + size);
}

function loadAllPatients(size) {
    showLoaderIn('#' + size + 'PatientList');
    $('#' + size + 'PatientList').load('/' + appLocation + 'loadAllPatients/' + size);
}

function initPatientLabelsTypeahead(searchInput, previewContainer, data) {
    const search = $(searchInput);
    const labels = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: data.map(({id, text, background, foreground}) => {
            return {
                id, name: text,
                html: `<span class="badge" style="background: ${background}; color: ${foreground}">${text}</span>`
            };
        })
    });

    labels.initialize(true);

    search.typeahead({
        hint: true, highlight: true, minLength: 1
    }, {
        name: "patientLabels",
        display: "name",
        source: labels.ttAdapter(),
        templates: {
            suggestion: function (data) {
                return `<div class="col-sm-offset-1">${data.html}</div>`;
            }
        }
    }).on("typeahead:selected", function (ev, suggestion) {
        const exists = $(".patientFilterLabelsPreview").find(`[value=${suggestion.id}]`).length > 0;
        if (!exists) $(previewContainer).append(`<span onclick="this.remove()" title="von Suche entfernen" style="cursor: pointer">
            <input type="hidden" value="${suggestion.id}" name="labels"/>
            ${suggestion.html}
        </span>`);
        $(this).typeahead("val", "").keyup();
    });
}

function filterPatients() {
    showLoaderIn('#filteredPatientList');
    $('#filteredPatientList').load('/' + appLocation + 'filterPatients?' + $('#patientsModulePatientFilterForm').serialize());
}

function sendMessageToPatient(receiverId) {
    openDialog('Message');

    if (receiverId != null) {
        $('#subject').focus().select();

        $('#selectReceiverValue').val(receiverId);
        $('#selectReceiver').val(($('#selectReceiver option[value="' + receiverId + '"]').html()));

        $.ajax({
            url: '/' + appLocation + 'getProfilePicBackgroundUrlForAccountWithId',
            context: document.body,
            type: 'post',
            data: {'accountId': receiverId}
        }).done(function (response) {
            if (response.status == 'SUCCESS') {
                var profilePicUrl = response.result;
                $('#messageReceiverProfilePic').css('background-image', 'url(' + profilePicUrl + ')')
            }
        }).fail(function () {
            actionFail();
        })
    } else {
        $('#selectReceiver').focus().select();
    }
}


function toggleFlexibleOpeningHoursText(medicalPracticeId) {
    if ($('#flexibleOpeningHoursRadio' + medicalPracticeId).is(':checked')) {
        $('#flexibleOpeningHoursText' + medicalPracticeId).show();
        $('#staticOpeningHoursText' + medicalPracticeId).hide();
    } else {
        $('#flexibleOpeningHoursText' + medicalPracticeId).hide();
        $('#staticOpeningHoursText' + medicalPracticeId).show();
    }
}

function toggleInvitePatient() {
    if ($('#invitePatientCheckbox').is(':checked')) {
        if (!$('#newPatientForDoctorEmail').val())
            $('#newPatientForDoctorEmail').val($('#contactEmail').val());
        $('#invitePatientInput').show();
        $('#newPatientForDoctorEmail').prop('disabled', false);
    } else {
        $('#newPatientForDoctorEmail').val('');
        $('#invitePatientInput').hide();
        $('#newPatientForDoctorEmail').prop('disabled', true);
    }
}

function toggleConcernTypes(concernTypeId) {
    $('#concernTypes').children().css('pointerEvents', 'none');

    $.ajax({
        url: '/' + appLocation + 'toggleConcernTypes',
        context: document.body,
        type: 'post',
        data: {'concernTypeId': concernTypeId}
    }).done(function () {
        actionSuccess();
        //$('#concernTypeText' + concernTypeId).css('color', 'green');
        $('#concernTypes').children().css('pointerEvents', 'auto');
    }).fail(function () {
        //$('#concernTypeText' + concernTypeId).css('color', 'red');
        $('#concernTypes').children().css('pointerEvents', 'auto');
        actionFail();
    })
}

function toggleAnamnesisQuestionnaireTypes(anamnesisQuestionnaireTypeId) {
    $('#anamnesisQuestionnaireTypes').children().css('pointerEvents', 'none');

    $.ajax({
        url: '/' + appLocation + 'toggleAnamnesisQuestionnaireTypes',
        context: document.body,
        type: 'post',
        data: {'anamnesisQuestionnaireTypeId': anamnesisQuestionnaireTypeId}
    }).done(function () {
        actionSuccess();
        //$('#anamnesisQuestionnaireTypeText' + anamnesisQuestionnaireTypeId).css('color', 'green');
        $('#anamnesisQuestionnaireTypes').children().css('pointerEvents', 'auto');
    }).fail(function () {
        //$('#anamnesisQuestionnaireTypeText' + anamnesisQuestionnaireTypeId).css('color', 'red');
        $('#anamnesisQuestionnaireTypes').children().css('pointerEvents', 'auto');
        actionFail();
    })
}

function toggleGenericDocumentTypes(genericDocumentTypeId) {
    $('#genericDocumentTypes').children().css('pointerEvents', 'none');

    $.ajax({
        url: '/' + appLocation + 'toggleGenericDocumentTypes',
        context: document.body,
        type: 'post',
        data: {'genericDocumentTypeId': genericDocumentTypeId}
    }).done(function () {
        actionSuccess();
        $('#genericDocumentTypes').children().css('pointerEvents', 'auto');
    }).fail(function () {
        $('#genericDocumentTypes').children().css('pointerEvents', 'auto');
        actionFail();
    })
}

function toggleDoctorsReimbursementItems(reimbursementItemId) {

    $.ajax({
        url: '/' + appLocation + 'toggleDoctorsReimbursementItems',
        context: document.body,
        type: 'post',
        data: {'reimbursementItemId': reimbursementItemId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function toggleDoctorsReimbursementContracts(reimbursementContractId) {
    $('#doctorsConcernTypes').children().css('pointerEvents', 'none');

    $.ajax({
        url: '/' + appLocation + 'toggleDoctorsReimbursementContracts',
        context: document.body,
        type: 'post',
        data: {'reimbursementContractId': reimbursementContractId}
    }).done(function () {
        actionSuccess();
        //$('#reimbursementContractText' + reimbursementContractId).css('color', 'green');
        $('#doctorsReimbursementContracts').children().css('pointerEvents', 'auto');
    }).fail(function () {
        //$('#reimbursementContractText' + reimbursementContractId).css('color', 'red');
        $('#doctorsReimbursementContracts').children().css('pointerEvents', 'auto');
        actionFail();
    })
}

function registerNewPatientForDoctorFormSubmitButton() {
    $('#newPatientFormForDoctor').submit(function () {
        //prevent double submitting
        var submitButtonId = 'newPatientForDoctorSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-newpatient-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-newpatient-form').show('fast');
            showAjaxResponse(response, 'newpatient');
            if (response.status == 'SUCCESS') {
                router.navigate(appLocation + 'PatientView/' + response.result, {trigger: true});
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-newpatient-form').show('fast');
            showAjaxError(error, 'newpatient');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function registerNewPatientViaConnectIdFormSubmitButton() {
    $('#newPatientViaConnectIdForm').submit(function () {
        //prevent double submitting
        var submitButtonId = 'newPatientViaConnectIdSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-newPatientViaConnectId-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-newPatientViaConnectId-form').show('fast');
            showAjaxResponse(response, 'newPatientViaConnectId');
            if (response.status == 'SUCCESS') {
                reloadCurrentContent();
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-newPatientViaConnectId-form').show('fast');
            showAjaxError(error, 'newPatientViaConnectId');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function registerNewReferrerFormSubmitButton() {
    $('.newReferrerForm').submit(function () {
        //prevent double submitting
        var submitButtonId = 'newReferrerSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-newreferrer-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-newreferrer-form').show('fast');
            showAjaxResponse(response, 'newreferrer');

            if (response.status == 'SUCCESS') {
                if ($('#UpdateReferrerModal'))
                    $('#UpdateReferrerModal').modal('hide');
                reloadCurrentContent();
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-newreferrer-form').show('fast');
            showAjaxError(error, 'newreferrer');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function connectWithPatient(patientId, patientName, accountId, accountName) {
    var message;
    if (!accountName)
        message = 'Sind Sie sicher, dass Sie ' + patientName + ' zu Ihren Patienten hinzufügen möchten?';
    else
        message = 'Sind Sie sicher, dass Sie ' + patientName + ' zu den Patienten von ' + accountName + ' hinzufügen möchten?';

    customConfirm('Bestätigen', message,
        function () {
            $.ajax({
                url: '/' + appLocation + 'connectWithPatient',
                context: document.body,
                type: 'post',
                data: {'patientId': patientId, 'accountId': accountId}
            }).done(function () {
                actionSuccess();
                reloadCurrentContent();
            }).fail(function () {
                actionFail();
            })
        }
    );
}

$.expr[':'].contains = $.expr.createPseudo(function (arg) {
    return function (elem) {
        return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});

function attachFilter() {
    var listItems = $('.data-tr');
    $('#filter').on('input', function () {
        var filter = $(this).val();
        var attribute = 'data-mao';
        if (filter) {
            listItems.hide();
            $('tr:contains(' + filter + ')').show();
        } else {
            listItems.show();
        }
    });
}

function attachTableFilter(headerId, attribute) {
    var listItems = $('.data-tr');

    $('#' + headerId).on('click', function () {
        var filtered = $('.data-tr').hasClass('filtered');
        if (filtered) {
            listItems.show();
            $('.data-tr').removeClass('filtered');
        } else {
            listItems.hide();
            $('.data-tr[data-' + attribute + '=true]').show();
            $('.data-tr').addClass('filtered');
        }
    });
}

function attachTableFilterCheckbox(headerId, attribute) {
    var listItems = $('.data-tr');
    $('#' + headerId).on('click', function () {
        var filtered = $(this).is(':checked');
        if (filtered) {
            listItems.hide();
            $('.data-tr[data-' + attribute + '=true]').show();
        } else {
            listItems.show();
        }
    });
}

function inviteAllPatients() {
    $('#inviteAllPatientsButton').prop('disabled', true);

    customConfirm('Bitte bestätigen',
        'Sind Sie sicher, dass Sie alle Patientinnen und Patienten, bei denen eine E-Mail-Adresse oder eine Telefonnummer vorhanden ist, ' +
        'zu MeinArztOnline einladen möchten? <br/><br/> Wenn eine E-Mail-Adresse vorhanden ist, erfolgt eine Einladung per E-Mail, ansonsten per SMS. ' +
        'Beim Versand von SMS entstehen Kosten von 10 € / 100 SMS. Der Versand von E-Mails ist kostenlos.',
        function () {
            $.ajax({
                url: '/' + appLocation + 'registerAllPatientsForDoctor',
                context: document.body,
                type: 'post'
            }).done(function (response) {
                actionSuccess();
                alert('Einladungen versendet: ' + response.result);
                $('#inviteAllPatientsButton').prop('disabled', false);
            }).fail(function () {
                actionFail();
                $('#inviteAllPatientsButton').prop('disabled', false);
            })
        },
        function () {
            $('#inviteAllPatientsButton').prop('disabled', false);
        }
    );
}

function registerMessageToAllPatientsFormSubmitButton() {
    $('#messageToAllPatientsForm').submit(function (e) {

        var text = $.trim($('#messageText').val());
        if (text.length > 0) {
            //disable button to prevent double submitting
            $('#messageToAllPatientsFormSubmitButton').prop('disabled', true);
            $('#messageToAllPatientsFormSubmitButton').prop('value', 'Bitte warten ...');

            $('.error-msg').remove();
            $('#error-msg-messageToAllPatients-form').hide();
            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                if (response.status == 'SUCCESS')
                    alert('Nachrichten versendet: ' + response.result);
                else
                    showAjaxError(response.status, 'messageToAllPatients');
            }).fail(function (error) {
                showAjaxError(response.status, 'messageToAllPatients');
            });
            //clear textarea to prevent double submitting
            $('#messageText').val('');
            //enable button to prevent double submitting
            $('#messageToAllPatientsFormSubmitButton').prop('disabled', false);
            $('#messageToAllPatientsFormSubmitButton').prop('value', 'Senden');
            $('#error-msg-messageToAllPatients-form').show('fast');
        }

        return false;
    });
}

function addReferrer(referrerId, referrerName, providerId, providerName) {
    var message;
    if (!providerName)
        message = 'Sind Sie sicher, dass Sie ' + referrerName + ' zu Ihren Zuweisern hinzufügen möchten?';
    else
        message = 'Sind Sie sicher, dass Sie ' + referrerName + ' zu den Zuweisern von ' + providerName + ' hinzufügen möchten?';

    message = message + '<br/><br/> Bitte stellen Sie sicher, dass diese EmpfängerIn auch Befunde über docsy von Ihnen erhalten möchte. Der Empfang von Befunden ist selbstverständlich kostenlos.';

    customConfirm('Bestätigen', message,
        function () {
            $.ajax({
                url: '/' + appLocation + 'addReferrer',
                context: document.body,
                type: 'post',
                data: {'providerId': providerId, 'referrerId': referrerId}
            }).done(function () {
                actionSuccess();

                if (currentContent == 'ReferrerModule')
                    reloadCurrentContent();
                else
                    router.navigate(appLocation + 'ReferrerModule', {trigger: true});
            }).fail(function () {
                actionFail();
            })
        }
    );
}


// not technically belonging to treating doctor but highly coupled to above
function updateReferrer(referrerId) {
    $('#UpdateReferrerModalReplace').load('/' + appLocation + 'getUpdateReferrerModal/' + referrerId, function () {
        $('#UpdateReferrerModal').modal({backdrop: 'static', keyboard: false});
    });
}

// not technically belonging to treating doctor but highly coupled to above
function inviteReferrerPerEmail(referrerId) {
    $('#InviteReferrerPerEmailModalReplace').load('/' + appLocation + 'getInviteReferrerPerEmailModalReplace/' + referrerId, function () {
        $('#InviteReferrerPerEmailModal').modal({backdrop: 'static', keyboard: false});

        //init button
        $('#inviteReferrerPerEmailForm').submit(function (e) {
            //prevent double submitting
            var oldButtonText = $('#inviteReferrerPerEmailSubmitButton').html();
            $('#inviteReferrerPerEmailSubmitButton').prop('disabled', true);
            $('#inviteReferrerPerEmailSubmitButton').html('Bitte warten ...');

            //remove old error messages after resubmit
            $('.error-msg').remove();
            $('#error-msg-inviteReferrerPerEmailForm-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                $('#error-msg-inviteReferrerPerEmailForm-form').show('fast');
                showAjaxResponse(response, 'inviteReferrerPerEmailForm', null, 'Die Einladung wurde erfolgreich gesendet.');

                if (response.status == 'SUCCESS') {
                    $('#InviteReferrerPerEmailModal').modal('hide');
                    reloadCurrentContent();
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            }).fail(function (error) {
                $('#error-msg-inviteReferrerPerEmailForm-form').show('fast');
                showAjaxError(error, 'inviteReferrerPerEmailForm');
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
            return false;
        });
    });
}


//resend email token to user with ID id
function resendEmailConfirmationToken(username, personId) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie das Token zur Bestätigung der E-Mail-Adresse an: ' + username + ' erneut senden möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'resendEmailConfirmationToken',
            context: document.body,
            type: 'post',
            data: {'personId': personId}
        }).done(function () {
            actionSuccess();
            reloadCurrentContent();
        }).fail(function () {
            actionFail('E-Mail konnte nicht gesendet werden!');
        })
    });
}

/**
 *
 * */

function registerPatientRecordFormSubmitButton(patientId) {
    $('#newPatientRecordForm').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'patientRecordCommitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-patientRecord-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-patientRecord-form').show('fast');
            showAjaxResponse(response, 'patientRecord');
            if (response.status == 'SUCCESS') {
                instantCloseDialogs();
                //clear field for next input
                $('#patientRecordText').summernote('reset');

                //default selected option is Ordination
                $('#patientRecordType').val('Ordination');
                //hide error message
                $('#error-msg-patientRecord-form').hide();
                loadById('PatientRecord', patientId);
                refreshPatientViewNewsfeedWidgetIfExists(patientId);
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                setFormHasChanges(document.getElementById('newPatientRecordForm'), false);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-patientRecord-form').show('fast');
            showAjaxError(error, 'patientRecord');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}


//load and show the modal to create
function savePatientRecord(patientRecordId, patientId, requirementId, input) {
    if (!patientRecordId) {
        patientRecordId = -1;
    }

    $('#PatientRecordModalReplace').load('/' + appLocation + 'getPatientRecordModal/' + patientRecordId + '/' + patientId, function () {
        $('#PatientRecordModal').modal({backdrop: 'static', keyboard: false});
        $('#patientRecordForm #patientId').attr('value', patientId);
        registerPatientRecordModalSubmitButton(patientId, undefined, requirementId, input);
    });
}

//what happens when user clicks on create
function registerPatientRecordModalSubmitButton(patientId, tableId, requirementId, input) {
    $('#patientRecordForm').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'patientRecordModalSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-patientRecordModal-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-patientRecordModal-form').show('fast');
            showAjaxResponse(response, 'patientRecordModal');

            if (response.status == 'SUCCESS') {
                $('#PatientRecordsModal').modal('hide');
                $('#PatientRecordModal').modal('hide');
                refreshPatientRecordsForPatient(patientId, tableId, requirementId, input);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-patientRecordModal-form').show('fast');
            showAjaxError(error, 'patientRecordModal');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function registerPatientDataFormSubmitButton() {
    $('#updatePatientDataForm').submit(function () {
        //disable button to prevent double submitting
        $('.updatePatientDataSubmitButton').prop('disabled', true);
        $('.updatePatientDataSubmitButton').html('Bitte warten ...');

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-patientdata-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-patientdata-form').show('fast');
            showAjaxResponse(response, 'patientdata');
            if (response.status == 'SUCCESS') {
                actionSuccess();
            } else {
                actionFail();
            }
            //enable button if done and failure
            $('.updatePatientDataSubmitButton').prop('disabled', false);
            $('.updatePatientDataSubmitButton').html('Speichern');
        }).fail(function (error) {
            actionFail();
            $('#error-msg-patientdata-form').show('fast');
            showAjaxError(error, 'newpatient');

            //enable button after fail
            $('.updatePatientDataSubmitButton').prop('disabled', false);
            $('.updatePatientDataSubmitButton').html('Speichern');
        });
        return false;
    });
}

function updatePatientNotes(treatingDoctorId, patientNotes, patientId) {
    $.ajax({
        url: '/' + appLocation + 'updatePatientNotes',
        context: document.body,
        type: 'post',
        data: {'treatingDoctorId': treatingDoctorId, 'patientNotes': patientNotes}
    }).done(function () {
        actionSuccess();
        reloadPatientNotesWidget(patientId);
        reloadPatientNotesTab(patientId);
    }).fail(function () {
        actionFail();
    })
}

function reloadPatientNotesWidget(patientId) {
    if ($('#patientViewDashboardpatientNotes'))
        loadWidgetToDashboard('patientViewDashboard', 'patientNotes', patientId);
}

function reloadPatientNotesTab(patientId) {
    loadById('PatientViewNotes', patientId);
}

function updateReferringDoctor(treatingDoctorId) {
    var referringDoctor = $('#referringDoctor' + treatingDoctorId).val();
    $.ajax({
        url: '/' + appLocation + 'updateReferringDoctor',
        context: document.body,
        type: 'post',
        data: {'treatingDoctorId': treatingDoctorId, 'referringDoctor': referringDoctor}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('referringDoctor' + treatingDoctorId);
    }).fail(function () {
        actionFail();
    })
}

function acceptPermission(thisButton, dataEntryId, dataEntryType) {
    customConfirm('Bestätigen', 'Dieser Eintrag wurde nicht von Ihnen erstellt. Sind Sie sicher, dass Sie diesen Eintrag annehmen möchten?',
        function () {
            $.ajax({
                url: '/' + appLocation + 'acceptPermission',
                context: document.body,
                type: 'post',
                data: {'dataEntryId': dataEntryId, 'dataEntryType': dataEntryType}
            }).done(function () {
                actionSuccess();
                var button = $(thisButton);
                button.prop('disabled', true);
                button.prop('title', 'Angenommen');
                var icon = button.children('span.glyphicon');
                icon.removeClass('glyphicon-question-sign not-accepted');
                icon.addClass('glyphicon-ok-sign accepted');
            }).fail(function () {
                actionFail();
            })
        }
    );
}

function openPatientViewNewPatientRecord() {
    if (window.location.href.indexOf('#patientViewNewPatientRecord') !== -1) {
        openDialog('PatientRecord');
        focusOnFirstInputInForm('newPatientRecordForm');
    }
}

function refreshPatientViewNewsfeedWidgetIfExists(patientId) {
    $('#commandLineInput').focus();
    if ($('#patientViewDashboardnewsfeed'))
        loadWidgetToDashboard('patientViewDashboard', 'newsfeed', patientId);
}

//reload drugs in patientViewDashboard widget
function refreshPatientViewDrugListIfExists(patientId) {
    if ($('#patientViewDashboarddrugsList'))
        loadWidgetToDashboard('patientViewDashboard', 'drugsList', patientId);
}

//reload appointment widgets in patientViewDashboard widget
function refreshPatientViewAppointmentWidgetsIfExists(patientId) {
    if ($('#patientViewDashboardlast3Appointments'))
        loadWidgetToDashboard('patientViewDashboard', 'last3Appointments', patientId);
}


function refreshPatientRecordsForPatient(patientId, tableId, requirementId, input) {
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);

    if ($('.PatientRecordBlock')) {
        // function load(text, callback, noScroll, tableId, selectionForRequirementWithId, questionnaireObjectId, questionnaireObjectClassName, input) {
        // load('PatientRecord', undefined, false, undefined, requirementId, undefined, undefined, input);
        loadById('PatientRecord', patientId, undefined, undefined, undefined, requirementId, undefined, undefined, input);
    }

    if ($('.PatientRecordBlock')) {
        // function load(text, callback, noScroll, tableId, selectionForRequirementWithId, questionnaireObjectId, questionnaireObjectClassName, input) {
        // load('PatientRecord', undefined, false, undefined, requirementId, undefined, undefined, input);
        loadById('PatientRecords', patientId, undefined, undefined, tableId, requirementId, undefined, undefined, input);
    }


}

function refreshDocumentsForPatient(patientId) {
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload drugs in phr tab
    if ($('.PatientViewDocumentsBlock'))
        loadById('PatientViewDocuments', patientId);
}

//refresh bills for patient if patientId is available - otherwise refresh all bills
function refreshBillsForPatient(patientId) {
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload documents in documents tab
    if ($('.PatientViewDocumentsBlock'))
        loadById('PatientViewDocuments', patientId);
    //reload bills in bills module
    if ($('.BillsBlock')) {
        filterBills();
    }
}

function refreshAppointmentsForPatient(patientId) {
    refreshPatientViewAppointmentWidgetsIfExists(patientId);
    if ($('.PatientViewAppointmentsBlock'))
        loadById('PatientViewAppointments', patientId);
}


function refreshDrugsForPatient(patientId, tableId, input, requirementId) {
    //reload drugs in patientViewDashboard widget
    refreshPatientViewDrugListIfExists(patientId);
    //reload documents if prescriptions got created
    refreshDocumentsForPatient(patientId);
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload drugs in phr tab
    if ($('.DrugsBlock')) {
        if (tableId) {
            loadById('Drugs', patientId, null, false, tableId, requirementId, undefined, undefined, input);
        } else {
            loadById('Drugs', patientId, null, false, 'DrugsTableForDoctor', requirementId, undefined, undefined, input);
        }
    }
}

function createNewDrugForPatient(patientId) {
    $('#DrugsModalReplace').load('/' + appLocation + 'getDrugsModal/0/' + patientId, function () {
        $('#DrugsModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Drug in Modal
        initializeDrugForm(
            function () {
                refreshDrugsForPatient(patientId);
            },
            function (response) {
                refreshDrugsForPatient(patientId);

                // response.result = helper class CreatedDocumentResponse
                // document was created with signature block --> sign it
                if (response.result.withSignaturePlaceholder) {
                    loadDocumentToSign(response.result.documentId,
                        //Callback what should be done when document is signed (finish button clicked)
                        function () {
                            customConfirm("Erfolg", "Das Dokument wurde erfolgreich signiert. Möchten Sie es jetzt versenden?",
                                //confirm
                                function () {
                                    // load documents to get necessary new created data (glyphicon and correct link)
                                    loadById("Documents", response.result.patientId, function () {
                                        router.navigate(appLocation + 'SendDocument/' + response.result.documentId + '/' + response.result.documentName
                                            + '/' + response.result.createdAt + '/' + response.result.patientId, {trigger: true});
                                    });
                                },
                                //cancel
                                function () {
                                    maybePrint('printPrescriptionNow', response.result.createdDocumentUrl);
                                    refreshDocumentsForPatient(patientId);
                                }
                            );
                        },
                        //callback when user canceled action
                        function () {
                            maybePrint('printPrescriptionNow', response.result.createdDocumentUrl);
                            refreshDocumentsForPatient(patientId);
                        }
                    );
                } else {
                    maybePrint('printPrescriptionNow', response.result.createdDocumentUrl);
                    refreshDocumentsForPatient(patientId);
                }
            }
        );
    });
}


function discontinueDrug(drugId, patientId) {
    customConfirm(
        'Bitte bestätigen',
        'Sind Sie sicher, dass Sie dieses Medikament absetzen möchten?',
        function () {

            $.ajax({
                url: '/' + appLocation + 'discontinueDrug',
                context: document.body,
                type: 'post',
                data: {drugId: drugId},
            })
                .done(function () {
                    actionSuccess();
                    refreshDrugsForPatient(patientId);
                })
                .fail(function () {
                    actionFail('Das Medikament konnte nicht abgesetzt werden.');
                });
        }
    );
}

function createNewAllergyForPatient(patientId, createNext = false) {
    $('#AllergiesModalReplace').load('/' + appLocation + 'getAllergiesModal/0/' + patientId, function () {
        document.getElementById("allergiesForm-createNext").checked = createNext;
        $('#AllergiesModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Drug in Modal
        initializeAllergyFormForDoctor(
            patientId,
            function () {
                refreshAllergiesForPatient(patientId);
            }
        );
    });
}

function initializeAllergyFormForDoctor(patientId, successCallback) {
    $("#allergyName").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            $('#newallergymodal').submit();
            return false;
        }
    });
    //Submit new Allergy in Modal
    $('#newallergymodal').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'allergyModalSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-allergy-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-allergy-form').show('fast');
            showAjaxResponse(response, 'allergy');
            if (response.status == 'SUCCESS') {
                $('#AllergiesModal').modal('hide');

                refreshAllergiesForPatient(patientId);
                successCallback();

            } else if (response.status == "SUCCESS-CREATENEXT") {
                $('#AllergiesModal').modal('hide');

                refreshAllergiesForPatient(patientId);
                successCallback();

                createNewAllergyForPatient(patientId, true);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-allergy-form').show('fast');
            showAjaxError(error, 'allergy');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function refreshAllergiesForPatient(patientId, tableId, requirementId, input) {
    //reload drugs in patientViewDashboard widget
    refreshPatientViewAllergiesListIfExists(patientId);
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload drugs in phr tab
    if ($('.AllergiesBlock')) {
        if (tableId) {
            loadById('Allergies', patientId, null, false, tableId, requirementId, undefined, undefined, input);
        } else {
            loadById('Allergies', patientId, null, false, 'AllergiesTableForDoctor', requirementId, undefined, undefined, input);
        }
    }
}

//reload diagnoses in patientViewDashboard widget
function refreshPatientViewAllergiesListIfExists(patientId) {
    if ($('#patientViewDashboardallergiesList'))
        loadWidgetToDashboard('patientViewDashboard', 'allergiesList', patientId);
}


function createNewVaccinationForPatient(patientId, createNext = false) {
    $('#VaccinationsModalReplace').load('/' + appLocation + 'getVaccinationsModal/0/' + patientId, function () {
        document.getElementById("vaccinationForm-createNext").checked = createNext;
        $('#VaccinationsModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Drug in Modal
        initializeVaccinationFormForDoctor(
            patientId,
            function () {
                refreshVaccinationsForPatient(patientId);
            }
        );
    });
}

function initializeVaccinationFormForDoctor(patientId, successCallback) {
    //Submit new Vaccination in Modal
    $('#newvaccinationmodal').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'vaccinationModalSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-vaccination-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-vaccination-form').show('fast');
            showAjaxResponse(response, 'vaccination');
            if (response.status == 'SUCCESS') {
                $('#VaccinationsModal').modal('hide');

                refreshVaccinationsForPatient(patientId);
                successCallback();
            } else if (response.status == "SUCCESS-CREATENEXT") {
                $('#VaccinationsModal').modal('hide');

                refreshVaccinationsForPatient(patientId);
                successCallback();
                createNewVaccinationForPatient(patientId, true);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-vaccination-form').show('fast');
            showAjaxError(error, 'vaccination');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });

        return false;
    });
}

function refreshVaccinationsForPatient(patientId, tableId, requirementId, input) {
    //reload vaccinations in patientViewDashboard widget
    refreshPatientViewVaccinationsListIfExists(patientId);
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload drugs in phr tab
    if ($('.VaccinationsBlock')) {
        if (tableId) {
            loadById('Vaccinations', patientId, null, false, tableId, requirementId, undefined, undefined, input);
        } else {
            loadById('Vaccinations', patientId, null, false, 'VaccinationsTableForDoctor', requirementId, undefined, undefined, input);
        }
    }
}

//reload diagnoses in patientViewDashboard widget
function refreshPatientViewVaccinationsListIfExists(patientId) {
    if ($('#patientViewDashboardvaccinationsList'))
        loadWidgetToDashboard('patientViewDashboard', 'vaccinationsList', patientId);
}

function createNewMedicalAidForPatient(patientId, createNext = false) {
    $('#MedicalAidsModalReplace').load('/' + appLocation + 'getMedicalAidsModal/0/' + patientId, function () {
        document.getElementById("medicalAidsForm-createNext").checked = createNext;
        $('#MedicalAidsModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Drug in Modal
        initializeMedicalAidFormForDoctor(
            patientId,
            function () {
                refreshMedicalAidsForPatient(patientId);
            }
        );
    });
}

function initializeMedicalAidFormForDoctor(patientId, successCallback) {
    $("#medicalAidName").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            $('#newmedicalAidmodal').submit();
            return false;
        }
    });
    //Submit new MedicalAid in Modal
    $('#newmedicalAidmodal').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'medicalAidModalSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-medicalAid-form').hide();
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-medicalAid-form').show('fast');
            showAjaxResponse(response, 'medicalAid');
            if (response.status == 'SUCCESS') {
                $('#MedicalAidsModal').modal('hide');

                refreshMedicalAidsForPatient(patientId);
                successCallback();
            } else if (response.status == "SUCCESS-CREATENEXT") {
                $('#MedicalAidsModal').modal('hide');

                refreshMedicalAidsForPatient(patientId);
                successCallback();
                createNewMedicalAidForPatient(patientId, true);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-medicalAid-form').show('fast');
            showAjaxError(error, 'medicalAid');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function refreshMedicalAidsForPatient(patientId, tableId, requirementId, input) {
    //reload drugs in patientViewDashboard widget
    refreshPatientViewMedicalAidsListIfExists(patientId);
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload drugs in phr tab
    if ($('.MedicalAidsBlock')) {
        if (tableId) {
            loadById('MedicalAids', patientId, null, false, tableId, requirementId, undefined, undefined, input);
        } else {
            loadById('MedicalAids', patientId, null, false, 'MedicalAidsTableForDoctor', requirementId, undefined, undefined, input);
        }
    }
}

//reload medialAids in patientViewDashboard widget
function refreshPatientViewMedicalAidsListIfExists(patientId) {
    if ($('#patientViewDashboardmedicalAidsList'))
        loadWidgetToDashboard('patientViewDashboard', 'medicalAidsList', patientId);
}

function createNewTherapyForPatient(patientId, createNext = false) {
    $('#TherapiesModalReplace').load('/' + appLocation + 'getTherapiesModal/0/' + patientId, function () {
        document.getElementById("therapiesForm-createNext").checked = createNext;
        $('#TherapiesModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Drug in ModalF
        initializeTherapyFormForDoctor(
            patientId,
            function () {
                refreshTherapiesForPatient(patientId);
            }
        );
    });
}

function initializeTherapyFormForDoctor(patientId, successCallback) {
    $("#therapyName").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            $('#newtherapymodal').submit();
            return false;
        }
    });
    //Submit new Therapy in Modal
    $('#newtherapymodal').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'therapyModalSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-therapy-form').hide();
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-therapy-form').show('fast');
            showAjaxResponse(response, 'therapy');
            if (response.status == 'SUCCESS') {
                $('#TherapiesModal').modal('hide');

                refreshTherapiesForPatient(patientId);
                successCallback();
            } else if (response.status == "SUCCESS-CREATENEXT") {
                $('#TherapiesModal').modal('hide');

                refreshTherapiesForPatient(patientId);
                successCallback();
                createNewTherapyForPatient(patientId, true);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-therapy-form').show('fast');
            showAjaxError(error, 'therapy');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function refreshTherapiesForPatient(patientId, tableId, requirementId, input) {
    //reload therapies in patientViewDashboard widget
    refreshPatientViewTherapiesListIfExists(patientId);
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload therapies in phr tab
    if ($('.TherapiesBlock')) {
        if (tableId) {
            loadById('Therapies', patientId, null, false, tableId, requirementId, undefined, undefined, input);
        } else {
            loadById('Therapies', patientId, null, false, 'TherapiesTableForDoctor', requirementId, undefined, undefined, input);
        }
    }
}

//reload therapies in patientViewDashboard widget
function refreshPatientViewTherapiesListIfExists(patientId) {
    if ($('#patientViewDashboardtherapiesList'))
        loadWidgetToDashboard('patientViewDashboard', 'therapiesList', patientId);
}

function createNewDietarySupplementForPatient(patientId, createNext = false) {
    $('#DietarySupplementsModalReplace').load('/' + appLocation + 'getDietarySupplementsModal/0/' + patientId, function () {
        document.getElementById("supplementsForm-createNext").checked = createNext;
        $('#DietarySupplementsModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Drug in Modal
        initializeDietarySupplementFormForDoctor(
            patientId,
            function () {
                refreshDietarySupplementsForPatient(patientId);
            }
        );
    });
}

function initializeDietarySupplementFormForDoctor(patientId, successCallback) {
    $("#dietarySupplementName").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            $('#newdietarySupplementmodal').submit();
            return false;
        }
    });
    //Submit new DietarySupplement in Modal
    $('#newdietarySupplementmodal').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'dietarySupplementModalSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-dietarySupplement-form').hide();
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-dietarySupplement-form').show('fast');
            showAjaxResponse(response, 'dietarySupplement');
            if (response.status == 'SUCCESS') {
                $('#DietarySupplementsModal').modal('hide');

                refreshDietarySupplementsForPatient(patientId);
                successCallback();
            } else if (response.status == "SUCCESS-CREATENEXT") {
                $('#DietarySupplementsModal').modal('hide');

                refreshDietarySupplementsForPatient(patientId);
                successCallback();
                createNewDietarySupplementForPatient(patientId, true);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-dietarySupplement-form').show('fast');
            showAjaxError(error, 'dietarySupplement');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function refreshDietarySupplementsForPatient(patientId, tableId, requirementId, input) {
    //reload drugs in patientViewDashboard widget
    refreshPatientViewDietarySupplementsListIfExists(patientId);
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload drugs in phr tab
    if ($('.DietarySupplementsBlock')) {
        if (tableId) {
            loadById('DietarySupplements', patientId, null, false, tableId, requirementId, undefined, undefined, input);
        } else {
            loadById('DietarySupplements', patientId, null, false, 'DietarySupplementsTableForDoctor', requirementId, undefined, undefined, input);
        }
    }
}

//reload diagnoses in patientViewDashboard widget
function refreshPatientViewDietarySupplementsListIfExists(patientId) {
    if ($('#patientViewDashboarddietarySupplementsList'))
        loadWidgetToDashboard('patientViewDashboard', 'dietarySupplementsList', patientId);
}

function createNewDiagnosisForPatient(patientId, createNext = false) {
    $('#DiagnosesModalReplace').load('/' + appLocation + 'getDiagnosesModal/0/' + patientId, function () {
        document.getElementById("diagnosesForm-createNext").checked = createNext;
        $('#DiagnosesModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Drug in Modal
        initializeDiagnosisFormForDoctor(
            patientId,
            function () {
                refreshDiagnosesForPatient(patientId);
            }
        );
    });
}

function initializeDiagnosisFormForDoctor(patientId, successCallback) {
    $("#diagnosisName").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            $('#newdiagnosismodal').submit();
            return false;
        }
    });

    //Submit new Diagnosis in Modal
    $('#newdiagnosismodal').submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'diagnosisModalSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-diagnosis-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-diagnosis-form').show('fast');
            showAjaxResponse(response, 'diagnosis');

            if (response.status == 'SUCCESS') {
                $('#DiagnosesModal').modal('hide');

                refreshDiagnosesForPatient(patientId);
                successCallback();
            } else if (response.status == "SUCCESS-CREATENEXT") {
                $('#DiagnosesModal').modal('hide');
                refreshDiagnosesForPatient(patientId);
                successCallback();
                createNewDiagnosisForPatient(patientId, true);
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-diagnosis-form').show('fast');
            showAjaxError(error, 'diagnosis');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function refreshDiagnosesForPatient(patientId, tableId, requirementId, input) {
    //reload drugs in patientViewDashboard widget
    refreshPatientViewDiagnosesListIfExists(patientId);
    //reload summary in patientViewDashboard widget
    refreshPatientViewNewsfeedWidgetIfExists(patientId);
    //reload drugs in phr tab
    if ($('.DiagnosesBlock')) {
        if (tableId) {
            loadById('Diagnoses', patientId, null, false, tableId, requirementId, undefined, undefined, input);
        } else {
            loadById('Diagnoses', patientId, null, false, 'DiagnosesTableForDoctor', requirementId, undefined, undefined, input);
        }
    }
}

//reload diagnoses in patientViewDashboard widget
function refreshPatientViewDiagnosesListIfExists(patientId) {
    if ($('#patientViewDashboarddiagnosesList'))
        loadWidgetToDashboard('patientViewDashboard', 'diagnosesList', patientId);
}

//resend email token to user with ID id
function resendPatientInvitationMailAsDoctor(username, id) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie das Einladungs-Mail-Token an: ' + username + ' erneut senden möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'resendPatientInvitationMailAsDoctor',
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail('E-Mail konnte nicht gesendet werden!');
        })
    });
}


/*
 *
 * PregnancyCalculator/Integrations CRUD
 *
 */

function createPregnancyCalculator(patientId) {
    $.ajax({
        url: '/' + appLocation + 'createPregnancyCalculator',
        context: document.body,
        type: 'post',
        data: {'patientId': patientId}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            router.navigate(appLocation + 'PatientViewPregnancyCalculator/' + patientId, {trigger: true});
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

/* default method to set a pregnancyCalculator setting */
function setPregnancyCalculatorSetting(pregnancyCalculatorId, setting, value) {
    if ($('#error-msg-pregnancyCalculator' + pregnancyCalculatorId + '-' + setting).text()) {
        $('#error-msg-pregnancyCalculator' + pregnancyCalculatorId + '-' + setting).html('');
    }
    $.ajax({
        url: '/' + appLocation + 'setPregnancyCalculatorSetting',
        context: document.body,
        type: 'post',
        data: {'pregnancyCalculatorId': pregnancyCalculatorId, 'setting': setting, 'value': value}
    }).done(function () {
        location.reload();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-pregnancyCalculator' + pregnancyCalculatorId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('pregnancyCalculator' + pregnancyCalculatorId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

/*
 *
 * MonitoringSettings/Integrations CRUD
 *
 */

function createMonitoringSettings(patientId) {
    $.ajax({
        url: '/' + appLocation + 'createMonitoringSettings',
        context: document.body,
        type: 'post',
        data: {'patientId': patientId}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            reloadCurrentContent();
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

/* default method to set a monitoringSettings setting */
function setMonitoringSettingsSetting(monitoringSettingsId, setting, value, successCallback) {
    if ($('#error-msg-monitoringSettings' + monitoringSettingsId + '-' + setting).text()) {
        $('#error-msg-monitoringSettings' + monitoringSettingsId + '-' + setting).html('');
    }
    $.ajax({
        url: '/' + appLocation + 'setMonitoringSettingsSetting',
        context: document.body,
        type: 'post',
        data: {'monitoringSettingsId': monitoringSettingsId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === 'function')
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-monitoringSettings' + monitoringSettingsId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('monitoringSettings' + monitoringSettingsId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

function addNewMonitoringIntegrationToMonitoringSettings(monitoringSettingsId) {
    $.ajax({
        url: '/' + appLocation + 'addNewMonitoringIntegrationToMonitoringSettings',
        context: document.body,
        type: 'post',
        data: {'monitoringSettingsId': monitoringSettingsId}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            reloadCurrentContent(null, function () {
                $('.monitoringSettings').show();
            });
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function deleteMonitoringIntegration(monitoringIntegrationId, monitoringSettingsId) {
    $.ajax({
        url: '/' + appLocation + 'deleteMonitoringIntegration',
        context: document.body,
        type: 'post',
        data: {'monitoringIntegrationId': monitoringIntegrationId, 'monitoringSettingsId': monitoringSettingsId}
    }).done(function () {
        actionSuccess();
        reloadCurrentContent();
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}


/* default method to set a monitoringIntegration setting */
function setMonitoringIntegrationSetting(monitoringIntegrationId, setting, value, successCallback) {
    if ($('#error-msg-monitoringIntegration' + monitoringIntegrationId + '-' + setting).text()) {
        $('#error-msg-monitoringIntegration' + monitoringIntegrationId + '-' + setting).html('');
    }
    $.ajax({
        url: '/' + appLocation + 'setMonitoringIntegrationSetting',
        context: document.body,
        type: 'post',
        data: {'monitoringIntegrationId': monitoringIntegrationId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (setting === 'name') {
            $('#monitoringIntegrationLink' + monitoringIntegrationId + ' span').html(value);
        }
        if (typeof successCallback === 'function')
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-monitoringIntegration' + monitoringIntegrationId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('monitoringIntegration' + monitoringIntegrationId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

function initCommandLineInput(patientId, patientEmailAddress, documentStyleInformation, genericDocumentTypes, patientName) {
    $('#commandLineInput').keypress(function (e) {
        var code;
        var goOn = false;

        if (true && e.which == 32) {        //whitespace (enabled)
            goOn = true;
            code = $(this).val();
        } else if (e.which == 13) {           //enter
            goOn = true;
            var inputString = $(this).val();

            var param1;     //optional
            var param2;     //optional

            var numberOfDoubleHashtags = inputString.split('##').length - 1;

            if (numberOfDoubleHashtags == 0) {
                code = inputString;
            }
            if (numberOfDoubleHashtags == 1) {
                code = inputString.substr(0, inputString.indexOf('##'));
                inputString = inputString.substr(inputString.indexOf('##') + 2);
                param1 = inputString;
            }
            if (numberOfDoubleHashtags == 2) {
                code = inputString.substr(0, inputString.indexOf('##'));
                inputString = inputString.substr(inputString.indexOf('##') + 2);
                param1 = inputString.substr(0, inputString.indexOf('##'));
                inputString = inputString.substr(inputString.indexOf('##') + 2);
                param2 = inputString;
            }
        }

        if (goOn) {
            var genericDocumentTypeFound = false;
            var nothingElseFound = false;

            genericDocumentTypes.forEach(function (element) {
                if (code == element.shortCut) {
                    saveGenericDocument(patientId, null, element.id, false);
                    genericDocumentTypeFound = true;
                }
            });

            if (code == 'prot') {
                openDialog('PatientRecord');
            } else if (code == "lei") {
                showNewUnbilledItemsDialog();
            } else if (code == 'hi') {
                if (patientEmailAddress) {
                    sendMessageToPatient(patientId);
                } else {
                    actionFail('Dieser Patient hat keinen Zugriff auf MeinArztOnline oder Sie haben das Modul Medizinische E-Mail nicht aktiviert.');
                    navigateInPatientView(patientId, 'Connect');
                }
            } else if (code == 'hon') {
                saveBill(patientId, undefined, patientName);
            } else if (code == 'med') {
                createNewDrugForPatient(patientId);
            } else if (code == 'dia') {
                createNewDiagnosisForPatient(patientId);
            } else if (code == 'adia') {
                saveWorkingDiagnosis(patientId);
            } else if (code == 'all') {
                createNewAllergyForPatient(patientId);
            } else if (code == 'nah') {
                createNewDietarySupplementForPatient(patientId);
            } else if (code == 'hilf') {
                createNewMedicalAidForPatient(patientId);
            } else if (code == 'the') {
                createNewTherapyForPatient(patientId);
            } else if (code == 'impf') {
                createNewVaccinationForPatient(patientId);
            } else if (code == 'adr' && documentStyleInformation.useAddressForEnvelope) {
                window.open('/' + appLocation + 'createAddressForEnvelope/' + patientId, '_blank');
            } else if (code == 'bef' && documentStyleInformation.useFindingsReport) {
                saveFindingsReport(patientId);
            } else if (code == 'ct' && documentStyleInformation.useCTReferral) {
                saveCTReferral(patientId);
            } else if (code == 'ein' && documentStyleInformation.useHospitalReferral) {
                createNewHospitalReferral(patientId);
            } else if (code == 'entb' && documentStyleInformation.useDueDateForBabyConfirmation) {
                createNewDueDateForBabyConfirmation(patientId);
            } else if (code == 'ins' && documentStyleInformation.useInsulinPlan) {
                saveInsulinPlan(patientId);
            } else if (code == 'mr' && documentStyleInformation.useMRIReferral) {
                saveMRIReferral(patientId);
            } else if (code == 'zeit' && documentStyleInformation.useVisitConfirmation) {
                createNewVisitConfirmation(patientId);
            } else if (code == 'über' && documentStyleInformation.useDoctorReferral) {
                createNewDoctorReferral(patientId);
            } else if (code == 'ver' && documentStyleInformation.useAdjuvantPrescription) {
                saveAdjuvantPrescription(patientId);
            } else {

                nothingElseFound = true;
            }

            if (!genericDocumentTypeFound && nothingElseFound) {
                actionFail('Unbekannter Code in der Konsole eingegeben.');
            } else {
                $('#commandLineInput').val('');
            }
            return false;
        }
    });
}

function getLastPatientContact(patientId) {
    $.ajax({
        url: `/${appLocation}last-contact/${patientId}`,
        context: document.body,
        type: 'PUT',
    }).done((result) => {
        let date = moment(result).format("DD.MM.YYYY | HH:mm:ss")
        $(`#last-patient-contact-${patientId}`).html(date)
    }).fail((result) => {
        actionFail()
    });
}

function updateLastPatientContact(patientId) {
    $.ajax({
        url: `/${appLocation}update-last-contact/${patientId}`,
        context: document.body,
        type: 'PUT',
    }).done((result) => {
        let date = moment(result).format("DD.MM.YYYY | HH:mm:ss")
        $(`#last-patient-contact-${patientId}`).html(date)
    }).fail((result) => {
        actionFail()
    });
}

function updateDiagnosisOrderNum(patientId, diagnosisId, orderNum) {
    $.ajax({
        url: '/' + appLocation + 'updateDiagnosisOrderNum',
        context: document.body,
        type: 'post',
        data: {'diagnosisId': diagnosisId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
        refreshDiagnosesForPatient(patientId);
    }).fail(function () {
        actionFail();
    });
}

/**
 * Formatiert den Wert des übergebenden Elements als IBAN, d.h. stellt sicher, dass auf alle 4 Zeichen ein Space folgt.
 * See: https://stackoverflow.com/questions/17260238/how-to-insert-space-every-4-characters-for-iban-registering
 * @param jQueryElement
 */
function formatIban(jQueryElement) {
    var iban = jQueryElement.val();
    iban = iban.replaceAll(" ", "").replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase();
    jQueryElement.val(iban);
}

function createRecallFromRecallType(recallTypeId, patientId) {
    if (!recallTypeId || !patientId) {
        actionFail();
        return;
    }

    $.ajax({
        url: '/' + appLocation + 'createRecallFromRecallType',
        context: document.body,
        type: 'post',
        data: {'patientId': patientId, 'recallTypeId': recallTypeId}
    }).done(function () {
        actionSuccess();
        loadWidgetToDashboard('patientViewDashboard', 'recallsList', patientId, null, null, null);
    }).fail(function (e) {
        actionFail();
    });
}

function deleteRecallType(recallId, patientId) {
    if (!recallId) {
        actionFail();
        return;
    }

    customConfirm("Bestätigen", "Sind sie sicher, dass sie dieses Recall löschen möchten?", function () {
        $.ajax({
            url: '/' + appLocation + 'api/recall/' + recallId,
            context: document.body,
            type: 'delete',
        }).done(function () {
            actionSuccess();
            loadWidgetToDashboard('patientViewDashboard', 'recallsList', patientId, null, null, null);
        }).fail(function (e) {
            actionFail();
        });
    })

}

function quickCreateGenericDocument(patientId, genericDocumentTypeId, print = false) {
    if (!patientId) {
        actionFail();
        return;
    }

    const theWindow = window.open(
        '/' + appLocation + "quickCreateGenericDocument/" + patientId + "/" + genericDocumentTypeId,
        '_blank'
    );
    openPopUpWindowWithBrowserPermissionCheck(theWindow);
    if (print) crossBrowserPrintWindow(theWindow);

}

function requestAnamnesisViaMediprimeApp(patientId, sendToPatient = true) {
    const successFunction = function () {
        $.ajax({
            url: '/' + appLocation + 'mp/requestAnamnesis',
            context: document.body,
            data: {'patientId': patientId, 'sendToPatient': sendToPatient},
            type: 'post'
        }).done(function (e) {
            if (e.status === "FAIL")
                actionFail(e.result);
            else {
                actionSuccess();
                loadWidgetToDashboard('patientViewDashboard', 'quickAnamnesis', patientId, null, null, null);
                // reloadCurrentContent();
            }
        }).fail(function (e) {
            actionFail();
        })
    };

    if (sendToPatient) {
        customConfirm(
            'Anamnese-Formular über mediprime.app versenden',
            'Sind Sie sicher, dass Sie dieser PatientIn einen Link senden wollen, damit das Anamnese-Formular über die mediprime.app ausgefüllt werden kann?',
            successFunction,
        );
    } else successFunction();
}

function inviteToMediprimeApp(patientId, update) {
    customConfirm(
        update ? "mediprime.app Kontakdaten aktualisieren" : "mediprime.app Einladung senden",
        update ?
            "ACHTUNG! Sollte die E-Mail-Adresse für eine versendete Einladung falsch gewesen sein, so kann diese nicht einfach aktualisiert werden. In dem Fall müssen Sie das erstellte Konto zuerst löschen und anschließend eine neue Einladung versenden. Die Aktualisierung der Kontakdaten bezieht sich vor allem auf eine falsche Mobiltelefonnummer für den Abruf von TANs, sowie natürlich auch für generelle Kontaktdaten (Name, Adresse)." :
            "Sind Sie sicher, dass Sie die PatientIn dazu einladen möchten, ein Konto bei der mediprime.app zu erstellen? Die PatientIn muss dieser Einladung vorher explizit zugestimmt haben. Es ist auch möglich Daten per mediprime.app zu übermitteln, ohne dass die PatientIn ein Konto erstellt.",
        function () {
            $.ajax({
                url: '/' + appLocation + 'mp/invitePatient',
                context: document.body,
                data: {patientId},
                type: 'post'
            }).done(function (e) {
                if (e.status === "FAIL")
                    actionFail(e.result);
                else {
                    actionSuccess();
                    reloadCurrentContent();
                }
            }).fail(function (e) {
                actionFail();
            })
        }
    )
}

function deletePatientOnMediprimeApp(patientId) {
    customConfirm(
        "mediprime.app Konto löschen",
        "ACHTUNG! Die PatientIn muss diesem Vorgang ausdrücklich zustimmen, da alle Daten gelöscht werden, auch die von anderen Praxen gesendeten Daten. Nach der Löschung wird die PatientIn außerdem per E-Mail über die Löschung durch Sie informiert.",
        function () {
            $.ajax({
                url: '/' + appLocation + 'mp/deletePatientOnMediprimeApp',
                context: document.body,
                data: {patientId},
                type: 'post'
            }).done(function (e) {
                if (e.status === "FAIL")
                    actionFail(e.result);
                else {
                    actionSuccess();
                    reloadCurrentContent();
                }
            }).fail(function (e) {
                actionFail();
            })
        }
    )
}

var mediprimeAppPanel;

function openMediprimeAppMessageModal(
    patientId,
    patientName,
    selectedDocumentIds = [],
    messageText = undefined,
    requestConfirmation = false,
) {
    if (mediprimeAppPanel) {
        mediprimeAppPanel.front();
        /* mediprimeAppPanel.unsmallify();
         mediprimeAppPanel.reposition({
             my: "center",
         });*/
        return;
    } else {
        const options = {
            footerToolbar: '<span class="pull-right">mediprime.app</span>',
            headerLogo:
                '<span style="padding:10px" class="glyphicon glyphicon-phone" title="mediprime.app" aria-hidden="true"></span>',
            contentSize: {width: '70%', height: '75%'},
            onwindowresize: true,
            closeOnEscape: true,
            borderRadius: 3,
            onbeforeclose: function (panel) {
                mediprimeAppPanel = null;
                return true;
            },
            content: function () {
                $(this.content).load("/" + appLocation + "mp/getMessageModal", {
                    patientId,
                    selectedDocumentIds,
                    messageText,
                    requestConfirmation
                });
            },
            headerTitle: "INFORMATIONEN PER MEDIPRIME.APP AN " + patientName + " VERSENDEN",
            theme: "#3cafa5",
        };

        mediprimeAppPanel = jsPanel.create(options);

        if ($(window).width() <= 1120) {
            mediprimeAppPanel.maximize();
        }
    }
}

function exportPatientData(patientId, mail) {
    customConfirm(
        "Patientendaten im ENDS Format exportieren",
        "Sind Sie sicher, dass Sie " + (patientId ? "die Daten dieses Patienten" : "ALLE Patientendaten") + " exportieren möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "exportUserData",
                context: document.body,
                type: "post",
                data: {patientId}
            }).done(function (res) {
                if (res === false && !patientId) {
                    customModalInfo(
                        "Fehlgeschlagen",
                        "Es ist bereits ein Datenexport für Sie geplant, dieser wird täglich ab 19:00 Uhr gestartet."
                    );
                } else {
                    customModalInfo(
                        "Erfolg",
                        "Datenexport wird "
                        + (patientId ? "jetzt" : "heute Abend")
                        + " erstellt. Wir versenden eine E-Mail"
                        + (mail ? " an " + mail : "")
                        + " sobald der Export fertiggestellt ist. Sie können Ihre Daten dann über die ToDo Liste in docsy herunterladen."
                    )
                }
                actionSuccess();
            }).fail(function (e) {
                actionFail();
            });
        })
}

function updateGenericDocumentWorkflowSetting(workflow, setting, value, workflowTypeName) {
    $.ajax({
        url: "/" + appLocation + "updateGenericDocumentWorkflow",
        method: "post",
        context: document.body,
        data: {workflow, setting, value, workflowTypeName}
    }).done(function (shouldRefresh) {
        if (shouldRefresh) location.reload();
        else actionSuccess();
    }).fail(function (error) {
        actionFail(error.responseText);
    });
}

function createGenericDocumentWorkflow(workflowTypeName, then = null) {
    $.ajax({
        url: "/" + appLocation + "createGenericDocumentWorkflow",
        method: "post", context: document.body,
        data: {workflowTypeName}
    }).done(function (id) {
        if (then) then(id);
        else location.reload();
    }).fail(function (error) {
        actionFail(error.responseText);
    });
}

function deleteGenericDocumentWorkflow(workflowId, workflowTypeName) {
    $.ajax({
        url: "/" + appLocation + "deleteGenericDocumentWorkflow",
        method: "post", context: document.body,
        data: {workflowId, workflowTypeName}
    }).done(function (id) {
        location.reload();
    }).fail(function (error) {
        actionFail(error.responseText);
    });
}

function applyMediprimeAppInboxFilter(page) {
    if (!document.forms.mpFeedFilterForm) return; // page is not (filtered) mpFeed
    const form = new FormData(document.forms.mpFeedFilterForm);
    if (page > -1) form.set("page", page);
    let query = new URLSearchParams(form).toString();
    const block = ".mediprimeAppMessageFeed";
    const selected = document.getElementById("mediprimeAppFilterSelectedEntity");
    if (selected) query += "&selectedEntity=" + selected.value;
    showLoaderIn(block);
    $(block).load("/" + appLocation + "mediprimeApp/feed?" + query);
    refreshMenuInfoBadges();
    if (page > -1) document.forms.mpFeedFilterForm.page.value = page;
    return false
}

function setMediprimeAppPrescriptionsEnabled(patientId, value) {
    $.ajax({
        url: "/" + appLocation + "mediprimeApp/setPrescriptionsEnabled",
        context: document.body, method: "post", data: {patientId, value}
    }).done(function () {
        actionSuccess();
        loadPatientView(patientId, "MediprimeApp", 0);
    }).fail(function () {
        actionFail();
    });
}

function showMPProcessStateDialog(processUUID, preSelected = "") {
    $.ajax({
        url: "/" + appLocation + "mediprimeApp/getProcessStates/" + processUUID + "?preSelected=" + preSelected,
        context: document.body, method: "get"
    }).done(function (message) {
        bootbox.dialog({
            title: "Status Bearbeiten", message,
            buttons: {
                confirm: {
                    label: "Bestätigen",
                    className: "btn-success",
                    callback: function () {
                        const data = new FormData(document.forms.processStateUpdateForm);
                        // check only for empty string. null means it's optional, and any other value is valid
                        if (data.get("changeReason") === "") {
                            document.forms.processStateUpdateForm.changeReason.classList.add("btn-danger")
                            return false;
                        }
                        updateMPProcessState(processUUID, Object.fromEntries(data));
                    }
                },
                close: {
                    label: "Abbrechen",
                    className: "btn-danger",
                }
            },
        });
    });
}

function updateMPProcessState(processUUID, form) {
    $.ajax({
        url: "/" + appLocation + "mediprimeApp/setProcessState",
        context: document.body, method: "post", data: {processUUID, ...form}
    }).done(function () {
        actionSuccess();
        applyMediprimeAppInboxFilter()
    }).fail(function () {
        actionFail();
    });
}

function toggleMPEntityUnread(mpUUID) {
    $.ajax({
        url: "/" + appLocation + "mediprimeApp/toggleMediprimeAppEntityUnread",
        context: document.body, method: "post", data: {mpUUID}
    }).done(function () {
        $("#mpEntityNew" + mpUUID).toggle();
        $("#mpEntityToggleNew" + mpUUID + " span").toggleClass("glyphicon-eye-close glyphicon-eye-open");
        refreshMenuInfoBadges();
    }).fail(function () {
        actionFail()
    })

}

function startDeviceIntegration(referenceId, referenceType, integration = null) {
    $.ajax({
        url: "/" + appLocation + "examination/start",
        context: document.body, method: 'POST',
        data: {referenceId, referenceType, integration}
    }).done(function ({status, result}) {
        if (status === "FAIL" && Array.isArray(result)) {
            bootbox.prompt({
                title: "Welche Geräteintegration?",
                inputType: 'select',
                value: result[0]?.id,
                inputOptions: result.map(({id, name, connected}) => {
                    return {
                        value: id,
                        text: `${name}${connected ? '' : ' | nicht verbunden'}`
                    }
                }),
                callback: function (result) {
                    if (result) startDeviceIntegration(referenceId, referenceType, result)
                }
            })
        } else if (typeof (result) === "string") actionFail(result)
        else actionSuccess();
    }).fail(function (message) {
        actionFail()
    })
}

function downloadDeviceIntegration() {
    const ids = $('#deviceIntegrationGenerator :checked').get()
        .map(it => `id=${it.value}`)

    if (ids.length == 0) {
        actionFail("Bitte wählen sie mindestens eine Integration.")
        return;
    }

    $.ajax({
        url: "/" + appLocation + "downloadDeviceIntegration?" + ids.join('&'),
        method: "GET", context: document.body,
        xhrFields: { responseType: 'blob' }
    }).done(function(blob) {
        // create link and simulate click
        const link = document.createElement('a');
        link.download = "docsyDeviceIntegration.zip";
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(url);
    }).fail(function() {
        actionFail();
    })

}

async function fetchKSERequest(patientId) {
    const kseReponse = await $.get(`/${appLocation}searchKonsultationData/${patientId}`);
}
