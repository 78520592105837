function deleteAccount() {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie Ihren Account deaktivieren möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "deleteAccount",
            context: document.body,
            type: 'post'
        }).done(function () {
            location.reload();
        }).fail(function () {
            actionFail("Ihr Account konnte leider nicht deaktiviert werden. Bitte wenden Sie sich an den Administrator!");
        })
    });
}

function copyData(fromFormDiv, toFormDiv) {
    var toForm = $(toFormDiv);
    var copyFrom = $(fromFormDiv + " .copyable");
    copyFrom.each(function (index, el) {
        var element = $(el);
        var value = element.val();
        var dataRole = element.attr("data-role");
        var toFormElement = toForm.find('[data-role=\'' + dataRole + '\']');
        toFormElement.val(value);
    });
}

function togglePasswordLoginEnabled(enabled) {
    var confirmMsg;
    var failMsg;
    if (enabled) {
        confirmMsg = "Mit der Deaktivierung des Passwort-Logins wird der Account sicherer. Soll der Login per Passwort deaktiviert werden?";
        failMsg = "Die Deaktivierung des Passwort-Logins konnte nicht durchgeführt werden. Bitte wenden Sie sich an den Administrator!";
    } else {
        confirmMsg = "Möchten Sie wirklich den Passwort-Login aktivieren? <br /><br /> <p class='danger'><strong>ACHTUNG:</strong> Der Passwort-Login ist weniger sicher als  der Login mit ID Austria oder Handy-Signatur.</p>";
        failMsg = "Die Aktivierung des Passwort-Logins konnte nicht durchgeführt werden. Bitte wenden Sie sich an den Administrator!";
    }

    customConfirm("Bitte bestätigen", confirmMsg, function () {
        $.ajax({
            url: "/" + appLocation + "togglePasswordLoginEnabled",
            context: document.body,
            type: 'post'
        }).done(function () {
            reloadCurrentContent(null, function () {
                $('#profileTabList a[href="#identification"]').tab('show');
            });
        }).fail(function () {
            actionFail(failMsg);
        })
    });
}

function getPermissionsList(dataEntryId, tableId, entityName) {
    showLoaderIn('.permissionBlock-' + dataEntryId + '-' + tableId);
    $('.permissionBlock-' + dataEntryId + '-' + tableId).load("/" + appLocation + "getPermissionsList/" + dataEntryId + "/" + tableId + "/" + entityName);
}

function confirmTogglePermission(permissionId, accountId, dataEntryId, tableId, entityName) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diese Information teilen möchten? Die Freigabe kann nachher nicht mehr rückgängig gemacht werden!", function () {
            togglePermission(permissionId, accountId, dataEntryId, tableId, entityName);
        },
        function () {
            instantCloseDialogs();
        });
}

function togglePermission(permissionId, accountId, dataEntryId, tableId, entityName) {
    if (permissionId == 'new') {
        permissionId = null;
    }

    $.ajax({
        url: "/" + appLocation + "togglePermission/" + entityName,
        context: document.body,
        type: 'post',
        data: {'permissionId': permissionId, 'accountId': accountId, 'dataEntryId': dataEntryId}
    }).done(function (reloadThis) {
        $('.permissiondoc' + accountId).css('color', 'green');
        instantCloseDialogs();
        load(reloadThis, null, false, tableId);
    }).fail(function () {
        $('.permissiondoc' + accountId).css('color', 'red');
        actionFail();
    })
}

function setGenericAnamnesisValue(type, value) {
    $.ajax({
        url: "/" + appLocation + "toggleGenericAnamnesisValue/" + type,
        context: document.body,
        type: 'post',
        data: {'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setNoDrugs(value) {
    $.ajax({
        url: "/" + appLocation + "setNoDrugs",
        context: document.body,
        type: 'post',
        data: {'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setNoDiagnoses(value) {
    $.ajax({
        url: "/" + appLocation + "setNoDiagnoses",
        context: document.body,
        type: 'post',
        data: {'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setNoAllergies(value) {
    $.ajax({
        url: "/" + appLocation + "setNoAllergies",
        context: document.body,
        type: 'post',
        data: {'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setNoMedicalAids(value) {
    $.ajax({
        url: "/" + appLocation + "setNoMedicalAids",
        context: document.body,
        type: 'post',
        data: {'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setNoTherapies(value) {
    $.ajax({
        url: "/" + appLocation + "setNoTherapies",
        context: document.body,
        type: 'post',
        data: {'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setNoDietarySupplements(value) {
    $.ajax({
        url: "/" + appLocation + "setNoDietarySupplements",
        context: document.body,
        type: 'post',
        data: {'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function createWidget(ctx, callback, clear = true) {
    var data = normalizeFormData("newDynamicWidget");
    $.ajax({
        url: "/" + appLocation + "widget/create",
        context: document.body,
        type: 'post',
        data: data
    }).done(function (data) {
        actionSuccess();
        if (clear) {
            $("#dynamicWidgetTitle").val("");
            $("#questionDropZone").empty();
            reloadWidgetEditor();
        }
        if (callback) callback(data);
    }).fail(function (data) {
        try {
            data = JSON.parse(data.responseText);
        } catch (e) {
            data = undefined
        }
        if (data && data.errorMessage) {
            var id = data.id;
            if (!id) {
                id = "default-widget";
            }

            $("#" + id + "-error").show();
            $("#" + id + "-error-message").html(data.errorMessage);

            window.setTimeout(function () {
                $("#" + id + "-error").hide();
            }, 10000);
        }

        actionFail();
    });
    return false;
}

function registerWidgetChartFormSubmitButton() {
    $('#widgetChartForm').submit((e) => {
        const submitButtonId = 'widgetChartSubmitButton';
        const form = $('#widgetChartForm');
        const oldButtonText = setButtonInWaitingMode(submitButtonId);
        $.ajax({
            url: form.attr('action'), context: document.body, type: 'post', data: form.serialize()
        }).done((response) => {
            actionSuccess();
            $("#ModalReplace .modal").modal("hide");
        }).fail((error) => {
            actionFail();
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function reloadWidgetEditor(widgetId = null) {
    showLoaderIn("#customWidgets");
    $("#customWidgets").load("/" + appLocation + "getCustomWidgets/" + (widgetId || ""));
}

function allowDrop(ev) {
    ev.preventDefault();
}

function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.append(document.getElementById(data));
}

function toggleActive(ctx, widgetId) {
    $.ajax({
        url: "/" + appLocation + "widget/toggleactive/" + widgetId,
        context: document.body,
        type: 'DELETE',
    }).done(function () {
        actionSuccess();
        $("#dynamicWidgetTitle").val("");
        $("#questionDropZone").empty();
        //muss eigentlich nit sein...
        load('CustomWidgetList');
    }).fail(function () {
        actionFail();
    });
}

function deleteWidget(ctx, widgetId) {
    $.ajax({
        url: "/" + appLocation + "widget/delete/" + widgetId,
        context: document.body,
        type: 'DELETE',
    }).done(function () {
        actionSuccess();
        reloadWidgetEditor();
    }).fail(function () {
        actionFail();
    });
}

function customWidgetToggleQuestion(element) {
    element = typeof element === "string" ? document.querySelector(element) : element;
    const dropZone = document.getElementById("questionDropZone");
    const inDropzone = dropZone.contains(element);

    if (! inDropzone) { // add to dropzone
        // deep clone node
        const clone = element.cloneNode(true);
        $(clone).find(".widget-toggle span").toggleClass("glyphicon-plus-sign glyphicon-minus-sign");
        $(clone).find(".widget-historysettings").show();
        // hide all occurrences of the element
        $(document.getElementsByClassName(element.className)).hide();
        // append the clone to dropzone
        dropZone.appendChild(clone);
    } else {
        // show all occurrences of the element
        $(document.getElementsByClassName(element.className)).show();
        // remove the clone from the drop zone
        element.remove();
    }
}

function editAnswerHistorySettings(element, type) {
    createWidget(null, (widgetTypeId) => {
        const elementId = $(element).find("input").val();

        const popup = bootbox.dialog({
            message: `<img src="/${appLocation}resources/images/loader.gif" alt="loading">`,
            title: "Anzeigeeinstellungen"
        });
        popup.bind('shown.bs.modal', function(it) {
            $(it.target)
                .find(".modal-body")
                .load("/" + appLocation + "answerHistory/getSettings?widget=" + widgetTypeId + "&" + type + "Id=" + elementId, function () {
                    $(it.target).find("form").submit(function (e) {
                        e.preventDefault();

                        $.ajax({
                            url:  $(this).attr("action"),
                            context: document.body,
                            type: 'post',
                            data: $(this).serialize()
                        }).done(function (response) {
                            popup.remove();
                        }).fail(function () {
                            actionFail()
                        });

                        return false;
                    });
                });
            return false;
        })

    }, false);
}

function showCustomWidgetTab(widgetTypeWidgetName, tab) {
    $(`#patientViewDashboard${widgetTypeWidgetName} [role=tabpanel]`).hide()
    $("#" + widgetTypeWidgetName + tab).show()
}