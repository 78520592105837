(function (global) {
    class QuickActions {
        constructor(options = {}) {
            this.options = options;
            this.context = {};
            this.actions = options.actions || [];
            this.currentIndex = -1;
            this.overlay = this.createQuickActionHTML();
            this.searchInput = document.getElementById('quick-search-input');
            this.searchResults = document.getElementById('search-results');
            this.noResults = document.getElementById('quick-search-no-results');
            this.prefixHandlers = options.prefixHandlers || {};

            this.initEventListeners();
        }

        // Erstelle das HTML für das QuickAction-Overlay
        createQuickActionHTML() {
            const overlay = document.createElement('div');
            overlay.id = 'quick-search-overlay';
            overlay.innerHTML = `
                <div id="quick-search">
                    <input type="text" id="quick-search-input" placeholder="Schnellaktionen..." autocomplete="off">
                    <ul id="search-results"></ul>
                    <span id="quick-search-no-results" style="display: none; justify-content: center"><h4>Keine Ergebnisse gefunden</h4></span>
                </div>
            `;
            document.body.appendChild(overlay);
            return overlay;
        }

        // Event Listener initialisieren
        initEventListeners() {
            // Öffne die Suche bei Ctrl + Space oder Command + Space
            document.addEventListener('keydown', (event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === ' ') {
                    event.preventDefault();
                    this.openSearch();
                }
            });

            // Schließe die Suche bei Escape
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    this.closeSearch();
                }
            });

            // Klicke außerhalb des Overlays, um die Suche zu schließen
            this.overlay.addEventListener('click', (event) => {
                if (event.target === this.overlay) {
                    this.closeSearch();
                }
            });

            // Verarbeite die Eingabe
            this.searchInput.addEventListener('input', async () => {
                const query = this.searchInput.value.trim().toLowerCase();
                this.searchResults.innerHTML = '';
                this.currentIndex = -1;
                if (query) {
                    const results = await this.processQuery(query);
                    this.displayResults(results, query);
                } else {
                    this.displayResults(this.actions);
                }
            });

            // Pfeiltasten-Navigation
            this.searchInput.addEventListener('keydown', (event) => {
                const items = Array.from(this.searchResults.querySelectorAll('li:not(.category-header)'));

                if (items.length === 0) return;

                if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    this.currentIndex = (this.currentIndex + 1) % items.length;
                    this.updateActiveItem(items);
                }

                if (event.key === 'ArrowUp') {
                    event.preventDefault();
                    this.currentIndex = (this.currentIndex - 1 + items.length) % items.length;
                    this.updateActiveItem(items);
                }

                if (event.key === 'Enter' && this.currentIndex !== -1) {
                    event.preventDefault();
                    items[this.currentIndex].click();
                }
            });
        }

        // Öffne die Suche
        openSearch() {
            this.overlay.style.display = 'flex';
            document.body.classList.add('overlay-active');
            this.searchInput.focus();
            this.searchResults.innerHTML = '';
            this.displayResults(this.actions);
        }

        // Schließe die Suche
        closeSearch() {
            this.overlay.style.display = 'none';
            document.body.classList.remove('overlay-active');
            this.searchInput.value = '';
            this.searchResults.innerHTML = '';
            this.currentIndex = -1;
        }

        // Verarbeite die Eingabe
        async processQuery(query) {
            const prefixMatch = query.match(/^(\w+):(.+)$/);
            if (prefixMatch) {
                const [, prefix, value] = prefixMatch;
                if (this.prefixHandlers[prefix]) {
                    const results = await this.prefixHandlers[prefix](value);
                    this.actions = this.actions.concat(results)
                    return results;
                }
            }

            // Standardfilter
            return this.actions.filter(action =>
                action.displayName.toLowerCase().includes(query) ||
                action.tags?.some(tag => tag.toLowerCase().includes(query)) ||
                action.href?.toLowerCase().includes(query)
            );
        }

        // Ergebnisse anzeigen
        displayResults(results, query) {
            const groupedActions = this.groupByCategory(results);

            if (Object.keys(groupedActions).length === 0) {
                this.noResults.style.display = 'flex';
                return;
            } else {
                this.noResults.style.display = 'none';
            }

            for (const [category, items] of Object.entries(groupedActions)) {
                const categoryHeader = document.createElement('li');
                categoryHeader.textContent = category;
                categoryHeader.className = 'category-header';
                this.searchResults.appendChild(categoryHeader);

                items.forEach((action) => {

                    if ((typeof action.hidden === 'function' && action.hidden(this.getContext())) || action.hidden === true) return;

                    const li = document.createElement('li');
                    let itemIsDisabled = action.disabled === true; //explizit true
                    if (typeof action.disabled === 'function') {
                        itemIsDisabled = action?.disabled(this.getContext());
                    }

                    if (itemIsDisabled) {
                        li.classList.add('disabled');
                    } else {
                        li.classList.remove('disabled');
                    }

                    li.textContent = action.displayName;

                    if (!itemIsDisabled) {
                        li.addEventListener('click', () => {
                            this.executeAction(action, query);
                            this.closeSearch();
                        });
                    }
                    this.searchResults.appendChild(li);
                });
            }

            const allItems = this.searchResults.querySelectorAll('li:not(.category-header)');
            if (allItems.length === 1) {
                this.currentIndex = 0; // Ersten Index setzen
                this.updateActiveItem(allItems);
            }
        }

        // Aktionen nach Kategorie gruppieren
        groupByCategory(actions) {
            return actions.reduce((acc, action) => {
                const category = action.category || 'Andere';
                if (!acc[category]) acc[category] = [];
                acc[category].push(action);
                return acc;
            }, {});
        }

        // Aktives Element aktualisieren
        updateActiveItem(items) {
            items.forEach((item, index) => {
                if (index === this.currentIndex) {
                    item.classList.add('active'); // Aktiv setzen
                    item.scrollIntoView({block: 'nearest'}); // Sichtbar machen
                } else {
                    item.classList.remove('active'); // Alle anderen deaktivieren
                }
            });
        }

        getContext() {
            this.context.patientId = this.context?.user?.id ? this.context?.user?.id : this.getPatientIdFromURL();
            return this.context;
        }

        getPatientIdFromURL() {
            const pathParts = window.location.pathname.split("/").filter(Boolean);
            const index = pathParts.findIndex(part => part.startsWith("PatientView"));
            if (index !== -1 && index + 1 < pathParts.length) {
                return pathParts[index + 1];
            }
            return undefined;
        }

        // Aktion ausführen
        executeAction(action, query) {
            if (action.href) {
                window.location.href = action.href;
            } else if (action.url) {
                window.open(action.url, '_blank');
            } else if (action.function) {
                action.function(action, query, this.getContext());
            }
        }
    }

    // Exportiere die Klasse als globale Variable
    global.QuickActions = QuickActions;
})(window);
