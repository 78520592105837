function initializeDoctorCalendar(
    calendarMinTime,
    calendarMaxTime,
    formattedSlotDurationInMinutes,
    showSchedulerSettings,
    showWeekendsInScheduler,
    showResourceColumnsInSchedulerDayView,
    fadePastEvents,
    printableView,
    defaultView = "agendaWeek"
) {
    mobileView = $('#visible-xs').is(":visible");
    const calendar = $('#calendar').fullCalendar({
        customButtons: {
            show24hours: {
                text: '24h',
                click: function () {
                    $('#calendar').fullCalendar('option', {
                        minTime: '00:00:00',
                        maxTime: '24:00:00',
                        header: {
                            right: 'month,agendaWeek,agendaDay,listDay showHorizontal,showCustomHours print settings,schedulerSettings'
                        }
                    });
                    $('.fc-spinner-button').hide();
                }
            },
            showCustomHours: {
                text: '-24h',
                click: function () {
                    $('#calendar').fullCalendar('option', {
                        minTime: calendarMinTime + ":00",
                        maxTime: calendarMaxTime + ":00",
                        header: {
                            right: 'month,agendaWeek,agendaDay,listDay showHorizontal,show24hours print settings,schedulerSettings'
                        }
                    });
                    $('.fc-spinner-button').hide();
                }
            },
            showVertical: {
                text: 'V',
                click: function () {
                    $('#calendar').fullCalendar('option', {
                        header: {
                            right: 'month,agendaWeek,agendaDay,listDay showHorizontal,show24hours print settings,schedulerSettings'
                        }
                    });
                    $('#calendar').fullCalendar('changeView', 'agendaWeek');
                    $('.fc-spinner-button').hide();
                }
            },
            showHorizontal: {
                text: 'H',
                click: function () {
                    $('#calendar').fullCalendar('option', {
                        header: {
                            right: 'timelineMonth,timelineWeek,timelineDay showVertical,show24hours print settings,schedulerSettings'
                        }
                    });
                    $('#calendar').fullCalendar('changeView', 'timelineWeek');
                    $('.fc-spinner-button').hide();
                }
            },
            spinner: {
                text: '',
                click: function () {
                    alert("Bitte kurz warten. Es werden gerade Termine für den Kalender geladen. Sollte diese Anzeige nicht verschwinden, prüfen Sie bitte Ihre Internetverbindung.");
                }
            },
            newAppointment: {
                text: '',
                click: function () {
                    update('Appointments', '0');
                }
            },
            datePicker: {
                text: '',
                click: function () {
                }
            },
            mobileSettings: {
                text: '',
                click: function () {
                }
            },
            refresh: {
                text: '',
                click: function () {
                    $('#calendar').fullCalendar('refetchResources');
                    $('#calendar').fullCalendar('refetchEvents');
                }
            },
            settings: {
                text: '',
                click: function () {
                    router.navigate(appLocation + 'AppointmentsModuleSettings', {trigger: true});
                }
            },
            print: {
                text: 'Drucken',
                click: function () {
                    if (!printableView) {
                        var theWindow = window.open(window.location.href.replace('#', '') + 'Printable');
                        openPopUpWindowWithBrowserPermissionCheck(theWindow);
                    } else
                        window.print();
                }
            },
            schedulerSettings: {
                text: '',
                click: function () {
                    toggleCalendarSettingsView(mobileView);
                }
            }
        },
        header: mobileView ? {
            left: 'newAppointment,datePicker prev,refresh,next spinner',
            center: 'title',
            right: 'mobileSettings schedulerSettings'
        } : {
            left: 'newAppointment today,datePicker prev,refresh,next spinner',
            center: 'title',
            right: 'month,agendaWeek,agendaDay,listDay showHorizontal,show24hours print settings,schedulerSettings'
        },
        views: {
            month: {
                titleFormat: 'MMM YYYY'
            },
            agendaWeek: {
                titleFormat: 'DD.MM.YYYY'
            },
            listDay: {
                titleFormat: 'DD.MM.YYYY'
            },
            agendaDay: {
                titleFormat: 'dd DD.MM.YYYY'
            },
            timelineMonth: {
                titleFormat: 'MMM YYYY'
            },
            timelineWeek: {
                titleFormat: 'DD.MM.YYYY'
            },
            timelineDay: {
                titleFormat: 'dd DD.MM.YYYY'
            }
        },
        height: 'parent',
        defaultDate: moment(),
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source', //TODO - buy this
        googleCalendarApiKey: 'AIzaSyDVwJSlDOsjUacw67JrBds3ESdgYZkccXc',
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        selectable: true,
        selectHelper: false,
        nowIndicator: !printableView,
        defaultView: defaultView,
        minTime: calendarMinTime + ":00",
        maxTime: calendarMaxTime + ":00",
        scrollTime: calendarMinTime + ":00", //TODO - use this with a displaySetting moment().subtract(1,"H").format("HH:mm:ss"),
        slotDuration: formattedSlotDurationInMinutes,
        defaultTimedEventDuration: formattedSlotDurationInMinutes,
        weekends: showWeekendsInScheduler,
        timeFormat: 'HH:mm', // uppercase H for 24-hour clock
        slotLabelFormat: "HH:mm",
        nextDayThreshold: '00:00:00',
        locale: 'de-at',
        timezone: 'local',
        displayEventEnd: false,
        eventTextColor: '#333',
        eventBorderColor: '#666',
        filterResourcesWithEvents: false,
        buttonIcons: {
            prev: 'left-single-arrow',
            next: 'right-single-arrow',
            prevYear: 'left-double-arrow',
            nextYear: 'right-double-arrow'
        },
        businessHours: combinedBusinessHours,
        weekNumbers: true,
        eventLimit: true, // allow "more" link when too many events
        eventSources: [{
            url: "/" + appLocation + "getFullCalendarEvents",
            type: 'get'
        }],
        resources: (mobileView || !showResourceColumnsInSchedulerDayView) ? false : {
            url: "/" + appLocation + "getFullCalendarResources",
            type: 'get',
        },
        viewRender: function (view, element) {
            $('#footer').hide();
            $('.fc-today-button').attr('title', 'Zum heutigen Tag springen');
            $('.fc-datePicker-button').attr('title', 'Datum auswählen');
            $('.fc-refresh-button').attr('title', 'Termine neu laden');

            $('.fc-month-button').attr('title', 'Monatsansicht');
            $('.fc-agendaWeek-button').attr('title', 'Wochenansicht');
            $('.fc-agendaDay-button').attr('title', 'Tagesansicht');
            $('.fc-listDay-button').attr('title', 'Terminübersicht (Tages-Liste)');

            $('.fc-prev-button').attr('title', 'Zurück / Letzte');
            $('.fc-next-button').attr('title', 'Vor / Nächste');

            $('.fc-showCustomHours-button').attr('title', 'Nur Ihre eingestellten Stunden anzeigen (z.B. 08-17 Uhr, siehe Einstellungen)');
            $('.fc-show24hours-button').attr('title', '24 Stunden anzeigen');
            $('.fc-showHorizontal-button').attr('title', 'Kalender untereinander anzeigen (gestapelt)');
            $('.fc-showVertical-button').attr('title', 'Kalender nebeneinander anzeigen (in Spalten)');

            if (mobileView)
                $('.fc-newAppointment-button').html('<span class="glyphicon glyphicon-plus-sign text-primary"></span>');
            else
                $('.fc-newAppointment-button').html('<span class="glyphicon glyphicon-plus-sign text-primary"></span>&nbsp; Termin');

            $('.fc-settings-button').html('<span class="glyphicon glyphicon-cog" id="doctorStartTour-appointmentsModuleSettings" title="Einstellungen"></span>');
            if (!printableView) {
                $('.fc-today-button').html('<span class="glyphicon glyphicon-record"></span>');
                $('.fc-print-button').html('<span class="glyphicon glyphicon-print" title="Druckfreundliche Version öffnen"></span>');

            }


            if (mobileView) {
                $('.fc-mobileSettings-button').html('<div class="dropdown">' +
                    '  <button style="padding-bottom: 2px;" class="btn btn-default fc-mobileSettings-button fc-corner-left dropdown-toggle" type="button" id="mobileSettingsDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">' +
                    '    <span class="caret"></span>' +
                    '  </button>' +
                    '  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="mobileSettingsDropdownMenu">' +
                    '    <li><a href="javascript:void(0);" onclick="$(\'#calendar\').fullCalendar(\'changeView\', \'agendaDay\');">Tagesansicht</a></li>' +
                    '    <li><a href="javascript:void(0);" onclick="$(\'#calendar\').fullCalendar(\'changeView\', \'agendaWeek\');">Wochenansicht</a></li>' +
                    '    <li><a href="javascript:void(0);" onclick="$(\'#calendar\').fullCalendar(\'changeView\', \'month\');">Monatsansicht</a></li>' +
                    '    <li><a href="javascript:void(0);" onclick="$(\'#calendar\').fullCalendar(\'changeView\', \'listDay\');">Terminliste (Tag)</a></li>' +
                    '    <li><a href="javascript:void(0);" onclick="$(\'#calendar\').fullCalendar(\'changeView\', \'listWeek\');">Terminliste (Woche)</a></li>' +
                    '    <li><a href="javascript:void(0);" onclick="$(\'#calendar\').fullCalendar(\'changeView\', \'listMonth\');">Terminliste (Monat)</a></li>' +
                    '  </ul>' +
                    '</div>');
                $('.fc-mobileSettings-button').replaceWith('<div class="fc-button fc-mobileSettings-button fc-corner-left">' + $('.fc-mobileSettings-button').html() + '</div>');
            }

            $('.fc-datePicker-button').html('<input type="hidden" id="datePickerInputField" />');
            $('.fc-datePicker-button').replaceWith('<div style="margin-bottom: 2px;" class="fc-datePicker-button fc-button fc-state-default fc-corner-right">' + $('.fc-datePicker-button').html() + '</div>');


            $('.fc-refresh-button').html('<span class="glyphicon glyphicon-refresh"></span>');

            $("#datePickerInputField").datepicker({
                buttonText: printableView ? 'Datum wählen' : '<i title="Datum auswählen" class=\'glyphicon glyphicon-calendar\'></i>',
                changeMonth: true,
                changeYear: true,
                showOn: 'both',
                onSelect: function (dateText) {
                    var d = new Date(dateText);
                    $('#calendar').fullCalendar('gotoDate', d);
                },
                closeText: "Schließen",
                prevText: "&#x3C;Zurück",
                nextText: "Vor&#x3E;",
                currentText: "Heute",
                monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni",
                    "Juli", "August", "September", "Oktober", "November", "Dezember"
                ],
                monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
                    "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
                ],
                dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                weekHeader: "KW",
                firstDay: 1,
                isRTL: false,
                showMonthAfterYear: false,
                yearSuffix: ""
            });

            if (showSchedulerSettings && !mobileView) {
                $('.fc-schedulerSettings-button').html('<span id="schedulerSettingsButtonIcon" title="Darstellung ausblenden" class="glyphicon glyphicon-eye-close"></span>');
            } else {
                $('.fc-schedulerSettings-button').html('<span id="schedulerSettingsButtonIcon" title="Darstellung einblenden" class="glyphicon glyphicon-eye-open"></span>');
            }

            if (printableView) {
                $('.fc-newAppointment-button').hide();
                $('.fc-settings-button').hide();
                $('.fc-mobileSettings-button').hide();
                $('.fc-schedulerSettings-button').hide();
                $('.fc-showHorizontal-button').hide();
            }
        },
        eventRender: function (event, element) {
            if (event.icon) {
                var paths = "";
                if (!event.icon.startsWith("fa-")) {
                    for (var i = 1; i <= 30; i++) {
                        paths += "<span class='path" + i + "'></span>";
                    }
                }
                element.find(".fc-content").prepend("&nbsp;<i class='fa " + event.icon + "'>" + paths + "</i>&nbsp;");
            }

            if (fadePastEvents) {

                var currentTime = moment();
                var eventEnd = moment(event.end);
                var eventStart = moment(event.start);

                if (!event.end) {
                    if (eventStart.isBefore(currentTime)) {
                        element.addClass("past-event");
                        element.children().addClass("past-event");
                    }
                } else {
                    if (eventEnd.isBefore(currentTime)) {
                        element.addClass("past-event");
                        element.children().addClass("past-event");
                    }
                }
            }

            element.attr('title',
                moment(event.start).format('HH:mm') +
                (event.end ? ' - ' + moment(event.end).format('HH:mm') : '') +
                " | " + event.title);
        },
        loading: function (isLoading, view) {
            if (isLoading) {
                if (!printableView)
                    $('.fc-spinner-button').html('<span class="error glyphicon glyphicon-hourglass"></span>');
                else
                    $('.fc-spinner-button').html('<span>Ladet ...</span>');
                $('.fc-spinner-button').show();
            } else {
                $('.fc-spinner-button').hide();
            }
        },
        eventClick: function (event, jsEvent, view) {
            if (event.url) {
                var theWindow = window.open(event.url);
                openPopUpWindowWithBrowserPermissionCheck(theWindow);
                return false;
            }
            update('Appointments', event.id);
        },
        eventDrop: function (event, delta, revertFunc) {
            /*if (!confirm("Wollen Sie diesen Termin verschieben?")) {
                revertFunc();
            }
            else{*/
            $.ajax({
                url: "/" + appLocation + "updateAppointmentTime",
                context: document.body,
                type: 'post',
                data: {'appointmentId': event.id, 'startTime': event.start.format(), 'endTime': event.end.format()}
            }).done(function () {
                actionSuccess();
            }).fail(function () {
                actionFail();
                revertFunc();
            })
            /*}*/
        },
        eventResize: function (event, delta, revertFunc) {
            /*if (!confirm("Wollen Sie diesen Termin verschieben?")) {
                revertFunc();
            }
            else{*/
            $.ajax({
                url: "/" + appLocation + "updateAppointmentTime",
                context: document.body,
                type: 'post',
                data: {'appointmentId': event.id, 'startTime': event.start.format(), 'endTime': event.end.format()}
            }).done(function () {
                actionSuccess();
            }).fail(function () {
                actionFail();
                revertFunc();
            })
            /*}*/
        },
        select: function (start, end, jsEvent, view, resource) {
            if (resource) {
                var indexOfSeperator = resource.id.indexOf('-');
                var calendarId = resource.id.substring(0, indexOfSeperator != -1 ? indexOfSeperator : resource.id.length);
                if (indexOfSeperator != -1)
                    var resourceId = resource.id.substring(indexOfSeperator + 1);
            }


            update('Appointments', 0, null, null, function () {
                $('.datetimepicker-appointment-startTime').datetimepicker({
                    format: 'DD.MM.YYYY HH:mm',
                    toolbarPlacement: 'bottom',
                    showTodayButton: true,
                    showClose: true,
                    locale: 'de',
                    sideBySide: true,
                    date: start,
                    stepping: 5
                });
                $('.datetimepicker-appointment-endTime').datetimepicker({
                    format: 'DD.MM.YYYY HH:mm',
                    toolbarPlacement: 'bottom',
                    showTodayButton: true,
                    showClose: true,
                    locale: 'de',
                    sideBySide: true,
                    date: end,
                    stepping: 5
                });
                if (calendarId) {
                    $("#calendarId").val(calendarId);
                    $("#calendarId").trigger('change');
                    if (resourceId) {
                        $('#calendarResourceIds').val(resourceId);
                    }
                }


                changeAppointmentDateTimePickerFormat(!start.hasTime(), false);
                $('#appointmentFormAllDay').prop('checked', !start.hasTime());
            });
            $('#calendar').fullCalendar('unselect');
        }
    });

    googleCalendarResourcesAsArray.forEach(function (googleCalendarResource, index) {
        //initialize new
        $('#calendar').fullCalendar('addResource', {
            id: 'googleCalendar' + googleCalendarResource.googleCalendarId,
            title: googleCalendarResource.name
        });

        $('#calendar').fullCalendar('addEventSource', {
            googleCalendarId: googleCalendarResource.googleCalendarId,
            color: googleCalendarResource.color,
            editable: false,
            resourceId: 'googleCalendar' + googleCalendarResource.googleCalendarId
        })
    });
}

function toggleCalendarSettingsView(mobileView) {
    if (!mobileView) {
        toggleSettings('showSchedulerSettings');
    }
    $('#scheduleSettingsColumn').toggle();
    $('#scheduleSettingsColumn').removeClass('hidden-xs');
    $('#doctorFullCalendarColumn').toggleClass('col-xs-12 col-sm-9 col-md-10');
    $('#schedulerSettingsButtonIcon').toggleClass('glyphicon glyphicon-eye-open').toggleClass('glyphicon glyphicon-eye-close');
    if (mobileView) {
        $('#calendar').toggle();
    }
}

/* patient calendar for online booking */
function initializeBooking(slotDuration, allowGoToStep2) {

    if (allowGoToStep2) {
        var defaultView;
        if ($('#visible-xs').is(":visible")) {
            defaultView = 'listMonth';
        } else {
            defaultView = 'listMonth'
        }
        goToBookingStep2();
        setCurrentBookingStep(2);

        //Reset after new selection
        $('#bookingCalendar').fullCalendar('destroy');
        //initialize new
        $('#bookingCalendar').fullCalendar({
            customButtons: {
                spinner: {
                    text: '',
                    click: function () {
                        alert("Bitte kurz warten. Es werden gerade Termine für den Kalender geladen. Sollte diese Anzeige nicht verschwinden, prüfen Sie bitte Ihre Internetverbindung.");
                    }
                }
            },
            header: {
                left: 'today prev,next spinner',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listMonth'
            },
            defaultDate: moment(),
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source', //TODO - buy this
            googleCalendarApiKey: 'AIzaSyDVwJSlDOsjUacw67JrBds3ESdgYZkccXc',
            navLinks: true, // can click day/week names to navigate views
            navLinkDayClick: 'agendaDay',
            navLinkWeekClick: 'agendaWeek',
            editable: false,
            noEventsMessage: 'Wenn Sie am oberen Rand des Fensters eine rote Sanduhr sehen, sucht das System noch nach freien Terminen. Ansonsten sind in diesem Monat leider keine Online-Termine mehr frei. \nKlicken Sie dann auf " > " um freie Termine zu finden. ',
            nowIndicator: true,
            defaultView: defaultView,
            minTime: '00:00:00',
            maxTime: '24:00:00',
            scrollTime: '07:00:00',
            slotDuration: slotDuration,
            defaultTimedEventDuration: slotDuration,
            timeFormat: 'HH:mm', // uppercase H for 24-hour clock
            slotLabelFormat: "HH:mm",
            locale: 'de-at',
            buttonIcons: {
                prev: 'left-single-arrow',
                next: 'right-single-arrow',
                prevYear: 'left-double-arrow',
                nextYear: 'right-double-arrow'
            },
            weekNumbers: true,
            eventLimit: true, // allow "more" link when too many events
            eventSources: [{
                url: "/" + appLocation + "api/getFreeSlots",
                type: 'get',
                data: function () { // a function that returns an object
                    return {
                        calendarId: $('#selectCalendar').val(),
                        appointmentTypeId: $('#selectAppointmentType').val(),
                        servingHealthCareProviderId: $('#servingHealthCareProviderId').val()
                    };
                }
            }],
            loading: function (isLoading, view) {
                if (isLoading) {
                    $('.fc-spinner-button').html('<span class="error glyphicon glyphicon-hourglass"></span>');
                    $('.fc-spinner-button').show();
                } else {
                    $('.fc-spinner-button').hide();
                }
            },
            viewRender: function (view, element) {
                $('.fc-today-button').html('Frühester');
                $('.fc-today-button').attr('title', 'Zeige die frühest möglichen Termine');

                $('.fc-month-button').attr('title', 'Monatsansicht');
                $('.fc-agendaWeek-button').attr('title', 'Wochenansicht');
                $('.fc-agendaDay-button').attr('title', 'Tagesansicht');
                $('.fc-listMonth-button').attr('title', 'Als Liste anzeigen');

                $('.fc-prev-button').attr('title', 'Früherer Termin');

                //$('.fc-prev-button').html(`<span className="fc-icon fc-icon-right-single-arrow">Früherer Termine</span>`);


                $('.fc-next-button').attr('title', 'Späterer Termin');
               // $('.fc-next-button').html(`<span className="fc-icon fc-icon-left-single-arrow">Späterer Termine</span>`);

            },
            validRange: function (nowDate) {
                return {
                    start: nowDate.clone().subtract(2, 'months'),
                    end: nowDate.clone().add(24, 'months')
                };
            },
            eventAfterAllRender: function (view) {

                //if there are no events after all events are rendered, go to next month and inform user about this
                if ($('.fc-list-item').length == 0 && $('.fc-event').length == 0) {

                    $('#infoToSelectSlotText').html('Leider keine freien Termine im ' + view.title + "!");

                    setTimeout(function () {
                        $('#infoToSelectSlotText').html('Bitte wählen Sie Ihren Wunschtermin durch Klick auf "Freier Termin".');
                    }, 3000);

                    $('#bookingCalendar').fullCalendar('next');
                }
            },
            eventClick: function (event, jsEvent, view) {
                if (event.url) {
                    var theWindow = window.open(event.url);
                    openPopUpWindowWithBrowserPermissionCheck(theWindow);
                    return false;
                }

                if (event != currentSelectedEvent) {
                    highlightEvent("bookingCalendar", event);
                    var servingHealthCareProviderId = $('#servingHealthCareProviderId').val();
                    if (!servingHealthCareProviderId)
                        servingHealthCareProviderId = -1;

                    $('#onlineBookingRequest').load("/" + appLocation + "api/getOnlineBookingRequest/" + $('#selectAppointmentType').val() + "/" + servingHealthCareProviderId + "/" + event.start.format() + "/" + event.end.format(), function () {
                        setCurrentBookingStep(2); //for animation if went back and selected a new slot
                        if (publicBooking) {
                            goToBookingStep3();
                            setCurrentBookingStep(3);
                        } else {
                            goToBookingStep4();
                            setCurrentBookingStep(4);
                        }

                        currentSelectedEvent = event;
                    });
                }
            }
        });

        googleCalendarResourcesAsArray.forEach(function (googleCalendarResource, index) {
            //initialize new

            $('#bookingCalendar').fullCalendar('addEventSource', {
                googleCalendarId: googleCalendarResource.googleCalendarId,
                color: googleCalendarResource.color,
                editable: false
            })
        });
    } else {
        goToBookingStep1();
        setCurrentBookingStep(1);
    }
}

function setCurrentBookingStep(step) {
    $('#bookingStep1done').hide();
    $('#navigateToNextBookingAtStep1').hide();
    $('#bookingStep2done').hide();
    $('#navigateToNextBookingAtStep2').hide();
    $('#bookingStep3done').hide();
    /*$('#navigateToNextBookingAtStep3').hide();*/
    $('#bookingStep4done').hide();
    $('#navigateToNextBookingAtStep4').hide();

    if (step == 1) {
        /*if appointmentType is already a valid selection, allow to go to step 2*/
        if ($('#selectAppointmentType').val() != 'Zuerst Kalender wählen' && $('#selectAppointmentType').val() != 'Terminart wählen' && $('#selectAppointmentType').val() != null) {
            $('#navigateToNextBookingAtStep1').show();
        }
    }

    for (var i = 1; i < step; i++) {
        $('#bookingStep' + i + 'done').show();
        //user can go forward again
        $('#navigateToNextBookingAtStep' + i).show();
    }
    currentBookingStep = step;
}

function goToBookingStep1() {
    //TODO - this is not working for refreshs of url in further steps ..
    var step1url = bookingLinkUuid ? 'bookingLink/' + bookingLinkUuid : 'onlineBookingFrame/' + healthCareProviderId
    if (currentBookingStep == 99) {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + step1url + '/bookingStep/1', {trigger: true});
        location.reload();
    } else {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + step1url + '/bookingStep/1');
        $('#bookingStep1start').show();
        $('#bookingStep2start').hide();
        $('#bookingStep3start').hide();
        $('#bookingStep4start').hide();

        /*if (document.getElementById('onlineBookingFrameDiv')) {
            document.getElementById('onlineBookingFrameDiv').scrollIntoView();
            document.getElementById('onlineBookingFrameDiv').focus();
        }*/
    }
}

function goToBookingStep2() {
    if (currentBookingStep == 99) {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + 'onlineBookingFrame/' + healthCareProviderId + '/bookingStep/1', {trigger: true});
        location.reload();
    } else {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + 'onlineBookingFrame/' + healthCareProviderId + '/bookingStep/2');
        $('#bookingStep1start').hide();
        $('#bookingStep2start').show();
        $('#bookingStep3start').hide();
        $('#bookingStep4start').hide();

        /*if (document.getElementById('onlineBookingFrameDiv')) {
            document.getElementById('onlineBookingFrameDiv').scrollIntoView();
            document.getElementById('onlineBookingFrameDiv').focus();
        }*/

    }
}

function goToBookingStep3() {
    if (currentBookingStep == 99) {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + 'onlineBookingFrame/' + healthCareProviderId + '/bookingStep/1', {trigger: true});
        location.reload();
    } else {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + 'onlineBookingFrame/' + healthCareProviderId + '/bookingStep/3');
        $('#bookingStep1start').hide();
        $('#bookingStep2start').hide();
        $('#bookingStep3start').show();
        $('#bookingStep4start').hide();

        if (document.getElementById('onlineBookingRequestForm')) {
            document.getElementById('onlineBookingRequestForm').scrollIntoView();
            document.getElementById('onlineBookingRequestForm').focus();
        }


        if (currentBookingStep == 2) {
            $('.onlineBookingRequestStartTime').effect("highlight", {color: '#ccffcc'}, 3000);
            $('.onlineBookingRequestEndTime').effect("highlight", {color: '#ccffcc'}, 3000);
        }
    }

}

function goToBookingStep4() {
    if (currentBookingStep == 99) {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + 'onlineBookingFrame/' + healthCareProviderId + '/bookingStep/1', {trigger: true});
        location.reload();
    } else {
        router.navigate(appLocation + (publicBooking ? 'api/' : '') + 'onlineBookingFrame/' + healthCareProviderId + '/bookingStep/4');
        $('#bookingStep1start').hide();
        $('#bookingStep2start').hide();
        $('#bookingStep3start').hide();
        $('#bookingStep4start').show();

        if (document.getElementById('onlineBookingRequestForm')) {
            document.getElementById('onlineBookingRequestForm').scrollIntoView();
            document.getElementById('onlineBookingRequestForm').focus();
        }

        //if user got a form error, redirected to page 3, corrected, go to page 4 -->
        // don't still show error message, because on page 4 can be no errors at all
        $('#error-msg-onlineBookingRequest-form').hide();

        if (currentBookingStep == 2) {
            $('.onlineBookingRequestStartTime').effect("highlight", {color: '#ccffcc'}, 3000);
            $('.onlineBookingRequestEndTime').effect("highlight", {color: '#ccffcc'}, 3000);
        }
    }

}

function goToBookingDone(response) {
    currentBookingStep = 99;
    router.navigate(appLocation + (publicBooking ? 'api/' : '') + 'onlineBookingFrame/' + healthCareProviderId + '/bookingStep/done');
    $('#bookingStep1start').hide();
    $('#bookingStep2start').hide();
    $('#bookingStep3start').hide();
    $('#bookingStep4start').hide();


    $('#onlineBookingRequest').html('');
    $('#bookingCalendar').html('');
    $('#onlineBookingResponse').html(response);
    $('#onlineBookingResponse').show();

    if (document.getElementById('onlineBookingResponse')) {
        document.getElementById('onlineBookingResponse').scrollIntoView();
        document.getElementById('onlineBookingResponse').focus();
    }
}

function highlightEvent(fullCalendarId, event) {
    if (currentSelectedEvent) {
        currentSelectedEvent.color = null;
    }
    if (event) {
        event.color = 'green';
    }
    $('#' + fullCalendarId).fullCalendar('rerenderEvents');
}

//what happens when user clicks on create
function registerOnlineBookingRequestFormSubmitButton() {
    $("#onlineBookingRequestForm").submit(function (e) {
        //prevent double submitting
        $("#onlineBookingRequestFormSubmitButton").prop("disabled", true);
        $("#onlineBookingRequestFormSubmitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-onlineBookingRequest-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'onlineBookingRequest');
            $("#onlineBookingRequestFormSubmitButton").prop("disabled", false);
            $("#onlineBookingRequestFormSubmitButton").prop('value', "Termin verbindlich buchen");

            if (response.status == "SUCCESS") {
                goToBookingDone(response.result);
            } else if (response.status != "APPOINTMENT-UNAVAILABLE") {
                goToBookingStep3(); //form errors only on page 3
            } else {
                //appointment is not available any more
                $('#error-msg-onlineBookingRequest-form').show();
            }
        }).fail(function (error) {
            showAjaxError(error, 'onlineBookingRequest');
            $("#error-msg-onlineBookingRequest-form").show('fast');
            $("#onlineBookingRequestFormSubmitButton").prop("disabled", false);
            $("#onlineBookingRequestFormSubmitButton").prop('value', "Termin verbindlich buchen");
        });
        return false;
    });
}

/* for doctor - don't use default deleteById because of reload */
function deleteAppointment(id) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diesen Termin löschen möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteAppointments",
                context: document.body,
                type: 'post',
                data: {'id': id}
            }).done(function () {
                actionSuccess();
                $('#AppointmentsModal').modal("hide");
                $('#calendar').fullCalendar('removeEvents', id);
            }).fail(function () {
                actionFail();
            })
        }
    );
}

function deleteRepeatedAppointment(id, upcomingAppointmentsOnly) {
    var message = upcomingAppointmentsOnly ? "Sind Sie sicher, dass Sie alle zukünftigen Termine (ab jetzt) in dieser Serie löschen möchten?" : "Sind Sie sicher, dass Sie diesen, alle vorangegangenen und alle zukünftigen Termine in dieser Serie löschen möchten?";
    customConfirm("Bestätigen", message,
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteRepeatedAppointment",
                context: document.body,
                type: 'post',
                data: {'id': id, 'upcomingAppointmentsOnly': upcomingAppointmentsOnly}
            }).done(function () {
                actionSuccess();
                $('#AppointmentsModal').modal("hide");
                $('#calendar').fullCalendar('refetchEvents');
            }).fail(function () {
                actionFail();
            })
        }
    );
}

function deleteAllWithICalName(iCalName) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie alle Termine die vom Kalender mit dem Namen '" + iCalName + "' importiert wurden löschen möchten?",
        function () {
            $("#deleteAllWithICalNameButton").prop("disabled", true);
            $("#deleteAllWithICalNameButton").prop('value', "Bitte warten ...");

            $.ajax({
                url: "/" + appLocation + "deleteAllWithICalName",
                context: document.body,
                type: 'post',
                data: {'iCalName': iCalName}
            }).done(function () {
                actionSuccess();
                $('#AppointmentsModal').modal("hide");
                $('#calendar').fullCalendar('refetchEvents');
            }).fail(function () {
                actionFail();
                $("#deleteAllWithICalNameButton").prop("disabled", false);
                $("#deleteAllWithICalNameButton").prop('value', ";Alle importierten Termine von diesem Kalender löschen");
            })
        }
    );
}

/* for doctor */
function confirmAppointment(appointmentId, calledFrom, currentPatientId) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diesen Termin bestätigen möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "confirmAppointment",
                context: document.body,
                type: 'post',
                data: {'appointmentId': appointmentId}
            }).done(function () {
                actionSuccess();

                if (calledFrom == 'doctorDashboard') {
                    loadWidgetToDashboard(calledFrom, "todaysAppointments");
                    loadWidgetToDashboard(calledFrom, "unconfirmedAppointments");
                } else if (calledFrom == 'appointmentsModule') {
                    $('#AppointmentsModal').modal("hide");
                    $('#calendar').fullCalendar('refetchEvents');
                }
                //if currentPatientId --> in patient view --> reload list
                if (currentPatientId) {
                    reload("Appointments", currentPatientId);
                } else {
                    $('#calendar').fullCalendar('refetchEvents');
                }
            }).fail(function () {
                actionFail();
            })
        }
    );
}

/* for doctor and patient */
function cancelAppointment(appointmentId, currentPatientId, canceledByPatient, calledFrom) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diesen Termin stornieren möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "cancelAppointment",
                context: document.body,
                type: 'post',
                data: {'appointmentId': appointmentId}
            }).done(function (response) {
                if (response.status == "SUCCESS") {
                    actionSuccess();
                    if (canceledByPatient) {
                        reloadCurrentContent();
                    }
                    //cancelled by doctor
                    else {
                        //if currentPatientId --> in patient view --> reload list
                        if (currentPatientId)
                            reload("Appointments", currentPatientId);
                        //if not currentPatientId --> in appointmentsModule --> refetch events for calendar
                        else {
                            if (calledFrom == 'doctorDashboard') {
                                loadWidgetToDashboard(calledFrom, "todaysAppointments");
                                loadWidgetToDashboard(calledFrom, "unconfirmedAppointments");
                            } else if (calledFrom == 'appointmentsModule') {
                                $('#AppointmentsModal').modal("hide");
                                $('#calendar').fullCalendar('refetchEvents');
                            } else {
                                $('#calendar').fullCalendar('refetchEvents');
                            }
                        }
                    }
                } else {
                    actionFail(response.result);
                }

            }).fail(function () {
                actionFail();
            })
        }
    );
}

/* default method to set a calendar setting */
function setCalendarSetting(calendarId, setting, value, successCallback) {
    if ($('#error-msg-calendar' + calendarId + '-' + setting).text()) {
        $('#error-msg-calendar' + calendarId + '-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setCalendarSetting",
        context: document.body,
        type: 'post',
        data: {'calendarId': calendarId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-calendar' + calendarId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('calendar' + calendarId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

function toggleCalendarCheckbox(input, calendarId, setting) {
    var value = input.prop('checked');
    setCalendarSetting(calendarId, setting, value);
}


function addCalendar() {
    $.ajax({
        url: "/" + appLocation + "addCalendar",
        context: document.body,
        type: 'post'
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            router.navigate(appLocation + 'AppointmentsModuleSettings/calendar' + response.result, {trigger: true});
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function toggleCalendarVisible(calendarId) {
    $.ajax({
        url: "/" + appLocation + "toggleCalendarVisible",
        context: document.body,
        type: 'post',
        data: {'calendarId': calendarId}
    }).done(function () {
        $('#calendar').fullCalendar('refetchResources');
        $('#calendar').fullCalendar('refetchEvents');
    }).fail(function () {
        actionFail();
    })
}

function toggleGoogleCalendarVisible(googleCalendarId, googleCalendarName, googleCalendarColor, checked) {
    $.ajax({
        url: "/" + appLocation + "toggleGoogleCalendarVisible",
        context: document.body,
        type: 'post',
        data: {'googleCalendarId': googleCalendarId}
    }).done(function () {
        toggleGoogleCalendarInView(checked, googleCalendarId, googleCalendarName, googleCalendarColor);
    }).fail(function () {
        actionFail();
    })
}


function toggleGoogleCalendarInView(checked, googleCalendarId, googleCalendarName, googleCalendarColor) {
    if (checked) {
        $('#calendar').fullCalendar('addResource', {
            id: 'googleCalendar' + googleCalendarId,
            title: googleCalendarName
        });

        $('#calendar').fullCalendar('addEventSource', {
            googleCalendarId: googleCalendarId,
            color: googleCalendarColor,
            editable: false,
            resourceId: 'googleCalendar' + googleCalendarId
        });
    } else {
        $('#calendar').fullCalendar('removeResource', {
            id: 'googleCalendar' + googleCalendarId,
            title: googleCalendarName
        });

        $('#calendar').fullCalendar('removeEventSource', {
            googleCalendarId: googleCalendarId,
            color: googleCalendarColor,
            editable: false,
            resourceId: 'googleCalendar' + googleCalendarId
        });
    }
}

function toggleAppointmentTypeVisible(appointmentTypeId) {
    $.ajax({
        url: "/" + appLocation + "toggleAppointmentTypeVisible",
        context: document.body,
        type: 'post',
        data: {'appointmentTypeId': appointmentTypeId}
    }).done(function () {
        $('#calendar').fullCalendar('refetchEvents');
    }).fail(function () {
        actionFail();
    })
}

function toggleServingHealthCareProviderVisible(servingHealthCareProviderId) {
    $.ajax({
        url: "/" + appLocation + "toggleServingHealthCareProviderVisible",
        context: document.body,
        type: 'post',
        data: {'servingHealthCareProviderId': servingHealthCareProviderId}
    }).done(function () {
        $('#calendar').fullCalendar('refetchEvents');
    }).fail(function () {
        actionFail();
    })
}

function toggleServingHealthCareProviderAvailableForCalendar(calendarId, servingHealthCareProviderId) {
    $.ajax({
        url: "/" + appLocation + "toggleServingHealthCareProviderAvailableForCalendar",
        context: document.body,
        type: 'post',
        data: {'calendarId': calendarId, 'servingHealthCareProviderId': servingHealthCareProviderId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function toggleServingHealthCareProviderAvailableForAppointmentType(appointmentTypeId, servingHealthCareProviderId) {
    $.ajax({
        url: "/" + appLocation + "toggleServingHealthCareProviderAvailableForAppointmentType",
        context: document.body,
        type: 'post',
        data: {'appointmentTypeId': appointmentTypeId, 'servingHealthCareProviderId': servingHealthCareProviderId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function toggleReminderTemplateAvailableForAppointmentType(appointmentTypeId, reminderTemplateId) {
    $.ajax({
        url: "/" + appLocation + "toggleReminderTemplateAvailableForAppointmentType",
        context: document.body,
        type: 'post',
        data: {'appointmentTypeId': appointmentTypeId, 'reminderTemplateId': reminderTemplateId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}
function toggleAnamnesisDocumentForAppointmentType(appointmentTypeId, documentTypeId) {
    $.ajax({
        url: "/" + appLocation + "toggleAnamnesisDocumentForAppointmentType",
        context: document.body,
        type: 'post',
        data: { appointmentTypeId, documentTypeId }
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function toggleCalendarResourceVisible(calendarResourceId) {
    $.ajax({
        url: "/" + appLocation + "toggleCalendarResourceVisible",
        context: document.body,
        type: 'post',
        data: {'calendarResourceId': calendarResourceId}
    }).done(function () {
        $('#calendar').fullCalendar('refetchResources');
        $('#calendar').fullCalendar('refetchEvents');
    }).fail(function () {
        actionFail();
    })
}

function deleteCalendar(calendarId) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diesen Kalender, sowie alle Terminarten und Ressourcen aus diesem Kalender löschen möchten? Diese Daten werden dadurch auch aus allen Terminen gelöscht. Die Termine bleiben jedoch bestehen.",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteCalendar",
                context: document.body,
                type: 'post',
                data: {'calendarId': calendarId}
            }).done(function () {
                actionSuccess();
                router.navigate(appLocation + 'AppointmentsModuleSettings', {trigger: true});
            }).fail(function (data) {
                actionFail(data.responseText);
            })
        }
    );
}

/* default method to set a appointmentType setting */
function setAppointmentTypeSetting(appointmentTypeId, setting, value, successCallback) {
    if ($('#error-msg-appointmentType' + appointmentTypeId + '-' + setting).text()) {
        $('#error-msg-appointmentType' + appointmentTypeId + '-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setAppointmentTypeSetting",
        context: document.body,
        type: 'post',
        data: {'appointmentTypeId': appointmentTypeId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-appointmentType' + appointmentTypeId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('appointmentType' + appointmentTypeId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}


/* default method to set a backgroundEvent setting */
function setBackgroundEventSetting(backgroundEventId, setting, value, successCallback) {
    if ($('#error-msg-backgroundEvent' + backgroundEventId + '-' + setting).text()) {
        $('#error-msg-backgroundEvent' + backgroundEventId + '-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setBackgroundEventSetting",
        context: document.body,
        type: 'post',
        data: {'backgroundEventId': backgroundEventId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-backgroundEvent' + backgroundEventId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('backgroundEvent' + backgroundEventId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}


function toggleCalendarResourceForBackgroundEvent(calendarResourceId, backgroundEventId) {
    $.ajax({
        url: "/" + appLocation + "toggleCalendarResourceForBackgroundEvent",
        context: document.body,
        type: 'post',
        data: {'calendarResourceId': calendarResourceId, 'backgroundEventId': backgroundEventId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function toggleAppointmentTypeCheckbox(input, appointmentTypeId, setting) {
    var value = input.prop('checked');
    setAppointmentTypeSetting(appointmentTypeId, setting, value);
}

/* default method to set a appointmentType setting */
function addAppointmentTypeToCalendar(calendarId) {
    $.ajax({
        url: "/" + appLocation + "addAppointmentTypeToCalendar",
        context: document.body,
        type: 'post',
        data: {'calendarId': calendarId}
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            router.navigate(appLocation + 'AppointmentsModuleSettings/calendar' + calendarId + '/appointmentType' + response.result, {trigger: true});
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}


/* default method to set a backgroundEvent setting */
function addBackgroundEventToCalendar(calendarId) {
    $.ajax({
        url: "/" + appLocation + "addBackgroundEventToCalendar",
        context: document.body,
        type: 'post',
        data: {'calendarId': calendarId}
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            router.navigate(appLocation + 'AppointmentsModuleSettings/calendar' + calendarId + '/backgroundEvent' + response.result, {trigger: true});
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}


function toggleRequiredCalendarResourceForAppointmentType(calendarResourceId, appointmentTypeId) {
    $.ajax({
        url: "/" + appLocation + "toggleRequiredCalendarResourceForAppointmentType",
        context: document.body,
        type: 'post',
        data: {'calendarResourceId': calendarResourceId, 'appointmentTypeId': appointmentTypeId}
    }).done(function () {
        actionSuccess();
        $('#calendar').fullCalendar('refetchEvents');
    }).fail(function () {
        actionFail();
    })
}

function deleteAppointmentType(appointmentTypeId, calendarId) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diese Terminart löschen möchten? Die Terminart wird dadurch auch aus allen Terminen mit dieser Terminart gelöscht. Die Termine bleiben jedoch bestehen.",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteAppointmentType",
                context: document.body,
                type: 'post',
                data: {'appointmentTypeId': appointmentTypeId}
            }).done(function () {
                actionSuccess();
                router.navigate(appLocation + 'AppointmentsModuleSettings/calendar' + calendarId, {trigger: true});
            }).fail(function (data) {
                actionFail(data.responseText);
            })
        }
    );
}

function deleteBackgroundEvent(backgroundEventId, calendarId) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie dieses Hintergrund-Termin löschen möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteBackgroundEvent",
                context: document.body,
                type: 'post',
                data: {'backgroundEventId': backgroundEventId}
            }).done(function () {
                actionSuccess();
                router.navigate(appLocation + 'AppointmentsModuleSettings/calendar' + calendarId, {trigger: true});
            }).fail(function (data) {
                actionFail(data.responseText);
            })
        }
    );
}

function containsHTML(str) {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlRegex.test(str);
}


function setGoogleCalendarResourceSetting(googleCalendarResourceName, setting, value, successCallback) {
    $.ajax({
        url: "/" + appLocation + "setGoogleCalendarResourceSetting",
        context: document.body,
        type: 'post',
        data: {'googleCalendarResourceName': googleCalendarResourceName, 'setting': setting, 'value': value}
    }).done(function () {
        if (setting == 'name') {
            //if name has changed --> name is used as id --> update list!
            location.reload();
        } else {
            actionSuccess();
            if (typeof successCallback === "function")
                successCallback();
        }

    }).fail(function () {
        actionFail();
    })
}


function addGoogleCalendarResource() {
    $.ajax({
        url: "/" + appLocation + "addGoogleCalendarResource",
        context: document.body,
        type: 'post'
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            location.reload();
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function deleteGoogleCalendarResource(googleCalendarResourceName) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diesen Google Kalender löschen möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteGoogleCalendarResource",
                context: document.body,
                type: 'post',
                data: {'googleCalendarResourceName': googleCalendarResourceName}
            }).done(function () {
                actionSuccess();
                location.reload();
            }).fail(function () {
                actionFail();
            })
        }
    );
}

/* default method to set a calendarResource setting */
function setCalendarResourceSetting(calendarResourceId, setting, value, successCallback) {
    if ($('#error-msg-calendarResource' + calendarResourceId + '-' + setting).text()) {
        $('#error-msg-calendarResource' + calendarResourceId + '-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setCalendarResourceSetting",
        context: document.body,
        type: 'post',
        data: {'calendarResourceId': calendarResourceId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-calendarResource' + calendarResourceId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('calendarResource' + calendarResourceId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

function addExternalCalendarSubscription() {
    $.ajax({
        url: "/" + appLocation + "addExternalCalendarSubscription",
        context: document.body,
        type: 'post'
    }).done(function (response) {
        if (!isNaN(response)) {
            actionSuccess();
            location.reload();
        } else {
            actionFail();
        }
    }).fail(function (e) {
        actionFail();
    })
}

function deleteExternalCalendarSubscription(subscriptionID) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diese Kalenderanbindung löschen möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "removeExternalCalendarSubscription",
                context: document.body,
                type: 'post',
                data: {'subscriptionID': subscriptionID}
            }).done(function () {
                actionSuccess();
                location.reload();
            }).fail(function () {
                actionFail();
            })
        }
    );
}


var externalAccountsAndCalendars = undefined;

function fetchAvailableAccountsAndCalendars() {
    fetch("/" + appLocation + "fetchAvailableAccountsAndCalendars", {
        method: "GET"
    })
        .then(function (response) {
            return response.json()
        }) // interprets result as JSON
        .then(function (data) {
            externalAccountsAndCalendars = data;
            updateExternalCalendarOptions();
        });
}

/** Updates the available options when selecting which calender to subscribe to */
function updateExternalCalendarOptions() {
    var optionsList = document.getElementsByClassName("calendarSubscriptionOptions");

    for (var i = 0; i < optionsList.length; i++) {
        var calendarSubscriptionOptions = optionsList[i];
        calendarSubscriptionOptions.innerHTML = "";

        var selected = document.getElementsByClassName("calendarSubscriptionOwner")[i].value;

        if (externalAccountsAndCalendars[selected] == undefined) {
            var option = document.createElement("option");
            option.text = "Keine Kalender verfügbar";
            option.disabled = true;
            calendarSubscriptionOptions.appendChild(option);
            return;
        }

        var tmp1 = document.createElement("option");
        tmp1.value = -1;
        tmp1.text = "Bitte währen sie einen Kalender";
        tmp1.selected = true; // default, any options selected afterwards will override this
        calendarSubscriptionOptions.appendChild(tmp1);

        var tmp2 = document.createElement("option");
        tmp2.value = -1;
        tmp2.text = "";
        tmp2.disabled = true;
        calendarSubscriptionOptions.appendChild(tmp2);

        for (var id in externalAccountsAndCalendars[selected]) {
            var desc = externalAccountsAndCalendars[selected][id];

            var option = document.createElement("option");
            option.value = id;
            option.text = desc;
            option.selected = id == calendarSubscriptionOptions.getAttribute("calid");
            calendarSubscriptionOptions.appendChild(option);
        }
    }

}

function toggleExternalCalendarSubscriptionVisible(subscriptionID) {
    setExternalCalendarSubscriptionSetting(subscriptionID, "showInScheduler", "toggle", function (response) {
        $('#calendar').fullCalendar('refetchEvents');
    });
}

function setExternalCalendarSubscriptionSetting(subscriptionID, setting, value, successCallback) {
    if (value == -2)
        router.navigate(appLocation + 'ApiSettings', {trigger: true});
    else {
        $.ajax({
            url: "/" + appLocation + "setExternalCalendarSubscription",
            context: document.body,
            type: 'post',
            data: {
                'subscriptionID': subscriptionID,
                'setting': setting,
                'value': value
            }
        }).done(function () {
            actionSuccess();
            if (typeof successCallback === "function")
                successCallback();
        }).fail(function (e) {
            actionFail(e.responseText);
        })
    }
}

function setCalendarPublication(calendarID, setting, value) {
    switch (setting) {
        case "enable":
            customConfirm("Bestätigen", (value
                    ? "Sollten sie fortfaren, wird ein Link erstellt, welcher Zugriff auf diesen Kalender gewährt. " +
                    "Da jeder mit diesem Link den Kalender einsehen kann, dürfen sie diesen <b>nicht weitergeben</b>. " +
                    "Sollten sie ihn jedoch versehentlich weitergegeben haben, können sie ihn widerrufen"
                    : "Sollten sie fortfahren, wird der angezeigte Link ungültig, und somit der externe Zugriff auf diesen Kalender gesperrt."),
                function () {
                    $.ajax({
                        url: "/" + appLocation + "calendarPublication/settings",
                        context: document.body,
                        type: "post",
                        data: {
                            "calendarID": calendarID,
                            "setting": setting,
                            "value": value
                        }
                    }).done(function () {
                        if (value) {
                            location.reload();
                        } else document.getElementById('publicationSettings-' + calendarID).classList.add("hide");
                        actionSuccess();
                    }).fail(function (e) {
                        actionFail();
                        if (value) document.getElementById('enablePublication-' + calendarID).checked = false;
                        else document.getElementById('enablePublication-' + calendarID).checked = true;
                    });
                },
                function () {
                    if (value) document.getElementById('enablePublication-' + calendarID).checked = false;
                    else document.getElementById('enablePublication-' + calendarID).checked = true;
                });
            break;
        default:
            $.ajax({
                url: "/" + appLocation + "calendarPublication/settings",
                context: document.body,
                type: 'post',
                data: {
                    'calendarID': calendarID,
                    'setting': setting,
                    'value': value
                }
            }).done(function (response) {
                actionSuccess();
            }).fail(function (e) {
                actionFail();
            });
    }
}

function regenerateUUIDForCalendarPublication(calendarID) {
    customConfirm("Bestätigen", "Sind sie sicher, dass sie den Link ungültig machen, und einen neuen erhalten möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "calendarPublication/newUUID",
            context: document.body,
            type: 'post',
            data: {
                "calendarID": calendarID
            }
        }).done(function (response) {
            var URL = document.getElementById("calendarPublicationURL-" + calendarID);
            URL.value = URL.getAttribute("baseurl") + "api/calendarPublication/" + response;
            $('#calendarPublicationURL-' + calendarID + 'HelpBlock').text('');
            actionSuccess();
        }).fail(function (e) {
            actionFail();
        });
    });
}

function toggleCalendarResourceCheckbox(input, calendarResourceId, setting) {
    var value = input.prop('checked');
    setCalendarResourceSetting(calendarResourceId, setting, value);
}

/* default method to set a appointmentType setting */
function addCalendarResourceToCalendar(calendarId) {
    $.ajax({
        url: "/" + appLocation + "addCalendarResourceToCalendar",
        context: document.body,
        type: 'post',
        data: {'calendarId': calendarId}
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            router.navigate(appLocation + 'AppointmentsModuleSettings/calendar' + calendarId + '/calendarResource' + response.result, {trigger: true});
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function deleteCalendarResource(calendarResourceId, calendarId) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diese Ressource löschen möchten? Die Ressource wird dadurch auch aus allen Terminen mit dieser Ressource gelöscht. Die Termine bleiben jedoch bestehen.",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteCalendarResource",
                context: document.body,
                type: 'post',
                data: {'calendarResourceId': calendarResourceId}
            }).done(function () {
                actionSuccess();
                router.navigate(appLocation + 'AppointmentsModuleSettings/calendar' + calendarId, {trigger: true});
            }).fail(function (data) {
                actionFail(data.responseText);
            })
        }
    );
}

function changeAppointmentDateTimePickerFormat(allDay, convertDate) {
    if (allDay) {
        $('.datetimepicker-appointment-startTime').datetimepicker().data('DateTimePicker').format('DD.MM.YYYY');
        $('.datetimepicker-appointment-endTime').datetimepicker().data('DateTimePicker').format('DD.MM.YYYY');
        if (convertDate) {
            $('.datetimepicker-appointment-endTime').datetimepicker().data('DateTimePicker').date(
                $('.datetimepicker-appointment-endTime').datetimepicker().data('DateTimePicker').date().add(1, 'day')
            );
        }
        $('#datetimepicker-appointment-startTime :input').attr('name', 'startDate');
        $('#datetimepicker-appointment-endTime :input').attr('name', 'endDate');
    } else {
        $('.datetimepicker-appointment-startTime').datetimepicker().data('DateTimePicker').format('DD.MM.YYYY HH:mm');
        $('.datetimepicker-appointment-endTime').datetimepicker().data('DateTimePicker').format('DD.MM.YYYY HH:mm');
        if (convertDate) {
            $('.datetimepicker-appointment-endTime').datetimepicker().data('DateTimePicker').date(
                $('.datetimepicker-appointment-endTime').datetimepicker().data('DateTimePicker').date().subtract(1, 'day')
            );
        }

        $('#datetimepicker-appointment-startTime :input').attr('name', 'startDateTime');
        $('#datetimepicker-appointment-endTime :input').attr('name', 'endDateTime');
    }
}

function toggleAppointmentModalInputMode(toggledField) {
    //toggle other checkbox
    if (toggledField == 'blocking')
        $('#scheduledService').prop('checked', false);
    if (toggledField == 'scheduledService')
        $('#blocking').prop('checked', false);

    //get states
    var isBlocking = $('#blocking').is(':checked');
    var isScheduledService = $('#scheduledService').is(':checked');


    if (isBlocking) {
        $('.patientAppointmentDiv').hide();
        $('#blockingAppointmentDiv').show();
        $('.scheduledServiceDiv').hide();

    }
    if (isScheduledService) {
        $('.patientAppointmentDiv').hide();
        $('#blockingAppointmentDiv').hide();
        $('.scheduledServiceDiv').show();
    }
    if (!isBlocking && !isScheduledService) {
        $('#blockingAppointmentDiv').hide();
        $('.patientAppointmentDiv').show();
        $('.scheduledServiceDiv').hide();
    }

    $('#AppointmentsModal').modal('handleUpdate');
}

/** Toggle state of modal, either repeated or not **/
function toggleRepeatedAppointment() {
    var isRepeatedAppointment = $("input#repeatingAppointment").is(':checked');

    if (isRepeatedAppointment) {
        $(".repeatingAppointmentFormDiv").show();
    } else {
        $(".repeatingAppointmentFormDiv").hide();
    }

    $('#AppointmentsModal').modal('handleUpdate');
}

function handleNewRepeatingAppointmentInterval() {

    var $interval = $("#repeatingAppointmentInterval");
    if ($interval.val() === "WEEK") {
        // show day of week selection
        $(".repeatingAppointmentWeekdayDiv").show();
    } else {
        $(".repeatingAppointmentWeekdayDiv").hide();
    }

    $('#AppointmentsModal').modal('handleUpdate');
}

function setupExclusiveOptionRepeatingAppointment() {
    var $times = $("#repeatTimes");
    var $end = $("#endIntervalDate");
    var $endDatePicker = $("#datetimepicker-appointment-endIntervalDate");

    // mutually exclusive options

    $times.change(function () {
        if ($times.val() === "") {
            $end.prop("disabled", false);
        } else {
            $end.prop("disabled", true);
        }
    });

    // link date picker change event to input change event
    $endDatePicker.on("dp.change", function () {
        $end.change();
    });

    $end.change(function () {
        if ($end.val() === "") {
            $times.prop("disabled", false);
        } else {
            $times.prop("disabled", true);
        }
    });
}

function loadAppointmentSettings(calendarId, appointmentTypeId) {
    load('AppointmentsModuleSettings', function () {
        if (calendarId) {
            $('#appointmentsModuleSettingsTabList a[href="#' + calendarId + '"]').tab('show');
        }
        if (appointmentTypeId) {
            if (appointmentTypeId.startsWith('appointmentType')) {
                $('#appointmentTypesSettingsTabList a[href="#' + appointmentTypeId + '"]').tab('show');
                $('#appointmentsModuleSettingsTabList a[href="#' + calendarId + '"]').on('shown.bs.tab', function (e) {
                    document.getElementById(appointmentTypeId + 'name').focus();
                });
                $('#appointmentTypesSettingsTabList a[href="#' + appointmentTypeId + '"]').on('shown.bs.tab', function (e) {
                    document.getElementById(appointmentTypeId + 'name').focus();
                });
            } else {
                $('#calendarResourcesSettingsTabList a[href="#' + appointmentTypeId + '"]').tab('show');
                $('#appointmentsModuleSettingsTabList a[href="#' + calendarId + '"]').on('shown.bs.tab', function (e) {
                    document.getElementById(appointmentTypeId + 'name').focus();
                });
                $('#calendarResourcesSettingsTabList a[href="#' + appointmentTypeId + '"]').on('shown.bs.tab', function (e) {
                    document.getElementById(appointmentTypeId + 'name').focus();
                });
            }
        }
    });
}

function showAssignExternalAppointmentToExistingPatientModal(appointmentId) {
    $("#AssignExternalAppointmentToExistingPatientModalReplace").load("/" + appLocation + "getAssignExternalAppointmentToExistingPatientModal/" + appointmentId, function () {
        $("#assignExternalAppointmentToExistingPatientModal").modal({backdrop: 'static', keyboard: false});
    });
}

function assignExternalAppointmentToExistingPatient(appointmentId) {
    var patientId = $('#assignExternalAppointmentToExistingPatientValue').val();

    if (patientId) {
        $.ajax({
            url: "/" + appLocation + "assignExternalAppointmentToExistingPatient",
            context: document.body,
            type: 'post',
            data: {'appointmentId': appointmentId, 'patientId': patientId}
        }).done(function () {
            closeModals();
            actionSuccess();
            $('#calendar').fullCalendar('refetchEvents');
            loadWidgetToDashboard('doctorDashboard', 'waitingListWidget'); // update the widget to show the change
        }).fail(function () {
            actionFail();
        })
    }
}

//initialize patient search
function searchAppointments() {
    var searchParam = $('#appointmentSearchInput').val();
    if (searchParam.length > 2) {
        showLoaderIn('#AppointmentSearchResultList');
        $('#AppointmentSearchResultList').load("/" + appLocation + "searchAppointments/" + encodeURIComponent(searchParam), function () {
            $(".prettydate").prettyDate(); //Format Dates after loading
        });
    } else {
        actionFail("Bitte mind. 3 Zeichen eingeben.");
    }
}

function clearAppointmentSearch() {
    $('#appointmentSearchInput').val('');
    $('#AppointmentSearchResultList').html('');
}


/* default method to set a calendarResource setting */
function setPersonSetting(personId, setting, value) {
    $.ajax({
        url: "/" + appLocation + "setPersonSetting",
        context: document.body,
        type: 'post',
        data: {'personId': personId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setFullCalendarMinTime(value) {
    $('#calendar').fullCalendar('option', 'minTime', value + ':00');
}

function setFullCalendarMaxTime(value) {
    $('#calendar').fullCalendar('option', 'maxTime', value + ':00');
}

function setFullCalendarSlotDuration(value) {
    $('#calendar').fullCalendar('option', 'slotDuration', '00:' + value + ':00');
}

function calendarOptionSelected(showSelectServingHealthCareProviderDiv, availableServingHealthCareProviderCount) {

    var calendarId = $('#selectCalendar').val();
    var healthCareProviderId = $('#healthCareProviderId').val();

    //show this description and hide all other descriptions
    $('.descriptionForCalendar').hide();
    if ($('#descriptionForCalendar' + calendarId))
        $('#descriptionForCalendar' + calendarId).show();

    //load appointmentTypes
    showLoaderIn('#selectAppointmentTypeDiv');
    $('#selectAppointmentTypeDiv').load("/" + appLocation + "api/getSelectAppointmentTypeDiv/" + healthCareProviderId + "/" + calendarId, function () {
        $('#selectAppointmentTypeDiv').find('[autofocus]').focus();
    });

    //reset servingHealthCareProvider only if visible and size > 1
    if (showSelectServingHealthCareProviderDiv && availableServingHealthCareProviderCount > 1) {
        $('#selectServingHealthCareProviderDiv').html('<label class="control-label col-sm-4 col-xs-12">ÄrztIn/TherapeutIn:</label>\n' +
            '                    <div class="col-sm-8 col-xs-12">\n' +
            '                        <input type="text" class="form-control" readonly="readonly" value="Zuerst Terminart wählen"/>\n' +
            '                    </div>');
    }

    setCurrentBookingStep(1);
}


function appointmentTypeOptionSelected(showSelectServingHealthCareProviderDiv) {

    var appointmentTypeId = $('#selectAppointmentType').val();
    var healthCareProviderId = $('#healthCareProviderId').val();

    //show this description and hide all other descriptions
    $('.descriptionForAppointmentType').hide();
    if ($('#descriptionForAppointmentType' + appointmentTypeId))
        $('#descriptionForAppointmentType' + appointmentTypeId).show();

    if (showSelectServingHealthCareProviderDiv) {
        //load availableServingHealthCareProviders
        showLoaderIn('#selectServingHealthCareProviderDiv');
        $('#selectServingHealthCareProviderDiv').load("/" + appLocation + "api/getSelectServingHealthCareProviderDiv/" + healthCareProviderId + "/" + appointmentTypeId, function () {
            $('#selectServingHealthCareProviderDiv').find('[autofocus]').focus();
        });
        setCurrentBookingStep(1);
    } else {
        setCurrentBookingStep(2);
    }
}

function updateEndTimeFromAppointmentType() {
    var duration = $('#appointmentType').find(':selected').attr('duration');
    $('.datetimepicker-appointment-endTime').data("DateTimePicker").date($('.datetimepicker-appointment-startTime').data("DateTimePicker").date().add(duration, 'minutes'));
    $('.datetimepicker-appointment-endTime').effect("bounce", {distance: 5, times: 3}, "slow");
}

function updateCustomQuestionsFromAppointmentType() {
    var appointmentTypeID = document.getElementById("appointmentType").value;
    fetch("/" + appLocation + "getCustomQuestionsForAppointmentType?appointmentTypeID=" + appointmentTypeID)
        .then(function (response) {
            return response.text()
        })
        .then(function (html) {
            document.getElementById("CustomQuestions").innerHTML = html;
            $('#AppointmentsModal').modal('handleUpdate');
        });
}

function updateVisibilityOfDefaultReminders() {
    var showDefaultReminderSection = $('#appointmentType').find(':selected').attr('showDefaultReminderSection');

    if (showDefaultReminderSection == 'true')
        $("#defaultReminderSection").show();
    else
        $("#defaultReminderSection").hide();

}

function updateEndTimeFromStartTime(e, defaultDuration) {
    var oldStartDate = e.oldDate;
    var newStartDate = e.date;

    if (oldStartDate != newStartDate) {
        if ($('.datetimepicker-appointment-endTime').data("DateTimePicker")) {
            var oldEndDate = $('.datetimepicker-appointment-endTime').data("DateTimePicker").date();
            if (oldStartDate && oldEndDate) {
                // there is already a endTime - don't use duration from appointmentType but from oldDuration between start and end

                var oldDuration = moment.duration(oldEndDate.diff(oldStartDate));
                $('.datetimepicker-appointment-endTime').data("DateTimePicker").date(newStartDate.add(oldDuration.asMinutes(), 'minutes'));
            } else {
                // there is no endTime - try to find out the right duration from appointmentType or otherwise use default duration

                var duration = $('#appointmentType').find(':selected').attr('duration');
                if (!duration || duration == 0) {
                    duration = defaultDuration;
                }
                $('.datetimepicker-appointment-endTime').data("DateTimePicker").date(newStartDate.add(duration, 'minutes'));
            }
            $('.datetimepicker-appointment-endTime').effect("bounce", {distance: 5, times: 3}, "slow");
        }
    }
}

function insertAtCursorPosition(id, placeholders) {
    placeholders = placeholders.map(function (entry) {
        return {name: "#" + entry};
    });

    var commonPlaceholders = new Bloodhound({
        local: placeholders,
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 100,
    });

    commonPlaceholders.initialize();
    var $typeahead = $('#' + id + "-Typeahead" + '.typeahead');

    $typeahead.typeahead({
        hint: true,
    }, {
        name: 'commonPlaceholders',
        displayKey: 'name',
        source: commonPlaceholders.ttAdapter()
    });

    $typeahead.on('keyup', function (e) {
        if (e.which == 13) {
            $(".tt-suggestion:first-child").trigger('click');
        }
    });

    $typeahead.on('typeahead:closed', function (evt, item) {
        $(this).val("");
    });

    $typeahead.on('typeahead:selected', function (evt, item) {
        if (item && item.name) {
            var idSelector = '#' + id;
            $(idSelector).val($(idSelector).val() + item.name);
            setCount(idSelector);
        }
        $(this).val("");
    });

    $('#' + id).on("keyup", function () {
        setCount('#' + id)
    });
    setCount('#' + id);
}

function setCount(selector) {
    var length = $(selector).val().length;
    // var lengthSelector = $(selector).parent().siblings().find(".msg-length")
    var lengthSelector = $(selector).siblings().find(".msg-length");
    if (lengthSelector && lengthSelector.length > 0) {
        var smsCount = parseInt((length / 160)) + 1;
        var multi = "";
        if (smsCount <= 1) {
            lengthSelector.removeClass("alert-warning");
            multi = " (~ 1 Nachricht)";
        } else {
            lengthSelector.addClass("alert-warning");
            multi = " (~ " + smsCount + " Nachrichten)";
        }
        lengthSelector.html("Etwa " + length + " Zeichen." + multi + "<br> Beachten Sie, dass die Länge der ausgefüllten Platzhalter nicht mit eingerechnet ist und die versendete Nachricht länger sein kann!");
    }
}

function addWaitingListStatus() {
    $.ajax({
        url: "/" + appLocation + "addWaitingListStatus",
        context: document.body,
        type: 'post',
        data: {}
    }).done(function (id) {
        location.reload();
    }).fail(function (e) {
        actionFail();
    });
}

function setWaitingListStatusSetting(statusID, setting, value) {
    var showInMasterList = document.getElementById("showEntriesInMasterList" + statusID);
    var showInSeparateList = document.getElementById("showEntriesInSeparateList" + statusID);

    if (setting === "showInMasterList" && value === false && !showInSeparateList.checked) {
        showInSeparateList.checked = true;
        setWaitingListStatusSetting(statusID, "showInSeparateList", true);
    } else if (setting === "showInSeparateList" && value === false && !showInMasterList.checked) {
        showInMasterList.checked = true;
        setWaitingListStatusSetting(statusID, "showInMasterList", true);
    }

    $.ajax({
        url: "/" + appLocation + "setWaitingListStatusSetting",
        context: document.body,
        type: 'post',
        data: {
            statusID: statusID,
            setting: setting,
            value: value
        }
    }).done(function () {
        actionSuccess();
    }).fail(function (e) {
        actionFail();
    });
}

function cancelBookingLinkForm(element) {
    ;
    return false;
}

function newBookingLink(event, form) {
    event.preventDefault();

    const jFormData = $(form).serialize();
    if (jFormData === "") {
        refreshBookingLinks();
        return;
    }
    const formdata = new FormData(form)
    const data = {
        bookableCalendars: [],
        bookableAppointmentTypes: []
    };
    const id = formdata.get("id");
    if (id) {
        data.id = id;
    }
    data.title = formdata.get("title");
    data.description = formdata.get("description");

    $('input[name="bookableCalendars"]:checked').map((index, it) => data.bookableCalendars.push(it.value));
    $('input[name="bookableAppointmentTypes"]:checked').map((index, it) => data.bookableAppointmentTypes.push(it.value));
    $.ajax({
        url: "/" + appLocation + "bookinglink",
        context: document.body,
        type: 'post',
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
    }).done((data) => {
        actionSuccess();
        refreshBookingLinks();
        form.reset();
    }).fail((e) => {
        actionFail();
    });
    return false;
}

function resetFormBookingLinkForm(formId) {
    $('#bookingLinkID').val(undefined)

    $(formId).trigger("reset");

    $(':input', formId)
        .not(':button, :submit, :reset')
        .prop('checked', false)
        .prop('selected', false);
}

function refreshBookingLinks() {
    $.ajax({
        url: `/${appLocation}bookinglink`,
        context: document.body,
        type: 'get',
    }).done((response) => {
        resetFormBookingLinkForm("#bookingLinkForm");

        var source = document.getElementById("booking-link-template").innerHTML;
        Handlebars.registerHelper('json', function (context) {
            return JSON.stringify(context);
        });
        const template = Handlebars.compile(source);
        const listContainer = document.getElementById(`bookingLinkList`);
        if (listContainer)
            listContainer.innerHTML = template({bookingLinks: response.result});
    }).fail((e) => {
        actionFail();
    });

    return false;
}

function editBookingLink(id) {
    const form = $("#bookingLinkForm");
    const bookingLinkID = parseFloat($("#bookingLinkID").val());
    if (bookingLinkID === id) {
        return;
    }
    resetFormBookingLinkForm("#bookingLinkForm");
    $.ajax({
        url: `/${appLocation}bookinglink/${id}`,
        context: document.body,
        type: 'GET',
    }).done((response) => {
        const entity = response.result;
        $("#bookingLinkID").val(entity.id)
        $("#bookingLinkTitle").val(entity.title)
        $("#bookingLinkDescription").val(entity.description)
        entity.bookableAppointmentTypes.map((it, index) => $(`#bookableAppointmentTypes-${it.id}`).prop('checked', true))
        entity.bookableCalendars.map((it, index) => $(`#bookingLinkCalendar-${it.id}`).prop('checked', true))

    }).fail((e) => {
        actionFail();
    });
    return false;
}

function deleteBookingLink(id) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie den Link löschen wollen?", function () {
        $.ajax({
            url: `/${appLocation}bookinglink/${id}`,
            context: document.body,
            type: 'DELETE',
        }).done((response) => {
            $(`#boookinglinkline${id}`).remove();
        }).fail((e) => {
            actionFail();
        });
    });
    return false;
}

function regenerateBookingLink(id) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie den Link neu erzeugen wollen ? Aktuelle Links werden dadurch ungültig !", function () {
        $.ajax({
            url: `/${appLocation}bookinglink/regenerate/${id}`,
            context: document.body,
            type: 'POST',
        }).done((response) => {
            actionSuccess();
            refreshBookingLinks();
        }).fail((e) => {
            actionFail();
        });
    });


    return false;
}

function checkScheduledServiceDisplayOptions(calendarID) {
    var allDay = document.getElementById("showServicesAsAllDayEvent" + calendarID).checked;
    var background = document.getElementById("showServicesAsBackgroundEvent" + calendarID).checked;
    if (background && !allDay) {
        setTimeout(function () {
            $('#showServicesAsBackgroundEventWarning' + calendarID).popover("show");
        }, 250);
    }
}