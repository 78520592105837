function managePatient(managedPatientId){
    $.ajax({
        url: "/" + appLocation +  "managePatient",
        context: document.body,
        type: 'post',
        data: {'managedPatientId': managedPatientId}
    }).done(function () {
        location.reload();
        router.navigate(appLocation + 'Home',{trigger:true});
    }).fail(function () {
        actionFail("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.");
    })
}

function returnToManager(){
    $.ajax({
        url: "/" + appLocation + "returnToManager",
        context: document.body,
        type: 'post'
    }).done(function () {
        location.reload();
        router.navigate(appLocation + 'ManagedPatientsModule',{trigger:true});
    }).fail(function () {
        actionFail("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.");
    })
}

function deleteManagedPatientRelation(relationId){
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?",function() {
        $.ajax({
            url: "/" + appLocation +  "deleteManagedPatientRelation",
            context: document.body,
            type: 'post',
            data: {'relationId': relationId}
        }).done(function () {
            location.reload();
            router.navigate(appLocation + 'ManagedPatientsModule',{trigger:true});
            actionSuccess();
        }).fail(function () {
            actionFail("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.");
        })
    })
}

function confirmManager(relationId){
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie dieser Person vollständigen Zugriff auf Ihr Profil erlauben möchten?",function() {
        $.ajax({
            url: "/" + appLocation +  "confirmManager",
            context: document.body,
            type: 'post',
            data: {'relationId': relationId}
        }).done(function () {
            location.reload();
            router.navigate(appLocation + 'ManagedPatientsModule',{trigger:true});
            actionSuccess();
        }).fail(function () {
            actionFail("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.");
        })
    })
}

function addNewPatient(){
    $("#NewPatientModalReplace").load("/" + appLocation+"getNewPatientModal", function () {
        $("#NewPatientModal").modal({backdrop: 'static', keyboard: false});
        registerNewPatientForm();
    });
}

function registerNewPatientForm(){
    //Submit new Patient in Modal
    $("#NewPatientForm").submit(function (e) {
        //prevent double submitting
        $("#NewPatientFormSubmitButton").prop("disabled", true);
        $("#NewPatientFormSubmitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-newPatient-form").hide();
        $.ajax({
            url:  $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'newPatient');
            if (response.status == 'SUCCESS') {
                $('#NewPatientModal').modal("hide");
                location.reload();
                router.navigate(appLocation + 'ManagedPatientsModule',{trigger:true});
                actionSuccess();
            }
            //re-enable submit button if failed
            $("#NewPatientFormSubmitButton").prop("disabled", false);
            $("#NewPatientFormSubmitButton").prop('value', "Senden");
        }).fail(function (error) {
            showAjaxError(error, 'newPatient');
            //re-enable submit button if failed
            $("#NewPatientFormSubmitButton").prop("disabled", false);
            $("#NewPatientFormSubmitButton").prop('value', "Senden");
        });
        $("#error-msg-newPatient-form").show('fast');
        return false;
    });
}

function manageExistingPatient(){
    $("#ManageExistingPatientModalReplace").load("/" + appLocation+"getManageExistingPatientModal", function () {
        $("#ManageExistingPatientModal").modal({backdrop: 'static', keyboard: false});
        registerManageExistingPatientForm();
    });
}

function registerManageExistingPatientForm(){
    //Submit new Patient in Modal
    $("#ManageExistingPatientForm").submit(function (e) {
        //prevent double submitting
        $("#ManageExistingPatientFormSubmitButton").prop("disabled", true);
        $("#ManageExistingPatientFormSubmitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-manageExistingPatient-form").hide();
        $.ajax({
            url:  $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'manageExistingPatient');
            if (response.status == 'SUCCESS') {
                $('#ManageExistingPatientModal').modal("hide");
                location.reload();
                router.navigate("ManagedPatientsModule",{trigger:true});
                actionSuccess();
            }
            //re-enable submit button if failed
            $("#ManageExistingPatientFormSubmitButton").prop("disabled", false);
            $("#ManageExistingPatientFormSubmitButton").prop('value', "Senden");
        }).fail(function (error) {
            showAjaxError(error, 'manageExistingPatient');
            //re-enable submit button if failed
            $("#ManageExistingPatientFormSubmitButton").prop("disabled", false);
            $("#ManageExistingPatientFormSubmitButton").prop('value', "Senden");
        });
        $("#error-msg-manageExistingPatient-form").show('fast');
        return false;
    });
}
