function registerNewExternalContactFormSubmitButton() {
    $('.newExternalContactForm').submit(function () {
        //prevent double submitting
        var submitButtonId = 'newExternalContactSubmitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $('.error-msg').remove();
        $('#error-msg-newExternalContact-form').hide();

        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $('#error-msg-newExternalContact-form').show('fast');
            showAjaxResponse(response, 'newExternalContact');

            if (response.status == 'SUCCESS') {
                $('#UpdateExternalContactModal').hide();
                reloadCurrentContent();
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $('#error-msg-newExternalContact-form').show('fast');
            showAjaxError(error, 'newExternalContact');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function updateExternalContact(externalContactId, copy) {
    const copyEntry = copy ? "?copy=true" : ""
    $('#UpdateExternalContactsModalReplace').load('/' + appLocation + 'getUpdateExternalContactModal/' + externalContactId + copyEntry, function () {
        $('#UpdateExternalContactModal').modal({backdrop: 'static', keyboard: false});
    });
}


function deleteExternalContact(externalContactId) {
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + `deleteExternalContact/${externalContactId}`,
            context: document.body,
            type: 'DELETE',
        }).done(function () {
            actionSuccess();
            $(`#${externalContactId}-contact-row`).html("")
        }).fail(function () {
            actionFail();
        })
    });
}


function initializeExternalContactSearch() {
    $("#externalContactSearchInputField").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            filterExternalContactList(this.value);
            return false;
        }
    });

    $("#externalContactSearchGoButton").click(() => {
        //when uses clicks search button
        filterExternalContactList($("#externalContactSearchInputField").val());
    });
}

function initializeNewExternalContactSearch() {
    $("#newExternalContactSearchInputField").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            filterNewExternalContactList(this.value);
            return false;
        }
    });

    $("#newExternalContactSearchGoButton").click(() => {
        //when uses clicks search button
        filterNewExternalContactList($("#newExternalContactSearchInputField").val());
    });
}

function initializeExternalContactSearch() {
    $("#externalContactSearchInputField").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            filterExternalContactList(this.value);
            return false;
        }
    });

    $("#externalContactSearchGoButton").click(() => {
        //when uses clicks search button
        filterExternalContactList($("#externalContactSearchInputField").val());
    });
}


function filterExternalContactList(searchParam) {
    if (searchParam.length > 0) {
        loadExternalContacts(searchParam);
    } else {
        loadAllExternalContacts();
    }
}

function filterNewExternalContactList(searchParam) {
    if (searchParam.length > 0) {
        loadNewExternalContacts(searchParam);
    } else {
        loadAllNewExternalContacts();
    }
}

function loadExternalContacts(filter) {
    showLoaderIn("#ExternalContactList");
    $("#ExternalContactList").load(
        "/" + appLocation + "loadExternalContacts/" + encodeURIComponent(filter)
    );
}

function loadExternalContacts(id) {
    showLoaderIn("#ExternalContactList");
    $("#ExternalContactList").load(
        "/" + appLocation + "loadExternalContacts/" + encodeURIComponent(id)
    );
}

function loadNewExternalContacts(filter) {
    showLoaderIn("#NewExternalContactList");
    $("#NewExternalContactList").load(
        "/" + appLocation + "loadNewExternalContacts/" + encodeURIComponent(filter)
    );
}

function loadAllExternalContacts() {
    showLoaderIn("#ExternalContactList");
    $("#ExternalContactList").load("/" + appLocation + "loadAllExternalContacts");
}

function loadAllNewExternalContacts() {
    showLoaderIn("#NewExternalContactList");
    $("#NewExternalContactList").load("/" + appLocation + "loadAllNewExternalContacts");
}
