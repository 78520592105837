/*
sections:
- global variables and initialization
- AJAX default CRUD framework
- help functions
- AJAX load data in blocks
- AJAX Load Data in Main Content Div
- solves the fragment login problem
- search all doctors page
- widgets action
- combobox - autosuggest for selects
*/

/* -------------------------------------------- */
/* section: global variables and initialization */
/* -------------------------------------------- */

let currentContent;
let currentRequestParameters;
let currentTabId;
let currentPage; //is -1 if there is no pagination
let tempCurrentPatientContext; //check if value is set correct when used, not refreshed automatically!
let refreshID;
let unreadOnly; //show only unread messages?
let before;
let after;
let loggedInWiki;
let router;
let appLocation;
let scrollTop;
let contentIsLoading;
let queuedContent;
let healthCareProviderId;
let bookingLinkUuid;
let styleSmallMenu;
let printQueueIntervalSet;
let externalCallsWidgetIntervalSet;
let externalCallsListPatientIntervalSet;

const navigationPredicates = [];

function isNavigationSafe() {
    return navigationPredicates.every(predicate => predicate());
}

window.addEventListener("beforeunload", function (event) {
    if (!isNavigationSafe()) event.preventDefault();
});

function initialize(appLoc) {
    appLocation = appLoc;
    before = 1000000;
    after = 0;
    loggedInWiki = false;
    scrollTop = 0;
    contentIsLoading = false;
    queuedContent = null;
    unreadOnly = false;
    printQueueIntervalSet = false;
    externalCallsWidgetIntervalSet = false;
    externalCallsListPatientIntervalSet = false;

    $.fn.select2.defaults.set("theme", "bootstrap");
    $.fn.select2.defaults.set("placeholder", "");

    /* override javascript alert - use bootstrap modal instead */
    /* info: not used at the moment - looking bad when mixed with modals*/
    /*(function(proxied) {
     window.alert = function(message) {

     bootbox.dialog({
     message: message,
     title: "Hinweis",
     buttons: {
     success: {
     label: "OK",
     className: "btn-success"
     }
     }
     });

     return proxied.apply(this, arguments);
     };
     })();*/


    //remove leading / for routing

    const sessionNotification = new Audio('/app/resources/sound/sessionNotification.wav');
    const timeoutPopup = function () {
        sessionNotification.play();
        const currentTime = new Date();
        currentTime.setMinutes(currentTime.getMinutes() + 15);
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let formattedTime = hours + ':' + minutes;
        customConfirm(
            "Sind Sie noch da?",
            "Sie haben längere Zeit nicht mehr mit unserem Server kommuniziert" +
            " und werden deshalb um " + formattedTime + " aus Sicherheitsgründen abgemeldet." +
            " Wenn Sie weiterhin angemeldet bleiben möchten, klicken Sie auf 'Sitzung verlängern'," +
            " insbesondere damit ungespeicherte Daten nicht verloren gehen.",
            () => {
                $.ajax({
                    url: "/" + appLocation + "keep-alive",
                    context: document.body,
                    type: 'post',
                    data: {}
                })
            }, () => {
            },
            "Sitzung verlängern", "Ignorieren"
        );
    }
    let timeoutID = -1;
    const resetTimeout = function () {
        if (timeoutID != -1) // only clear the timeout if it already exists
            clearTimeout(timeoutID);
        // check if the sessionTimeout is defined
        // any sessionTimeout smaller than 1s should be disregarded
        if (typeof sessionTimeout !== "undefined" && sessionTimeout >= 1000)
            timeoutID = setTimeout(timeoutPopup, sessionTimeout);
    }
    $.ajaxSetup({
        complete: function (xhr, status) {
            if (status === "success") resetTimeout();
        }
    });
    document.addEventListener("submit", resetTimeout, true);
    resetTimeout();

    const patientViewRegex = new RegExp("app/PatientView[^/]*/(\\d+)");
    let patientId = undefined;

    //routing with backbone.js
    let myRouter = Backbone.Router.extend({
        routes: {
            "": "Home",
            app: "Home",
            "/": "Home",
            "app/": "Home",
            Home: "Home",
            "app/Home": "Home",
            Help: "Help",
            "app/Help": "Help",
            RestartTour: "RestartTour",
            "app/RestartTour": "RestartTour",
            StartTourPackageCode: "StartTourPackageCode",
            "app/StartTourPackageCode": "StartTourPackageCode",
            "app/ChangePassword": "ChangePassword",
            ChangePassword: "ChangePassword",
            "app/ContactData": "ContactData",
            ContactData: "ContactData",
            "app/MedicalPractices": "MedicalPractices",
            MedicalPractices: "MedicalPractices",
            "app/ProfilePicture": "ProfilePicture",
            ProfilePicture: "ProfilePicture",
            "app/Personalization": "Personalization",
            Personalization: "Personalization",
            "app/DocumentStyleInformation": "DocumentStyleInformation",
            DocumentStyleInformation: "DocumentStyleInformation",
            "app/GenericDocumentTypes": "GenericDocumentTypes",
            GenericDocumentTypes: "GenericDocumentTypes",
            "app/ConcernTypes": "ConcernTypes",
            ConcernTypes: "ConcernTypes",
            "app/ReimbursementItems(/:billNumberGroupId)": "ReimbursementItems",
            "ReimbursementItems(/:billNumberGroupId)": "ReimbursementItems",
            "app/Identification": "Identification",
            Identification: "Identification",
            "app/ReturnFromIdentification": "Identification",
            ReturnFromIdentification: "Identification",
            "app/TextBlocks": "TextBlocks",
            TextBlocks: "TextBlocks",
            "app/AppointmentsModuleSettings(/:calendarId)(/:appointmentTypeId)":
                "AppointmentsModuleSettings",
            "AppointmentsModuleSettings(/:calendarId)(/:appointmentTypeId)":
                "AppointmentsModuleSettings",
            "app/Admin/DashboardAdministration(/:dashboardTypeId)(/:widgetTypeId)":
                "DashboardAdministration",
            "Admin/DashboardAdministration(/:dashboardTypeId)(/:widgetTypeId)":
                "DashboardAdministration",

            "app/Admin/CapabilityManager": "AdminCapabilityManager",
            "Admin/CapabilityManager": "AdminCapabilityManager",

            "app/Admin/AdminTools": "AdminTools",
            "Admin/AdminTools": "AdminTools",

            "app/Admin/AdminMessages": "AdminMessagesManager",
            "Admin/AdminMessages": "AdminMessagesManager",

            "app/PromotionSettings(/:promotionId)": "PromotionSettings",
            "PromotionSettings(/:promotionId)": "PromotionSettings",
            "app/AutoSuggestionSettings": "AutoSuggestionSettings",
            AutoSuggestionSettings: "AutoSuggestionSettings",

            "app/CompoundTemplates": "CompoundTemplates",
            CompoundTemplates: "CompoundTemplates",

            "app/WikiPagesSettings": "WikiPagesSettings",
            WikiPagesSettings: "WikiPagesSettings",

            "app/ReminderTemplates": "ReminderTemplates",
            ReminderTemplates: "ReminderTemplates",

            "app/RecallTypes": "RecallTypes",
            RecallTypes: "RecallTypes",

            "app/CapabilityManager": "CapabilityManager",
            CapabilityManager: "CapabilityManager",

            "app/CustomWidgetSettings": "CustomWidgetSettings",
            CustomWidgetSettings: "CustomWidgetSettings",
            "app/AnamnesisQuestionnaireTypes": "AnamnesisQuestionnaireTypes",
            AnamnesisQuestionnaireTypes: "AnamnesisQuestionnaireTypes",
            "Admin/AnamnesisQuestionnaireTypes(/:questionnaireTabs)":
                "AnamnesisQuestionnaireTypesModule",
            "app/Admin/AnamnesisQuestionnaireTypes(/:questionnaireTabs)":
                "AnamnesisQuestionnaireTypesModule",
            "app/Settings": "Settings",
            Settings: "Settings",
            "app/NotificationSettings": "NotificationSettings",
            NotificationSettings: "NotificationSettings",
            "app/ReturnFromReady2Order": "ApiSettings",
            ReturnFromReady2Order: "ApiSettings",
            "app/ApiSettings": "ApiSettings",
            ApiSettings: "ApiSettings",
            "app/CustomStyles": "CustomStyles",
            CustomStyles: "CustomStyles",
            "app/InvoiceSummary": "InvoiceSummary",
            InvoiceSummary: "InvoiceSummary",
            "app/DeleteAccount": "DeleteAccount",
            DeleteAccount: "DeleteAccount",
            "app/ConnectId": "ConnectId",
            ConnectId: "ConnectId",
            "app/Feedback": "Feedback",
            Feedback: "Feedback",
            "app/Message/:id": "Message",
            "Message/:id": "Message",
            "app/Concern/:id": "Concern",
            "Concern/:id": "Concern",
            "app/Booking/:id": "Booking",
            "Booking/:id": "Booking",
            "app/api/onlineBookingFrame/:id/bookingStep/1": "ApiBookingStep1",
            "api/onlineBookingFrame/:id/bookingStep/1": "ApiBookingStep1",
            "app/onlineBookingFrame/:id/bookingStep/1": "BookingStep1",
            "onlineBookingFrame/:id/bookingStep/1": "BookingStep1",
            "app/api/onlineBookingFrame/:id/bookingStep/2": "ApiBookingStep2",
            "api/onlineBookingFrame/:id/bookingStep/2": "ApiBookingStep2",
            "app/onlineBookingFrame/:id/bookingStep/2": "BookingStep2",
            "onlineBookingFrame/:id/bookingStep/2": "BookingStep2",
            "app/api/onlineBookingFrame/:id/bookingStep/3": "ApiBookingStep3",
            "api/onlineBookingFrame/:id/bookingStep/3": "ApiBookingStep3",
            "app/onlineBookingFrame/:id/bookingStep/3": "BookingStep3",
            "onlineBookingFrame/:id/bookingStep/3": "BookingStep3",
            "app/api/onlineBookingFrame/:id/bookingStep/4": "ApiBookingStep4",
            "api/onlineBookingFrame/:id/bookingStep/4": "ApiBookingStep4",
            "app/onlineBookingFrame/:id/bookingStep/4": "BookingStep4",
            "onlineBookingFrame/:id/bookingStep/4": "BookingStep4",
            "app/api/onlineBookingFrame/:id/bookingStep/done": "ApiBookingStepDone",
            "api/onlineBookingFrame/:id/bookingStep/done": "ApiBookingStepDone",
            "app/onlineBookingFrame/:id/bookingStep/done": "BookingStepDone",
            "onlineBookingFrame/:id/bookingStep/done": "BookingStepDone",
            "app/CreateSystemAccountFromAppointment/:appointmentId":
                "CreateSystemAccountFromAppointment",
            "app/CreateRelativeFromAccount/:accountId": "CreateRelativeFromAccount",
            "CreateSystemAccountFromAppointment/:appointmentId":
                "CreateSystemAccountFromAppointment",
            "CreateRelativeFromAccount/:accountId": "CreateRelativeFromAccount",
            "app/ApproachDoctorModule/:id": "ApproachDoctorModule",
            "ApproachDoctorModule/:id": "ApproachDoctorModule",
            "app/PrescriptionRequest/:id": "PrescriptionRequest",
            "PrescriptionRequest/:id": "PrescriptionRequest",
            "app/ReferralRequest/:id": "ReferralRequest",
            "ReferralRequest/:id": "ReferralRequest",
            "app/OnlineConsultation/:id": "OnlineConsultation",
            "OnlineConsultation/:id": "OnlineConsultation",
            "app/TreatingDoctorRequest/:id": "TreatingDoctorRequest",
            "TreatingDoctorRequest/:id": "TreatingDoctorRequest",
            "app/ExternalCallRequest/:id": "ExternalCallRequest",
            "ExternalCallRequest/:id": "ExternalCallRequest",
            "app/Messages/:page": "Messages",
            "Messages/:page": "Messages",
            "app/Concerns/:page": "Concerns",
            "Concerns/:page": "Concerns",
            "app/PrescriptionRequests/:page": "PrescriptionRequests",
            "PrescriptionRequests/:page": "PrescriptionRequests",
            "app/ReferralRequests/:page": "ReferralRequests",
            "ReferralRequests/:page": "ReferralRequests",
            "app/OnlineConsultations/:page": "OnlineConsultations",
            "OnlineConsultations/:page": "OnlineConsultations",
            "app/TreatingDoctorRequests/:page": "TreatingDoctorRequests",
            "TreatingDoctorRequests/:page": "TreatingDoctorRequests",
            "app/ExternalCallRequests/:page": "ExternalCallRequests",
            "ExternalCallRequests/:page": "ExternalCallRequests",
            "app/doctorDashboard/:page": "doctorDashboard",
            "doctorDashboard/:page": "doctorDashboard",
            "app/patientDashboard/:page": "patientDashboard",
            "patientDashboard/:page": "patientDashboard",
            "app/adminDashboard/:page": "adminDashboard",
            "adminDashboard/:page": "adminDashboard",
            "app/PatientsModuleAddPatient": "PatientsModuleAddPatient",
            PatientsModuleAddPatient: "PatientsModuleAddPatient",
            "app/PatientView/:id(/:page)": "PatientView",
            "PatientView/:id(/:page)": "PatientView",
            "app/PatientViewPatientRecords/:id(/:page)": "PatientViewPatientRecords",
            "PatientViewPatientRecords/:id(/:page)": "PatientViewPatientRecords",
            "app/PatientViewPHREntries/:id(/:page)": "PatientViewPHREntries",
            "PatientViewPHREntries/:id(/:page)": "PatientViewPHREntries",
            "app/PatientViewAnamnesisQuestionnaires/:id(/:page)":
                "PatientViewAnamnesisQuestionnaires",
            "PatientViewAnamnesisQuestionnaires/:id(/:page)":
                "PatientViewAnamnesisQuestionnaires",
            "app/PatientViewDocuments/:id(/:page)": "PatientViewDocuments",
            "PatientViewDocuments/:id(/:page)": "PatientViewDocuments",
            "app/PatientViewClinicalExaminations/:id(/:typeId)(/:page)": "PatientViewClinicalExaminations",
            "PatientViewClinicalExaminations/:id(/:typeId)(/:page)": "PatientViewClinicalExaminations",
            "app/PatientViewBills/:id(/:page)": "PatientViewBills",
            "PatientViewBills/:id(/:page)": "PatientViewBills",
            "app/NewUnbilledItems/:id(/:tab)(/:page)": "PatientViewNewUnbilledItems",
            "NewUnbilledItems/:id(/:tab)(/:page)": "PatientViewNewUnbilledItems",
            "app/PatientViewCollectiveBills/:id(/:page)":
                "PatientViewCollectiveBills",
            "PatientViewCollectiveBills/:id(/:page)": "PatientViewCollectiveBills",
            "app/PatientViewReimbursementBills/:id(/:page)":
                "PatientViewReimbursementBills",
            "PatientViewReimbursementBills/:id(/:page)":
                "PatientViewReimbursementBills",
            "app/PatientViewMessages/:id(/:page)": "PatientViewMessages",
            "PatientViewMessages/:id(/:page)": "PatientViewMessages",
            "app/PatientViewAppointments/:id(/:page)": "PatientViewAppointments",
            "PatientViewAppointments/:id(/:page)": "PatientViewAppointments",
            "app/PatientViewContactDetails/:id(/:page)": "PatientViewContactDetails",
            "PatientViewContactDetails/:id(/:page)": "PatientViewContactDetails",
            "app/PatientViewConnect/:id(/:page)": "PatientViewConnect",
            "PatientViewConnect/:id(/:page)": "PatientViewConnect",

            "app/PatientViewNotes/:id(/:page)": "PatientViewNotes",
            "PatientViewNotes/:id(/:page)": "PatientViewNotes",

            "app/PatientViewLabor/:id(/:page)": "PatientViewLabor",
            "PatientViewLabor/:id(/:page)": "PatientViewLabor",

            "app/PatientViewTodos/:id(/:page)": "PatientViewTodos",
            "PatientViewTodos/:id(/:page)": "PatientViewTodos",

            "app/PatientViewRecalls/:id(/:page)": "PatientViewRecalls",
            "PatientViewRecalls/:id(/:page)": "PatientViewRecalls",

            "app/PatientViewMediprimeApp/:id(/:page)": "PatientViewMediprimeApp",
            "PatientViewMediprimeApp/:id(/:page)": "PatientViewMediprimeApp",

            "app/PatientViewMonitoring/:id(/:page)": "PatientViewMonitoring",
            "PatientViewMonitoring/:id(/:page)": "PatientViewMonitoring",
            "app/PatientViewPregnancyCalculator/:id(/:page)":
                "PatientViewPregnancyCalculator",
            "PatientViewPregnancyCalculator/:id(/:page)":
                "PatientViewPregnancyCalculator",
            "app/PatientViewExternalCalls/:id(/:page)": "PatientViewExternalCalls",
            "PatientViewExternalCalls/:id(/:page)": "PatientViewExternalCalls",
            "app/PatientViewImages/:id(/:page)": "PatientViewImages",
            "PatientViewImages/:id(/:page)": "PatientViewImages",
            "app/DoctorsPublicPage/:id": "DoctorsPublicPage",
            "DoctorsPublicPage/:id": "DoctorsPublicPage",
            "app/NewMessage/:receiverId": "NewMessage",
            "NewMessage/:receiverId": "NewMessage",
            "app/NewConcern/:receiverId": "NewConcern",
            "NewConcern/:receiverId": "NewConcern",
            "app/NewReferralRequest/:receiverId": "NewReferralRequest",
            "NewReferralRequest/:receiverId": "NewReferralRequest",
            "app/NewOnlineConsultation/:receiverId": "NewOnlineConsultation",
            "NewOnlineConsultation/:receiverId": "NewOnlineConsultation",
            "app/NewExternalCallRequest/:receiverId": "NewExternalCallRequest",
            "NewExternalCallRequest/:receiverId": "NewExternalCallRequest",
            "app/NewPatientMessage/:receiverId": "NewPatientMessage",
            "NewPatientMessage/:receiverId": "NewPatientMessage",
            "app/NewSendMedia/:receiverId": "NewSendMedia",
            "NewSendMedia/:receiverId": "NewSendMedia",
            "app/NewAnamnesisQuestionnaire/:receiverId": "NewAnamnesisQuestionnaire",
            "NewAnamnesisQuestionnaire/:receiverId": "NewAnamnesisQuestionnaire",
            "app/SecondOpinion/:receiverId": "SecondOpinion",
            "SecondOpinion/:receiverId": "SecondOpinion",
            "app/SendDocument/:documentId/:documentName/:createdAt(/:patientId)":
                "SendDocument",
            "SendDocument/:documentId/:documentName/:createdAt(/:patientId)":
                "SendDocument",
            "app/NewDrug": "NewDrug",
            NewDrug: "NewDrug",

            "StartPayment/:packageTypeEntry": "StartPayment",
            "app/StartPayment/:packageTypeEntry": "StartPayment",
            "ActivateFreePackage/:packageTypeEntry/:createdFor":
                "ActivateFreePackage",
            "app/ActivateFreePackage/:packageTypeEntry/:createdFor":
                "ActivateFreePackage",
            "Payment/:packageTypeEntry/:createdFor/:paymentMethod": "PreparePayment",
            "app/Payment/:packageTypeEntry/:createdFor/:paymentMethod":
                "PreparePayment",
            "PaymentSuccess/:packageId": "PaymentSuccess",
            "app/PaymentSuccess/:packageId": "PaymentSuccess",
            "PackageStore/:voucherCode": "EnterVoucherCode",
            "app/PackageStore/:voucherCode": "EnterVoucherCode",

            "app/MediprimeAppModule": "MediprimeAppModule",
            "app/MediprimeAppModule?*query": "MediprimeAppModule",
            "MediprimeAppModule": "MediprimeAppModule",
            "MediprimeAppModule?*query": "MediprimeAppModule",
            "PatientViewMediprimeApp/:patientId/:page?*query": "PatientViewMedipimeApp",
            "app/PatientViewMediprimeApp/:patientId/:page?*query": "PatientViewMedipimeApp",
            "app/Admin/:site": "loadAdminSite",
            "Admin/:site": "loadAdminSite",
            "app/:site": "loadSite",
            "app/:site/:id": "loadSite",
            ":site": "loadSite",
        },

        checkPendingChanges: function (route, params) {
            if (!isNavigationSafe()) {
                if (!confirm('Sie haben ungespeicherte Änderungen. Möchten sie diese Aktion wirklich durchführen?')) {
                    Backbone.history.history.back();
                    return false;
                }
            }
            return true;
        },

        navigate: function (fragment, options) {
            const newPatientId = patientViewRegex.exec(fragment)?.[1];
            if (patientId && patientId !== newPatientId && !isNavigationSafe()) {
                if (!confirm('Sie haben ungespeicherte Änderungen! Möchten Sie diese Aktion wirklich durchführen, auch wenn die eingegebenen Daten dadurch verloren gehen?')) {
                    return false;
                } else {
                    imagesToUpload.length = 0;
                }
            }
            patientId = newPatientId;
            Backbone.Router.prototype.navigate.call(this, fragment, options);
        },

        Home: function () {
            loadMainDashboard();
        },
        Settings: function () {
            loadToMainContentDiv("Profile");
        },
        ContactData: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#contactData"]').tab("show");
            });
        },
        MedicalPractices: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#medicalPractices"]').tab("show");
            });
        },
        ChangePassword: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#password"]').tab("show");
            });
        },
        ProfilePicture: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#picture"]').tab("show");
            });
        },
        Personalization: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#personalization"]').tab("show");
            });
        },
        DocumentStyleInformation: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#documentStyleInformation"]').tab("show");
            });
        },
        GenericDocumentTypes: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#genericDocumentTypes"]').tab("show");
            });
        },
        ConcernTypes: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#concernTypes"]').tab("show");
            });
        },
        ReimbursementItems: function (billNumberGroupId) {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#reimbursementItems"]').tab("show");

                if (billNumberGroupId) {
                    window.setTimeout(function () {
                        $(
                            '#billNumberGroupSettingsTabList a[href="#billNumberGroup' +
                            billNumberGroupId +
                            '"]'
                        ).tab("show");
                    }, 1000);
                }
            });
        },
        TextBlocks: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#autoSuggestion"]').tab("show");
            });
        },
        AnamnesisQuestionnaireTypes: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#anamnesisQuestionnaireTypes"]').tab("show");
            });
        },
        AnamnesisQuestionnaireTypesModule: function (questionnaireTabId) {
            loadToMainContentDiv(
                "AnamnesisQuestionnaireTypesModule",
                null,
                function () {
                    // shownProfileAppointmentsModuleSettings = true;
                    $("#" + questionnaireTabId).tab("show"); // a[href="#genericQuestionnaireTabList"]').tab('show');
                    // loadAppointmentSettings(questionnaireTabId, appointmentTypeId);
                }
            );
        },
        Identification: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#identification"]').tab("show");
            });
        },
        AutoSuggestionSettings: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#autoSuggestion"]').tab("show");
            });
        },
        CompoundTemplates: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#compoundTemplates"]').tab("show");
            });
        },
        WikiPagesSettings: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#wikiPagesSettings"]').tab("show");
            });
        },
        ReminderTemplates: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#reminderTemplates"]').tab("show");
            });
        },
        RecallTypes: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#recallTypes"]').tab("show");
            });
        },
        CapabilityManager: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#capabilityManager"]').tab("show");
            });
        },

        CustomWidgetSettings: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#customWidgets"]').tab("show");
            });
        },
        NotificationSettings: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#notificationSettings"]').tab("show");
            });
        },
        ApiSettings: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#apiSettings"]').tab("show");
            });
        },
        CustomStyles: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#customStyles"]').tab("show");
            });
        },
        ConnectId: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#connectId"]').tab("show");
            });
        },
        DeleteAccount: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#delete"]').tab("show");
            });
        },
        InvoiceSummary: function () {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#invoiceSummary"]').tab("show");
            });
        },
        RestartTour: function () {
            restartPatientStartTour();
        },
        StartTourPackageCode: function () {
            startPackageCodeTour();
        },
        PatientsModuleAddPatient: function () {
            loadToMainContentDiv("PatientsModule", null, function () {
                $('#patientsModuleTabList a[href="#newPatientTab"]').tab("show");
            });
        },
        PatientView: function (id, page) {
            closeJSPanel(function () {
                loadToMainContentDivPage("PatientView/" + id, page);
                currentTabId = null;
            });
        },
        PatientViewPatientRecords: function (id, page) {
            loadPatientView(id, "PatientRecords", page);
        },
        PatientViewAnamnesisQuestionnaires: function (id, page) {
            loadPatientView(id, "AnamnesisQuestionnaires", page);
        },
        PatientViewPHREntries: function (id, page) {
            loadPatientView(id, "PHREntries", page);
        },
        PatientViewDocuments: function (id, page) {
            loadPatientView(id, "Documents", page);
        },
        PatientViewClinicalExaminations: function (id, tabId, page) {
            loadPatientView(id, "ClinicalExaminations", page, null, function () {
                showTabInPatientViewModule("clinicalExaminationTabList", tabId);
            });
        },
        PatientViewBills: function (id, page) {
            loadPatientView(id, "Bills", page);
        },
        PatientViewNewUnbilledItems: function (id, tab, page) {
            loadPatientView(id, "Bills", page, null, function () {
                showNewUnbilledItemsDialog(tab);
            });
        },
        PatientViewCollectiveBills: function (id, page) {
            loadPatientView(id, "CollectiveBills", page);
        },
        PatientViewReimbursementBills: function (id, page) {
            loadPatientView(id, "ReimbursementBills", page);
        },
        PatientViewMessages: function (id, page) {
            loadPatientView(id, "Messages", page);
        },
        PatientViewAppointments: function (id, page) {
            loadPatientView(id, "Appointments", page);
        },
        PatientViewContactDetails: function (id, page) {
            loadPatientView(id, "ContactDetails", page);
        },
        PatientViewConnect: function (id, page) {
            loadPatientView(id, "Connect", page);
        },
        PatientViewNotes: function (id, page) {
            loadPatientView(id, "Notes", page);
        },
        PatientViewLabor: function (id, page) {
            loadPatientView(id, "Labor", page);
        },
        PatientViewTodos: function (id, page) {
            loadPatientView(id, "Todos", page);
        },
        PatientViewRecalls: function (id, page) {
            loadPatientView(id, "Recalls", page);
        },
        PatientViewMonitoring: function (id, page) {
            loadPatientView(id, "Monitoring", page);
        },
        PatientViewPregnancyCalculator: function (id, page) {
            loadPatientView(id, "PregnancyCalculator", page);
        },
        PatientViewExternalCalls: function (id, page) {
            loadPatientView(id, "ExternalCalls", page);
        },
        PatientViewImages: function (id, page) {
            loadPatientView(id, "Images", page);
        },
        DoctorsPublicPage: function (id) {
            loadToMainContentDiv("DoctorsPublicPage/" + id);
        },
        loadSite: function (site, id) {
            if (site !== "Admin") {
                if (id) site = site + "/" + id;
                loadToMainContentDiv(site);
            }
        },
        loadAdminSite: function (site) {
            loadToMainContentDivAdmin(site);
        },
        Help: function () {
            loadToMainContentDiv("Help");
        },
        Feedback: function () {
            loadToMainContentDiv("Help", "feedbackText");
        },
        Message: function (id) {
            loadToMainContentDiv("Message/" + id, "message" + id, function () {
                $(".showAllMessagesActions").show();
            });
        },
        Concern: function (id) {
            loadToMainContentDiv("Concern/" + id, "message" + id, function () {
                $(".showAllMessagesActions").show();
            });
        },
        CreateSystemAccountFromAppointment: function (appointmentId) {
            loadToMainContentDiv(
                "CreateSystemAccountFromAppointment/" + appointmentId,
                null,
                function () {
                    $('#patientsModuleTabList a[href="#newPatientTab"]').tab("show");
                }
            );
        },
        CreateRelativeFromAccount: function (accountId) {
            loadToMainContentDiv(
                "CreateRelativeFromAccount/" + accountId,
                null,
                function () {
                    $('#patientsModuleTabList a[href="#newPatientTab"]').tab("show");
                }
            );
        },
        Booking: function (id) {
            loadToMainContentDiv("Booking", null, function () {
                //callback to select correct doctor
                $("#bookingDoctor").val(id);
                showBookingFrame(id);
            });
        },
        ApiBookingStep1: function (urlHealthCareProviderId) {
            goToBookingStep1();
        },
        BookingStep1: function (urlHealthCareProviderId) {
            if (healthCareProviderId) {
                goToBookingStep1();
            } else {
                loadToMainContentDiv("Booking", null, function () {
                    //callback to select correct doctor
                    $("#bookingDoctor").val(urlHealthCareProviderId);
                    showBookingFrame(urlHealthCareProviderId);
                });
            }
        },
        ApiBookingStep2: function (urlHealthCareProviderId) {
            goToBookingStep2();
        },
        BookingStep2: function (urlHealthCareProviderId) {
            if (healthCareProviderId) {
                goToBookingStep2();
            } else {
                loadToMainContentDiv("Booking", null, function () {
                    //callback to select correct doctor
                    $("#bookingDoctor").val(urlHealthCareProviderId);
                    showBookingFrame(urlHealthCareProviderId);
                });
            }
        },
        ApiBookingStep3: function (urlHealthCareProviderId) {
            goToBookingStep3();
        },
        BookingStep3: function (urlHealthCareProviderId) {
            if (healthCareProviderId) {
                goToBookingStep3();
            } else {
                loadToMainContentDiv("Booking", null, function () {
                    //callback to select correct doctor
                    $("#bookingDoctor").val(urlHealthCareProviderId);
                    showBookingFrame(urlHealthCareProviderId);
                });
            }
        },
        ApiBookingStep4: function (urlHealthCareProviderId) {
            goToBookingStep4();
        },
        BookingStep4: function (urlHealthCareProviderId) {
            if (healthCareProviderId) {
                goToBookingStep4();
            } else {
                loadToMainContentDiv("Booking", null, function () {
                    //callback to select correct doctor
                    $("#bookingDoctor").val(urlHealthCareProviderId);
                    showBookingFrame(urlHealthCareProviderId);
                });
            }
        },
        ApiBookingStepDone: function (urlHealthCareProviderId) {
            goToBookingStep1();
        },
        BookingStepDone: function (urlHealthCareProviderId) {
            loadToMainContentDiv("Booking", null, function () {
                //callback to select correct doctor
                $("#bookingDoctor").val(urlHealthCareProviderId);
                showBookingFrame(urlHealthCareProviderId);
            });
        },
        AppointmentsModuleSettings: function (calendarId, appointmentTypeId) {
            loadToMainContentDiv("Profile", null, function () {
                shownProfileAppointmentsModuleSettings = true;
                $('#profileTabList a[href="#appointmentsModuleSettings"]').tab("show");
                loadAppointmentSettings(calendarId, appointmentTypeId);
            });
        },
        DashboardAdministration: function (dashboardTypeId, widgetTypeId) {
            loadToMainContentDivAdmin("DashboardAdministration", null, function () {
                if (dashboardTypeId) {
                    $(
                        '#dashboardAdministrationTabList a[href="#' + dashboardTypeId + '"]'
                    ).tab("show");
                }
                if (widgetTypeId) {
                    $('#widgetTypesSettingsTabList a[href="#' + widgetTypeId + '"]').tab(
                        "show"
                    );
                }
            });
        },
        AdminCapabilityManager: function (capabilitiyTypeId, widgetTypeId) {
            loadToMainContentDivAdmin("CapabilityManager", null, function () {
                if (capabilitiyTypeId) {
                    $(
                        '#capabilityManagerTabList a[href="#' + capabilitiyTypeId + '"]'
                    ).tab("show");
                }
            });
        },
        AdminMessagesManager: function (adminMessagesTypeId, widgetTypeId) {
            loadToMainContentDivAdmin("AdminMessagesManager", null, function () {
                if (adminMessagesTypeId) {
                    $(
                        '#adminMessagesManagerTabList a[href="#' +
                        adminMessagesTypeId +
                        '"]'
                    ).tab("show");
                }
            });
        },
        AdminTools: function (adminToolsTypeId, widgetTypeId) {
            loadToMainContentDivAdmin("AdminTools", null, function () {
                if (adminToolsTypeId) {
                    $('#adminToolsTabList a[href="#' + adminToolsTypeId + '"]').tab(
                        "show"
                    );
                }
            });
        },
        PromotionSettings: function (promotionId) {
            loadToMainContentDivAdmin("PackageTypesModule", null, function () {
                $('#promotionSettingsTabList a[href="#' + promotionId + '"]').tab(
                    "show"
                );
            });
        },
        ApproachDoctorModule: function (id) {
            loadToMainContentDiv("ApproachDoctorModule/" + id);
        },
        PrescriptionRequest: function (id) {
            loadToMainContentDiv(
                "PrescriptionRequest/" + id,
                "message" + id,
                function () {
                    $(".showAllMessagesActions").show();
                }
            );
        },
        ReferralRequest: function (id) {
            loadToMainContentDiv(
                "ReferralRequest/" + id,
                "message" + id,
                function () {
                    $(".showAllMessagesActions").show();
                }
            );
        },
        OnlineConsultation: function (id) {
            loadToMainContentDiv(
                "OnlineConsultation/" + id,
                "message" + id,
                function () {
                    $(".showAllMessagesActions").show();
                }
            );
        },
        TreatingDoctorRequest: function (id) {
            loadToMainContentDiv(
                "TreatingDoctorRequest/" + id,
                "message" + id,
                function () {
                    $(".showAllMessagesActions").show();
                }
            );
        },
        ExternalCallRequest: function (id) {
            loadToMainContentDiv(
                "ExternalCallRequest/" + id,
                "message" + id,
                function () {
                    $(".showAllMessagesActions").show();
                }
            );
        },
        PatientMessage: function (id) {
            loadToMainContentDiv("PatientMessage/" + id, "message" + id, function () {
                $(".showAllMessagesActions").show();
            });
        },
        Messages: function (page) {
            loadToMainContentDivPage(
                "Messages",
                page,
                null,
                null
            );
        },
        Concerns: function (page) {
            loadToMainContentDivPage("Concerns", page);
        },
        PrescriptionRequests: function (page) {
            loadToMainContentDivPage("PrescriptionRequests", page);
        },
        ReferralRequests: function (page) {
            loadToMainContentDivPage("ReferralRequests", page);
        },
        OnlineConsultations: function (page) {
            loadToMainContentDivPage("OnlineConsultations", page);
        },
        TreatingDoctorRequests: function (page) {
            loadToMainContentDivPage("TreatingDoctorRequests", page);
        },
        ExternalCallRequests: function (page) {
            loadToMainContentDivPage("ExternalCallRequests", page);
        },
        doctorDashboard: function () {
            loadMainDashboard();
        },
        patientDashboard: function (page) {
            loadMainDashboard();
        },
        adminDashboard: function (page) {
            loadWidgetToDashboard("adminDashboard", "newsfeed", null, null, page);
        },
        NewExternalCallRequest: function (receiverId) {
            loadToMainContentDiv("NewExternalCallRequest/" + receiverId);
        },
        NewReferralRequest: function (receiverId) {
            loadToMainContentDiv("NewReferralRequest/" + receiverId);
        },
        NewOnlineConsultation: function (receiverId) {
            loadToMainContentDiv("NewOnlineConsultation/" + receiverId);
        },
        NewPatientMessage: function (receiverId) {
            loadToMainContentDiv("NewPatientMessage/" + receiverId);
        },
        NewSendMedia: function (receiverId) {
            loadToMainContentDiv("NewSendMedia/" + receiverId);
        },
        NewAnamnesisQuestionnaire: function (receiverId) {
            loadToMainContentDiv("NewAnamnesisQuestionnaire/" + receiverId);
        },
        SecondOpinion: function (receiverId) {
            loadToMainContentDiv("SecondOpinion/" + receiverId);
        },
        NewConcern: function (receiverId) {
            let focusId;

            if (receiverId == 0) focusId = "selectReceiver";
            else focusId = "selectConcernType";

            loadToMainContentDiv(
                "NewConcern/" + receiverId,
                focusId, //autofocus
                function () {
                    //callback to select correct doctor
                    $("#selectReceiver").val(receiverId);
                    if (receiverId != 0) $("#selectReceiver").removeClass("error");
                }
            );
        },
        NewMessage: function (receiverId) {
            let focusId;

            if (receiverId) focusId = "subject";
            else focusId = "messageReceiver";

            loadToMainContentDiv(
                "Messages",
                null, //autofocus
                function () {
                    //callback to select correct receiver
                    openDialog("Message");
                    $("#" + focusId)
                        .focus()
                        .select();

                    setComboBoxValue("messageReceiver", receiverId);
                }
            );
        },
        SendDocument: function (documentId, documentName, createdAt, patientId) {
            let documentNameWithGlyphiconAndLink = $(
                "#documentName" + documentId
            ).html();
            if (patientId != null) {
                loadPatientView(patientId, "Messages", null, null, function () {
                    navigateInPatientView(patientId, "Messages");
                    openDialog("Message");

                    $("#subject").val(documentName);
                    $("#text").focus().select();
                    //setComboBoxValue("messageReceiver", patientId);

                    //save attachment to attachments array (necessary to delete)
                    let attachment = attachments.push({
                        id: documentId,
                        name: documentNameWithGlyphiconAndLink,
                        date: createdAt,
                        idSuffix: "",
                    });
                    //append it to html container and increase attachment count (hidden input id)
                    appendAttachment(attachments[attachment - 1]);
                    attachmentCount++;
                });
            } else {
                loadToMainContentDiv("Messages", null, function () {
                    openDialog("Message");

                    $("#subject").val(documentName);

                    if (patientId != null) {
                        $("#text").focus().select();

                        setComboBoxValue("messageReceiver", patientId);
                    } else {
                        $("#selectReceiver").focus().select();
                    }

                    //save attachment to attachments array (necessary to delete)
                    let attachment = attachments.push({
                        id: documentId,
                        name: documentNameWithGlyphiconAndLink,
                        date: createdAt,
                        idSuffix: "",
                    });
                    //append it to html container and increase attachment count (hidden input id)
                    appendAttachment(attachments[attachment - 1]);
                    attachmentCount++;
                });
            }
        },
        NewDrug: function () {
            loadToMainContentDiv("CoreData", "coreDataDrugs", function () {
                update("Drugs", 0, null, "phrDrugsTableForPatient");
            });
        },
        StartPayment: function (packageTypeEntry) {
            loadToMainContentDiv("StartPayment/" + packageTypeEntry);
        },
        PreparePayment: function (packageTypeEntry, createdFor, paymentMethod) {
            loadToMainContentDiv(
                "Payment/" + packageTypeEntry + "/" + createdFor + "/" + paymentMethod
            );
        },
        ActivateFreePackage: function (packageTypeEntry, createdFor) {
            loadToMainContentDiv(
                "ActivateFreePackage/" + packageTypeEntry + "/" + createdFor
            );
        },
        PaymentSuccess: function (packageId) {
            loadToMainContentDiv("PaymentSuccess/" + packageId);
        },
        EnterVoucherCode: function (voucherCode) {
            loadToMainContentDiv("PackageStore", null, function () {
                toggleDiv("voucherCodeDiv", "voucherCodeDivChevron");
                $("#voucherCodeInput").val(voucherCode);
                $("#voucherCodeInput").focus();
            });
        },
        MediprimeAppModule: function (query) {
            loadToMainContentDiv("MediprimeAppModule?" + query);
        },
        PatientViewMediprimeApp: function (patientId, page, query) {
            if (! page) page = 0;
            let entityId = null;
            if (query) {
                const params = new URLSearchParams(query);
                entityId = params.get("selectedEntity");
            }
            if (! document.getElementById("mediprimeAppFilterSelectedEntity")) {
                const selection = document.body.appendChild(document.createElement("input"));
                selection.type = "hidden";
                selection.id = "mediprimeAppFilterSelectedEntity";
            }
            document.getElementById("mediprimeAppFilterSelectedEntity").value = entityId;
            loadPatientView(patientId, "MediprimeApp", page, null);
        },
    });

    /* Close all popovers on click outside */
    $("body").on("click", function (e) {
        //did not click a popover toggle, or icon in popover toggle, or popover
        if (
            $(e.target).data("toggle") !== "popover" &&
            $(e.target).parents('[data-toggle="popover"]').length === 0 &&
            $(e.target).parents(".popover.in").length === 0
        ) {
            $('[data-toggle="popover"]').popover("hide");
        }
    });

    $(document).click(function (e) {
        if (e.target.id == "popovercloseid") {
            $(".popoverclose").popover("hide");
        }
    });

    //Submit new Vital in Dialog
    $(document).ready(function () {
        $("#newvitalform").submit(function (e) {
            $(".error-msg").remove();
            $("#error-msg-vitalDialog-form").hide();
            $.ajax({
                url: $(this).attr("action"),
                context: document.body,
                type: "post",
                data: $(this).serialize(),
            })
                .done(function (response) {
                    showAjaxResponse(response, "vitalDialog");
                    if (response.status == "SUCCESS") {
                        instantCloseDialogs();
                        reloadCurrentContent();
                    }
                })
                .fail(function (error) {
                    showAjaxError(error, "vitalDialog");
                });
            $("#error-msg-vitalDialog-form").show("fast");
            return false;
        });
    });

    /* Initialize offcanvas menu - default is largeMenu */
    $("[data-toggle=offcanvas]").click(function () {
        $(this).find("i").toggleClass("glyphicon-backward glyphicon-forward");
        if ($(this).prop("title") == "Menü minimieren") {
            $(this).prop("title", "Menü einblenden");
        } else {
            $(this).prop("title", "Menü minimieren");
        }
        $(".row-offcanvas").toggleClass("active");
        $(".menuEntryText").toggle();
        $(".menuHeader").toggle();
        $(".menuEntry").toggleClass("smallMenu");
        $(".menuBadge").toggleClass("smallMenu");
        $("#menu").toggleClass("isSmallMenu");
    });

    $(".menuEntry.navigate").click(function () {
        $(".menuEntrySelected").toggleClass("menuEntrySelected");
        $(this).addClass("menuEntrySelected");
    });

    //show small menu if set in settings or on mobile view
    if (styleSmallMenu || $("#visible-xs").is(":visible")) {
        $("[data-toggle=offcanvas]").click();
    }

    $("#menu").show();

    // toggle new comment form
    $(document).on("click", "button.new-comment", function (evt) {
        let $root = $(this).parents(".feedentry");
        let $comment = $root.find(".message-comment");
        $comment.slideToggle();
        let $commentButtonText = $root.find("#commentButtonText");

        let text = $commentButtonText.html();
        let newText = "Abbrechen";

        if (text === "Antwort verfassen") {
            initUserSummernote($comment.find("textarea"));
        } else if (text === "Abbrechen") {
            newText = "Antwort verfassen";
            destroySummernoteFor($comment.find("textarea"));
        }

        $commentButtonText.html(newText);
    });

    // toggle hidden comments
    $(document).on("click", "button.toggle-inner-messages", function () {
        let $comments = $(this).parents(".feedentry").find(".inner-messages");
        let $hiddenComments = $comments.find("[hiddenComment=true]");
        let $icon = $(this).find("span.glyphicon");
        let $buttonText = $(this).find("span.buttonText");

        $hiddenComments.slideToggle();
        $icon
            .toggleClass("glyphicon glyphicon-chevron-up")
            .toggleClass("glyphicon glyphicon-chevron-down");

        let $buttonShowText = "Weitere Kommentare (" + $hiddenComments.length + ")";
        let $buttonHideText =
            "Kommentare ausblenden (" + $hiddenComments.length + ")";
        $buttonText.html(
            $buttonText.html() == $buttonShowText ? $buttonHideText : $buttonShowText
        );
    });

    //action for newReplyForm submit
    $(document).on("submit", ".newreplyform", function (e) {
        let $comment = $(this).parents(".message-comment");
        let nr = $comment.attr("data-id");
        let receiver = $comment.attr("data-receiver");
        let subject = $comment.attr("data-resubject");

        e.preventDefault();

        $("#newreply" + nr + " #replyToId").val(nr);
        $("#newreply" + nr + " #receiver").val(receiver);
        $("#newreply" + nr + " #resubject").val("Re: " + subject);

        //prevent double clicking
        $("#newreply" + nr + " #submitReplyButton").prop("disabled", true);
        $("#newreply" + nr + " #submitReplyButton").prop(
            "value",
            "Bitte warten ..."
        );
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                showAjaxResponse(response, "newReply");
                if (response.status == "SUCCESS") {
                    instantCloseDialogs();
                    currentPage = -1; //the message will be on the top of the first page, because its the newest!
                    reloadCurrentContent("message" + nr);
                    closeModals();
                    destroySummernoteFor($("#replytext"));
                    //reset attachment count
                    attachmentCount = 0;
                }
                $("#newreply" + nr + " #submitReplyButton").prop("disabled", false);
                $("#newreply" + nr + " #submitReplyButton").prop("value", "Senden");
            })
            .fail(function (error) {
                showAjaxError(error, "reply");
                //$(this).find("#error-msg-reply").show(500);
                $("#newreply" + nr + " #submitReplyButton").prop("disabled", false);
                $("#newreply" + nr + " #submitReplyButton").prop("value", "Senden");
            });
    });
    //Submit new Message in Dialog
    $(document).ready(function () {
        $("#newmessageform").submit(function (e) {
            $(".error-msg").remove();
            $("#error-msg-message-form").hide();
            $.ajax({
                url: $(this).attr("action"),
                context: document.body,
                type: "post",
                data: $(this).serialize(),
            })
                .done(function (response) {
                    /*$("#error-msg-message-form").hide();*/

                    if (response.status == "SUCCESS") {
                        instantCloseDialogs();
                        reloadCurrentContent();
                    } else {
                        showAjaxResponse(response, "message");
                    }
                })
                .fail(function (error) {
                    showAjaxError(error, "message");
                });
            return false;
        });
    });

    /* start the router */
    router = new myRouter();
    Backbone.history.start({pushState: true});

    prefetchModal();

    connectSocket().then(() => console.debug("creating socket connection to server"))

    Gino.errorHandler = function (error) {
        actionFail(error);
    };
}

// open a pop up and check if the browser allows to do so
// Note: The message is copied to helper.ts - when changing one, also change the other.
function openPopUpWindowWithBrowserPermissionCheck(openedWindow) {
    if (openedWindow == null || typeof openedWindow == "undefined") {
        alert(
            "Achtung! Ihr Browser verhindert es, dass docsy Pop-Ups öffnen kann (siehe Warn-Symbol, meist rechts in der URL Zeile). " +
            "Damit docsy korrekt funktioniert, müssen Sie Pop-Ups für docsy immer erlauben. " +
            'Bitte ändern Sie daher in Ihrem Browser diese Einstellung für docsy auf "Pop-Ups immer zulassen" und rufen Sie den Link anschließend erneut auf.'
        );
    } else {
        openedWindow.focus();
    }
}

//used in patient view - change tabs --> don't reload page, but refresh browser url and current content
// if something is reloaded or refreshed by the patient

function navigateInPatientView(patientId, tabName, trigger) {
    if (tabName === "Connect") getLastPatientContact(patientId)
    setCurrentContentAndBrowserUrl(
        "PatientView/" + patientId,
        trigger ? trigger : false,
        "PatientView" + tabName + "/" + patientId,
        "patientView" + tabName
    );
}

function setCurrentContentAndBrowserUrl(
    content,
    trigger,
    divergentBrowserUrl,
    tabId
) {
    currentContent = content;
    currentRequestParameters = "";
    currentTabId = tabId;
    browserUrl = divergentBrowserUrl ? divergentBrowserUrl : currentContent;
    router.navigate(appLocation + browserUrl, {trigger: trigger});
}

//routes to page if not already there, otherwise performs a action, when already on the target page (e.g. reload a list)
function routeToPageOrPerformAction(pageName, action) {
    targetRoute = appLocation + pageName;
    if (Backbone.history.getFragment() == targetRoute) {
        action();
    } else {
        router.navigate(targetRoute, {trigger: true});
    }
}

/* ---------------------------------------- */
/* section: AJAX default CRUD framework     */
/* info: used for all kinds of datalistings */
/* ---------------------------------------- */
// 'deleteById(\'PatientRecord\','+${patientRecord.key.id} +',null,' + ${patientRecord.key.createdFor.id} + ');'
//Delete (set inactive) items with id
function deleteById(
    item,
    id,
    callback,
    patientId,
    tableId,
    requirementId,
    input
) {
    customConfirm(
        "Bitte bestätigen",
        "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
        function () {
            if (!patientId) {
                patientId = -1;
            }
            showLoaderIn("." + item + "Block");
            $.ajax({
                url: "/" + appLocation + "delete" + item,
                context: document.body,
                type: "post",
                data: {id: id},
            })
                .done(function () {
                    actionSuccess();
                    if (patientId == -1) {
                        reload(item, patientId, callback, tableId, input, requirementId);
                    } else {
                        if (item == "UnbilledItemsList") loadUnbilledItemsList(patientId);
                        else if (item == "Documents") refreshDocumentsForPatient(patientId);
                        else if (item == "Bills") refreshBillsForPatient(patientId);
                        else if (item == "PatientRecords" || item == "PatientRecord")
                            refreshPatientRecordsForPatient(
                                patientId,
                                tableId,
                                requirementId,
                                input
                            );
                        else if (item == "Drugs")
                            refreshDrugsForPatient(patientId, tableId, input, requirementId);
                        else if (item == "Diagnoses")
                            refreshDiagnosesForPatient(
                                patientId,
                                tableId,
                                requirementId,
                                input
                            );
                        else if (item == "Allergies")
                            refreshAllergiesForPatient(
                                patientId,
                                tableId,
                                requirementId,
                                input
                            );
                        else if (item == "DietarySupplements")
                            refreshDietarySupplementsForPatient(
                                patientId,
                                tableId,
                                requirementId,
                                input
                            );
                        else if (item == "MedicalAids")
                            refreshMedicalAidsForPatient(
                                patientId,
                                tableId,
                                requirementId,
                                input
                            );
                        else if (item == "Therapies")
                            refreshTherapiesForPatient(
                                patientId,
                                tableId,
                                requirementId,
                                input
                            );
                        else if (item == "Vaccinations")
                            refreshVaccinationsForPatient(
                                patientId,
                                tableId,
                                requirementId,
                                input
                            );
                        else
                            reload(item, patientId, callback, tableId, input, requirementId);
                    }
                })
                .fail(function () {
                    reload(item, patientId, callback, tableId);
                    actionFail("Der Eintrag konnte nicht gelöscht werden.");
                });
        }
    );
}

function reload(item, id, callback, tableId, input, requirementId) {
    if (id != null && id != -1) {
        loadById(
            item,
            id,
            callback,
            false,
            tableId,
            undefined,
            undefined,
            undefined,
            input
        );
    } else {
        // function load(text, callback, noScroll, tableId, selectionForRequirementWithId, questionnaireObjectId, questionnaireObjectClassName, input) {
        load(
            item,
            callback,
            false,
            tableId,
            requirementId,
            undefined,
            undefined,
            input
        );
    }
}

//Delete (set inactive) items with id for admin
function deleteByIdAdmin(item, id) {
    customConfirm(
        "Bitte bestätigen",
        "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
        function () {
            showLoaderIn("." + item + "Block");
            $.ajax({
                url: "/" + appLocation + "admin/delete" + item,
                context: document.body,
                type: "post",
                data: {id: id},
            })
                .done(function (response) {
                    loadAdmin(item);
                    actionSuccess();
                })
                .fail(function (error) {
                    actionFail(error);
                });
        }
    );
}

//Update or create items with id (patientId for doctors creating data for their patients)
function update(item, id, patientId, tableId, callback, input, requirementId) {
    if (!patientId) {
        patientId = -1;
    }

    $("#" + item + "ModalReplace").load(
        "/" + appLocation + "get" + item + "Modal/" + id + "/" + patientId,
        function () {
            const modal = $("#" + item + "Modal");
            const form = $(modal).find("form:first")[0];
            modal.modal({backdrop: "static", keyboard: false}).on("hide.bs.modal", function () {
                if (formChangesPending(form)) {
                    customConfirm("Sind Sie sicher, dass Sie das Fenster schließen möchten?", "Alle nicht gespeicherten Formulardaten gehen dabei verloren!", () => {
                        setFormHasChanges(form, false);
                        modal.modal("hide");
                    });
                    return false;
                }
            });
            if (callback != null && typeof callback === "function") {
                callback();
            }
            registerModalSubmitButtons(patientId, tableId, input, requirementId);
            observeForm(form);
        }
    );
}

//Update or create items with id for admin
function updateAdmin(item, id) {
    $("#" + item + "ModalReplace").load(
        "/" + appLocation + "admin/get" + item + "Modal/" + id,
        function () {
            $("#" + item + "Modal").modal({backdrop: "static", keyboard: false});
            registerModalSubmitButtonsAdmin();
        }
    );
}

//registerModalSubmitButtons
//subject Id --> mostly the patients Id for documents or phr entries - used by doctor to create data for them
/**
 * @param {{response.result.createdDocumentUrl:string}} response
 */
function registerModalSubmitButtons(patientId, tableId, input, requirementId) {
    registerPatientRecordModalSubmitButton(
        patientId,
        tableId,
        requirementId,
        input
    );
    //Submit new Appointment in Modal
    $("#newappointmentmodal").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "appointmentModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-appointment-form").empty();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                showAjaxResponse(response, "appointment");
                if (response.status == "SUCCESS") {
                    $("#AppointmentsModal").modal("hide");
                    actionSuccess();
                    if (patientId != null && patientId != -1) {
                        //created in patientView -->
                        refreshAppointmentsForPatient(patientId);
                    } else {
                        //created in appointmentsModule, don't reload, create event in frontEnd
                        $("#calendar").fullCalendar("refetchEvents");
                    }

                    //print document if one was created
                    if (response.result && response.result.createdDocumentUrl && $('#printNextAppointmentTemplate').is(':checked'))
                        openUrlInNewTabAndPrint(response.result.createdDocumentUrl)

                } else {
                    // only open external patient part if there is a error in this section
                    for (let i = 0; i < response.result.length; i++) {
                        if (response.result[i].field.startsWith("external")) {
                            $(".optionalFieldsAppointmentExternalAccount").show();
                            $(".optionalFieldsAppointmentSystemAccount").hide();

                            $("#chevronForOptionalFieldsAppointment").attr(
                                "class",
                                "glyphicon glyphicon-chevron-up"
                            );
                            $("#toggleTextForOptionalFieldsAppointment").text(
                                "Bestehender Patient"
                            );
                        }
                    }
                    $("#AppointmentsModal").modal("handleUpdate");
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                showAjaxError(error, "appointment");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Edit Document Form (Modal)
    $("#editDocumentForm").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "documentModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-document-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-document-form").show("fast");
                showAjaxResponse(response, "document", "DocumentsModal");
                if (response.status == "SUCCESS") {
                    $("#DocumentsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshDocumentsForPatient(patientId);
                    } else {
                        load("Documents");
                    }
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-document-form").show("fast");
                showAjaxError(error, "document");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new Document in Modal
    $("#newDocumentForm").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "documentModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-document-form").hide();

        let myForm = new FormData(this);

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: myForm,
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(function (response) {
                $("#error-msg-document-form").show("fast");
                showAjaxResponse(response, "document", "DocumentsModal");
                if (response.status == "SUCCESS") {
                    $("#DocumentsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshDocumentsForPatient(patientId);
                    } else {
                        load("Documents");
                    }
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-document-form").show("fast");
                showAjaxError(error, "document");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Edit Finding Form (Modal)
    $("#editFindingForm").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "findingModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-finding-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-finding-form").show("fast");
                showAjaxResponse(response, "finding", "FindingsModal");
                if (response.status == "SUCCESS") {
                    $("#FindingsModal").modal("hide");
                    load("Findings");
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-finding-form").show("fast");
                showAjaxError(error, "finding");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new Finding in Modal
    $("#newFindingForm").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "findingModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-finding-form").hide();

        let myForm = new FormData(this);

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: myForm,
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(function (response) {
                $("#error-msg-finding-form").show("fast");
                showAjaxResponse(response, "finding", "FindingsModal");
                if (response.status == "SUCCESS") {
                    $("#FindingsModal").modal("hide");
                    load("Findings");
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-finding-form").show("fast");
                showAjaxError(error, "finding");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new Diagnosis in Modal
    $("#newdiagnosismodal").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "diagnosisModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-diagnosis-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-diagnosis-form").show("fast");
                showAjaxResponse(response, "diagnosis");
                if (response.status == "SUCCESS") {
                    $("#DiagnosesModal").modal("hide");

                    if (patientId != null && patientId != -1) {
                        refreshDiagnosesForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Diagnoses", patientId, null, tableId, input, requirementId);
                    }
                } else if (response.status == "SUCCESS-CREATENEXT") {
                    $("#DiagnosesModal").modal("hide");

                    if (patientId != null && patientId != -1) {
                        refreshDiagnosesForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Diagnoses", patientId, null, tableId, input, requirementId);
                    }
                    update('Diagnoses', 0, patientId, tableId, function () {
                        document.getElementById("diagnosesForm-createNext").checked = true;
                    }, input, requirementId);
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-diagnosis-form").show("fast");
                showAjaxError(error, "diagnosis");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new Vaccination in Modal
    $("#newvaccinationmodal").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "vaccinationModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-vaccination-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-vaccination-form").show("fast");
                showAjaxResponse(response, "vaccination");
                if (response.status == "SUCCESS") {
                    $("#VaccinationsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshVaccinationsForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Vaccinations", patientId, null, tableId, input, requirementId);
                    }
                } else if (response.status == "SUCCESS-CREATENEXT") {
                    $("#VaccinationsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshVaccinationsForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Vaccinations", patientId, null, tableId, input, requirementId);
                    }
                    update('Vaccinations', 0, patientId, tableId, function () {
                        document.getElementById("vaccinationForm-createNext").checked = true;
                    }, input, requirementId);
                } else {
                    reload(
                        "Vaccinations",
                        patientId,
                        null,
                        tableId,
                        input,
                        requirementId
                    );
                }
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            })
            .fail(function (error) {
                $("#error-msg-vaccination-form").show("fast");
                showAjaxError(error, "vaccination");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new Allergy in Modal
    $("#newallergymodal").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "allergyModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-allergy-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-allergy-form").show("fast");
                showAjaxResponse(response, "allergy");
                if (response.status == "SUCCESS") {
                    $("#AllergiesModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshAllergiesForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Allergies", patientId, null, tableId, input, requirementId);
                    }
                } else if (response.status == "SUCCESS-CREATENEXT") {
                    $("#AllergiesModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshAllergiesForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Allergies", patientId, null, tableId, input, requirementId);
                    }
                    update('Allergies', 0, patientId, tableId, function () {
                        document.getElementById("allergiesForm-createNext").checked = true;
                    }, input, requirementId);
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-allergy-form").show("fast");
                showAjaxError(error, "allergy");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new MedicalAid in Modal
    $("#newmedicalAidmodal").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "medicalAidModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-medicalAid-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-medicalAid-form").show("fast");
                showAjaxResponse(response, "medicalAid");
                if (response.status == "SUCCESS") {
                    $("#MedicalAidsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshMedicalAidsForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload(
                            "MedicalAids",
                            patientId,
                            null,
                            tableId,
                            input,
                            requirementId
                        );
                    }
                } else if (response.status == "SUCCESS-CREATENEXT") {
                    $("#MedicalAidsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshMedicalAidsForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload(
                            "MedicalAids",
                            patientId,
                            null,
                            tableId,
                            input,
                            requirementId
                        );
                    }
                    update('MedicalAids', 0, patientId, tableId, function () {
                        document.getElementById("medicalAidsForm-createNext").checked = true;
                    }, input, requirementId);
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-medicalAid-form").show("fast");
                showAjaxError(error, "medicalAid");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new Therapy in Modal
    $("#newtherapymodal").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "therapyModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-therapy-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-therapy-form").show("fast");
                showAjaxResponse(response, "therapy");
                if (response.status == "SUCCESS") {
                    $("#TherapiesModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshTherapiesForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Therapies", patientId, null, tableId, input, requirementId);
                    }
                } else if (response.status == "SUCCESS-CREATENEXT") {
                    $("#TherapiesModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshTherapiesForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload("Therapies", patientId, null, tableId, input, requirementId);
                    }
                    update('Therapies', 0, patientId, tableId, function () {
                        document.getElementById("therapiesForm-createNext").checked = true;
                    }, input, requirementId);
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-therapy-form").show("fast");
                showAjaxError(error, "therapy");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    //Submit new DietarySupplement in Modal
    $("#newdietarySupplementmodal").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "dietarySupplementModalSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-dietarySupplement-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-dietarySupplement-form").show("fast");
                showAjaxResponse(response, "dietarySupplement");
                if (response.status == "SUCCESS") {
                    $("#DietarySupplementsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshDietarySupplementsForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload(
                            "DietarySupplements",
                            patientId,
                            null,
                            tableId,
                            input,
                            requirementId
                        );
                    }
                } else if (response.status == "SUCCESS-CREATENEXT") {
                    $("#DietarySupplementsModal").modal("hide");
                    if (patientId != null && patientId != -1) {
                        refreshDietarySupplementsForPatient(
                            patientId,
                            tableId,
                            requirementId,
                            input
                        );
                    } else {
                        reload(
                            "DietarySupplements",
                            patientId,
                            null,
                            tableId,
                            input,
                            requirementId
                        );
                    }
                    update('DietarySupplements', 0, patientId, tableId, function () {
                        document.getElementById("supplementsForm-createNext").checked = true;
                    }, input, requirementId);
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-dietarySupplement-form").show("fast");
                showAjaxError(error, "dietarySupplement");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });

    // console.log("trace: initializeDrugForm; with", {tableId: tableId});
    //Submit new Drug in Modal
    initializeDrugForm(
        function () {
            if (patientId != null && patientId != -1) {
                refreshDrugsForPatient(patientId, tableId, input, requirementId);
            } else {
                reload("Drugs", patientId, null, tableId, input, requirementId);
            }
        },
        function (response) {
            if (patientId != null && patientId != -1) {
                refreshDrugsForPatient(patientId, tableId, input, requirementId);
            } else {
                reload("Drugs", patientId, null, tableId, input, requirementId);
            }
            // response.result = helper class CreatedDocumentResponse
            // document was created with signature block --> sign it
            if (response.result.withSignaturePlaceholder) {
                loadDocumentToSign(
                    response.result.documentId,
                    //Callback what should be done when document is signed (finish button clicked)
                    function () {
                        customConfirm(
                            "Erfolg",
                            "Das Dokument wurde erfolgreich signiert. Möchten Sie es jetzt versenden?",
                            //confirm
                            function () {
                                // load documents to get necessary new created data (glyphicon and correct link)
                                loadById("Documents", response.result.patientId, function () {
                                    router.navigate(
                                        appLocation +
                                        "SendDocument/" +
                                        response.result.documentId +
                                        "/" +
                                        response.result.documentName +
                                        "/" +
                                        response.result.createdAt +
                                        "/" +
                                        response.result.patientId,
                                        {trigger: true}
                                    );
                                });
                            },
                            //cancel
                            function () {
                                maybePrint(
                                    "printPrescriptionNow",
                                    response.result.createdDocumentUrl
                                );
                                refreshDocumentsForPatient(patientId);
                            }
                        );
                    },
                    //callback when user canceled action
                    function () {
                        maybePrint(
                            "printPrescriptionNow",
                            response.result.createdDocumentUrl
                        );
                        refreshDocumentsForPatient(patientId);
                    }
                );
            } else {
                maybePrint("printPrescriptionNow", response.result.createdDocumentUrl);
                navigateInPatientView(patientId, "Documents");
            }
        },
        tableId
    );

    //Submit Doctor referral templates in Modal
    $("#doctorReferralTemplatesForm").submit(function (e) {
        //prevent double submitting
        let submitButtonId = "doctorReferralTemplateSubmitButton";
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-doctorReferralTemplate-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                $("#error-msg-doctorReferralTemplate-form").show("fast");
                showAjaxResponse(response, "doctorReferralTemplate");
                if (response.status == "SUCCESS") {
                    $("#DoctorReferralTemplateModal").modal("hide");
                    load("DoctorReferralTemplates");
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            })
            .fail(function (error) {
                $("#error-msg-doctorReferralTemplate-form").show("fast");
                showAjaxError(error, "doctorReferralTemplate");
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
        return false;
    });
}

//registerModalSubmitButtonsAdmin
function registerModalSubmitButtonsAdmin() {
    //Submit new feed type in Modal
    $("#newfeedtypemodal").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-infostreams-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                showAjaxResponse(response, "infostreams");
                if (response.status == "SUCCESS") {
                    $("#InfostreamsModal").modal("hide");
                    loadAdmin("Infostreams");
                }
            })
            .fail(function (error) {
                showAjaxError(error, "infostreams");
            });
        $("#error-msg-infostreams-form").show("fast");
        return false;
    });

    //Submit new feed entry in Modal
    $("#newfeedentrymodal").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-feedEntry-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                showAjaxResponse(response, "infostreams");
                if (response.status == "SUCCESS") {
                    $("#FeedEntryModal").modal("hide");
                    // loadAdmin("Infostreams");
                    $("#feedEntriesBlock").fadeOut();
                    loadToMainContentDivAdmin("InfostreamManagement");
                }
            })
            .fail(function (error) {
                showAjaxError(error, "infostreams");
            });
        $("#error-msg-feedEntry-form").show("fast");
        return false;
    });

    //Submit new feed entry condition in Modal
    $("#newfeedentryconditionmodal").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-feedEntryCondition-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: $(this).serialize(),
        })
            .done(function (response) {
                showAjaxResponse(response, "feedEntryCondition");
                if (response.status == "SUCCESS") {
                    $("#FeedEntryConditionModal").modal("hide");
                    // loadAdmin("Infostreams");
                    $("#feedEntryConditionsBlock").fadeOut();
                    loadToMainContentDivAdmin("InfostreamManagement");
                }
            })
            .fail(function (error) {
                showAjaxError(error, "feedEntryCondition");
            });
        $("#error-msg-feedEntryCondition-form").show("fast");
        return false;
    });
}

/* ------------------------- */
/* section: help functions */

/* ------------------------- */


function openDialog(name, callback, keepOpen) {

    if ($("#new" + name).is(":hidden")) {
        instantCloseDialogs();
        /* $("#new" + name).fadeIn(200); */
        $("#new" + name).slideDown(200);

        // update dateTime to now, every time dialog for new patient record is shown
        if (name == "PatientRecord") {
            $("#patientRecordText").summernote("focus");

            if (
                $("#datetimepicker-patientRecord-createdAt").data("DateTimePicker") !=
                null
            ) {
                $(".datetimepicker-patientRecord-createdAt")
                    .data("DateTimePicker")
                    .destroy();
            }
            $("#createdAt").val("");
            $(".datetimepicker-patientRecord-createdAt").datetimepicker({
                format: "DD.MM.YYYY HH:mm",
                sideBySide: true,
                widgetPositioning: {
                    horizontal: "auto",
                    vertical: "top",
                },
                toolbarPlacement: "bottom",
                showClose: true,
                showClear: true,
                showTodayButton: true,
                locale: "de",
                defaultDate: new Date(),
            });
        }
    } else {

        if (name === "PatientRecord") {
            const form = document.getElementById("newPatientRecordForm")
            doPendingChangesCheck(form, true, ({save}) => {
                if (save) $(form).submit();
                instantCloseDialogs();
            });
            return;
        }

        if (!keepOpen) {
            instantCloseDialogs();
        }
    }
}

function instantCloseDialogs() {
    $(".hideit").hide();

    //Close popovers
    $(".popoverclose").popover("hide");
    $('[data-toggle="popover"]').popover("hide");

    //Close left menu on mobile view if it is visible
    if ($("#visible-xs").is(":visible") && !$("#menu").hasClass("isSmallMenu")) {
        $("[data-toggle=offcanvas]").click();
    }

    //Collapse Top Nav Menu on mobile View
    if ($("#topMenuCollapseButton").is(":visible")) {
        if (
            $("#bs-example-navbar-collapse-1").hasClass("navbar-collapse collapse in")
        ) {
            $("#bs-example-navbar-collapse-1").collapse("hide");
        }
    }
}

function closeDialogs() {
    $(".hideit").fadeOut(500);
}


$(document).keydown(function (e) {
    let hash;
    if (e.ctrlKey && e.altKey) {
        switch (e.key.toLowerCase()) {
            case 'm':
                hash = "medikamente";
                break;
            case 'd':
                hash = "diagnosen";
                break;
            case 'a':
                hash = "allergien";
                break;
            case 'n':
                hash = "nahrungsergaenzungsmittel";
                break;
            case 'h':
                hash = "heilbehelfe";
                break;
            case 't':
                hash = "therapien";
                break;
            case 'i':
                hash = "impfungen";
                break;
        }
    } else if (e.key === 'Escape') {
        hash = undefined;
        $(".modal").slideUp(150);
    }
    if (hash) {
        document
            .getElementById(hash)
            .scrollIntoView({behavior: "smooth", block: "start"});
    }
});

function removeClassFromElementWithId(classname, id) {
    $("#" + id).removeClass(classname);
}

//always convert these tables to dataTables
function datatableUs() {
    $.fn.dataTable.moment("DD.MM.YYYY");
    $.fn.dataTable.moment("DD.MM.YYYY HH:mm");
    $.fn.dataTable.moment("DD.MM.YYYY | HH:mm");
    $.fn.dataTable.moment("DD.MM.YYYY | HH:mm:ss");

    $(".datatable").each(function (index, table) {
        let exTableId = $(table).attr("id");
        let orderByColumn;
        try {
            orderByColumn = $(table).find("tr .sort_by").index();
        } catch (e) {
            orderByColumn = undefined;
        }

        datatableMe(exTableId, false, orderByColumn);
    });
}

// convert a html table to a datatable (for more information go to www.datatables.net)
function datatableMe(
    tableId,
    selectable,
    orderByColumn,
    orderType,
    slim,
    numberOfEntries,
    staticColumns
) {
    let tableSelector =
        typeof tableId == "string" ? $("#" + tableId) : $(tableId);

    //check if it is a dataTable already - dont init twice to avoid DataTables warning
    if ($.fn.DataTable.isDataTable(tableSelector)) {
        return tableSelector;
    }

    if (!orderType || orderType == null) {
        orderType = "desc";
    }
    if (orderByColumn == null) {
        try {
            let id = tableSelector.attr("id");
            orderByColumn = $("#" + id + " tr .tabledate").index();
        } catch (e) {
        }
        if (!orderByColumn || orderByColumn < 0) {
            orderByColumn = 2;
        }
    }
    let pageLength = numberOfEntries ? numberOfEntries : 10;

    // date format for sorting
    // see https://datatables.net/blog/2014-12-18
    $.fn.dataTable.moment("DD.MM.YYYY");
    $.fn.dataTable.moment("DD.MM.YYYY HH:mm");
    $.fn.dataTable.moment("DD.MM.YYYY | HH:mm");
    $.fn.dataTable.moment("DD.MM.YYYY | HH:mm:ss");

    // date format that ignores invalid values, instead of breaking sorting
    $.fn.dataTable.ext.type.order['nullable-date-pre'] = function (data) {
        // create a div that is not part of the actual DOM, as we don't add it anywhere
        const parser = document.createElement("div");
        // set the innerHTML to be data
        // this was tested to not actually execute any code
        // neither in script tags nor in any event handlers
        parser.innerHTML = data;
        // read only the text, and trim whitespace
        data = parser.innerText.trim();
        const _moment = moment(data, [
            "DD.MM.YYYY",
            "DD.MM.YYYY HH:mm",
            "DD.MM.YYYY | HH:mm",
            "DD.MM.YYYY | HH:mm:ss"
        ], true);

        if (!_moment.isValid()) return null;
        return _moment.valueOf() || null;
    };

    const dateColumns = [];
    tableSelector.find('thead th').each(function (index) {
        const columnData = tableSelector
            .find('tbody tr td:nth-child(' + (index + 1) + ')') // ChatGPT masterpiece
            .map(function () {
                return $(this).text().trim();
            })
            .get();

        const hasDates = columnData.some(function (val) {
            return moment(val, [
                "DD.MM.YYYY",
                "DD.MM.YYYY HH:mm",
                "DD.MM.YYYY | HH:mm",
                "DD.MM.YYYY | HH:mm:ss"
            ], true).isValid();
        });

        if (hasDates) dateColumns.push(index);
    });

    if (!staticColumns) {
        staticColumns = [-1]; // -1 = last column
    }

    let dataTableOptions = {
        columnDefs: [
            {
                targets: staticColumns,
                orderable: false,
                searchable: false,
            },
            {
                targets: dateColumns,
                type: "nullable-date"
            }
        ],
        language: {
            decimal: ",",
            emptyTable: "Keine Einträge vorhanden.",
            info: "Seite _PAGE_ von _PAGES_",
            infoEmpty: "Keine Einträge vorhanden.",
            infoFiltered: "(gefiltert von _MAX_ Einträgen)",
            infoPostFix: "",
            thousands: ".",
            lengthMenu: "Zeige _MENU_ Zeilen pro Seite",
            loadingRecords: "Ladet ...",
            processing: "In Arbeit ...",
            search: "Suche:",
            zeroRecords: "Keine Einträge gefunden.",
            paginate: {
                first: "Erste",
                last: "Letzte",
                next: ">",
                previous: "<",
            },
            aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
            },
            select: {
                rows: "%d Einträge ausgewählt",
            },
            buttons: {
                selectAll: "<span title =\"Alle auswählen\" class=\"glyphicon glyphicon-expand\" aria-hidden=\"true\"></span><span>&nbsp; Alle auswählen</span>",
                selectNone: "<span title =\"Keine auswählen\" class=\"glyphicon glyphicon-unchecked\" aria-hidden=\"true\"></span><span>&nbsp; Keine auswählen</span>"
            }
        },
        buttons: [
            'selectAll',
            'selectNone'
        ],
        info: false,
        select: {style: "api", info: false, select: true},
        order: [[orderByColumn, orderType]],
        fixedHeader: true,
        stateSave: true,
        stateDuration: -1, //Use sessionStorage instead of localStorage
        retrieve: true, //see https://datatables.net/manual/tech-notes/3
        drawCallback: function (settings) {
            let api = new $.fn.dataTable.Api(settings);
            //always show the table if there are records to display
            if (settings.fnRecordsDisplay() > 0) {
                datatableWrapper(settings.sTableId).show();
                $(".deactivatedtable" + settings.sTableId).show();
            } else if (
                api.search().trim() === "" &&
                settings.fnRecordsDisplay() <= 0
            ) {
                //otherwise, hide the table, but only when there is no global search
                datatableWrapper(settings.sTableId).hide();
                $(".deactivatedtable" + settings.sTableId).hide();
            }
            if (tableId === "appointmentsTable") {
                addRowNumbers(api);
            }
        },
    };

    if (pageLength > 0) {
        dataTableOptions.pageLength = pageLength;
        dataTableOptions.lengthMenu = [pageLength, 25, 50, 75, 100];
    } else {
        dataTableOptions.lengthMenu = -1;
        dataTableOptions.bLengthChange = false;
        dataTableOptions.bInfo = false;
        dataTableOptions.paging = false;
    }

    if (slim) {
        dataTableOptions.paging = false;
        dataTableOptions.info = false;
    }
    if (!selectable) {
        dataTableOptions.select = selectable;
    }
    //needs to be before initialisation of table !!!!
    $.fn.dataTable.ext.search.push(function (
        settings,
        searchData,
        index,
        rowData,
        counter
    ) {
        try {
            let metaColumn = $(rowData[0]);
            let drawDeactived = $("#datatablesToggleActive" + settings.sTableId).is(
                ":checked"
            );
            let isDeactivated = metaColumn.hasClass("deactivated");
            //draw if neither deactivate nor expired
            if (!drawDeactived && isDeactivated) {
                return false;
            }

            let isExpired = metaColumn.hasClass("expired");
            let drawExpired = $("#datatablesToggleExpired" + settings.sTableId).is(
                ":checked"
            );
            if (!drawExpired && isExpired) {
                return false;
            }
        } catch (e) {
            // console.log("No metaColumn for row");
            return true;
        }
        return true;
    });

    let table = tableSelector.DataTable(dataTableOptions);

    // Füge rückwärts zählende Zeilennummern hinzu
    function addRowNumbers(tableApi) {
        if (tableId === "appointmentsTable") {

            let info = tableApi.page.info();
            let totalRecords = info.recordsDisplay;
            let pageLength = info.length;
            let startIndex = info.start;
            let currentPage = info.page;

            tableApi.column(0, {page: 'current'}).nodes().each(function (cell, i) {
                let rowNumber = totalRecords - (startIndex + i);
                cell.innerHTML = rowNumber;
            });
        }
    }


    if (selectable) {
        $("#" + tableId + " tbody").on("click", "tr", function () {
            let data = table.row(this).data();
            try {
                $(this).toggleClass("selected");
                let checkBox = $(this).find("input:checkbox");
                checkBox.attr("checked", !checkBox.attr("checked"));
                const selected = $(this).hasClass("selected");
                const checked = checkBox.attr("checked");
                document.getElementById(tableId).dispatchEvent(new CustomEvent("phr-table-select", {
                    detail: {data, checked, selected, row: this},
                }))

            } catch (e) {
                console.log(e);
                console.log("first td needs to be a checkbox");
            }
        });

        table.buttons().container()
            .appendTo('#' + tableId + '_wrapper .col-sm-6:eq(0)');

        table.on('select', function (e, dt, type, indexes) {
            table.rows(indexes).nodes().to$().find("input:checkbox").each(function () {
                $(this).attr("checked", true);
            })
        });

        table.on('deselect', function (e, dt, type, indexes) {
            table.rows(indexes).nodes().to$().find("input:checkbox").each(function () {
                $(this).attr("checked", false);
            })
        });
    }

    $("#datatablesToggleActive" + tableId + ",#datatablesToggleExpired" + tableId).on("click", function () {
        table.draw();
    });

    return table;
}

function clearDatatablesData() {
    localStorage.removeItem("MAODataTables");
}

function clearSelectedRowsFor(tableId) {
    let localDatatableStorage =
        JSON.parse(localStorage.getItem("MAODataTables")) || {};
    delete localDatatableStorage[tableId];
    localStorage.setItem("MAODataTables", JSON.stringify(localDatatableStorage));
}

function getRevisionGetter(revisionsGetter) {
    revisionsGetter = revisionsGetter.toLowerCase();
    if (revisionsGetter === "drugs") {
        return "getDrugRevisions";
    }
    if (revisionsGetter === "medicalaids") {
        return "getMedicalAidRevisions";
    }
    if (revisionsGetter === "dietarysupplements") {
        return "getDietarySupplementRevisions";
    }
    if (revisionsGetter === "therapies") {
        return "getTherapyRevisions";
    }
    if (revisionsGetter === "allergies") {
        return "getAllergyRevisions";
    }
    if (revisionsGetter === "vaccinations") {
        return "getVaccinationRevisions";
    }
    if (revisionsGetter === "diagnoses") {
        return "getDiagnosisRevisions";
    }
}

function toggleRevisions(button, tableId, revisionsGetterStrg, id) {
    let revisionsGetter = getRevisionGetter(revisionsGetterStrg);
    let dataTable = $("#" + tableId).DataTable();
    let tr = $(button).closest("tr");
    let row = dataTable.row(tr);

    if (row.child.isShown()) {
        row.child.hide();
        // row.deselect();
    } else {
        row.child.show();
        // row.select();

        let revisionsTableId = tableId + "Revisions";

        $.get(
            "/" +
            appLocation +
            revisionsGetter +
            "/" +
            id +
            "?revisionsTableId=" +
            revisionsTableId,
            function (data) {
                row.child(data, "revisions").show();

                diffRevisions(revisionsTableId);
            }
        );
    }
}

function diffRevisions(revisionTableId) {
    jQuery.fn.reverse = [].reverse;
    let previous = null;
    // for each row, from bottom to top
    $("#" + revisionTableId)
        .find("tr")
        .reverse()
        .each(function () {
            // skip the first row
            if (previous !== null) {
                // for each cell with the 'diff' class
                $(this)
                    .find("td.diff")
                    .each(function () {
                        let columnIndex = $(this).prevAll().length;
                        // get the text from same column in previous row, without 'diff-removed' parts
                        let previousText = $(previous)
                            .find("td")
                            .eq(columnIndex)
                            .clone()
                            .find(".diff-removed")
                            .remove()
                            .end()
                            .text();
                        // get the text from this cell, without 'diff-removed' parts
                        let thisText = $(this)
                            .clone()
                            .find(".diff-removed")
                            .remove()
                            .end()
                            .text();
                        // replace the text with colored diff
                        $(this).empty().append(colorDiff(previousText, thisText));
                    });
            }
            previous = this;
        });
}

function colorDiff(previousText, thisText) {
    let diff = JsDiff.diffWords(previousText, thisText);
    let coloredDiff = $("<span></span>");

    diff.forEach(function (part) {
        let coloredPart = $("<span></span>");
        let className = part.added
            ? "diff-added"
            : part.removed
                ? "diff-removed"
                : "diff-unchanged";
        coloredPart.addClass(className);
        coloredPart.append(document.createTextNode(part.value));
        coloredDiff.append(coloredPart);
    });

    return coloredDiff;
}

function datatableWrapper(tableId) {
    return $("#" + tableId)
        .parents("div.dataTables_wrapper")
        .first();
}

function showPasswordStrengthMeter(element) {
    $(element).strength({
        strengthClass: "form-control",
        strengthMeterClass: "strength_meter",
        strengthButtonClass: "button_strength",
        strengthButtonText:
            '<span class="glyphicon glyphicon-eye-open"></span>&nbsp;Passwort einblenden',
        strengthButtonTextToggle:
            '<span class="glyphicon glyphicon-eye-close"></span>&nbsp;Passwort ausblenden',
    });
}

//activate a bootstrap tab
function activateTab(tab) {
    $('.nav-pills a[href="#' + tab + '"]').tab("show");
}

/* ----------------------------------------------------------------------- */
/* section: AJAX load data in blocks (e.g. diagnoses or drugs in concerns) */

/* ----------------------------------------------------------------------- */

function loadInBlock(
    text,
    block,
    url,
    callback,
    noScroll,
    tableId,
    selectionForRequirementWithId,
    questionnaireObjectId,
    questionnaireObjectClassName,
    input,
    customParameters = {}
) {
    let parameters = {...customParameters};
    let parametersString = "";
    if (tableId) parameters.tableId = tableId;
    if (selectionForRequirementWithId)
        parameters.selectionForRequirementWithId = selectionForRequirementWithId;
    if (questionnaireObjectId)
        parameters.questionnaireObjectId = questionnaireObjectId;
    if (questionnaireObjectClassName)
        parameters.questionnaireObjectClassName = questionnaireObjectClassName;
    if (input) parameters.input = input;

    if (parameters) {
        //immer true d.h. immer url + ? -> NumberFormatException: For input string: "-1?"
        parametersString = "?" + $.param(parameters);
    }

    if (parametersString && parametersString == "?") {
        parametersString = "";
    }

    if (!noScroll) scrollTop = $("#main").scrollTop();

    showLoaderIn(block);

    $(block).load(url + parametersString, function () {
        $(".prettydate").prettyDate(); //Format Dates after loading

        $('[data-toggle="popover"]').popover({
            trigger: "hoover focus manual",
        });

        if (typeof callback === "function") callback();

        datatableUs(text, tableId);

        if (!noScroll) $("#main").scrollTop(scrollTop);
    });
}

// load get<text> in element with className <text>Block
function load(
    text,
    callback,
    noScroll,
    tableId,
    selectionForRequirementWithId,
    questionnaireObjectId,
    questionnaireObjectClassName,
    input
) {
    loadInBlock(
        text,
        //if tableId is set, load into the block which has both classes <text>block and <tableId>
        tableId ? "." + text + "Block" + "." + tableId : "." + text + "Block",
        "/" + appLocation + "get" + text,
        callback,
        noScroll,
        tableId,
        selectionForRequirementWithId,
        questionnaireObjectId,
        questionnaireObjectClassName,
        input
    );
}

// used for messages in patientView
function loadByIdAndPage(
    text,
    id,
    page,
    callback,
    noScroll,
    tableId,
    selectionForRequirementWithId,
    questionnaireObjectId,
    questionnaireObjectClassName,
    input
) {
    loadInBlock(
        text,
        //if tableId is set, load into the block which has both classes <text>block and <tableId>
        tableId ? "." + text + "Block" + "." + tableId : "." + text + "Block",
        "/" + appLocation + "get" + text + "/" + id + "?page=" + page,
        callback,
        noScroll,
        tableId,
        selectionForRequirementWithId,
        questionnaireObjectId,
        questionnaireObjectClassName,
        input
    );
}

// used (among others) for doctors concern modal (phr insights for patient via requirement)
// load get<text>/<id>(?tableId=<tableId>) in element with className <text>Block
// selectionForRequirementWithId: when a user can select a set of phr entries as answer to a requirement
function loadById(
    text,
    id,
    callback,
    noScroll,
    tableId,
    selectionForRequirementWithId,
    questionnaireObjectId,
    questionnaireObjectClassName,
    input
) {
    loadInBlock(
        text,
        //if tableId is set, load into the block which has both classes <text>block and <tableId>
        tableId ? "." + text + "Block" + "." + tableId : "." + text + "Block",
        "/" + appLocation + "get" + text + "/" + id,
        callback,
        noScroll,
        tableId,
        selectionForRequirementWithId,
        questionnaireObjectId,
        questionnaireObjectClassName,
        input
    );
}

// load admin/get<text> in element with className <text>Block
function loadAdmin(text, callback, noScroll) {
    loadInBlock(
        text,
        "." + text + "Block",
        "/" + appLocation + "admin/get" + text,
        callback,
        noScroll
    );
}

// load admin/get<text>/<id> in element with className <text>Block
function loadByIdAdmin(text, id, callback, noScroll) {
    loadInBlock(
        text,
        "." + text + "Block",
        "/" + appLocation + "admin/get" + text + "/" + id,
        callback,
        noScroll
    );
}

// load admin/get<text>/<id> in element with className <text>Block
function loadByIdAdminIntoBlock(text, block, id, callback, noScroll) {
    loadInBlock(
        text,
        "." + block + "Block",
        "/" + appLocation + "admin/get" + text + "/" + id,
        callback,
        noScroll
    );
}

function loadByIdAdminIntoBlockWithURL(text, block, url, callback, noScroll) {
    loadInBlock(text, "." + block + "Block", url, callback, noScroll);
}

// used for loading doctors of managed patients
// load get<text>/<id> in element with className <text><id>Block
function loadManagedPatientsDoctors(text, id, callback, noScroll) {
    loadInBlock(
        text,
        "." + text + id + "Block",
        "/" + appLocation + "get" + text + "/" + id,
        callback,
        noScroll
    );
}

/* ------------------------------------------- */
/* section: AJAX Load Data in Main Content Div */

/* ------------------------------------------- */

function loadURLToMainContentDiv(url, focusId, callback) {
    //if something is loaded while another content is still loading, the content get queued
    if (contentIsLoading) {
        queuedContent = {url: url, focusId: focusId, callback: callback}; //always overwritten if another page is loaded - so only the most recent page will be loaded at the end
        //console.log("store to queue: " + url);
    } else {
        contentIsLoading = true; //mark that this is still loading
        //console.log("load " + url);
        instantCloseDialogs(); //closes popovers, navbars in mobile view and things like that
        showLoaderIn("#main-content-div");
        refreshMenuInfoBadges();

        /* RELOAD clearInterval(refreshID); */
        $("#main-content-div").load(url, function () {
            $(".prettydate").prettyDate(); //Format Dates after loading

            if (focusId && document.getElementById(focusId)) {
                document.getElementById(focusId).scrollIntoView();
                document.getElementById(focusId).focus();
            }

            if (callback != null && typeof callback === "function") {
                callback();
            }
            contentIsLoading = false; //mark that everything is loaded now
            //console.log("loaded " + url);
            //if something got queued while loading - load it now (this is more recent)
            if (queuedContent != null) {
                //console.log("load from queue: " + queuedContent.url);
                loadURLToMainContentDiv(
                    queuedContent.url,
                    queuedContent.focusId,
                    queuedContent.callback
                );
                queuedContent = null;
            }
        });
    }
}

function loadToMainContentDiv(text, focusId, callback) {
    const queryIndex = text.indexOf('?')
    currentContent = queryIndex === -1 ? text : text.substring(0, queryIndex);
    currentRequestParameters = queryIndex === -1 ? '' : text.substring(queryIndex);
    currentPage = -1;
    loadURLToMainContentDiv("/" + appLocation + "get" + text, focusId, callback);
}

function loadPatientView(patientId, module, page, focusId, callback) {
    currentTabId = "patientView" + module;
    loadToMainContentDivPage(
        "PatientView",
        page,
        focusId,
        function () {
            $('#patientViewTabList a[href="#' + currentTabId + '"]').tab("show");

            if (callback != null && typeof callback === "function") {
                callback();
            }
        },
        null,
        patientId,
        module
    );
}

//used for pagination
function loadToMainContentDivPage(
    text,
    page,
    focusId,
    callback,
    additionalRequestParams,
    patientId,
    patientViewModule
) {
    let getString;
    let urlString;

    if (additionalRequestParams &&
        (additionalRequestParams.startsWith('?') || additionalRequestParams.startsWith('&'))) {
        additionalRequestParams = additionalRequestParams.substring(1) // cut off
    }

    if (patientId) {
        getString = text + "/" + patientId;
        urlString = text + patientViewModule + "/" + patientId;
    } else {
        getString = text;
        urlString = text;
    }

    if (!page) page = 0;
    currentContent = text;
    currentRequestParameters = additionalRequestParams;
    currentPage = page;
    const query = '?page=' + page + (additionalRequestParams ? '&' + additionalRequestParams : "");
    loadURLToMainContentDiv(
        "/" +
        appLocation +
        "get" +
        getString +
        query,
        focusId,
        callback
    );

    router.navigate(appLocation + urlString + "/" + page, {trigger: false});
}

function loadToMainContentDivAdmin(text, focusId, callback) {
    const queryIndex = text.indexOf('?')
    currentContent = queryIndex === -1 ? text : text.substring(0, queryIndex);
    currentRequestParameters = queryIndex === -1 ? '' : text.substring(queryIndex);
    currentPage = -1;
    loadURLToMainContentDiv(
        "/" + appLocation + "admin/get" + text,
        focusId,
        callback
    );
}

function loadMainDashboard(callback) {
    if ($("div.comment:visible").length === 0) {
        //Only Reload if User is not writing an Answer
        //if something is loaded while another content is still loading, the content get queued
        if (contentIsLoading) {
            queuedContent = {
                url: "/" + appLocation + "getDashboard",
                focusId: null,
                callback: callback,
            }; //always overwritten if another page is loaded - so only the most recent page will be loaded at the end
        } else {
            contentIsLoading = true; //mark that this is still loading
            instantCloseDialogs();

            showLoaderIn("#main-content-div");
            refreshMenuInfoBadges();

            //deselect selected menu entry
            $(".menuEntrySelected").toggleClass("menuEntrySelected");

            currentContent = "Home";
            currentRequestParameters = "";
            $("#main-content-div").load(
                "/" + appLocation + "getDashboard",
                function () {
                    after = $("div.feedentry").length;
                    if (after > before) {
                        //there are new entries
                        notifyMe(); //create desktop notification
                    }
                    $(".prettydate").prettyDate(); //Format Dates after loading
                    before = after;

                    if (callback != null && typeof callback === "function") {
                        callback();
                    }

                    contentIsLoading = false; //mark that everything is loaded now
                    //if something got queued while loading - load it now (this is more recent)
                    if (queuedContent != null) {
                        //console.log("load from queue: " + queuedContent.url);
                        loadURLToMainContentDiv(
                            queuedContent.url,
                            queuedContent.focusId,
                            queuedContent.callback
                        );
                        queuedContent = null;
                    }
                }
            );
        }
    }
}

function reloadCurrentContent(focusId, callback) {
    //Please do not use alerts -> annoys users if forgotten
    // alert(currentContent)
    // alert(currentTabId)
    // alert(currentPage)

    scrollTop = $("#main").scrollTop();

    //loadToMainContentDiv sets currentPage to -1
    //loadToMainContentDivPage sets the current page correct

    if (currentContent.startsWith("PatientView/") && currentTabId) {
        loadToMainContentDivPage(currentContent, currentPage, focusId, function () {
            $('#patientViewTabList a[href="#' + currentTabId + '"]').tab("show");

            if (callback != null && typeof callback === "function") {
                callback();
            }
            $("#main").scrollTop(scrollTop);
        }, currentRequestParameters);
    } else if (currentPage != null && currentPage != -1) {
        if (currentContent === "Home") {
            loadToMainContentDivPage("Dashboard", currentPage, focusId, function () {
                if (callback != null && typeof callback === "function") {
                    callback();
                }
                $("#main").scrollTop(scrollTop);
            }, currentRequestParameters);
        } else {
            loadToMainContentDivPage(
                currentContent,
                currentPage,
                focusId,
                function () {
                    if (callback != null && typeof callback === "function") {
                        callback();
                    }
                    $("#main").scrollTop(scrollTop);
                }, currentRequestParameters
            );
        }
    } else {
        if (currentContent === "Home") {
            loadMainDashboard(function () {
                if (callback != null && typeof callback === "function") {
                    callback();
                }
                $("#main").scrollTop(scrollTop);
            });
        } else {
            //fix to remember unreadOnly state --> append state if Messages Module is reloaded
            let urlToLoad = currentContent;
            if (currentContent === "Messages")
                urlToLoad = currentContent + '?' + currentRequestParameters.substring(1); // cut off the first '&' or '?'
            // I'd like to use getMessageFilter() but that would use the current state not the applies state

            loadToMainContentDiv(urlToLoad, focusId, function () {
                if (callback != null && typeof callback === "function") {
                    callback();
                }
                $("#main").scrollTop(scrollTop);
            });
        }
    }
}

// refresh the new message count information in the menu
function refreshMenuInfoBadges() {
    if ($(".NewMessageCountBlock").length)
        $(".NewMessageCountBlock").load("/" + appLocation + "getNewMessageCount");
    if ($(".DaMeFindingsReportBlock").length)
        $(".DaMeFindingsReportBlock").load(
            "/" + appLocation + "getNewDaMeFindingsReportCount"
        );
    if ($(".NewConcernCountBlock").length)
        $(".NewConcernCountBlock").load("/" + appLocation + "getNewConcernCount");
    if ($(".NewPrescriptionRequestCountBlock").length)
        $(".NewPrescriptionRequestCountBlock").load(
            "/" + appLocation + "getNewPrescriptionRequestCount"
        );
    if ($(".NewReferralRequestCountBlock").length)
        $(".NewReferralRequestCountBlock").load(
            "/" + appLocation + "getNewReferralRequestCount"
        );
    if ($(".NewOnlineConsultationCountBlock").length)
        $(".NewOnlineConsultationCountBlock").load(
            "/" + appLocation + "getNewOnlineConsultationCount"
        );
    if ($(".NewTreatingDoctorRequestCountBlock").length)
        $(".NewTreatingDoctorRequestCountBlock").load(
            "/" + appLocation + "getNewTreatingDoctorRequestCount"
        );
    if ($(".NewExternalCallRequestCountBlock").length)
        $(".NewExternalCallRequestCountBlock").load(
            "/" + appLocation + "getNewExternalCallRequestCount"
        );
    if ($(".OpenConcernCountBlock").length)
        $(".OpenConcernCountBlock").load("/" + appLocation + "getOpenConcernCount");
    if ($(".OpenPrescriptionRequestCountBlock").length)
        $(".OpenPrescriptionRequestCountBlock").load(
            "/" + appLocation + "getOpenPrescriptionRequestCount"
        );
    if ($(".OpenReferralRequestCountBlock").length)
        $(".OpenReferralRequestCountBlock").load(
            "/" + appLocation + "getOpenReferralRequestCount"
        );
    if ($(".OpenOnlineConsultationCountBlock").length)
        $(".OpenOnlineConsultationCountBlock").load(
            "/" + appLocation + "getOpenOnlineConsultationCount"
        );
    if ($(".TodaysExternalCallsCountBlock").length)
        $(".TodaysExternalCallsCountBlock").load(
            "/" + appLocation + "getTodaysExternalCallsCount"
        );
    if ($(".ActiveExternalCallsCountBlock").length)
        $(".ActiveExternalCallsCountBlock").load(
            "/" + appLocation + "getActiveExternalCallsCount"
        );
    if ($(".eCardMessagesCountBlock").length)
        $(".eCardMessagesCountBlock").load("/" + appLocation + "getECardMessagesCount");

    if ($(".MediprimeAppNotificationCountBlock").length)
        $(".MediprimeAppNotificationCountBlock").load("/" + appLocation + "getMediprimeAppUnreadCount");

    if ($(".MediprimeAppVideoMeetingsCountBlock").length)
        $(".MediprimeAppVideoMeetingsCountBlock").load("/" + appLocation + "getMediprimeAppVideoMeetingsCount");

    if ($(".DeviceIntegrationBadgesBlock").length)
        $(".DeviceIntegrationBadgesBlock").load("/" + appLocation + "getDeviceIntegrationBadges")
}

/* ------------------------------------------ */
/* section: solves the fragment-login-Problem */
/* ------------------------------------------ */

// we should not do that...
(function (XHR) {
    "use strict";

    let open = XHR.prototype.open;
    let send = XHR.prototype.send;

    XHR.prototype.open = function (method, url, async, user, pass) {
        this._url = url;
        open.call(this, method, url, async, user, pass);
    };

    XHR.prototype.send = function (data) {
        let self = this;
        let oldOnReadyStateChange;
        let url = this._url;

        if (url && url.indexOf("maps.googleapis.com") <= 0) {
            this.setRequestHeader(
                "X-CSRF-Token",
                $("meta[name='_csrf']").attr("content")
            );

            function onReadyStateChange() {
                if (self.readyState == 4 /* complete */) {
                    /* This is where you can put code that you want to execute post-complete*/
                    /* URL is kept in this._url */

                    if (self.status == 403) {
                        window.location.href = "signin";
                    }
                }

                if (oldOnReadyStateChange) {
                    oldOnReadyStateChange();
                }
            }

            /* Set xhr.noIntercept to true to disable the interceptor for a particular call */
            if (!this.noIntercept) {
                if (this.addEventListener) {
                    this.addEventListener("readystatechange", onReadyStateChange, false);
                } else {
                    oldOnReadyStateChange = this.onreadystatechange;
                    this.onreadystatechange = onReadyStateChange;
                }
            }
        }

        send.call(this, data);
    };
})(XMLHttpRequest);

/* ------------------------------------------ */
/* section: solves reset of bootstrap combobox */
/* ------------------------------------------ */

(function (methods) {
    methods.clear = function () {
        if (!this.selected) {
            return;
        }

        this.$element.val("").trigger("change");
        this.$target.val("").trigger("change");
        this.$source.val("").trigger("change");
        this.$container.removeClass("combobox-selected");
        this.selected = false;
    };

    (function (refresh) {
        methods.refresh = function () {
            refresh.apply(this);
            if (this.selected && this.$source.val() === "") {
                this.$element.val("").trigger("change");
                this.$target.val("").trigger("change");
                this.$container.removeClass("combobox-selected");
                this.selected = false;
            }
        };
    })(methods.refresh);
})($.fn.combobox.Constructor.prototype);

/* -------------------------------- */
/* section: search all doctors page */

/* -------------------------------- */

function initializeDoctorSearch() {
    /* initialize doctor search on all doctors page*/
    $("#doctorSearchInputField").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            filterPatientCommunicationDoctorList(this.value);
            return false;
        }
    });

    $("#doctorSearchGoButton").click(function () {
        //when uses clicks search button
        filterPatientCommunicationDoctorList($("#doctorSearchInputField").val());
    });

    /* initialize networkDoctor search on all networkDoctors page*/
    $("#networkDoctorSearchInputField").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            filterNetworkDoctorList(this.value);
            return false;
        }
    });

    $("#networkDoctorSearchGoButton").click(function () {
        //when uses clicks search button
        filterNetworkDoctorList($("#networkDoctorSearchInputField").val());
    });
}

function initializeReferrerSearch() {
    $("#referrerSearchInputField").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            filterReferrerList(this.value);
            return false;
        }
    });

    $("#referrerSearchGoButton").click(function () {
        //when uses clicks search button
        filterReferrerList($("#referrerSearchInputField").val());
    });
}

function initializeNewReferrerSearch() {
    $("#newReferrerSearchInputField").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            filterNewReferrerList(this.value);
            return false;
        }
    });

    $("#newReferrerSearchGoButton").click(function () {
        //when uses clicks search button
        filterNewReferrerList($("#newReferrerSearchInputField").val());
    });
}

function filterPatientCommunicationDoctorList(searchParam) {
    if (searchParam.length > 0) {
        loadPatientCommunicationDoctors(searchParam);
    } else {
        loadAllPatientCommunicationDoctors();
    }
}

function filterNetworkDoctorList(searchParam) {
    if (searchParam.length > 0) {
        loadNetworkDoctors(searchParam);
    } else {
        loadAllNetworkDoctors();
    }
}

function filterReferrerList(searchParam) {
    if (searchParam.length > 0) {
        loadReferrers(searchParam);
    } else {
        loadAllReferrers();
    }
}

function filterNewReferrerList(searchParam) {
    if (searchParam.length > 0) {
        loadNewReferrers(searchParam);
    } else {
        loadAllNewReferrers();
    }
}

function loadPatientCommunicationDoctors(filter) {
    showLoaderIn("#DoctorList");
    $("#DoctorList").load(
        "/" +
        appLocation +
        "loadPatientCommunicationDoctors/" +
        encodeURIComponent(filter)
    );
}

function loadNetworkDoctors(filter) {
    showLoaderIn("#NetworkDoctorList");
    $("#NetworkDoctorList").load(
        "/" + appLocation + "loadNetworkDoctors/" + encodeURIComponent(filter)
    );
}

function loadReferrers(filter) {
    showLoaderIn("#ReferrerList");
    $("#ReferrerList").load(
        "/" + appLocation + "loadReferrers/" + encodeURIComponent(filter)
    );
}

function loadNewReferrers(filter) {
    showLoaderIn("#NewReferrerList");
    $("#NewReferrerList").load(
        "/" + appLocation + "loadNewReferrers/" + encodeURIComponent(filter)
    );
}

function loadAllPatientCommunicationDoctors() {
    showLoaderIn("#DoctorList");
    $("#DoctorList").load(
        "/" + appLocation + "loadAllPatientCommunicationDoctors"
    );
}

function loadAllNetworkDoctors() {
    showLoaderIn("#NetworkDoctorList");
    $("#NetworkDoctorList").load("/" + appLocation + "loadAllNetworkDoctors");
}

function loadAllReferrers() {
    showLoaderIn("#ReferrerList");
    $("#ReferrerList").load("/" + appLocation + "loadAllReferrers");
}

function loadAllNewReferrers() {
    showLoaderIn("#NewReferrerList");
    $("#NewReferrerList").load("/" + appLocation + "loadAllNewReferrers");
}

/**
 * widgets action
 * */

function toggleActivities() {
    setAllActivityLogsDisplayed();

    let activityRoot = $("#activityLogWidget");
    let activities = activityRoot.find(".activities");
    let icon = $("#activityChevron");

    activities.slideToggle(500, function () {
        icon.toggleClass("glyphicon-chevron-down glyphicon-chevron-up");
    });
}

function setAllActivityLogsDisplayed() {
    $.ajax({
        url: "/" + appLocation + "setAllActivityLogsDisplayed",
        context: document.body,
        type: "post",
    })
        .done(function () {
            $("#notDisplayedActivityLogCount").html("");
        })
        .fail(function () {
            actionFail();
        });
}

/**
 * section: combobox autoSuggest for selects
 * */
function clearComboBox(selectId) {
    $("select[id^=" + selectId + "]")
        .data("combobox")
        .$source.val("");
    $("select[id^=" + selectId + "]")
        .data("combobox")
        .refresh();

    if ($("#" + selectId + "ProfilePic"))
        $("#" + selectId + "ProfilePic").css("background-image", "");
}

function setComboBoxValue(selectId, accountId, callback) {
    if (accountId && accountId != -1) {
        $("#" + selectId + "Value").val(accountId);
        $("#" + selectId).val(
            $("#" + selectId + ' option[value="' + accountId + '"]').html()
        );

        $.ajax({
            url: "/" + appLocation + "getProfilePicBackgroundUrlForAccountWithId",
            context: document.body,
            type: "post",
            data: {accountId: accountId},
        })
            .done(function (response) {
                if (response.status == "SUCCESS") {
                    let profilePicUrl = response.result;
                    $("#" + selectId + "ProfilePic").css(
                        "background-image",
                        "url(" + profilePicUrl + ")"
                    );
                }
                if (callback != null && typeof callback === "function") {
                    callback();
                }
            })
            .fail(function () {
                actionFail();
            });
    }
}

function initializeComboBoxWithPatientRecordLink(selectId, linkId, callback) {
    initializeComboBoxWithClassname("combobox", selectId, callback);
    // the easiest way to implement this, is to just add additional listeners
    $(".combobox").on("customValueSelected", function () {
        let newAccountId = parseInt($(this).val(), 10);
        document.getElementById(linkId).href = "/" + appLocation + "PatientView/" + newAccountId;
        document.getElementById(linkId).removeAttribute("disabled");
    });

    $(".combobox").on("customSelectionReset", function () {
        document.getElementById(linkId).setAttribute("disabled", "disabled");
    });
}

function initializeComboBox(selectId, callback) {
    initializeComboBoxWithClassname("combobox", selectId, callback);
}

function initializeComboBoxWithClassname(
    comboboxClassname,
    selectId,
    callback
) {
    if ($("." + comboboxClassname).length > 0) {
        $("." + comboboxClassname).combobox({
            appendId: "",
            bsVersion: "3",
            renderLimit: 10,
        });

        //load profile pic for default selected user

        let accountId = parseInt(
            $("select[id^=" + selectId + "]")
                .data("combobox")
                .$source.val(),
            10
        );

        if (!isNaN(accountId))
            $.ajax({
                url: "/" + appLocation + "getProfilePicBackgroundUrlForAccountWithId",
                context: document.body,
                type: "post",
                data: {accountId: accountId},
            })
                .done(function (response) {
                    if (response.status == "SUCCESS") {
                        let profilePicUrl = response.result;
                        $("#" + selectId + "ProfilePic").css(
                            "background-image",
                            "url(" + profilePicUrl + ")"
                        );
                    }
                })
                .fail(function () {
                    actionFail();
                });

        //show the profile pic when user selected a receiver
        $("." + comboboxClassname).on("customValueSelected", function () {
            let newAccountId = parseInt($(this).val(), 10);
            if (!isNaN(newAccountId))
                $.ajax({
                    url: "/" + appLocation + "getProfilePicBackgroundUrlForAccountWithId",
                    context: document.body,
                    type: "post",
                    data: {accountId: newAccountId},
                })
                    .done(function (response) {
                        if (response.status == "SUCCESS") {
                            let profilePicUrl = response.result;
                            $("#" + selectId + "ProfilePic").css(
                                "background-image",
                                "url(" + profilePicUrl + ")"
                            );
                        }
                    })
                    .fail(function () {
                        actionFail();
                    });
        });

        $("." + comboboxClassname).on("customSelectionReset", function () {
            $("#" + selectId + "ProfilePic").css("background-image", "");
        });
    }

    if (callback != null && typeof callback === "function") {
        callback();
    }
}

function initializeCheckReferrerInvitationCode() {
    /* initialize checkReferrerInvitationCodeButton on all doctors page*/
    $("#referrerInvitationCode").keypress(function (e) {
        if (e.which == 13) {
            //when user clicks enter
            checkReferrerInvitationCode();
            return false;
        }
    });
}

function checkReferrerInvitationCode() {
    let referrerInvitationCode = $("#referrerInvitationCode").val();
    window.location.href =
        "/" + appLocation + "signupReferrer/" + referrerInvitationCode;
}

function secureToggleInsuranceOrganisationForPatient(
    insuranceOrganisationId,
    patientId,
    updatedByPatient
) {
    customConfirm(
        "Bitte bestätigen",
        "Sind Sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?",
        function () {
            toggleInsuranceOrganisationForPatient(
                insuranceOrganisationId,
                patientId,
                updatedByPatient
            );
        }
    );
}

function toggleInsuranceOrganisationForPatient(
    insuranceOrganisationId,
    patientId,
    updatedByPatient
) {
    $.ajax({
        url: "/" + appLocation + "toggleInsuranceOrganisationForPatient",
        context: document.body,
        type: "post",
        data: {
            insuranceOrganisationId: insuranceOrganisationId,
            patientId: patientId,
        },
    })
        .done(function () {
            actionSuccess();
            if (updatedByPatient) reloadCurrentContent();
            else loadById("PatientsInsuranceOrganisations", patientId);
        })
        .fail(function () {
            actionFail();
        });
}

let todolistPanel;

function showToDoListModal() {
    if (todolistPanel) {
        todolistPanel.front();
        todolistPanel.unsmallify();
        todolistPanel.reposition({
            my: "center",
        });
        return;
    }

    let options = {
        footerToolbar: '<span class="pull-right">To-Do-Liste</span>',
        headerLogo:
            '<span style="padding:10px" class="glyphicon glyphicon-check" title="To-Do-Liste" aria-hidden="true"></span>',
        contentSize: {width: "80%", height: "80%"},
        onwindowresize: true,
        closeOnEscape: true,
        borderRadius: 3,
        onbeforeclose: function (panel) {
            todolistPanel = null;
            return true;
        },
        content: function () {
            $(this.content).load("/" + appLocation + "getToDoListModal");
        },
        headerTitle: "To-Do-Liste",
        theme: "#f2f2f2",
    };

    todolistPanel = jsPanel.create(options);

    if ($(window).width() <= 1120) {
        todolistPanel.maximize();
    }
}


//Prefetch Global ToDoList Modal to reduce load time
function prefetchModal() {
    // $("#ToDoListModalReplace").load("/" + appLocation + "getToDoListModal");
}

function refreshToDoList() {
    if ($(".ToDoListEntriesBlock")) {
        load("ToDoListEntries");
    }
    if ($("#doctorDashboardopenToDoListEntries")) {
        loadWidgetToDashboard("doctorDashboard", "openToDoListEntries");
    }
    if ($("#doctorDashboardallOpenToDoListEntries")) {
        loadWidgetToDashboard("doctorDashboard", "allOpenToDoListEntries");
    }

    postMessageToReactIFrame({
        action: "refreshToDoList",
    });
}

function postMessageToReactIFrame(message) {
    let iframe = $("#mao-iframe")[0];
    if (iframe) {
        //https://stackoverflow.com/questions/40991114/issue-communication-with-postmessage-from-parent-to-child-iframe
        iframe.contentWindow.postMessage(
            {
                message: message,
            },
            iframe.src
        );
    }
}

function registerToDoListEntryFormSubmitButton(patientId) {
    $("#toDoListEntryForm").submit(function (e) {
        //disable button to prevent double submitting
        $("#toDoListEntryCommitButton").prop("disabled", true);
        $("#toDoListEntryCommitButton").prop("value", "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-toDoListEntry-form").hide();
        let formData = $(this).serialize();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: "post",
            data: formData,
        })
            .done(function (response) {
                //enable button if done and failure
                $("#toDoListEntryCommitButton").prop("disabled", false);
                $("#toDoListEntryCommitButton").prop("value", "Speichern");
                showAjaxResponse(response, "toDoListEntry");
                if (response.status == "SUCCESS") {
                    closeDialogs();
                    //clear field for next input
                    $("#toDoListEntryText").summernote("reset");
                    //hide error message
                    $("#error-msg-toDoListEntry-form").hide();

                    refreshToDoList();
                }
            })
            .fail(function (error) {
                showAjaxError(error, "toDoListEntry");

                //enable button after fail
                $("#toDoListEntryCommitButton").prop("disabled", false);
                $("#toDoListEntryCommitButton").prop("value", "Speichern");
            });
        $("#error-msg-toDoListEntry-form").show("fast");
        return false;
    });
}

//set items with id to done
function toggleToDoListEntryDone(id) {
    showLoaderIn(".ToDoListEntriesBlock");
    $.ajax({
        url: "/" + appLocation + "toggleToDoListEntryDone",
        context: document.body,
        type: "post",
        data: {id: id},
    })
        .done(function () {
            actionSuccess();
            refreshToDoList();
        })
        .fail(function () {
            load("ToDoListEntries");
            actionFail("Vorgang war nicht erfolgreich.");
        });
}


function focusOnFirstInputInForm(formId) {
    let theForm = $("form#" + formId);
    let firstInput = $(
        ":input:not(input[type=button],input[type=submit],button):visible:first",
        theForm
    );
    firstInput.focus();
}

/*workaround for IE and Edge select option display none bug*/
$.fn.showOption = function () {
    this.each(function () {
        let parentSpan = $(this).parent();
        if ($(this).css("display") == "none")
            parentSpan.replaceWith($(this).show());
    });
};
$.fn.hideOption = function () {
    this.each(function () {
        if ($(this).css("display") != "none") $(this).wrap("<span>").hide();
    });
};

function initDocTypeahead(genericQuestionnaireType) {
    let commonTextBlockTypes = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 100,
        prefetch: {
            url:
                "/" +
                appLocation +
                "getAnswersTypeahead?textBlockType=" +
                encodeURIComponent(genericQuestionnaireType),
            filter: function (list) {
                return $.map(list, function (entry) {
                    return entry;
                });
            },
            ttl: 10000,
        },
    });

    // kicks off the loading/processing of `local` and `prefetch`

    commonTextBlockTypes.initialize();
    $(".typeahead").on("typeahead:selected", function (evt, item) {
        if (item && item.hash) {
            $(".complexTypeSelectorClass").hide();
            $("#complexTypeSelector" + item.hash).show();
        }
    });

    $("#question_typeahead_input .typeahead").typeahead(
        {
            hint: true,
            highlight: true,
            minLength: 0,
        },
        {
            name: "commonTextBlockTypes",
            display: "name",
            source: commonTextBlockTypes.ttAdapter(),
        }
    );
}

function initDameTypeahead(selectorId, onSelection) {
    let commonTextBlockTypes = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 100,
        remote: {
            url: "/" + appLocation + "getDaMeUserInfoTypeahead?query=%QUERY",
            wildcard: "%QUERY",
            filter: function (page) {
                return $.map(page.content, function (data) {
                    return {name: data.dameUserInfoName, value: data.hvUid, data: data};
                });
            },
            ttl: 86400000, //The time (in milliseconds) the prefetched data should be cached in local storage. Defaults to 86400000 (1 day).
        },
    });

    commonTextBlockTypes.initialize();

    $(".typeahead").on("typeahead:selected", function (obj, datum, name) {
        if (onSelection) {
            onSelection(datum);
        }
    });

    $("#dameuserinfo-input" + selectorId + ".typeahead").typeahead(
        {
            hint: true,
            highlight: true,
            minLength: 3,
        },
        {
            name: "dameuserinfoInput",
            displayKey: "value",
            templates: {
                suggestion: function (data) {
                    return "<p>" + data.name + "</p>";
                },
            },
            source: commonTextBlockTypes.ttAdapter(),
        }
    );
}

function initDameUserSearchTypeahead(selectorId) {
    let dameUserSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 100,
        remote: {
            url: "/" + appLocation + "getDaMeUserInfoTypeahead?query=%QUERY",
            wildcard: "%QUERY",
            filter: function (page) {
                return $.map(page.content, function (data) {
                    return {name: data.commonNameAndAddress};
                });
            },
            ttl: 86400000, //The time (in milliseconds) the prefetched data should be cached in local storage. Defaults to 86400000 (1 day).
        },
    });

    dameUserSearch.initialize();

    $("#" + selectorId).typeahead(
        {
            hint: true,
            highlight: true,
            minLength: 3,
        },
        {
            name: "dameUserSearch",
            displayKey: "name",
            source: dameUserSearch.ttAdapter(),
        }
    );
}

function initEHVZUserSearchTypeahead(selectorId) {
    let ehvzUserSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 200,
        remote: {
            url: "/" + appLocation + "getEHVZUserInfoTypeahead?query=%QUERY",
            wildcard: "%QUERY",
            filter: function (page) {
                return $.map(page, function (data) {
                    return {name: data.commonNameAndAddressEHVD};
                });
            },
            ttl: 86400000, //The time (in milliseconds) the prefetched data should be cached in local storage. Defaults to 86400000 (1 day).
        },
    });

    ehvzUserSearch.initialize();

    $("#" + selectorId).typeahead(
        {
            hint: true,
            highlight: true,
            minLength: 3,
        },
        {
            name: "ehvzUserSearch",
            displayKey: "name",
            display: "name",
            source: ehvzUserSearch.ttAdapter(),
        }
    );
}

/* inserts text at last cursor position. if editor is empty, replace editor content with value of text, to avoid leading br from summernotes default value*/
function insertTextToSummernoteEditor(editorId, value) {
    if ($("#" + editorId).summernote("isEmpty")) {
        $("#" + editorId).summernote("code", value);
    } else {
        $("#" + editorId).summernote("pasteHTML", value);
    }
}

function setActiveMedicalPractice(medicalPracticeId) {
    $.ajax({
        url: "/" + appLocation + "setActiveMedicalPractice",
        context: document.body,
        type: "post",
        data: {medicalPracticeId: medicalPracticeId},
    })
        .done(function () {
            actionSuccess();
        })
        .fail(function () {
            actionFail("Vorgang war nicht erfolgreich.");
        });
}

function setActiveHealthCareProvider(hcProviderId) {
    $.ajax({
        url: "/" + appLocation + "setActiveHealthCareProvider",
        context: document.body,
        type: "post",
        data: {hcProviderId: hcProviderId},
    })
        .done(function () {
            location.reload();
            actionSuccess();
        })
        .fail(function () {
            actionFail("Vorgang war nicht erfolgreich.");
        });
}

function popoverHistory(ctx) {
    let e = $(ctx);
    $.get(e.data("poload"), function (d) {
        e.popover({html: true, content: d, id: ctx.id}).popover("toggle");
    });
}

function initDateTimePicker(forClass, format, minDate, maxDate) {
    let options = {
        format: format,
        showTodayButton: true,
        showClose: true,
        locale: "de",
    };
    if (maxDate != null) {
        options.maxDate = moment(maxDate, format).toDate();
    }

    if (minDate != null) {
        options.minDate = moment(minDate, format).toDate();
    }
    $(forClass).datetimepicker(options);
}

function postCommunicationSettings(ctx) {
    let formData = $(ctx).serialize();
    $.ajax({
        url: $(ctx).attr("action"),
        context: document.body,
        type: "post",
        data: formData,
    })
        .done(function (response) {
            actionSuccess();
            return false;
        })
        .fail(function () {
            actionFail("Vorgang war nicht erfolgreich.");
            return false;
        });
    return false;
}

function loadBookingModal(id, isPublic) {
    let url = location.origin + "/";
    if (!isPublic) {
        loadBooking(id);
        return;
    } else {
        url += appLocation + "api/onlineBookingFrame/" + id;
    }
    $(".onlineBookingFrameModal").on("shown.bs.modal", function () {
        //correct here use 'shown.bs.modal' event which comes in bootstrap3
        $(this).find("iframe").attr("src", url);
    });
    $(".onlineBookingFrameModal").modal({show: true});
}

function getRandomCodeForPatientButton() {
    $.ajax({
        url: "/" + appLocation + "getRandomCodeForPatient",
        context: document.body,
        type: "get",
    })
        .done(function (response) {
            $("#randomCodeForPatient").html(response);
            $("#randomCodeForPatientInput").val(response);
        })
        .fail(function () {
            actionFail();
        });
    return false;
}

function sendLabuniqRequest(patientId, timestamp) {
    let diagnosesDataTable = $("#DiagnosesTableForLabuniq").DataTable();
    let diagnosesData = diagnosesDataTable.rows(".selected").data();
    let diagnosesIds = [];
    diagnosesData.each(function (diagnoses) {
        diagnosesIds.push($(diagnoses[0]).val());
    });
    let url = "/" + appLocation + "labuniqRequest?patientId=" + patientId;
    if (timestamp) {
        url += "&timestamp=" + timestamp;
    }
    $.ajax({
        url: url,
        contentType: "application/json; charset=utf-8",
        type: "POST",
        data: JSON.stringify(diagnosesIds),
    })
        .done(function (response) {
            actionSuccess();
            window.open(response, "_blank");
            // openJSPanelIFrame(response); //haut nit hin wegen cross origin
            if (!timestamp) {
                $("#LabuniqListBlock").load(
                    "/" + appLocation + "getLabuniqPatient/" + patientId
                );
            }
            return false;
        })
        .fail(function () {
            actionFail("Vorgang war nicht erfolgreich.");
            return false;
        });
    return false;
}

function sendDataToFotoFinder(patientId) {
    let url =
        "/" +
        appLocation +
        "gdt/FoFiRS01.gdt?patientId=" +
        patientId;

    window.open(url, "_blank");
}

function sendDataToLabAnf(patientId) {
    let url =
        "/" +
        appLocation +
        "gdt/LabAnf.gdt?patientId=" +
        patientId;

    window.open(url, "_blank");
}

function sendLabRequestRequest(patientId) {
    let diagnosesDataTable = $("#DiagnosesTableForLabRequest").DataTable();
    let diagnosesData = diagnosesDataTable.rows(".selected").data();
    let diagnosesIds = [];
    diagnosesData.each(function (diagnoses) {
        diagnosesIds.push($(diagnoses[0]).val());
    });
    let url =
        "/" +
        appLocation +
        "lab-request/import.txt?allDiagnoses=false&patientId=" +
        patientId;

    let labRequestHealthCareProviderDoctorId = $('#labRequestHealthCareProviderDoctor').val();

    if (labRequestHealthCareProviderDoctorId == '-1') {
        alert("Achtung, kein anfordernde(r) Arzt/Ärztin gewählt!");
    } else {
        url += "&labRequestHealthCareProviderDoctorId=" + labRequestHealthCareProviderDoctorId
        diagnosesIds.forEach(function (diagnosisId) {
            url += "&diagnosesIds=" + diagnosisId;
        });
        window.open(url, "_blank");
    }
}

function openJSPanelIFrame(response) {
    let content =
        "<iframe src='" +
        response +
        '\' style="width: 100%; height: 100%;"></iframe>';
    let options = {
        headerLogo: '<span style="margin:10px"></span>',
        contentSize: {width: "80%", height: "80%"},
        onwindowresize: true,
        closeOnEscape: true,
        borderRadius: 3,
        content: content,
        headerTitle: "Labuniq Daten",
        theme: "#f2f2f2",
    };
    jsPanel.create(options);
}

window.addEventListener(
    "message",
    function (evt) {
        if (!window.reactURL) return;
        if (!evt.origin) return;

        let eventHost = new URL(evt.origin).host;
        let reactHost = new URL(window.reactURL).host;

        if (eventHost === reactHost) {
            let data = evt.data.message;
            if (!data) return;
            if (data.action === "display_patient") {
                openPatientView(data.id);
            }
            if (data.action === "display_preview") {
                openPreview(data);
            }
        }
    },
    false
);

function openPreview(data) {
    $("#PDFPreviewModalReplace").load(
        "/" + appLocation + "documentpreview/" + data.type + "/" + data.id,
        function (response) {
            $("#PDFPreviewModal").modal({backdrop: "static", keyboard: false});
        }
    );
}

function openPatientView(patientId) {
    router.navigate(appLocation + "PatientView/" + patientId, {trigger: true});
}

let selectedPZNQuantity = new Map();

function calculateDaysTillFinished(drugId, startFromDate) {
    let result;

    let intakeMorning = $("#intakeMornings" + drugId).val();
    let intakeMiday = $("#intakeMidays" + drugId).val();
    let intakeEvening = $("#intakeEvenings" + drugId).val();
    let intakeNight = $("#intakeNights" + drugId).val();
    let dailyIntake =
        Number(intakeMorning) +
        Number(intakeMiday) +
        Number(intakeEvening) +
        Number(intakeNight);

    if (
        dailyIntake == null ||
        isNaN(dailyIntake) ||
        dailyIntake === Infinity ||
        dailyIntake == 0
    )
        result = "Einnahme pro Tag kann nicht berechnet werden, im Format F-M-A-N nur Zahlen eingeben.";
    else {
        let quantity = selectedPZNQuantity.get(drugId.toString());
        if (
            quantity == null ||
            isNaN(quantity) ||
            quantity === Infinity ||
            quantity == 0
        ) {
            result = "Menge pro Packung nicht gültig. Wählen Sie eine Packungsgröße.";
        } else {
            let amountOP = $("#amountOPs" + drugId).val();
            if (
                amountOP == null ||
                isNaN(amountOP) ||
                amountOP === Infinity ||
                amountOP == 0
            ) {
                result =
                    "Anzahl Packungen nicht gültig. Geben Sie die Anzahl Packungen (OP) ein.";
            } else {
                let availableUnits = amountOP * quantity;
                let daysTillFinished = availableUnits / dailyIntake;
                let finishDate = moment(startFromDate, "YYYY-MM-DD")
                    .add(daysTillFinished - 1, "days")
                    .format("DD.MM.YYYY");
                result = daysTillFinished + " Tage, Start: " + moment(startFromDate, "YYYY-MM-DD")
                    .format("DD.MM.YYYY") + ", Ende: " + finishDate;
            }
        }
    }
    $("#calculateDaysTillFinished" + drugId.toString()).html(result);
}

//webkitURL is deprecated but nevertheless
URL = window.URL || window.webkitURL;
let gumStream; //stream from getUserMedia()
let recorder; //MediaRecorder object
let chunks = []; //Array of chunks of audio data from the browser
let extension;
let currentAudioRecording;

function initRecorder() {
    $("#audioRecordings").show();

    let recordButton = document.getElementById("recordButton");
    let stopButton = document.getElementById("stopButton");
    let pauseButton = document.getElementById("pauseButton");
    let pauseButtonText = $("#pauseButtonText");

    //add events to those 2 buttons
    recordButton.addEventListener("click", startRecording);
    stopButton.addEventListener("click", stopRecording);
    pauseButton.addEventListener("click", pauseRecording);

    // true on chrome, false on firefox
    console.log(
        "audio/webm:" + MediaRecorder.isTypeSupported("audio/webm;codecs=opus")
    );
    // false on chrome, true on firefox
    console.log(
        "audio/ogg:" + MediaRecorder.isTypeSupported("audio/ogg;codecs=opus")
    );

    if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
        extension = "webm";
    } else {
        extension = "ogg";
    }
}

function startRecording() {
    console.log("recordButton clicked");

    /*
        Simple constraints object, for more advanced audio features see
        https://addpipe.com/blog/audio-constraints-getusermedia/
    */

    let constraints = {audio: true};

    /*
       Disable the record button until we get a success or fail from getUserMedia()
   */

    recordButton.disabled = true;
    stopButton.disabled = false;
    pauseButton.disabled = false;

    /*
        We're using the standard promise based getUserMedia()
        https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    */

    navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
            console.log(
                "getUserMedia() success, stream created, initializing MediaRecorder"
            );

            /*  assign to gumStream for later use  */
            gumStream = stream;

            let options = {
                audioBitsPerSecond: 256000,
                videoBitsPerSecond: 2500000,
                bitsPerSecond: 2628000,
                mimeType: "audio/" + extension + ";codecs=opus",
            };

            /*
            Create the MediaRecorder object
        */
            recorder = new MediaRecorder(stream, options);

            //when data becomes available add it to our attay of audio data
            recorder.ondataavailable = function (e) {
                console.log("recorder.ondataavailable:" + e.data);

                console.log(
                    "recorder.audioBitsPerSecond:" + recorder.audioBitsPerSecond
                );
                console.log(
                    "recorder.videoBitsPerSecond:" + recorder.videoBitsPerSecond
                );
                console.log("recorder.bitsPerSecond:" + recorder.bitsPerSecond);
                // add stream data to chunks
                chunks.push(e.data);
                // if recorder is 'inactive' then recording has finished
                if (recorder.state == "inactive") {
                    // convert stream data chunks to a 'webm' audio format as a blob
                    const blob = new Blob(chunks, {
                        type: "audio/" + extension,
                        bitsPerSecond: 128000,
                    });
                    createDownloadLink(blob);
                }
            };

            recorder.onerror = function (e) {
                console.log(e.error);
            };

            //start recording using 1 second chunks
            //Chrome and Firefox will record one long chunk if you do not specify the chunck length
            recorder.start(1000);

            //recorder.start();
        })
        .catch(function (err) {
            //enable the record button if getUserMedia() fails
            recordButton.disabled = false;
            stopButton.disabled = true;
            pauseButton.disabled = true;
        });
}

function pauseRecording() {
    // console.log("pauseButton clicked recorder.state=", recorder.state);
    if (recorder.state == "recording") {
        //pause
        recorder.pause();
        pauseButtonText.innerHTML = "Fortsetzen";
    } else if (recorder.state == "paused") {
        //resume
        recorder.resume();
        pauseButtonText.innerHTML = "Pause";
    }
}

function stopRecording() {
    // console.log("stopButton clicked");

    //disable the stop button, enable the record too allow for new recordings
    stopButton.disabled = true;
    recordButton.disabled = false;
    pauseButton.disabled = true;

    //reset button just in case the recording is stopped while paused
    pauseButtonText.innerHTML = "Pause";

    //tell the recorder to stop the recording
    recorder.stop();

    //stop microphone access
    gumStream.getAudioTracks()[0].stop();
}

function createDownloadLink(blob) {
    currentAudioRecording = blob;
    let url = URL.createObjectURL(blob);
    let audioElement = document.createElement("audio");

    //add controls to the <audio> element
    audioElement.controls = true;
    audioElement.src = url;

    //add the li element to the ordered list
    $("#recordingResult").show();
    $("#recordingResult .audioContainer").html(audioElement);
}

function uploadCurrentAudioRecording(patientId) {
    if (currentAudioRecording == null || !currentAudioRecording) {
        actionFail("Keine Aufnahme vorhanden.");
    }
    let fd = new FormData();
    fd.append("file", currentAudioRecording);
    fd.append("patientId", patientId);

    $.ajax({
        url: "/" + appLocation + "saveAudioRecording",
        context: document.body,
        type: "post",
        data: fd,
        processData: false,
        contentType: false,
    })
        .done(function () {
            actionSuccess();
            resetAudioRecordings();
            loadById("Documents", patientId);
        })
        .fail(function (error) {
            actionFail(error);
        });
    return false;
}

function resetAudioRecordings() {
    $("#recordingResult .audioContainer").html("");
    $("#recordingResult").hide();
    $("#audioRecordings").hide();
    chunks = [];
    currentAudioRecording = null;
}


function filterElements(elementQuery, searchTerm, mapping) {
    searchTerm = searchTerm.toLowerCase();
    document.querySelectorAll(elementQuery).forEach(function (element) {
        let content = (mapping ? mapping(element) : element.innerText).toLowerCase();
        element.style.display = content.indexOf(searchTerm) > -1 ? "" : "none";
    });
}

function customChoose() {
    bootbox.dialog({
        message: message,
        title: title,
        buttons: {
            success: {
                label: "Einverstanden",
                className: "btn-success",
                callback: function (e) {
                    //prevent double submitting
                    this.find(".btn-success").attr("disabled", true);
                    this.find(".btn-success").html("Bitte warten .....");

                    if (successFunction == null) {
                        //do nothing
                    } else if (typeof successFunction === "function") successFunction();
                    else alert("Success, but invalid callback");
                },
            },
            danger: {
                label: "Ablehnen",
                className: "btn-danger",
                callback: function () {
                    //prevent double submitting
                    this.find(".btn-danger").attr("disabled", true);
                    this.find(".btn-danger").html("Bitte warten .....");

                    if (deniedFunction == null) {
                        //do nothing
                    } else if (typeof deniedFunction === "function") deniedFunction();
                    else alert("Denied, but invalid callback");
                },
            },
        },
    });
}

function querySessionType() {
    let accountType = null;
    $.ajax({
        url: "/" + appLocation + "api/getSessionType",
        success: function (result) {
            if (result !== "null") accountType = result
        },
        async: false
    });
    return accountType
}

function luminance(r, g, b) {
    let a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function determineTextColor(backgroundColor) {
    // expects color from "window.computedStyle(elem).backgroundColor"
    let rgbMatch = backgroundColor.match(/\d+/g);
    const rgb = rgbMatch ? rgbMatch.map(Number) : null;
    return luminance(rgb[0], rgb[1], rgb[2]) > 0.5 ? 'black' : 'white';
}

function initMessageLabelsTypeahead(target, labels) {
    // https://twitter.github.io/typeahead.js/examples/
    const {messageId, mpAppEntityUUID} = target
    const elem = $("#messageLabelsInput-" + (messageId || mpAppEntityUUID));
    elem.typeahead({minLength: 0, hint: false, highlight: true},
        {
            name: "messageLabels",
            displayKey: 'text',
            source: function (query, callback) {
                const matches = []
                $.each(labels, function (i, label) {
                    if (matches.length > 10) return;
                    if (query === '' || label.text.indexOf(query) > -1) {
                        matches.push(label);
                    }
                });

                callback(matches);
            },
            templates: {
                suggestion: function ({background, foreground, text}) {
                    return '<div class="col-sm-offset-1">' +
                        `<span class="badge" style="background: ${background}; color: ${foreground}">${text}</span>` +
                        '</div>';
                }
            }
        }
    ).on("typeahead:selected", function (evt, item) {
        messageLabelsAction({action: 'add', labelId: item.id, ...target});
        elem.typeahead("val", "").trigger("keyup");
    });

    // Manuell die Vorschläge anzeigen, wenn das Feld fokussiert wird
    elem.focus(function () {
        elem.typeahead('val', 'a').trigger("keyup"); // Temporär ein a hinzufügen (Leerzeichen funktioniert nicht)
        elem.typeahead('val', '').trigger("keyup");  // Leerzeichen wieder entfernen, um Source-Callback zu triggern
    });
}

function manageMessageLabels(target) {
    const {messageId, mpAppEntityUUID} = target
    const input = $('#messageLabelsInput-' + (messageId || mpAppEntityUUID));
    if (input.attr("data-new") !== "true") return;
    input.parent().on('shown.bs.collapse', function () {
        messageLabelsAction({action: "fetch", labelId: -1, ...target});
        input.focus();
    });
    input.parent().on('hidden.bs.collapse', function () {
        messageLabelsAction({action: "fetch", labelId: -1, edit: false, ...target});
    });
    $.ajax({
        url: '/' + appLocation + `listMessageLabels`,
        context: document.body,
        type: 'post'
    }).done(function (response) {
        initMessageLabelsTypeahead(target, JSON.parse(response));
        input.attr("data-new", "false");
    });

}

function messageLabelsAction({action, messageId, mpAppEntityUUID, labelId, edit = true}) {

    if (messageId) {
        $.ajax({
            url: '/' + appLocation + 'messageLabelsAction',
            context: document.body,
            type: 'post',
            data: {action, messageId, labelId, edit}
        }).done(function (fragment) {
            $(".messageLabels-" + messageId).replaceWith(fragment)
        }).fail(function (e) {
            actionFail()
        })
    } else if (mpAppEntityUUID) {
        $.ajax({
            url: '/' + appLocation + 'mediprimeApp/labelsAction',
            context: document.body,
            type: 'post',
            data: {action, mpAppEntityUUID, labelId, edit}
        }).done(function (fragment) {
            $(".messageLabels-" + mpAppEntityUUID).replaceWith(fragment)
        }).fail(function (e) {
            actionFail()
        })
    }

}

function createMessageLabel() {
    $.ajax({
        url: `/${appLocation}labels/new`,
        context: document.body, type: 'get'
    }).done(function (response) {
        actionSuccess();
        document.getElementById('messageLabelSettings').innerHTML = response;
        initMessageLabelColorPicker();
    }).fail(function (e) {
        actionFail()
    });
}

function deleteMessageLabel(labelId) {
    $.ajax({
        url: `/${appLocation}labels/${labelId}/delete`,
        context: document.body, type: 'get'
    }).done(function (response) {
        actionSuccess();
        document.getElementById('messageLabelSettings').innerHTML = response;
        initMessageLabelColorPicker();
    }).fail(function (e) {
        actionFail()
    });
}

function setMessageLabelSetting(labelId, setting, value) {
    $.ajax({
        url: `/${appLocation}labels/${labelId}/setting`,
        context: document.body, type: 'post', data: {setting, value}
    }).done(function () {
        if (setting === "background") {
            const bg = window.getComputedStyle(document.getElementById('labelBackgroundPreview-' + labelId)).backgroundColor
            const fg = $('#labelForegroundInput-' + labelId)
            fg.val(determineTextColor(bg))
            fg.trigger('change')
            fg.trigger('blur')
        }
    }).fail(function (e) {
        if (e && e.responseText) {
            actionFail(e.responseText);
        } else {
            actionFail();
        }
    })
}

function editMessageLabels(entityType, entityId) {
    $.ajax({
        url: `/${appLocation}labels/editModal`,
        context: document.body, type: 'get',
        data: {entityType, entityId}
    }).done(function (message) {
        const box = bootbox.dialog({
            title: "Labels Bearbeiten",
            message,
            buttons: {
                confirm: {
                    label: "Bestätigen",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: `/${appLocation}labels/set`,
                            context: document.body, type: 'post',
                            data: $("#messageLabelsForm").serialize()
                        }).done(function () {
                            actionSuccess();
                            $(`#messageLabels${entityType}-${entityId}`).load(`/${appLocation}labels/get?entityId=${entityId}&entityType=${entityType}`);
                        }).fail(function (error) {
                            actionFail(error)
                        })
                    }
                },
                close: {
                    label: "Abbrechen",
                    className: "btn-danger",
                }
            },
        });
    }).fail(function (e) {
        actionFail(e);
    })
}


async function addTableListener(tableId) {
    const element = await waitForElm(tableId);
    const error = $(`#abs-diagnoses-selector`);

    element.addEventListener('phr-table-select', (event) => {

        const selectedRows = $("#" + event.currentTarget.id + " .selected");
        const diagnosesLongerThan = selectedRows.map(function () {
            const txt = $(this).find("td:eq(2)").text().trim();
            return txt.length > 100 ? txt : undefined; // Holt den Text aus der zweiten Zelle
        }).get();

        if (diagnosesLongerThan.length >= 1) {
            error.show();
            const errorText = error.children(".abs-error")
            errorText.html("Eine Diagnose darf maximal 100 Zeichen haben !");
        } else error.hide();

    });
}


const validations = {
    isBetweenOrEmpty: (value, options) => {
        const {name, min, max} = options
        return !value || value.length === 0 || (value.length >= min && value.length < max) ? {
            valid: true
        } : {
            valid: false,
            error: `Eingaben im Feld "${name}" müssen ${min} Zeichen übersteigen und sind auf maximal ${max} Zeichen limitiert!`
        };
    },
};

function attachLiveValidation() {
    const issueERecipe = $("input[name=issueERecipe]");
    issueERecipe.on('change', (event) => {
        console.log(event.currentTarget.value);
    });

    $(`input[data-custom-validation]`).each((index, element) => {
        const input = $(element);
        const errorBlock = input.siblings("[class^=error-]")
        const fn = (element, selector) => {
            const ecardValidationActive = $("input[name=issueERecipe]").val() === "true";
            const value = event.currentTarget.value;
            const fn = input.attr('data-custom-validation')
            const min = input.attr('min')
            const max = input.attr('max')
            const name = input.attr('data-validation-name')

            const {valid, error} = validations[fn](value, {name, min, max});
            if (!valid && ecardValidationActive) {
                input.addClass('is-invalid');
                errorBlock.show();
                errorBlock.html(error);
            } else errorBlock.hide();
        }

        input.on('change', fn);
        input.on('blur', fn);
    });
}

function generateRandomString(length, characters = "abcdefghijklmnopqrstuvqxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789") {
    return Array(length)
        .fill(0)
        .map(it => characters[Math.floor(Math.random() * characters.length)])
        .join("")
}

