/*

    stompClient.on("connect", (frame) => {
        console.log("STOMP verbunden!", frame);
    });

    stompClient.on("disconnect", (frame) => {
        console.warn("STOMP getrennt", frame);
    });

    stompClient.on("error", (errorFrame) => {
        console.error("Fehler aufgetreten:", errorFrame);
    });

    stompClient.on("/user/queue/ui-action", (payload) => {
        console.log("UI-Aktion erhalten:", payload);
    });

stompClient.on("/user/queue/updated-entity", (payload, message) => { // subscribed automatisch !
    console.log("Updated Entity erhalten über EventEmitter:", payload);
});

 */

/**
 * Stellt eine Verbindung zum STOMP-Server her.
 *
 * Diese Funktion überprüft zunächst, ob Benachrichtigungen unterstützt werden und
 * fordert gegebenenfalls die Berechtigung zur Anzeige von Desktop-Benachrichtigungen an.
 * Anschließend wird ein STOMP-Client erstellt und die Verbindung aufgebaut.
 *
 * Fehler während der Verbindungsherstellung werden in der Konsole protokolliert.
 *
 * @async
 * @function
 */
async function connectSocket() {
    try {
        if (window.Notification) {
            try {
                const permission = await Notification.requestPermission();
                console.log("Notification permission:", permission);
            } catch (error) {
                if (error instanceof TypeError) {
                    Notification.requestPermission(() => {
                        console.warn("Notification permission requested via callback (Safari).");
                    });
                } else {
                    throw error;
                }
            }
        }

        const client = await createStompClient();
        console.log("STOMP client connected", client);

    } catch (error) {
        console.error("Error connecting socket:", error);
    }
}

/**
 * Erstellt einen neuen STOMP-Client und authentifiziert den Benutzer.
 *
 * Die Funktion führt einen AJAX-Request an die Authentifizierungs-API aus, um die
 * Benutzerdaten abzurufen. Nach erfolgreicher Authentifizierung wird der
 * STOMP-Client initialisiert, die erforderlichen Subscriptions werden eingerichtet
 * und die Verbindung wird aktiviert.
 *
 * Bei Fehlern während der Authentifizierung wird das Promise mit einer Fehlermeldung abgelehnt.
 *
 * @returns {Promise<Object>} Ein Promise, das den initialisierten STOMP-Client zurückgibt.
 * @function
 */
async function createStompClient() {
    const userData = await get("/app/api/authenticated");
    if (!userData) return;
    const stompClient = new StompClient(
        "/app/socket",
        userData,
        topic => data => console.log(`HandlerFactory: ${topic}`, data),
        () => {
            console.log("Resubscribing...");
            stompClient.resubscribeAll();
            setupSubscriptions(stompClient, userData);
        },
        (disconnectInfo) => {
            console.warn("Disconnected:", disconnectInfo);
        },
        true // Debug-Modus
    );
    window.stompClient = stompClient;
    return stompClient;
}

/**
 * Richtet die notwendigen Subscriptions für den STOMP-Client ein.
 *
 * Diese Funktion registriert Callback-Funktionen für bestimmte Topics, um
 * auf Nachrichten vom Server zu reagieren. Sie behandelt:
 * - Aktualisierungen von Entitäten (`/user/queue/updated-entity`)
 * - UI-Aktionen (`/user/queue/ui-action`)
 *
 * @param {Object} stompClient - Die Instanz des STOMP-Clients.
 * @param {Object} userData - Die Benutzerdaten des aktuell angemeldeten Nutzers.
 * @function
 */
function setupSubscriptions(stompClient, userData) {
    stompClient.subscribe({
        topic: `/user/queue/updated-entity`,
        callback: (content) => handleUpdatedEntity(content, userData)
    });

    stompClient.subscribe({
        topic: `/user/queue/ui-action`,
        callback: handleUiAction
    });
}

/**
 * Verarbeitet Nachrichten zu aktualisierten Entitäten, die vom Server empfangen wurden.
 *
 * Diese Funktion prüft den Typ der Entität und führt darauf basierende Aktionen aus:
 * - Zeigt eine Benachrichtigung an, wenn ein neuer ToDo-Listeneintrag vorhanden ist.
 * - Aktualisiert die ToDo-Liste oder das E-Card-Dialog-Panel bei Änderungen.
 *
 * @param {Object} content - Die empfangene Nachricht mit den Details zur aktualisierten Entität.
 * @param {Object} userData - Die Benutzerdaten des aktuell angemeldeten Nutzers.
 * @function
 */
function handleUpdatedEntity(content, userData) {
    const {entity, authenticatedUserId, body, id} = content;
    switch (entity) {
        case "ToDoListEntry":
            if (authenticatedUserId !== userData.id && window.Notification && Notification.permission === "granted") {
                new Notification("Neuer ToDo-Listeneintrag!", {
                    body,
                    tag: id,
                });
            }
            refreshToDoList();
            break;

        case "ECardDialog":
            if (window.ecardPanel) {
                refreshECardDialogTable();
            }
            break;
        case "ECardMessage":
            console.log(content)
            // if(content) onECardMessage(content?.body)
            break;

        default:
            console.warn("Unhandled entity:", entity);
    }
}

/**
 * Reagiert auf UI-bezogene Aktionen, die über den STOMP-Client empfangen werden.
 *
 * Diese Funktion behandelt verschiedene Benutzeroberflächen-Interaktionen, wie z.B.:
 * - Erfolgsmeldungen (`actionSuccess`)
 * - Fehlermeldungen (`actionFail`)
 * - Starten/Stoppen von Ladeanzeigen (`actionStartLoading` / `actionStopLoading`)
 *
 * @param {Object} content - Die empfangene Nachricht mit Details zur auszuführenden Aktion.
 * @function
 */
function handleUiAction(content) {
    const {action, entity, message} = content;

    switch (action) {
        case "actionSuccess":
            return actionSuccess();

        case "actionFail":
            return actionFail(message);

        case "actionStartLoading":
            return actionStartLoading();

        case "actionStopLoading":
            return actionStopLoading();

        default:
            console.warn("Unhandled UI action:", content);
    }
}