class PdfViewer {

    constructor(options) {
        this.outputScale = window.devicePixelRatio || 1;
        this.pdfData = options.pdfData;
        this.container = options.selector;
        this.currentPageIndex = 0;
        this.pageMode = 1;
        this.cursorIndex = Math.floor(this.currentPageIndex / this.pageMode);
        this.pdfInstance = null;
        this.totalPagesCount = 0;
        this.signatureCoordinates = this.position = options.placeholderCoordinates;
        this.viewport = document.querySelector(this.container);
    }

    initPDFViewer() {

        let pdfLoadingTask = pdfjsLib.getDocument(this.pdfData);
        pdfLoadingTask.promise.then(pdfDocument => {
            this.pdfInstance = pdfDocument;
            this.totalPagesCount = pdfDocument.numPages;
            this.currentPageIndex = 0;
            // initPageMode();
            this.initPageDisplay();
            // initDragAndDrop();
            this.render();
        });
    };

    onPagerButtonsClick(event) {
        const action = event.target.getAttribute('data-pager');
        const pageInput = document.querySelector('#pagination input');
        if (action === 'prev') {
            if (this.currentPageIndex === 0) {
                return;
            }
            this.currentPageIndex -= this.pageMode;
            if (this.currentPageIndex < 0) {
                this.currentPageIndex = 0;
            }
            pageInput.value = this.currentPageIndex + 1;
            this.render();
        }
        if (action === 'next') {
            if (this.currentPageIndex === this.totalPagesCount - 1) {
                return;
            }
            this.currentPageIndex += this.pageMode;
            if (this.currentPageIndex > this.totalPagesCount - 1) {
                this.currentPageIndex = this.totalPagesCount - 1;
            }
            pageInput.value = this.currentPageIndex + 1;
            this.render();
        }
    }

    onPageModeChange(event) {
        this.pageMode = Number(event.target.value);
        this.render();
    }


    initPageMode() {
        const input = document.querySelector('#page-mode input');
        input.setAttribute('max', this.totalPagesCount);
        input.addEventListener('change', this.onPageModeChange);
        return () => {
            input.removeEventListener('change', this.onPageModeChange);
        };
    }

    initPageDisplay() {
        const pager = document.querySelector('#pager');
        if (this.totalPagesCount <= 1) pager.style.display = 'none';
        pager.addEventListener('click', this.onPagerButtonsClick.bind(this));

        const display = document.querySelector('#pagination h4');
        display.innerHTML = `${this.totalPagesCount}`;
        const pageInput = document.querySelector('#pagination input');
        pageInput.value = 1
        pageInput.setAttribute('max', this.totalPagesCount);
        pageInput.addEventListener('change', (event) => {
            const newPage = Number(event.target.value);
            this.currentPageIndex = newPage >= 1 && newPage <= this.totalPagesCount ? newPage : newPage > this.totalPagesCount ? this.totalPagesCount : newPage <= 1 ? 1 : newPage;
            this.render();
        });

        pageInput.addEventListener('keydown', (event) => {
            if (event.keyCode === 13) {
                const newPage = Number(event.target.value);
                this.currentPageIndex = newPage >= 1 && newPage <= this.totalPagesCount ? newPage : newPage > this.totalPagesCount ? this.totalPagesCount : newPage <= 1 ? 1 : newPage;
                this.render();
            }
        });
        return () => {
            pageInput.removeEventListener('change', this.onPageModeChange.bind(this));
            pager.removeEventListener('click', this.onPagerButtonsClick.bind(this));
        };
    }


    render() {
        this.cursorIndex = Math.floor(this.currentPageIndex / this.pageMode);
        const startPageIndex = this.cursorIndex * this.pageMode;
        const endPageIndex =
            startPageIndex + this.pageMode < this.totalPagesCount
                ? startPageIndex + this.pageMode - 1
                : this.totalPagesCount - 1;

        const renderPagesPromises = [];
        for (let i = startPageIndex; i <= endPageIndex; i++) {
            renderPagesPromises.push(this.pdfInstance.getPage(i + 1));
        }

        Promise.all(renderPagesPromises).then(pages => {
            const pagesHTML = `<div style="width: ${this.pageMode > 1 ? '50%' : '100%'}"><canvas></canvas></div>`.repeat(pages.length);
            this.viewport.innerHTML = pagesHTML;
            pages.forEach(this.renderPage.bind(this));
        });
    }


    renderPage(page) {
        var pdfViewport = page.getViewport({scale: this.outputScale});
        const container = this.viewport.children[page._pageIndex - this.cursorIndex * this.pageMode];
        const canvas = container.children[0];
        const context = canvas.getContext('2d');
        canvas.height = pdfViewport.height;
        canvas.width = pdfViewport.width;
        // canvas.style.width = Math.floor(pdfViewport.width) + 'px';
        // canvas.style.height = Math.floor(pdfViewport.height) + 'px';
        var transform = this.outputScale !== 1
            ? [this.outputScale, 0, 0, this.outputScale, 0, 0]
            : null;

        // initDragAndDrop();
        page.render({
            transform: transform,
            canvasContext: context,
            viewport: pdfViewport
        });

        // const canvas = this.viewport.getElementsByTagName("canvas")[0];
        // var context = canvas.getContext('2d');
        //
        // var viewport = page.getViewport(1);
        // var scale = viewport.clientWidth / viewport.width;
        // viewport = page.getViewport(scale);
        //
        // canvas.height = "270mm";//viewport.height;
        // canvas.width = "190mm";//viewport.width;
        //
        // page.render({
        //     canvasContext: context,
        //     viewport: viewport
        // });
    }


    initDragAndDrop() {
        $('#resetPlaceholder').on('click', () => {
            this.position = this.signatureCoordinates;
            $('#placeholderImageWrapper').css({
                'left': $('#placeholderImageWrapper').data('originalLeft'),
                'top': $('#placeholderImageWrapper').data('origionalTop')
            });
        });
        var placeholder = $('#placeholderImage');
        placeholder.resizable({
            aspectRatio: placeholder.width() / placeholder.height(),
        });


        $(`#${this.viewport.id} canvas`).mousemove((event) => {
            var x = event.offsetX;
            var y = event.offsetY;
            let placeholder = document.getElementById('placeholderImage');

            if (!placeholder) return;

            let placeholderRect = placeholder.getBoundingClientRect();

            this.pdfInstance.getPage(this.cursorIndex + 1).then((page) => {
                var viewPort = page.getViewport({scale: outputScale,});
                let convertToPdfPoint = viewPort.convertToPdfPoint(x, y);
                let convertToViewportPoint = viewPort.convertToViewportPoint(x, y);
                // position = {
                //     x: x,
                //     y: y ,
                //     width: placeholderRect.width,
                //     height: placeholderRect.height,
                //     page: this.cursorIndex + 1
                // };
                if (false)
                    $('#debug').html(
                        JSON.stringify(convertToPdfPoint) + '<br>' +
                        JSON.stringify(position) + '<br>'
                        + JSON.stringify(convertToViewportPoint))
            })
        })
        // https://javascript.info/coordinates
        $('#placeholderImageWrapper').draggable({
            containment: `#${this.viewport.id} canvas`,
            scroll: false,
            drag: function (event, ui) {
                let canvas = document.querySelector(`#${this.viewport.id} canvas`);
                let placeholder = document.getElementById('placeholderImage');
                let canvasRect = canvas.getBoundingClientRect();
                let placeholderRect = placeholder.getBoundingClientRect();

                let x = ui.offset.left - canvasRect.x;
                let y = Math.abs(((ui.offset.top + placeholderRect.height) - canvasRect.top) - canvasRect.height);


                this.cursorIndex = Math.floor(this.currentPageIndex / this.pageMode);
                this.pdfInstance.getPage(this.cursorIndex + 1).then((page) => {
                    var viewPort = page.getViewport({scale: outputScale,});
                    let convertToPdfPoint = viewPort.convertToPdfPoint(x, y);
                    let convertToViewportPoint = viewPort.convertToViewportPoint(x, y);

                    this.position = {
                        x: x,
                        y: y - placeholderRect.height,
                        width: placeholderRect.width,
                        height: placeholderRect.height,
                        page: this.cursorIndex + 1
                    };

                    if (false)
                        $('#debug').html(JSON.stringify(canvasRect) + '<br>'
                            + JSON.stringify(placeholderRect) + '<br>'
                            + JSON.stringify(position) + '<br>'
                            + JSON.stringify(convertToPdfPoint) + '<br>'
                            + JSON.stringify(convertToViewportPoint))
                })
            }
        });

        $('#placeholderImageWrapper').data({
            'originalLeft': $('#placeholderImageWrapper').css('left'),
            'origionalTop': $('#placeholderImageWrapper').css('top')
        });

        $(`#${this.viewport.id} canvas`).droppable();
    }

    getCoordinates() {
        return position;
    }
}
