async function initQuickActions() {
    const quickActionContext = await $.get(`/app/getQuickActionContext`);
    const {wikiPages, ginos, medicalPractices, genericDocumentTypes} = quickActionContext;
    const actions = [
        {category: "Allgemein", displayName: "Nachrichten", usageCount: 0, href: "/app/Messages"},
        {category: "Allgemein", displayName: "DaMe-Befunde", usageCount: 0, href: "/app/DaMeFindingsReports"},
        {
            category: "Allgemein", displayName: "GeräteIntegrationen", tags: ["devices"],
            usageCount: 0, href: "/app/DeviceIntegrationModule"
        },
        {
            category: "Allgemein",
            displayName: "Startseite",
            usageCount: 0,
            tags: ["home", "/", "app"],
            href: "/app/"
        },
        {
            category: "Einstellungen",
            displayName: "Einstellungen",
            usageCount: 0,
            tags: ["settings", "einstellungen"],
            href: "/app/Profile"
        },
        {
            category: "Einstellungen",
            displayName: "Design für Dokumente und Formulare",
            usageCount: 0,
            tags: ["design", "dokumente", "formulare"],
            href: "/app/DocumentStyleInformation"
        },
        {
            category: "Einstellungen",
            displayName: "Vorlagen Dokumente und Formulare",
            usageCount: 0,
            tags: ["vorlagen", "dokumente", "formulare", "Aktivierte / Verfügbare Formulare und Dokumente"],
            href: "/app/GenericDocumentTypes"
        },
        {
            category: "Einstellungen",
            displayName: "Ordinationen",
            usageCount: 0,
            tags: ["practice"],
            href: "/app/MedicalPractices"
        },
        {
            category: "Einstellungen",
            displayName: "Honorarnoten",
            usageCount: 0,
            href: "/app/ReimbursementItems"
        },
        {
            category: "Einstellungen",
            displayName: "Kalender-Einstellungen",
            usageCount: 0,
            href: "/app/AppointmentsModuleSettings"
        },
        {category: "ToDos", displayName: "Todoliste anzeigen", usageCount: 0, function: () => showToDoListModal()},
        {
            category: "Kalender",
            displayName: "Kalender",
            usageCount: 0,
            tags: ["kalender", "calendar", "appointments"],
            href: "/app/AppointmentsModule"
        },
        {
            category: "Kalender",
            displayName: "Terminstatistik",
            usageCount: 0,
            tags: ["terminstatistik", "appointmentstatistics"],
            href: "/app/AppointmentsList"
        },
        {
            category: "Patient",
            displayName: "Anamnese & Untersuchung",
            usageCount: 0,
            tags: ["Anamnese", "Untersuchung"],
            function: (action, query, context) => {
                const {patientId} = context;
                router.navigate(`/app/PatientViewClinicalExaminations/${patientId}`, {trigger: true});
            }
        },
        {
            category: "Patient",
            displayName: "Dokumente",
            usageCount: 0,
            tags: ["Documents", "Dokumente"],
            function: (action, query, context) => {
                const {patientId} = context;
                router.navigate(`/app/PatientViewDocuments/${patientId}`, {trigger: true});
            }
        },
        {
            category: "Patient",
            displayName: "Honorarnoten",
            usageCount: 0,
            tags: ["Honorarnoten", "bills", "rechnung"],
            function: (action, query, context) => {
                const {patientId} = context;
                router.navigate(`/app/PatientViewBills/${patientId}`, {trigger: true});
            }
        },
        {
            category: "Patient",
            displayName: "Termine",
            usageCount: 0,
            tags: ["Appointments", "Termine"],
            function: (action, query, context) => {
                const {patientId} = context;
                router.navigate(`/app/PatientViewAppointments/${patientId}`, {trigger: true});
            }
        },
        {
            category: "Extern",
            displayName: "e-Impfpass",
            tags: ["extern"],
            usageCount: 0,
            url: "https://gda.gesundheit.gv.at/"
        },
        {
            category: "e-Card", displayName: "e-Card Services", tags: ["ecard", "abs", "erezept"],
            usageCount: 0, function: () => showECardModal()
        },
        // {
        //     category: "e-Card",
        //     displayName: "Neuer Behandlungsfall",
        //     tags: ["ecard", "abs", "erezept"],
        //     disabled: (context) => {
        //         const {patientId} = context;
        //         return patientId === undefined;
        //     },
        //     usageCount: 0,
        //     function: (action, query, context) => {
        //         const {patientId} = context;
        //         const svnr = document.getElementById("patientSVNR").value;
        //         if (patientId && svnr)
        //             startBehandlungsfall(svnr, patientId, "192.168.1.199")
        //     }
        // },
    ];

    wikiPages?.forEach(page => {
        actions.push({
                category: "Wikipages",
                displayName: page.name,
                usageCount: 0,
                tags: [page.name, "wiki"],
                href: "/app/WikiPages/" + page.id
            },
        );
    });
    genericDocumentTypes?.forEach(document => {
        actions.push({
                category: "Document",
                disabled: (context) => {
                    const {patientId} = context;
                    return patientId === undefined;
                },
                displayName: document.name,
                usageCount: 0,
                value: document,
                function: (action, query, context) => {
                    const {value} = action;
                    const {patientId} = context;
                    const fullName = context?.user?.fullName;
                    if (patientId)
                        saveGenericDocument(patientId, null, value.id, false, fullName, undefined, value.documentTypeName, [])
                    // console.log(action, query);
                }
            },
        );
    });
    ginos?.forEach(gino => {
        actions.push({
                category: "e-Card",
                displayName: `Neuen Dialog anmelden: ${gino.name}`,
                usageCount: 0,
                tags: ["dialog anmelden", 'anmelden', gino.name],
                value: gino,
                function: (action, query) => {
                    const {internalIPAddress, ocardPin, id} = action.value;
                    createECardDialogWithOCard(internalIPAddress, ocardPin, id, undefined, true);
                }
            },
        );
        // actions.push({
        //     category: "e-Card",
        //     displayName: `Gino Status: ${gino.name}`,
        //     usageCount: 0,
        //     tags: ["gino status", 'anmelden', gino.name],
        //     value: gino,
        //     function: async (action, query) => {
        //         const {internalIPAddress, ocardPin, id} = action.value;
        //         await Gino.get(internalIPAddress).status();
        //         // , ocardPin, id, undefined, true);
        //     }
        // });
    });
    medicalPractices?.forEach(medicalPractice => {
        actions.push({
                category: "Praxis",
                displayName: `Praxis wechseln: ${medicalPractice.name}`,
                usageCount: 0,
                tags: ["praxis wechseln", 'wechseln', medicalPractice.name],
                value: medicalPractice,
                function: (action, query) => {
                    const {id} = action.value;
                    setActiveMedicalPractice(id);
                    $("#medicalPractice").val(id);
                }
            },
        );
    });
    const quickActions = new QuickActions({
        actions,
        prefixHandlers:
            {
                pat: async (query) => {
                    if (query.length < 3) return [];

                    const patients = await $.get(`/${appLocation}searchPatients?query=${query}`);
                    return patients.map(patient => {
                        return {
                            category: "Patienten",
                            displayName: patient.fullName,
                            tags: ["pat", "max"],
                            usageCount: 0,
                            function: () => {
                                quickActions.context.user = patient;
                                router.navigate(`/app/PatientView/${patient.id}`, {trigger: true});
                            }
                        }
                    })
                },
                // TODO: Prefix matcher mit onEnter
                // todo: async (query) => {
                //     console.log(query);
                //     const patients = await $.post(`/api/todolist-items`, {query: query});
                //     return [];
                //     // return patients.map(patient => {
                //     //     return {
                //     //         category: "Patienten",
                //     //         displayName: patient.fullName,
                //     //         tags: ["pat", "max"],
                //     //         usageCount: 0,
                //     //         href: "/app/PatientView/" + patient.id
                //     //     }
                //     // })
                // }
            }
    });

}
