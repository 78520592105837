function initializeDrugForm(saveDrugAction, savePrescriptionAction, tableId = "DrugsTableForDoctor") {
    //Submit new Drug in Modal
    $("#drugForm").submit(function () {
        //prevent double submitting
        $("#drugFormSubmitDrugButton").prop("disabled", true);
        $("#drugFormSubmitDrugButton").html("Bitte warten ...");
        $("#drugFormSubmitPrescriptionRequestButton").prop("disabled", true);
        $("#drugFormSubmitPrescriptionRequestButton").html("Bitte warten ...");
        $("#drugFormSubmitPrescriptionButton").prop("disabled", true);
        $("#drugFormSubmitPrescriptionButton").html("Bitte warten ...");

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-drug-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $("#error-msg-drug-form").show('fast');
            showAjaxResponse(response, 'drug');

            if (response.status == 'SUCCESS') {
                $('#DrugsModal').modal("hide");

                if (response.result == null) {
                    if (saveDrugAction) {
                        saveDrugAction(response);
                    }
                } else if (response.result && response.result.responseType === "drugChain") {
                    if (saveDrugAction) saveDrugAction(response);

                    if (response.result.createPrescription) {
                        showDrugChainPrescriptionDialog(response.result.patientId, response.result.drugIds, response.result.lastDrug);
                    } else {
                        update('Drugs', 0, response.result.patientId, tableId, function() {
                            document.getElementById("drugChain").value = response.result.drugIds;
                            document.getElementById("drugForm-createNext").checked = true;
                        }, true);
                    }
                } else {
                    if (savePrescriptionAction) {
                        savePrescriptionAction(response);
                    }
                }

                // if the drug form is connected to a process (which can only happen when MPEntity feed is open)
                // then reload the feed
                if ($("#mpProcessUUID").val()) applyMediprimeAppInboxFilter()
            } else {
                //re-enable submit button if failed
                $("#drugFormSubmitDrugButton").prop("disabled", false);
                $("#drugFormSubmitDrugButton").html("Medikament speichern");
                $("#drugFormSubmitPrescriptionRequestButton").prop("disabled", false);
                $("#drugFormSubmitPrescriptionRequestButton").html("Medikament speichern, Rezept erstellen und antworten");
                $("#drugFormSubmitPrescriptionButton").prop("disabled", false);
                $("#drugFormSubmitPrescriptionButton").html("Medikament speichern und  Rezept erstellen");
            }
        }).fail(function (error) {
            $("#error-msg-drug-form").show('fast');
            showAjaxError(error, 'drug');
            //re-enable submit button if failed
            $("#drugFormSubmitDrugButton").prop("disabled", false);
            $("#drugFormSubmitDrugButton").html("Medikament speichern");
            $("#drugFormSubmitPrescriptionRequestButton").prop("disabled", false);
            $("#drugFormSubmitPrescriptionRequestButton").html("Medikament speichern, Rezept erstellen und antworten");
            $("#drugFormSubmitPrescriptionButton").prop("disabled", false);
            $("#drugFormSubmitPrescriptionButton").html("Medikament speichern und  Rezept erstellen");
        });
        return false;
    });
}


function showDrugChainPrescriptionDialog(patientId, drugChain, lastDrug) {
    bootbox.dialog({
        message: "Sie haben " + drugChain.length + " Medikamente hintereinander erstellt. M&ouml;chten sie das Rezept nur für das letzte, oder für alle Medikamente erstellen?",
        title: "Bitte w&auml;hlen",
        buttons: {
          success: {
            label: "Nur das Letzte",
            className: "btn-default",
            
            callback: function () {
              this.find(".btn-default").attr("disabled", true);
              this.find(".btn-default").html("Bitte warten .....");

              getPrescriptionModal([lastDrug], patientId, undefined, undefined, undefined)
            },
          },
          danger: {
            label: "Alle " + drugChain.length,
            className: "btn-success",
            callback: function () {
              this.find(".btn-success").attr("disabled", true);
              this.find(".btn-success").html("Bitte warten .....");

              getPrescriptionModal(drugChain, patientId, undefined, undefined, undefined)
            },
          },
        },
      });
}

function performDrugInteractionCheck(patientId) {
    $("#DrugsModalReplace").html("");
    $("#DrugInteractionsModalReplace").load("/" + appLocation + "getDrugInteractionsModal/" + patientId, function () {
        $("#drugInteractionsModal").modal({backdrop: 'static', keyboard: false});
    });
}

function setDrugTypeAndReloadInfo(pznId, uid, name, drugId = -1) {
    $(`#prefetch-drugTypes${drugId} .typeahead`).typeahead('val', name + ' - ' + uid);

    $('#drugTypeInfoDiv' + drugId).load("/" + appLocation + "getDrugTypeInfoByName", {
        "drugId": drugId,
        "patientId": document.forms["drugForm"].patientId.value,
        "drugTypeName": name,
        "uid": uid
    }, function () {
        $('#drugTypeInfoDiv' + drugId).show();
        $('#DrugsModal').modal('handleUpdate');
        $("tr#" + pznId).click();
        $(`[href='#ekoTool${drugId}']`).click();
        actionSuccess();
    });
}