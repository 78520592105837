function revokeAccessToReady2Order(){
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie die Verbindung zu ready2order entfernen wollen?", function() {
        $.ajax({
            url: "/" + appLocation + "revokeAccessToReady2Order",
            context: document.body,
            type: 'post'
        }).done(function () {
            location.reload();
        }).fail(function () {
            actionFail();
        })
    });
}

function setR2OUser(id){
    $.ajax({
        url: "/" + appLocation + "setR2OUser",
        context: document.body,
        type: 'post',
        data: {'id': id}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });
}

function setR2OProduct(id){
    $.ajax({
        url: "/" + appLocation + "setR2OProduct",
        context: document.body,
        type: 'post',
        data: {'id': id}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });
}

function setR2OPDFFormat(pdfFormat){
    $.ajax({
        url: "/" + appLocation + "setR2OPDFFormat",
        context: document.body,
        type: 'post',
        data: {'pdfFormat': pdfFormat}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });
}

function updateReady2orderUsers(){
    $('#r2oUsersDiv').load("/" + appLocation + "updateReady2orderUsers");
}

function updateReady2orderProducts(){
    $('#r2oProductsDiv').load("/" + appLocation + "updateReady2orderProducts");
}

function updateReady2orderPaymentMethods(){
    $('#r2oPaymentMethodsDiv').load("/" + appLocation + "updateReady2orderPaymentMethods");
}