function loadDynamicWidgetToDashboard(dashboardName, widgetName, widgetTypeId, patientId, page, callback) {
    var div = '#' + dashboardName + widgetName;
    showLoaderIn(div);

    var pageQuery = '';
    if (page) {
        pageQuery = '?page=' + page;
    }

    //POST with patientId param
    if (patientId) {
        $(div).load("/" + appLocation + "getWidget/" + dashboardName + "/customWidget/" + widgetTypeId + pageQuery, {"patientId": patientId}, function () {
            if (callback && typeof callback === "function")
                callback();

            $(".prettydate").prettyDate();
        });
    } else {
        console.error('customWidget requires a patientId');
    }

}


function loadWidgetDashboard(config) {
    const {dashboardName, widgetName, patientId, widgetTypeId, page, callback, filter} = config;
    if (widgetName && widgetName.startsWith("customWidget")) {
        loadDynamicWidgetToDashboard(dashboardName, widgetName, widgetTypeId, patientId, page, callback);
    } else {
        var div = '#' + dashboardName + widgetName;
        showLoaderIn(div);

        var pageQuery = '';
        if (page) {
            pageQuery = '?page=' + page + (filter ? `&${filter}` : "");
        } else if (filter) {
            pageQuery = `?${filter}`;
        }

        //POST with patientId param
        if (patientId) {
            $(div).load("/" + appLocation + "getWidget/" + dashboardName + "/" + widgetName + pageQuery, {"patientId": patientId}, function () {
                if (callback && typeof callback === "function")
                    callback();

                $(".prettydate").prettyDate();
            });
        }
        //GET without patientId param
        else {
            $(div).load("/" + appLocation + "getWidget/" + dashboardName + "/" + widgetName + pageQuery, function () {
                if (callback && typeof callback === "function")
                    callback();

                $(".prettydate").prettyDate();
            });
        }
    }
}


function loadWidgetToDashboard(dashboardName, widgetName, patientId, widgetTypeId, page, callback) {
    loadWidgetDashboard({dashboardName, widgetName, patientId, widgetTypeId, page, callback})
}


function hideAndUpdateDashboardSettings(dashboardId) {
    $('#showDashboardSettings' + dashboardId).show();
    $('.dashboardSettings' + dashboardId).hide();
    reloadCurrentContent();
}

function showDashboardSettings(dashboardId) {
    $('#showDashboardSettings' + dashboardId).hide();
    $('.dashboardSettings' + dashboardId).show();
}

/* default method to set a dashboard setting */
function setDashboardSetting(dashboardId, setting, value, successCallback) {
    $.ajax({
        url: "/" + appLocation + "setDashboardSetting",
        context: document.body,
        type: 'post',
        data: {'dashboardId': dashboardId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function () {
        actionFail();
    })
}

/* method to update a dashboard setting from a button group (button as param) */
function updateDashboardSetting(button, dashboardId, setting) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(":input")).val();

    setDashboardSetting(dashboardId, setting, value, function () {
        unhighlightSavedValue('dashboard' + dashboardId + setting);
    });
}

/* default method to set a widget setting */
function setWidgetSetting(widgetId, setting, value, successCallback) {
    $.ajax({
        url: "/" + appLocation + "setWidgetSetting",
        context: document.body,
        type: 'post',
        data: {'widgetId': widgetId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function () {
        actionFail();
    })
}

/* method to update a widget setting from a button group (button as param) */
function updateWidgetSetting(button, widgetId, setting) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(":input")).val();

    setWidgetSetting(widgetId, setting, value, function () {
        unhighlightSavedValue('widget' + widgetId + setting);
    });
}


function toggleWidgetCheckbox(input, widgetId, setting) {
    var value = input.prop('checked');

    setWidgetSetting(widgetId, setting, value);
}

function setNotificationRead(notificationId, dashboardName) {
    $.ajax({
        url: "/" + appLocation + "setNotificationRead",
        context: document.body,
        type: 'post',
        data: {'notificationId': notificationId}
    }).done(function () {
        var newNewNotificationCount = $('#newNotificationCount').text() - 1;
        if (newNewNotificationCount > 0) {
            $('#notification' + notificationId).hide();
            $('#newNotificationCount').text(newNewNotificationCount);
        } else {
            loadWidgetToDashboard(dashboardName, 'notificationWidget');
        }
    }).fail(function () {
        actionFail();
    })
}

function setAllNotificationsRead(dashboardName) {
    $.ajax({
        url: "/" + appLocation + "setAllNotificationsRead",
        context: document.body,
        type: 'post'
    }).done(function () {
        loadWidgetToDashboard(dashboardName, 'notificationWidget');
    }).fail(function () {
        actionFail();
    })
}


function confirmMediprimeAppEntity(mediprimeAppEntityId, reloadEntities = false, value = true) {
    $.ajax({
        url: "/" + appLocation + "confirmMediprimeAppEntity",
        context: document.body,
        type: 'post',
        data: { 'mediprimeAppEntityId': mediprimeAppEntityId, value }
    }).done(function () {
        if(reloadEntities) {
            applyMediprimeAppInboxFilter();
        } else actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function initializeWaitingListForToday(dashboardName) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie die Warteliste zurücksetzen und mit den heutigen Terminen neu initialisieren wollen?", function () {
        $.ajax({
            url: "/" + appLocation + "initializeWaitingListForToday",
            context: document.body,
            type: 'post'
        }).done(function () {
            loadWidgetToDashboard(dashboardName, 'waitingListWidget');
        }).fail(function () {
            actionFail();
        })
    });
}

function clearWaitingList(dashboardName, statusFilter = null) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie die Warteliste komplett leeren wollen?", function () {
        $.ajax({
            url: "/" + appLocation + "clearWaitingList",
            context: document.body,
            type: 'post',
            data: {"statusFilter": statusFilter}
        }).done(function () {
            loadWidgetToDashboard(dashboardName, 'waitingListWidget');
        }).fail(function () {
            actionFail();
        })
    });
}

async function removeWaitingListEntry(waitingListEntryId, dashboardName, callback) {
    $.ajax({
        url: "/" + appLocation + "removeWaitingListEntry",
        context: document.body,
        type: 'post',
        data: {'waitingListEntryId': waitingListEntryId}
    }).done(function () {
        $('#waitingListEntry' + waitingListEntryId).hide();
        callback();
    }).fail(function () {
        actionFail();
    })
}

function toggleWaitingListEntryWaitingSince(waitingListEntryId, dashboardName) {
    $.ajax({
        url: "/" + appLocation + "toggleWaitingListEntryWaitingSince",
        context: document.body,
        type: 'post',
        data: {'waitingListEntryId': waitingListEntryId}
    }).done(function () {
        loadWidgetToDashboard(dashboardName, 'waitingListWidget');
    }).fail(function () {
        actionFail();
    })
}

function addAppointmentToWaitingList(appointmentId, dashboardName) {
    $.ajax({
        url: "/" + appLocation + "addAppointmentToWaitingList",
        context: document.body,
        type: 'post',
        data: {'appointmentId': appointmentId}
    }).done(function () {
        loadWidgetToDashboard(dashboardName, 'waitingListWidget');
    }).fail(function () {
        actionFail();
    })
}

function addCustomWaitingListEntryToWaitingList(dashboardName, waitingListStatus = "", widgetType = null) {
    $.ajax({
        url: "/" + appLocation + "addCustomWaitingListEntryToWaitingList",
        context: document.body,
        type: 'post',
        data: {
            'customWaitingListEntryText': $('#customWaitingListEntryText' + waitingListStatus).val(),
            'waitingListStatus': waitingListStatus
        }
    }).done(function () {
        loadWidgetToDashboard(dashboardName, 'waitingListWidget');
        if (widgetType != null) loadWidgetToDashboard(dashboardName, widgetType);
    }).fail(function () {
        actionFail();
    })
}

function clearPrintQueue(dashboardName) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie die Druckablage komplett leeren wollen?", function () {
        $.ajax({
            url: "/" + appLocation + "clearPrintQueue",
            context: document.body,
            type: 'post'
        }).done(function () {
            loadWidgetToDashboard(dashboardName, 'printQueueWidget');
        }).fail(function () {
            actionFail();
        })
    });
}

function removeDocumentFromPrintQueue(documentId) {
    $.ajax({
        url: "/" + appLocation + "removeDocumentFromPrintQueue",
        context: document.body,
        type: 'post',
        data: {'documentId': documentId}
    }).done(function () {
        $('#printQueueEntry' + documentId).hide();
    }).fail(function () {
        actionFail();
    })
}


/**
 * tagged diagnoses
 * */


//Update or create items with id (patientId for doctors creating data for their patients)
function saveTaggedDiagnosis(patientId, diagnosisId, tag) {
    if (!diagnosisId)
        diagnosisId = -1;

    $("#DiagnosesModalReplace").load("/" + appLocation + "saveTaggedDiagnosis?patientId=" + patientId + "&diagnosisId=" + diagnosisId + "&tag=" + tag, function () {
        $("#DiagnosesModal").modal({backdrop: 'static', keyboard: false});

        initializeDiagnosisFormForDoctor(
            patientId,
            function () {
                loadWidgetToDashboard('patientViewDashboard', tag + 'DiagnosesList', patientId);
                refreshDiagnosesForPatient(patientId);
            }
        )
    });
}

function tagDiagnosis(diagnosisId, patientId, tag) {
    $.ajax({
        url: "/" + appLocation + "tagDiagnosis",
        context: document.body,
        type: 'post',
        data: {diagnosisId: diagnosisId, tag: tag}
    }).done(function () {
        actionSuccess();
        loadWidgetToDashboard('patientViewDashboard', tag + 'DiagnosesList', patientId);
    }).fail(function (e) {
        actionFail(e.getMessage() ? e.getMessage() : "Vorgang war nicht erfolgreich.");
    })
}

function deleteTaggedDiagnosis(taggedDiagnosisId, patientId, tag) {
    $.ajax({
        url: "/" + appLocation + "deleteTaggedDiagnosis",
        context: document.body,
        type: 'post',
        data: {taggedDiagnosisId: taggedDiagnosisId}
    }).done(function () {
        actionSuccess();
        loadWidgetToDashboard('patientViewDashboard', tag + 'DiagnosesList', patientId);
    }).fail(function (e) {
        actionFail(e.getMessage() ? e.getMessage() : "Vorgang war nicht erfolgreich.");
    })
}

function updateWaitingListEntryStatus(entryID, statusID, dashboardType, widgetType) {
    if (dashboardType == null || dashboardType == "null") dashboardType = "doctorDashboard";
    $.ajax({
        url: "/" + appLocation + "setWaitingListEntryStatus",
        context: document.body,
        type: 'post',
        data: {
            entryID: entryID,
            statusIDString: statusID
        }
    }).done(function () {
        actionSuccess();
        // update current list
        loadWidgetToDashboard(dashboardType, widgetType);
        // update other list
        if (statusID == null) { // true, if the status was removed -> other list = main list
            loadWidgetToDashboard(dashboardType, (dashboardType == "doctorDashboard" ? "waitingListWidget" : "separateWaitingListWidget"));
        } else { // build name from dashboard type and new statusID
            loadWidgetToDashboard(dashboardType, (dashboardType == "doctorDashboard" ? "doctorCustomWaitingList" : "customWaitingList") + "-" + statusID);
        }

    }).fail(function (e) {
        actionFail();
        loadWidgetToDashboard(dashboardType, widgetType);
    });
}


function deleteWaitingListStatus(statusID) {
    customConfirm("Wartelisten-Status löschen", "Sind Sie sicher, dass Sie diesen Wartelisten-Status löschen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "deleteWaitingListStatus",
            context: document.body,
            type: 'post',
            data: {statusID: statusID}
        }).done(function () {
            location.reload();
        }).fail(function (e) {
            actionFail();
        });
    })
}

function clearWaitingListStati(statusID, dashboardType, widgetType) {
    $.ajax({
        url: "/" + appLocation + "clearWaitingListStati",
        context: document.body,
        type: 'post',
        data: {statusID: statusID}
    }).done(function () {
        actionSuccess();
        loadWidgetToDashboard(dashboardType, widgetType);
        loadWidgetToDashboard(dashboardType, (dashboardType == "doctorDashboard" ? "waitingListWidget" : "separateWaitingListWidget"));
    }).fail(function (e) {
        actionFail();
    });
}

function newAppointmentsModal() {
    $.ajax({
        url: '/' + appLocation + 'getWidget/doctorDashboard/AppointmentsModal',
        context: document.body,
        type: 'get',
        data: {}
    }).done(function (response) {
        document.getElementById("AppointmentsModalReplace").innerHTML = response;

        $('.datetimepicker-appointment-startTime').datetimepicker({
            format: 'DD.MM.YYYY HH:mm',
            toolbarPlacement: 'bottom',
            showTodayButton: true, showClose: true,
            locale: 'de',
            sideBySide: true,
            stepping: 1
        });

        $('.datetimepicker-appointment-endTime').datetimepicker({
            format: 'DD.MM.YYYY HH:mm',
            toolbarPlacement: 'bottom',
            showTodayButton: true, showClose: true,
            locale: 'de',
            sideBySide: true,
            stepping: 1
        });

        $('.datetimepicker-appointment-startTime').on("dp.change", function (e) {
            updateEndTimeFromStartTime(e, eventSlotTime);
        });

        $('.datetimepicker-appointment-startTime').data("DateTimePicker").date(new Date());

        $('#newappointmentmodal').submit(function (e) {
            e.preventDefault();
            $.ajax({
                url: "/" + appLocation + "submitAppointmentAndAddToWaitingList",
                type: 'POST',
                data: $('#newappointmentmodal').serialize(),
                success: function (e) {
                    if (e.status == "SUCCESS") {
                        actionSuccess();
                        $("#AppointmentsModal").modal("hide");

                        loadWidgetToDashboard('doctorDashboard', 'waitingListWidget');
                    } else {
                        for (var i = 0; i < e.result.length; i++) {
                            var res = e.result[i];
                            var msgBox = document.getElementById("error-msg-appointment-" + res.field);

                            msgBox.classList = ["alert alert-danger alert-dismissible"];
                            msgBox.style = "";
                            msgBox.innerHTML = "<button type=\"button\" onclick=\"document.getElementById('" + msgBox.id + "').style = 'display: none;'; \" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button>";
                            msgBox.innerHTML += "<span>" + res.defaultMessage + "</span>";
                        }
                    }
                }
            });
        });

        $("#AppointmentsModal").modal("show")
    });
}

function initMessageLabelColorPicker() {
    $('.colorpicker').colorpicker({
        customClass: 'colorpicker-2x',
        sliders: {
            saturation: {
                maxLeft: 200,
                maxTop: 200
            },
            hue: {
                maxTop: 200
            }
        },
        colorSelectors: {
            'Red': '#FF0000',
            'White': '#FFFFFF',
            'Cyan': '#00FFFF',
            'Silver': '#C0C0C0',
            'Blue': '#0000FF',
            'Gray or Grey': '#808080',
            'DarkBlue': '#0000A0',
            'Black': '#000000',
            'LightBlue': '#ADD8E6',
            'Orange': '#FFA500',
            'Purple': '#800080',
            'Brown': '#A52A2A',
            'Yellow': '#FFFF00',
            'Maroon': '#800000',
            'Lime': '#00FF00',
            'Green': '#008000',
            'Magenta': '#FF00FF',
            'Olive': '#808000',
        }
    }).on('hidePicker', function (e) {
        if ($(this).val())
            $(this).parent().find('i').css('backgroundColor', e.color.toString('rgba'));
        else
            $(this).parent().find('i').css('backgroundColor', "");
    });
}

function toggleQRCodeWidget() {
    const widget = $('#qrCodeWidget');
    widget.toggle();
    const enable = widget[0].style.display !== "none";

    if (widget.is(":empty")) {
        widget.append('<video id="qrScannerVideoFeed" style="display: block; width: 100%"></video>');
        window.qrScanner = new QrScanner(
            document.getElementById("qrScannerVideoFeed"),
            result => location.replace(result.data),
            {  },
        );
    }

    if (enable) {
        qrScanner.start();
    } else qrScanner.stop();

}

let videoStream = null;
const imagesToUpload = [];
let userFacing = false;
navigationPredicates.push(function () { return imagesToUpload.length == 0; });

document.addEventListener("fullscreenchange", () => {
    // when exiting fullscreen, stop the camera
    if (!document.fullscreenElement) stopCamera()
})

function startCamera() {
    if (videoStream) videoStream.getTracks().forEach(track => track.stop());
    const video = document.getElementById("takeImageVideoFeed");
    const container = $("#takeImageContainer");
    const button = $('#openCameraButton');

    const constraints = {
      video: { facingMode: userFacing ? 'user' : 'environment' }
    };

    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        videoStream = stream;
        video.srcObject = videoStream;
        container.removeClass('hidden');

        container[0].requestFullscreen().catch(err => { actionFail() })
    }).catch(error => {
        console.error('Kamera-Zugriff verweigert:', error)
        customModalInfo(
            "Kamera-Zugriff verweigert",
            "Sie haben den Zugriff auf ihre Kamara verweigert. Dieser wird von uns benötigt, um Fotos aufzunehmen."
        )
    })
}
function stopCamera() {
    if (! videoStream) return
    videoStream.getTracks().forEach(track => track.stop());
    const video = document.getElementById("takeImageVideoFeed");
    const container = $("#takeImageContainer");
    const button = $('#openCameraButton');

    videoStream = null;
    video.srcObject = null;

    container.addClass('hidden');
    button.html('<span class="glyphicon glyphicon-camera mr-2"></span>Kamera öffnen');
    button.removeClass("btn-danger");
    button.off('click').on('click', startCamera);

    if (document.fullscreenElement) { document.exitFullscreen(); }
}
function switchCamera(videoElement) {
    userFacing = !userFacing;
    startCamera({ videoElement });
}
async function createImagePreview(imageData, filename = null) {
    // const date = new Date().toLocaleString("de").replace(',', '');

    const file = { imageData, filename: "", id: Date.now() };
    imagesToUpload.push(file);

    const preview = $('<div class="row" style="position: relative;"></div>');
    preview.load("/" + appLocation + "patientView/imagePreview", () => {
        preview.find("img").attr("src", URL.createObjectURL(imageData));
        preview.find("button").click(function () {
            const index = imagesToUpload.indexOf(file);
            imagesToUpload.splice(index, 1);
            preview.remove();
            showPreviewContainer();
        });
        preview.find("input")
            .val(filename || "")
            .on('change', function (event) { file.filename = event.target.value; });

        $('#imageUploadPreview').append(preview);
        showPreviewContainer();
    });
}

async function captureImage() {
    const buttonText = setButtonInWaitingMode("takeImageCaptureButton");

    // Get the video element
    const video = document.getElementById("takeImageVideoFeed");
    video.pause(); // Freeze video to indicate capture
    video.classList.add("p-5");

    // Get the MediaStreamTrack from the video
    const stream = video.srcObject;
    if (!stream) {
        alert("No video stream found");
        return;
    }
    try {
        const track = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(track);
        // Take a high-quality photo
        await imageCapture.takePhoto()
            .then(createImagePreview)
            .then(() => setTimeout(() => {
                wakeButtonFromWaitingMode("takeImageCaptureButton", buttonText);
                video.play(); // Resume video after timeout
                video.classList.remove("p-5");
            }, 1000))
    } catch (error) {
        alert("Error capturing image:", error);
    }
}


function showPreviewContainer() {
    const previews = $('#imageUploadPreview');
    const previewContainer = $('#imageUploadPreviewContainer');
    const isEmpty = previews.is(":empty");
    if (isEmpty) {
        previewContainer.addClass('hidden');
    }
    else {
        previewContainer.removeClass('hidden');
    }
}
function submitCapturedImages(patientId, authorId, documentTypeId) {
    if (imagesToUpload.length <= 0) return actionFail("Keine bilder ausgewählt");
    const avoidImageCompression = document.getElementById("imageUploadAvoidCompression").checked;

    const originalText = setButtonInWaitingMode("takeImageSubmitButton");

    const form = new FormData();
    imagesToUpload.forEach(function (image, index) {
        const id = image.id + ".png";
        form.append('newDocumentFormFile', image.imageData, id)
        form.append('documentNames[' + id + ']',(image.filename ? image.filename  : "unbennant") + ".png")
    });

    form.append('documentTypeId', documentTypeId);
    form.append('signature', false);
    form.append('patientId', patientId);
    form.append('authorType', 'internal');
    form.append('authorId', authorId);
    form.append('avoidCompression', avoidImageCompression);
    form.append("externalAuthor", "");

    $.ajax({
        url: "/" + appLocation +  "uploadDocument",
        type: 'post',
        context: document.body,
        data: form,
        processData: false,
        contentType: false
    }).done(function (response) {
        actionSuccess();
        imagesToUpload.length = 0; // clear the buffer
        $('#imageUploadPreview').empty(); // clear the preview
        stopCamera(); // done uploading, we don't need the camera anymore
        showPreviewContainer();
        // show images
        $("#patientViewTabList a[aria-controls='patientViewImages']").tab('show');
        navigateInPatientView(patientId, 'Images');
        wakeButtonFromWaitingMode("takeImageSubmitButton", originalText);
    }).fail(function (error) {
        showAjaxError(error);
        wakeButtonFromWaitingMode("takeImageSubmitButton", originalText);
    });
}
function createPreviewForLocalImages(input) {
    Array.from(input.files).forEach(function (file) {
        if (! file.type.startsWith('image/')) { return } // skip non-images
        const data = new Blob([file], { type: file.type });
        createImagePreview(data, file.name);
    });

    input.value = [];
    input.files.length = 0;
}

/**
 * Loads all images defined as data-src
 * by copying data-src to src
 * and then deleting data-src
 * @param element the parent container
 */
function loadImages(element) {
    $(element).find("img[data-src]").each(function() {
        this.src = this.getAttribute("data-src");
        this.removeAttribute("data-src")
    });
}