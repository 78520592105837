class Gino {
    static EMPTY_HANDLE = "00000000000000000000000000000000";
    static ginos = [];

    /**
     * Set a custom error handler. The default behavior is to make an alert that shows the respective error message.
     * It must be a function taking one argument.<br>
     * <br>
     * E.g:<br>
     * Gino.errorHandler = function (error) { // doSomething... }
     */
    static errorHandler = function (error) {
        console.error(error);
    };

    static get(ginoIp) {
        if (!(ginoIp in Gino.ginos)) {
            Gino.ginos[ginoIp] = new Gino(ginoIp);
        }
        return Gino.ginos[ginoIp]
    }

    handle = Gino.EMPTY_HANDLE;
    logging = true;

    constructor(ginoIp) {
        this.baseUrl = "https://" + ginoIp + "/gino/v2";
    }

    /**
     * @param pin O-Card PIN
     * @returns {*} A JSON Object containing "cardData" and "token"
     */
    async statusWithOCard(pin) {

        const nfc = await this.status({
            "ignoreSlots": ["baseContact"],
            "sigType": "vp",
            "pin": pin
        });
        const slot = await this.status({
            "ignoreSlots": ["baseNfc"],
            "sigType": "vp",
            "pin": pin
        });
        return [nfc, slot].filter(x => !!x);
    }


    async getECardToken(svnr, vpnr) {
        const nfc = await this.status({
            "ignoreSlots": ["baseContact"],
            svnr,
            vpnr
        });
        const slot = await this.status({
            "ignoreSlots": ["baseNfc"],
            svnr,
            vpnr
        });
        return [nfc, slot].filter(x => !!x);
    }

    async waitForECard() {
        return await Promise.race([this.status({
            "ignoreSlots": ["baseContact"],
            "await": [
                "e-card"
            ]
        }), this.status({
            "ignoreSlots": ["baseNfc"],
            "await": [
                "e-card"
            ]
        })]);
    }


    /**
     * Performs a Status request and returns a Promise with a JSON object if fullfilled.
     * The result will depend on the data sent.<br>
     * See: https://www.chipkarte.at/de/swagger-ui/index-2.html#/Ressourcen/post_status
     * <br><br>
     * Returns a Promise containing a JSON object if fulfilled. The JSON Object is the data for
     * the slot in which the card is in. It may contain the keys
     * "registered", "cardData", "token", "sig", "cert" and possibly others, depending on the request.
     *
     * @param data e.g. { "vpnr" : "123456" }
     * @param waitForNewCard Use true if the request should wait for a new card. Else: false (default)
     * @returns Promise NOTE: The promise will only be fulfilled if there is data available.
     */
    status(data = {}, waitForNewCard = false,) {
        return this.post("/status", data, waitForNewCard);
    }

    /**
     * Performs an asynchronous POST the given JSON data to the given card reader resource URL:
     * - path: e.g., "status"
     * - data: e.g., { "vpnr" : "123456" }
     * - waitForNewCard: true, if the request should wait for a new card. Else: false (default)
     *
     * Returns a Promise containing a JSON object if fulfilled. The JSON Object is the data for
     * the slot in which the card is in. It may contain the keys
     * "registered", "cardData", "token", "sig", "cert", depending on the request.
     *
     * In Case of an error, the Promise is not fulfilled and an error is passed to the Gino.errorHandler
     *
     * @returns Promise
     */
    post(path, data = {}, waitForNewCard = false) {
        if (waitForNewCard && this.handle !== Gino.EMPTY_HANDLE) {
            data.handle = this.handle;
        }
        if (this.logging) {
            console.log("GINO Request " + path, data);
        }
        let that = this;
        return fetch(this.baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => new Promise((resolve, reject) =>
            response.json().then(json => {
                let ok = response.status === 200;
                if (that.logging) {
                    console.log("GINO " + (ok ? "Response" : "Error"), json);
                }
                if ("handle" in json) {
                    that.handle = json.handle;
                }
                if (ok) {
                    //sollte eig nicht die lib entscheiden ob die daten valid sind oder nicht
                    let slotData = this.getDataForFilledSlot(json);
                    console.log("GINO Slot Data", slotData);
                    // if (slotData) {
                    resolve(slotData);
                    // } else {
                    //     reject("GINO Fehler: Keine Daten\nBitte prüfen Sie, ob eine Karte gesteckt ist.");
                    // }
                } else {
                    let msg = "GINO Fehler";
                    if ("code" in json) {
                        msg += " " + json.code + " - " + json.detailCode + "\n" + json.text;
                    }
                    reject(msg)
                }
            })
        )).catch((error) => {
            if (error) {
                if (error instanceof TypeError && error.message === "Failed to fetch") {
                    Gino.errorHandler("GINO Verbindungsfehler.\nBitte prüfen Sie, ob das Gerät eingeschaltet und mit dem Netzwerk verbunden ist.");
                } else {
                    Gino.errorHandler(error);
                }
            } else {
                Gino.errorHandler("GINO Verbindungsfehler.\nBitte prüfen Sie, ob das Gerät eingeschaltet und mit dem Netzwerk verbunden ist.");
            }
        });
    }

    /**
     * Return the data for the slot that contains the card.
     * The slots are chcked in the following order: "baseNfc", "baseContact", "satellite".
     * If, for example, the card is in the NFC slot, then the result will be json["status"]["baseNfc"]
     *
     * @param json
     * @returns {*|null}
     */
    getDataForFilledSlot(json) {
        let data = this.getSlotContent(json, "baseNfc");
        if (data) {
            data.slot = "baseNfc";
            console.log("GINO Info: Card is in NFC");
            return data;
        }
        data = this.getSlotContent(json, "baseContact");
        if (data) {
            data.slot = "baseContact";
            console.log("GINO Info: Card is in Slot");
            return data;
        }
        data = this.getSlotContent(json, "satellite");
        if (data) {
            data.slot = "satellite";
            console.log("GINO Info: Card is in Satellite");
            return data;
        }
        return null;
    }

    /**
     * Get the data for a slot if there is a card present.
     * @param json JSON Object as returned by GINO
     * @param slot "baseContact", "baseNfc" or "satellite"
     * @returns {*|null} json["status"][key] if there's a card there, or else null
     */
    getSlotContent(json, slot) {
        return "status" in json && slot in json["status"]
        && "registered" in json["status"][slot]
        && ["e-card", "o-card"].includes(json["status"][slot]["registered"])
            ? json["status"][slot] : null;
    }

    static getSlotsToIgnore(slot) {
        switch (slot) {
            case "baseNfc":
                return ["baseContact"];
            case "baseContact":
                return ["baseNfc"];
        }
    }
}