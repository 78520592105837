function administrateAccount(accountId) {
    var reason = $('#reasonForSignInAsAccount' + accountId).val();
    $.ajax({
        url: '/' + appLocation + 'admin/administrateAccount',
        context: document.body,
        type: 'post',
        data: {'accountId': accountId, 'reason': reason}
    }).done(function (response) {
        if (response.status == 'SUCCESS')
            window.location.href = response.result;
    }).fail(function () {
        actionFail('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.');
    })
}

//load users in user administration subPage
function loadUsers(source) {
    const userNameSearch = $('#userNameSearch');
    const userTypesSelect = $('#userTypesSelect');

    const searchEmpty = userNameSearch.val() == '';

    if (searchEmpty) userNameSearch.focus();

    // we do not want to load ALL users of a type if only the type dropdown changes,
    // as this often takes longer than the filtered request.
    // which then overrides the filtered with the unfiltered results
    if (searchEmpty && source == userTypesSelect[0]) {
        // focus the search if it was empty
        userNameSearch.focus();
        return
    }

    showLoaderIn('#UserAdministrationList');

    var filter = searchEmpty ? 'noFilter' : userNameSearch.val();
    if (!userTypesSelect.val()) userTypesSelect.val('All');
    $('#UserAdministrationList').load('/' + appLocation + 'admin/getUserAdministrationList/' + userTypesSelect.val() + '/' + encodeURIComponent(filter));
}

function togglePasswordLoginForAccount(event, accountId) {

    const elem = event.target;
    const wasActive = elem.classList.contains("btn-success");

    customConfirm(
        "Passwort Login " + (wasActive ? "deaktivieren?" : "aktivieren?"),
        "Sind Sie sicher, dass sie das Passwort für den account mit der id " + accountId +
        " wirklich " + (wasActive ? "deaktivieren" : "aktivieren") + " wollen?",
        () => {
            $.ajax({
                url: "/" + appLocation + "admin/togglePasswordLoginForAccount",
                context: document.body,
                type: 'post',
                data: {accountId}
            }).done(function () { // manually toggle the button style, so we don't lose form-changes by reloading
                actionSuccess();
                if (wasActive) {
                    elem.classList.remove("btn-success");
                    elem.setAttribute("title", "Login mit passwort ist deaktiviert");
                } else {
                    elem.classList.add("btn-success");
                    elem.setAttribute("title", "Login mit passwort ist aktiviert");
                }
            }).fail(function () {
                actionFail();
            })
        }
    );

}

//load users in user administration subPage
function loadAdminMessages() {
    showLoaderIn('#AdminMessageAdministrationList');
    $('#AdminMessageAdministrationList').load('/' + appLocation + 'admin/getAdminMessageAdministration');
}

//load groups in group administration subPage
function loadGroups(source) {
    const groupNameSearch = $('#groupNameSearch');
    const groupTypesSelect = $('#groupTypesSelect');

    const searchEmpty = groupNameSearch.val() == '';

    if (searchEmpty) groupNameSearch.focus();

    if (searchEmpty && source == groupTypesSelect[0]) {
        groupNameSearch.focus();
        return
    }

    showLoaderIn('#GroupAdministrationList');

    var filter = searchEmpty ? 'noFilter' : groupNameSearch.val();
    if (!groupTypesSelect.val()) groupTypesSelect.val('All');
    $('#GroupAdministrationList').load('/' + appLocation + 'admin/getGroupAdministrationList/' + $('#groupTypesSelect').val() + '/' + encodeURIComponent(filter));
}

function adminShowPatientsDoctors(forPatientId) {
    $('#patientsDoctorsFor' + forPatientId).load('/' + appLocation + 'admin/getPatientsDoctors/' + forPatientId);
}

function adminShowDoctorsAssistants(forDoctorId) {
    $('#doctorsAssistantsFor' + forDoctorId).load('/' + appLocation + 'admin/getDoctorsAssistants/' + forDoctorId);
}

function adminShowDoctorsPatients(forDoctorId) {
    $('#doctorsPatientsFor' + forDoctorId).load('/' + appLocation + 'admin/getDoctorsPatients/' + forDoctorId);
}

function adminShowDoctorsReferrers(forDoctorId) {
    $('#doctorsReferrersFor' + forDoctorId).load('/' + appLocation + 'admin/getDoctorsReferrers/' + forDoctorId);
}

function adminShowDoctorsMembers(forDoctorId) {
    $('#doctorsMembersFor' + forDoctorId).load('/' + appLocation + 'admin/getDoctorsMembers/' + forDoctorId);
}


function adminShowAccountAdministration(forAccountId, focus) {
    $('#accountAdministrationReplace').load('/' + appLocation + 'admin/getAccountAdministration/' + forAccountId, function () {
        if (focus) {
            document.getElementById(focus).scrollIntoView();
            document.getElementById(focus).focus();
        } else {
            document.getElementById('accountAdministrationReplace').scrollIntoView();
            document.getElementById('accountAdministrationReplace').focus();
        }
    });
}

function setSettingForAccount(accountId, setting, value) {
    $.ajax({
        url: '/' + appLocation + 'admin/setSettingForAccount',
        context: document.body,
        type: 'post',
        data: {'accountId': accountId, 'setting': setting, 'value': value}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
        } else {
            actionFail(response.result);
        }
    }).fail(function () {
        actionFail();
    })
}

function updateSettingForAccount(button, accountId, setting) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/setSettingForAccount',
        context: document.body,
        type: 'post',
        data: {'accountId': accountId, 'setting': setting, 'value': value}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            unhighlightSavedValue(setting + accountId);
        } else {
            actionFail(response.result);
        }
    }).fail(function () {
        actionFail();
    })
}

function copyDocsyApiKey(accountId) {
    let value = $("#docsyApiKey" + accountId).val();
    if (value === "") value = undefined;
    copyTextToClipboard(value)
}

function submitDocsyApiKey(button, accountId, setting, deleteKey) {
    const deleteMessage = deleteKey ? "Sind Sie sicher, dass Sie den Api-Key löschen möchten ? " : "Sind Sie sicher, dass Sie den Api-Key erneuern möchten? Alle alten Zugriffe werden dadurch deaktiviert!";

    customConfirm('Bestätigen', deleteMessage, function () {

        let value = undefined;
        const selector = $("#docsyApiKey" + accountId);
        if (!deleteKey) {
            const token = crypto.randomUUID();
            value = sha256(token);
        }
        $.ajax({
            url: '/' + appLocation + 'admin/setSettingForAccount',
            context: document.body,
            type: 'post',
            data: {'accountId': accountId, 'setting': setting, 'value': value}
        }).done(function (response) {
            if (response.status == "SUCCESS") {
                actionSuccess();
                selector.val(value)
                unhighlightSavedValue(setting + accountId);
            } else {
                actionFail(response.result);
            }
        }).fail(function () {
            actionFail();
        });
    });
    return false;
}

function toggleAdminMessageActive(id) {
    $.ajax({
        url: '/' + appLocation + 'admin/toggleActive/' + id,
        type: 'post',
    }).done(function (response) {
        if (response.status == "SUCCESS")
            actionSuccess()
        else
            actionFail()
    }).fail(function (error) {
        actionFail(error)
    });
}

function deleteAdminMessage(id) {
    $.ajax({
        url: '/' + appLocation + 'admin/deleteAdminMessage/' + id,
        type: 'delete',
    }).done(function () {
        loadAdminMessages();
    }).fail(function () {
        actionFail();
    });
}


function newAdminMessage(id) {
    var url = 'admin/getNewAdminMessageModal';
    if (id) {
        url += '?id=' + id;
    }
    $('#NewAdminMessageModalReplace').load('/' + appLocation + url, function () {
        $('#AdminMessageModal').modal({backdrop: 'static', keyboard: false});

        //Submit new Doctor in Modal
        $('#newadminmessageform').submit(function (e) {
            //prevent double submitting
            var submitButtonId = 'newAdminMessageSubmitButton';
            var oldButtonText = setButtonInWaitingMode(submitButtonId);

            $('.error-msg').remove();
            $('#error-msg-newdoctor-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                $('#error-msg-newdoctor-form').show('fast');
                showAjaxResponse(response, 'newAdminMessage');
                if (response.status == 'SUCCESS') {
                    $('#AdminMessageModal').modal('hide');
                    loadAdminMessages();
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            }).fail(function (error) {
                showAjaxError(error, 'newdoctor');
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
            return false;
        });
    });
}

//create a new doctor in admin backend
function newDoctor() {
    $('#NewDoctorModalReplace').load('/' + appLocation + 'admin/getNewDoctorModal', function () {
        $('#DoctorModal').modal({backdrop: 'static', keyboard: false});

        //Submit new Doctor in Modal
        $('#newdoctormodal').submit(function (e) {
            //prevent double submitting
            var submitButtonId = 'newDoctorSubmitButton';
            var oldButtonText = setButtonInWaitingMode(submitButtonId);

            $('.error-msg').remove();
            $('#error-msg-newdoctor-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                $('#error-msg-newdoctor-form').show('fast');
                showAjaxResponse(response, 'newdoctor');
                if (response.status == 'SUCCESS') {
                    $('#DoctorModal').modal('hide');
                    loadToMainContentDivAdmin('UserAdministration', null, function () {
                        $('#userTypesSelect').val('Doctor');
                        loadUsers();
                    });
                } else {
                    wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                }
            }).fail(function (error) {
                showAjaxError(error, 'newdoctor');
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            });
            return false;
        });
    });
}


//create a new doctor in admin backend
function newAssistant() {
    $('#NewAssistantModalReplace').load('/' + appLocation + 'admin/getNewAssistantModal', function () {
        $('#AssistantModal').modal({backdrop: 'static', keyboard: false});
        //Submit new Assistant in Modal
        $('#newassistantmodal').submit(function (e) {
            $('.error-msg').remove();
            $('#error-msg-newassistant-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                showAjaxResponse(response, 'newassistant');
                if (response.status == 'SUCCESS') {
                    $('#AssistantModal').modal('hide');
                    loadToMainContentDivAdmin('UserAdministration', null, function () {
                        $('#userTypesSelect').val('Assistant');
                        loadUsers();
                    });
                }
            }).fail(function (error) {
                showAjaxError(error, 'newassistant');
            });
            $('#error-msg-newassistant-form').show('fast');
            return false;
        });
    });
}


//create a new doctor in admin backend
function newDoctorGroup() {
    $('#NewDoctorGroupModalReplace').load('/' + appLocation + 'admin/getNewDoctorGroupModal', function () {
        $('#DoctorGroupModal').modal({backdrop: 'static', keyboard: false});

        //Submit new DoctorGroup in Modal
        $('#newDoctorGroupForm').submit(function (e) {
            $('.error-msg').remove();
            $('#error-msg-newDoctorGroup-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                showAjaxResponse(response, 'newDoctorGroup');
                if (response.status == 'SUCCESS') {
                    $('#DoctorGroupModal').modal('hide');
                    loadToMainContentDivAdmin('GroupAdministration', null, function () {
                        $('#groupTypesSelect').val('DoctorGroup');
                        loadGroups();
                    });
                }
            }).fail(function (error) {
                showAjaxError(error, 'newDoctorGroup');
            });
            $('#error-msg-newDoctorGroup-form').show('fast');
            return false;
        });
    });
}

//delete User with ID id
function toggleUserEnabled(username, id, currentState) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie den User: ' + username + (currentState ? ' deaktivieren ' : ' aktivieren ') + 'möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/toggleUserEnabled',
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
            loadUsers();
        }).fail(function () {
            $('#msg').html('<span class="formFieldError">Server failed to process request</span>');
        })
    });
}

//delete Group with ID id
function toggleGroupEnabled(groupname, id, currentState) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie die Gruppe: ' + groupname + (currentState ? ' deaktivieren ' : ' aktivieren ') + 'möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/toggleUserEnabled',
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
            loadGroups();
        }).fail(function () {
            $('#msg').html('<span class="formFieldError">Server failed to process request</span>');
        })
    });
}


function addMembersToHealthCareProvider(id) {
    $('#NewHealthCareProviderMemberModalReplace').load('/' + appLocation + 'admin/getNewHealthCareProviderMemberModal/' + id, function () {
        $('#NewHealthCareProviderMemberModal').modal({backdrop: 'static', keyboard: false});

        //Submit new HealthCareProviderMember in Modal
        $('#newHealthCareProviderMemberForm').submit(function (e) {
            $('.error-msg').remove();
            $('#error-msg-newHealthCareProviderMember-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                showAjaxResponse(response, 'newHealthCareProviderMember');
                if (response.status == 'SUCCESS') {
                    $('#NewHealthCareProviderMemberModal').modal('hide');
                    loadGroups();
                }
            }).fail(function (error) {
                showAjaxError(error, 'newHealthCareProviderMember');
            });
            $('#error-msg-newHealthCareProviderMember-form').show('fast');
            return false;
        });
    });
}

function updateGroupMemberOrderNum(groupId, memberId, orderNum) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateGroupMemberOrderNum',
        context: document.body,
        type: 'post',
        data: {'groupId': groupId, 'memberId': memberId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });
}

// in group administration
function removeMemberFromGroup(groupId, memberId, groupName, memberName) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie ' + memberName + ' von der Gruppe ' + groupName + ' entfernen möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/removeMemberFromGroup',
            context: document.body,
            type: 'post',
            data: {'groupId': groupId, 'memberId': memberId}
        }).done(function () {
            actionSuccess();
            loadGroups();
        }).fail(function () {
            actionFail('Account konnte nicht entfernt werden!');
        })
    });
}

// in user administration
function removeMemberFromGroupInUserAdmin(groupId, memberId, groupName, memberName) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie ' + memberName + ' von der Gruppe ' + groupName + ' entfernen möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/removeMemberFromGroup',
            context: document.body,
            type: 'POST',
            data: {'groupId': groupId, 'memberId': memberId}
        }).done(function (response) {
            adminShowDoctorsMembers(memberId);
        }).fail(function () {
            actionFail();
        })
    });
}

//resend email token to user with ID id
function resendEmailToken(username, id) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie das E-Mail-Token von: ' + username + ' erneut senden möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/resendEmailToken',
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail('E-Mail konnte nicht gesendet werden!');
        })
    });
}

//resend email token to user with ID id
function resendPatientInvitationMail(username, id) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie das Einladungs-Mail-Token an: ' + username + ' erneut senden möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/resendPatientInvitationMail',
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail('E-Mail konnte nicht gesendet werden!');
        })
    });
}

//send password reset mail token to user with ID id
function sendResetPasswordMail(username, id) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie dem Benutzer: ' + username + ' ein Passwort-Reset-Token senden möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/sendResetPasswordMail',
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail('E-Mail konnte nicht gesendet werden!');
        })
    });
}

//generate a new password for user with ID id
function generateNewPassword(username, id) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie für: ' + username + ' ein zufälliges Passwort generieren möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/generateNewPassword',
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function (response) {
            alert('Das neue Passwort ist: ' + response.result);
            actionSuccess();
        }).fail(function () {
            actionFail('Es ist ein Fehler aufgetreten!');
        })
    });
}

//verify User with ID id
function verifyAccount(username, id, value) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie die Identifizierung für den Account: ' + username + ' auf ' + !value + ' ändern möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/verifyAccount',
            context: document.body,
            type: 'post',
            data: {'id': id, 'value': !value}
        }).done(function () {
            actionSuccess();
            loadUsers();
        }).fail(function () {
            actionFail('Verifizierung konnte nicht aktualisiert werden!');
        })
    });
}

//verifyProfession for User with ID id
function verifyProfession(username, id, value) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie die Ärztezulassung von: ' + username + ' auf ' + !value + ' ändern möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/verifyProfession',
            context: document.body,
            type: 'post',
            data: {'id': id, 'value': !value}
        }).done(function () {
            actionSuccess();
            loadUsers();
        }).fail(function () {
            actionFail('Ärztezulassung konnte nicht aktualisiert werden!');
        })
    });
}

function toggleSettingForAccount(username, accountId, setting, checkbox) {
    customConfirm('Bestätigen', 'Sind Sie sicher dass Sie für den User: ' + username + ' die Einstellung <strong>' + setting + '</strong> auf <strong>' + checkbox.prop('checked') + '</strong> setzen möchtest?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/toggleSettingForAccount',
            context: document.body,
            type: 'post',
            data: {'accountId': accountId, 'setting': setting}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail();
        })
    }, function () {
        checkbox.prop('checked', !checkbox.prop('checked'))
    });
}

//unblock ip
function unblockIP(ip) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie den IP von: ' + ip + ' aufheben möchten?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/unblockIP',
            context: document.body,
            type: 'post',
            data: {'ip': ip}
        }).done(function () {
            actionSuccess();
            loadUsers();

        }).fail(function () {
            actionFail('Konnte nicht zurückgesetzt werden!');
        })
    });
}

/**
 *
 * abstract requirements + concernTypes + genericQuestionnaireTypes + anamnesisQuestions
 *
 * */

/**
 * configuration of abstractRequirements for Admin (concernTypes + genericQuestionnaireTypes)
 * controller + javascript action is the same
 * */


function updatePHRRequirementPhrClassName(requirementId, phrClassName) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updatePHRRequirementPhrClassName',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'phrClassName': phrClassName}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updatePHRRequirementWithSelection(requirementId) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updatePHRRequirementWithSelection',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function updatePHRRequirementDisplay(requirementId) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updatePHRRequirementDisplay',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updatePHRRequirementPrintType(requirementId, printType) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updatePHRRequirementPrintType',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, printType: printType}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function toggleAbstractRequirementHiddenTitle(requirementId) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/toggleAbstractRequirementHiddenTitle',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function setFormat(questionId) {
    var val = $('#setFormat' + questionId + 'Id').val();
    $.ajax({
        url: '/' + appLocation + 'admin/setFormat',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, format: val}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function testRegex(test) {
    $.ajax({
        url: '/' + appLocation + 'admin/testPlaceholderRegex',
        context: document.body,
        type: 'post',
        data: {'toTest': test}
    }).done(function (data) {
        console.log(data);
    }).fail(function () {
        actionFail();
    })
}

function updateSimpleQuestionType(questionId, simpleQuestionType) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateSimpleQuestionType',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'simpleQuestionType': simpleQuestionType}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateComplexQuestionSimpleQuestionType(questionId, simpleQuestionType) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateComplexQuestionSimpleQuestionType',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'simpleQuestionType': simpleQuestionType}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateInformationRequirement(requirementId, html) {
    if (!html) {
        html = $('#informationRequirementHtml' + requirementId).val();
    }
    $.ajax({
        url: '/' + appLocation + 'admin/updateInformationRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'html': html}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('informationRequirementHtml' + requirementId);
    }).fail(function () {
        actionFail();
    })
}

function updateInformationRequirementJson(requirementId) {

    const json = $(`#informationRequirementJSONEditor${requirementId}TextArea`).attr("value");
    $.ajax({
        url: '/' + appLocation + 'admin/updateInformationRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'json': json}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('informationRequirementHtml' + requirementId);
    }).fail(function () {
        actionFail();
    })
}

function toggleReferenceRequirement(requirementId) {
    $.ajax({
        url: '/' + appLocation + 'admin/toggleReferenceRequirement',
        type: 'post',
        data: {'requirementId': requirementId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateRequirementAcroFieldName(requirementId) {
    var acroFieldName = $('#acroFieldName' + requirementId).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRequirementAcroFieldName',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'acroFieldName': acroFieldName}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('acroFieldName' + requirementId);
    }).fail(function () {
        actionFail();
    })
}

function toggleAbstractRequirementOtherAcroField(requirementId) {
    var doctorTypeId = $('#doctorTypesSelect').val();
    $.ajax({
        url: '/' + appLocation + 'admin/toggleAbstractRequirementOtherAcroField',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractRequirementTitle(button, requirementId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var title = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'title': title}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractRequirementTitle' + requirementId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractRequirementRow(button, requirementId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var row = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'row': row}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractRequirementRow' + requirementId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioQuestionAllowMultiselect(button, questionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioQuestionAllowMultiselect',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('radioQuestionAllowMultiselect' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioQuestionAsDropDown(button, questionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioQuestionAsDropDown',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('radioQuestionAsDropDown' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateComplexQuestionAllowMultiselect(button, questionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateComplexQuestionAllowMultiselect',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('complexQuestionAllowMultiselect' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateComplexQuestionAsDropDown(button, questionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateComplexQuestionAsDropDown',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('complexQuestionAsDropDown' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateQuestionMeta(form, questionId) {
    var data = $('#questionMeta' + questionId).serializeArray()
        .reduce(function (a, x) {
            a[x.name] = x.value;
            return a;
        }, {});
    data.defaultChecked = data.defaultChecked === 'on';
    data.id = questionId;
    $.ajax({
        url: '/' + appLocation + 'admin/updateQuestionMeta',
        context: document.body,
        type: 'post',
        data: data
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function updateRadioOptionValue(button, radioOptionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOption',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'value': value}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('radioOptionValue' + radioOptionId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioOptionAcroFieldName(button, radioOptionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOption',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'acroFieldName': value}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('radioOptionAcroFieldName' + radioOptionId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioOptionAbsolutPosition(radioOptionId) {
    var x = $('#radioOptionViewOptionsX' + radioOptionId).val();
    var y = $('#radioOptionViewOptionsY' + radioOptionId).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOptionAbsolutPosition',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'x': x, 'y': y}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('radioOptionValue' + radioOptionId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioOptionViewOptionsWidth(radioOptionId) {
    var radioOptionViewOptionsWidth = $('#radioOptionViewOptionsWidth' + radioOptionId).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOptionViewOptionsWidth',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'width': radioOptionViewOptionsWidth}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionQuestionText(questionId, questionText) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'questionText': questionText}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractQuestionQuestionText' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionPlaceholder(button, questionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var placeholder = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'placeholder': placeholder}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractQuestionPlaceholder' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionHelpBlock(button, questionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var helpBlock = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'helpBlock': helpBlock}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractQuestionHelpBlock' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionGenericFunctionName(button, questionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var genericFunctionName = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'genericFunctionName': genericFunctionName}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractQuestionGenericFunctionName' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionGenericFunctionParamQuestionIds(button, questionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var genericFunctionParamQuestionIds = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'genericFunctionParamQuestionIds': genericFunctionParamQuestionIds}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractQuestionGenericFunctionParamQuestionIds' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionStep(button, questionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var step = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'step': step}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('abstractQuestionLabels' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionLabels(button, questionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var labels = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'labels': labels}
    }).done(function () {
        actionSuccess();
        // unhighlightSavedValue('abstractQuestionStep' + questionId);
    }).fail(function () {
        actionFail();
    })
}

function deleteAnamnesisQuestion(anamnesisQuestionId) {
    customConfirm('Bestätigen', 'Sind Sie sicher dass Sie diese Frage - und damit auch alle Antworten auf diese Frage - löschen möchtest?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/deleteAnamnesisQuestion',
            context: document.body,
            type: 'post',
            data: {'anamnesisQuestionId': anamnesisQuestionId}
        }).done(function () {
            actionSuccess();
            loadAdmin('AnamnesisQuestions');
        }).fail(function () {
            actionFail();
        })
    });
}

/**
 * abstract requirements
 * controller action are the same - javascript reload is different (see context)
 * */

function getTextAndBlock(forContext, genericQuestionnaireTypeId) {
    var text = 'GenericQuestionnaireType';
    var parameters = '&genericQuestionnaireTypeId=' + (genericQuestionnaireTypeId ? genericQuestionnaireTypeId : '-1');
    var reloadContext = {
        text: text,
        block: 'GenericQuestionnaireTypeBlock',
        url: '/' + appLocation + 'admin/get' + text + '?questionnaireTypeClassName=GenericDocumentType' + parameters
    };
    if (forContext) {
        if (typeof forContext === 'string') {
            forContext = forContext.toLowerCase();
        }

        if (forContext == 'genericdocumenttype') {
            reloadContext = {
                text: text,
                block: 'GenericDocumentType',
                url: '/' + appLocation + 'admin/get' + text + '?questionnaireTypeClassName=GenericDocumentType' + parameters
            }

        } else if (forContext == 'concerntype') {
            reloadContext = {
                text: text,
                block: 'ConcernType',
                url: '/' + appLocation + 'admin/get' + text + '?questionnaireTypeClassName=ConcernType' + parameters
            }

        } else if (forContext == 'anamnesisquestionnairetype') {
            reloadContext = {
                text: text,
                block: 'AnamnesisQuestionnaireType',
                url: '/' + appLocation + 'admin/get' + text + '?questionnaireTypeClassName=AnamnesisQuestionnaireType' + parameters
            }
        }
    }

    return reloadContext;
}

function reloadAbstractRequirementAfterUpdateForContext(context, typeId, callback) {
    //the typeId is null if we are in anamnesis module
    if (typeId == null)
        loadAdmin('AnamnesisQuestions');
    else {
        var reloadContext = getTextAndBlock(context, typeId);
        loadByIdAdminIntoBlockWithURL(reloadContext.text, reloadContext.block, reloadContext.url, function () {
            if (typeId != -1) {
                $('#genericQuestionnaireTypeBody' + typeId).show();
                if (callback && typeof callback === 'function') {
                    callback();
                }
            }
        });
    }
}

function showAbstractRequirementAlertMessage(context, requirementId, typeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'alertMessage': 'Text eingeben'}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractRequirementAlertMessage(context, button, requirementId, typeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var alertMessage = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'alertMessage': alertMessage}
    }).done(function () {
        actionSuccess();
        if (alertMessage == '') {
            reloadAbstractRequirementAfterUpdateForContext(context, typeId);
        } else
            unhighlightSavedValue('abstractRequirementAlertMessage' + requirementId);
    }).fail(function () {
        actionFail();
    })
}

function addExistingAbstractQuestion(context, button, requirementId, radioOptionId, typeId, anamnesisQuestionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var abstractQuestionId = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/addExistingAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {
            'abstractQuestionId': abstractQuestionId,
            'requirementId': requirementId,
            'radioOptionId': radioOptionId,
            'anamnesisQuestionId': anamnesisQuestionId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function addNewAnamnesisQuestion(context, anamnesisQuestionId, requirementId, typeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addNewAnamnesisQuestion',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'anamnesisQuestionId': anamnesisQuestionId}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function normalizeFormData(formId) {
    var obj = {};
    $('#' + formId).find(':input, :button').each(function (key, item) {
        if (item.name) {
            if (item.type === 'checkbox' || item.type === 'radio') {
                if (item.checked) {
                    obj[item.name] = item.value;
                }
            } else {
                var value = parseInt(item.value);
                if (!value) {
                    value = item.value;
                }
                obj[item.name] = value;
            }
        }
    });
    return obj;
}

var ignore = ['click'];

function toSimpleObject(form) {
    var array = $(form).serializeArray();
    var data = {};

    for (var i = 0; i < array.length; i++) {
        data[array[i].name] = array[i].value;
    }
    return data;

}

function updateQuestionBorderViewOptions(event, context, type, abstractQuestionId) {
    var formData = normalizeFormData('borderForm' + abstractQuestionId);
}

function updateAbstractQuestionViewOptionBorders(event, form, type, abstractQuestionId) {

    var border = toSimpleObject(form);

    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestionViewOptionBorders/' + abstractQuestionId + '/' + type,
        context: document.body,
        type: 'post',
        data: border
    }).done(function () {
        actionSuccess();
        $('#viewOptionsForm' + abstractQuestionId).find(':input, :button').each(function (key, item) {
            if (item && item.id) {
                unhighlightSavedValue(item.id);
            }
        });
        var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
        //dont reload / user can use preview button if needed - reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, abstractQuestionId);
    }).fail(function () {
        actionFail();
    });

    return false; //prevent form submit

}

function updateQuestionViewOptions(event, context, type, abstractQuestionId) {
    //TODO: onfocuschange
    // if (event && ignore.indexOf(event.type) >= 0) {
    //     return false; //ignore click && focus events
    // }
    var formData = normalizeFormData('viewOptionsForm' + type + abstractQuestionId);


    var postData = {
        elementId: abstractQuestionId
    };
    $.extend(postData, formData);
    // now questionRow
    // postData.orderNum = $("#abstractQuestionOrderNum" + abstractQuestionId).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateViewOptions/' + type,
        context: document.body,
        type: 'post',
        data: postData
    }).done(function () {
        actionSuccess();
        $('#viewOptionsForm' + abstractQuestionId).find(':input, :button').each(function (key, item) {
            if (item && item.id) {
                unhighlightSavedValue(item.id);
            }
        });
        var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
        //dont reload / user can use preview button if needed - reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, abstractQuestionId);
    }).fail(function () {
        actionFail();
    });

    return false; //prevent form submit
}

function updateBackgroundImagePath(context, reqId, type, value) {
    var data = {
        type: type,
        value: value
    };
    $.ajax({
        url: '/' + appLocation + 'admin/updateBackgroundImage/' + reqId,
        context: document.body,
        type: 'post',
        data: data
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function updateDocumentTypeSizeAndMargins(context, documentTypeId) {
    var margins = normalizeFormData('inputMargins' + documentTypeId);
    margins.documentTypeId = documentTypeId;
    $.ajax({
        url: '/' + appLocation + 'admin/updateDocumentTypeSizeAndMargins',
        context: document.body,
        type: 'post',
        data: margins
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });

    return false;
}

function updateDocumentContentSettings(context, documentTypeId) {
    var margins = normalizeFormData('inputDocumentContentOptions' + documentTypeId);
    margins.documentTypeId = documentTypeId;
    $.ajax({
        url: '/' + appLocation + 'admin/updateDocumentContentSettings',
        context: document.body,
        type: 'post',
        data: margins
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });

    return false;
}

function toggleShowPageNumbers(context, documentTypeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/toggleShowPageNumbers',
        context: document.body,
        type: 'post',
        data: {'genericDocumentTypeId': documentTypeId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });

    return false;
}

function toggleShowFoldingMark(context, documentTypeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/toggleShowFoldingMark',
        context: document.body,
        type: 'post',
        data: {'genericDocumentTypeId': documentTypeId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });

    return false;
}

function registerDocumentTypeBackgroundFormButton(id) {
    var buttonSelector = '#submitDocumentBackgroundButton' + id;

    $('#documentBackgroundForm' + id).submit(function (e) {
        $('#error-msg-documentbackground' + id + '-form').hide();
        $(buttonSelector).prop('disabled', true);
        $(buttonSelector).prop('value', 'Bitte warten ...');

        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'documentbackground' + id);
            if (response.status == 'SUCCESS') {
                updateDocumentTypeBackgroundImage(response.result, id);
            } else if (response.status == 'RESET') {
                updateDocumentTypeBackgroundImage(response.result, id);
            }

            $(buttonSelector).prop('disabled', false);
            $(buttonSelector).prop('value', 'Aktualisieren');
            $('#error-msg-documentbackground' + id + '-form').show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'dsi' + id);
            $(buttonSelector).prop('disabled', false);
            $(buttonSelector).prop('value', 'Aktualisieren');
            $('#error-msg-documentbackground' + id + '-form').show('fast');
        });

        return false;
    });
}


function uploadRequirementBackground(ctx, id) {

    $('#requirementBackgroundForm' + id).submit(function (e) {
        $('#error-msg-requirementbackground' + id + '-form').hide();
        $(ctx).prop('disabled', true);
        $(ctx).prop('value', 'Bitte warten ...');

        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {

            showAjaxResponse(response, 'requirementbackground' + id);
            if (response.status == 'SUCCESS') {
                updateDocumentTypeBackgroundImage(response.result, id);
            } else if (response.status == 'RESET') {
                updateDocumentTypeBackgroundImage(response.result, id);
            }

            $(ctx).prop('disabled', false);
            $(ctx).prop('value', 'Aktualisieren');
            $('#error-msg-requirementbackground' + id + '-form').show('fast');

        }).fail(function (error) {
            showAjaxError(error, 'dsi' + id);
            $(ctx).prop('disabled', false);
            $(ctx).prop('value', 'Aktualisieren');
            $('#error-msg-requirementbackground' + id + '-form').show('fast');
        });

        return false;
    });
    return false;
}

function updateDocumentTypeBackgroundImage(url, id) {
    if (url == null) {
        $('#documentbackground-pic' + id).attr('src', '');
        $('#documentbackground-pic' + id).attr('alt', 'Kein Bild vorhanden');
    } else {
        $('#documentbackground-pic' + id).attr('src', '/' + appLocation + url);
        $('#documentbackground-pic' + id).attr('alt', '');
    }
}


function updateRequirementBackground(event, ctx, id) {
    var data = $(ctx).serialize();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRequirementBackground/' + id,
        context: document.body,
        type: 'post',
        data: data
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });
    event.preventDefault();
    return false;
}

function requirementViewGroupChange(context, requirementId) {
    var value = $(context).val();
    var enabled = $(context).is(':checked');

    $.ajax({
        url: '/' + appLocation + 'admin/changeRequirementViewGroup',
        context: document.body,
        type: 'post',
        data: {
            'requirementId': requirementId,
            'value': value,
            'enabled': enabled
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, requirementId);
    }).fail(function () {
        actionFail();
    })
}

function addNewSimpleQuestion(context, genericQuestionnaireTypeId, simpleQuestionType, requirementId, radioOptionId, typeId, anamnesisQuestionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addNewSimpleQuestion',
        context: document.body,
        type: 'post',
        data: {
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'requirementId': requirementId,
            'simpleQuestionType': simpleQuestionType,
            'radioOptionId': radioOptionId,
            'anamnesisQuestionId': anamnesisQuestionId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId,
            function () {
                try {
                    $('#collapseRequirement' + requirementId).slideToggle(400, function () {
                        $(this).get(0).scrollIntoView();
                    });
                } catch (e) {
                    //and it's gone
                }
            });
    }).fail(function () {
        actionFail();
    })
}


function addNewComplexQuestion(context, genericQuestionnaireTypeId, simpleQuestionType, requirementId, radioOptionId, typeId, anamnesisQuestionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addNewComplexQuestion',
        context: document.body,
        type: 'post',
        data: {
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'requirementId': requirementId,
            'simpleQuestionType': simpleQuestionType,
            'radioOptionId': radioOptionId,
            'anamnesisQuestionId': anamnesisQuestionId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function addNewRadioQuestion(context, genericQuestionnaireTypeId, requirementId, radioOptionId, typeId, anamnesisQuestionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addNewRadioQuestion',
        context: document.body,
        type: 'post',
        data: {
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'requirementId': requirementId,
            'radioOptionId': radioOptionId,
            'anamnesisQuestionId': anamnesisQuestionId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function addNewQuestionnaireLabel(context, genericQuestionnaireTypeId, requirementId, typeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addNewQuestionnaireLabel',
        context: document.body,
        type: 'post',
        data: {
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'requirementId': requirementId
        }
    }).done(function () {
        actionSuccess();

        reloadAbstractRequirementAfterUpdateForContext(context, typeId,
            function () {
                try {
                    $('#collapseRequirement' + requirementId).slideToggle(400, function () {
                        $(this).get(0).scrollIntoView();
                    });
                } catch (e) {
                    //and it's gone
                }
            });
    }).fail(function () {
        actionFail();
    })
}

function updateQuestionnaireLabel(context, labelText, abstractQuestionLabelId, questionnaireRequirementId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateQuestionnaireLabel',
        context: document.body,
        type: 'post',
        data: {
            'labelText': labelText,
            'abstractQuestionLabelId': abstractQuestionLabelId,
            'questionnaireRequirementId': questionnaireRequirementId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function deleteQuestionnaireLabel(context, abstractQuestionLabelId, questionnaireRequirementId) {
    $.ajax({
        url: '/' + appLocation + 'admin/deleteQuestionnaireLabel',
        context: document.body,
        type: 'post',
        data: {
            'abstractQuestionLabelId': abstractQuestionLabelId,
            'questionnaireRequirementId': questionnaireRequirementId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function addNewRadioOption(context, radioQuestionId, typeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addNewRadioOption',
        context: document.body,
        type: 'post',
        data: {'radioQuestionId': radioQuestionId}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function addNewRadioOptionToComplexQuestion(context, questionId, typeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addNewRadioOptionToComplexQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractRequirementAlertType(context, button, requirementId, typeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var alertType = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'alertType': alertType}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractRequirementOrderNum(context, button, requirementId, typeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var orderNum = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractRequirement',
        context: document.body,
        type: 'post',
        data: {'requirementId': requirementId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateQuestionnaireTabTitle(button, questionnaireTabId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var title = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateQuestionnaireTab',
        context: document.body,
        type: 'post',
        data: {'questionnaireTabId': questionnaireTabId, 'title': title}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('questionnaireTabTitle' + questionnaireTabId);
        $('#questionnaireTabId' + questionnaireTabId + '-title').text(title)
    }).fail(function () {
        actionFail();
    })
}


function updateQuestionnaireTabColumnCount(context, button, questionnaireTabId, genericDocumentTypeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var columnCount = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateQuestionnaireTab',
        context: document.body,
        type: 'post',
        data: {'questionnaireTabId': questionnaireTabId, 'columnCount': columnCount}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, genericDocumentTypeId);
    }).fail(function () {
        actionFail();
    })
}


function updateQuestionnaireTabOrderNum(context, button, questionnaireTabId, genericDocumentTypeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var orderNum = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateQuestionnaireTab',
        context: document.body,
        type: 'post',
        data: {'questionnaireTabId': questionnaireTabId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, genericDocumentTypeId);
    }).fail(function () {
        actionFail();
    })
}

function showRadioOptionAlertMessage(context, radioOptionId, typeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOption',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'alertMessage': 'Text eingeben'}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioOptionAlertMessage(context, button, radioOptionId, typeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var alertMessage = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOption',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'alertMessage': alertMessage}
    }).done(function () {
        actionSuccess();
        if (alertMessage == '') {
            reloadAbstractRequirementAfterUpdateForContext(context, typeId);
        } else
            unhighlightSavedValue('radioOptionAlertMessage' + radioOptionId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioOptionAlertType(context, button, radioOptionId, typeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var alertType = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOption',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'alertType': alertType}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function updateRadioOptionOrderNum(context, button, radioOptionId, typeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var orderNum = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateRadioOption',
        context: document.body,
        type: 'post',
        data: {'radioOptionId': radioOptionId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


function deleteRadioOption(context, radioOptionId, typeId) {
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/deleteRadioOption',
            context: document.body,
            type: 'post',
            data: {'radioOptionId': radioOptionId}
        }).done(function () {
            actionSuccess();
            reloadAbstractRequirementAfterUpdateForContext(context, typeId);
        }).fail(function () {
            actionFail();
        })
    });
}

function deleteAbstractQuestion(context, questionId, typeId) {
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/deleteAbstractQuestion',
            context: document.body,
            type: 'post',
            data: {'questionId': questionId}
        }).done(function () {
            actionSuccess();
            reloadAbstractRequirementAfterUpdateForContext(context, typeId);
        }).fail(function () {
            actionFail();
        })
    });
}

function duplicateAbstractQuestion(context, requirementId, questionId, typeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/duplicateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'requirementId': requirementId}
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(context, typeId);
    }).fail(function () {
        actionFail();
    })
}

function updateAbstractQuestionOrderNum(context, button, questionId, typeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var orderNum = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

/**
 * Configuration of GenericQuestionnaireTypes For Admin
 * */

//load genericQuestionnaireTypes in user administration subPage
function loadGenericQuestionnaireTypesIntoBlock(block, type) {
    var healthCareProviderId = $('#healthCareProviderSelect').val();
    if (!healthCareProviderId)
        healthCareProviderId = -1;
    var doctorTypeId = $('#doctorTypeSelect').val();
    if (!doctorTypeId)
        doctorTypeId = -1;

    showLoaderIn(block);
    $(block).load('/' + appLocation + 'admin/getGenericQuestionnaireTypes?questionnaireTypeClassName=' + type
        + '&healthCareProviderId=' + healthCareProviderId + '&doctorTypeId=' + doctorTypeId);

    $('.' + type + 'Block').html('');
}

//load genericQuestionnaireTypes in user administration subPage
function loadGenericQuestionnaireTypeIntoBlock(block, type) {
    var genericQuestionnaireTypeId = $('#questionnaireTypeSelect').val();
    if (!genericQuestionnaireTypeId)
        genericQuestionnaireTypeId = -1;

    showLoaderIn(block);
    $(block).load('/' + appLocation + 'admin/getGenericQuestionnaireType?questionnaireTypeClassName=' + type
        + '&genericQuestionnaireTypeId=' + genericQuestionnaireTypeId);
}

function createNewGenericQuestionnaireType(button, questionnaireTypeClassName) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var description = $(divInputGroup.find(':input')).val();
    var healthCareProviderId = $('#healthCareProviderSelect').val();
    var doctorTypeId = $('#doctorTypeSelect').val();

    $.ajax({
        url: '/' + appLocation + 'admin/createNewGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'healthCareProviderId': healthCareProviderId,
            'doctorTypeId': doctorTypeId,
            'description': description
        }
    }).done(function () {
        loadGenericQuestionnaireTypesIntoBlock('.' + questionnaireTypeClassName + 'sBlock', questionnaireTypeClassName);
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateGenericQuestionnaireTypeDescription(button, genericQuestionnaireTypeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var newDescription = $(divInputGroup.find(':input')).val();
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateGenericQuestionnaireTypeDescription',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'newDescription': newDescription
        }
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('genericQuestionnaireTypeDescription' + genericQuestionnaireTypeId);
    }).fail(function () {
        actionFail();
    })
}

function updateFontName(genericQuestionnaireTypeId, value) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateFontName',
        context: document.body,
        type: 'post',
        data: {'genericQuestionnaireTypeId': genericQuestionnaireTypeId, 'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateAnamnesisQuestionnaireTypeAvailableForPatients(anamnesisQuestionnaireTypeId, value) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateAnamnesisQuestionnaireTypeAvailableForPatients',
        context: document.body,
        type: 'post',
        data: {'anamnesisQuestionnaireTypeId': anamnesisQuestionnaireTypeId, 'value': value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateHealthCareProviderForGenericQuestionnaireType(genericQuestionnaireTypeId, newHealthCareProviderId) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateHealthCareProviderForGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'newHealthCareProviderId': newHealthCareProviderId
        }
    }).done(function () {
        actionSuccess();
        if (genericQuestionnaireTypeId == null)
            loadByIdAdmin('GenericQuestionnaireTypes', newHealthCareProviderId,
                function () {
                    $('#healthCareProviderSelect').val(newHealthCareProviderId);
                    $('#genericQuestionnaireTypeBody' + genericQuestionnaireTypeId).show();
                });
    }).fail(function () {
        actionFail();
    })
}


function updateDoctorTypeForGenericQuestionnaireType(genericQuestionnaireTypeId, newDoctorType) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateDoctorTypeForGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'newDoctorTypeId': newDoctorType
        }
    }).done(function () {
        actionSuccess();
        if (genericQuestionnaireTypeId == null)
            loadByIdAdmin('GenericQuestionnaireTypes', newDoctorType,
                function () {
                    $('#healthCareProviderSelect').val(newDoctorType);
                    $('#genericQuestionnaireTypeBody' + genericQuestionnaireTypeId).show();
                });

    }).fail(function () {
        actionFail();
    })
}


function toggleHideQuestionText(questionId, hideQuestionText) {
    $.ajax({
        url: '/' + appLocation + 'admin/toggleHideQuestionText',
        context: document.body,
        type: 'post',
        data: {
            'questionId': questionId,
            'hideQuestionText': hideQuestionText
        }
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function deleteAbstractRequirementFromGenericQuestionnaireType(requirementId, genericQuestionnaireTypeId, questionnaireTabId) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();

    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/deleteAbstractRequirementFromGenericQuestionnaireType',
            context: document.body,
            type: 'post',
            data: {
                'requirementId': requirementId,
                'questionnaireTypeClassName': questionnaireTypeClassName,
                'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
                'questionnaireTabId': questionnaireTabId,
            }
        }).done(function () {
            actionSuccess();
            reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, genericQuestionnaireTypeId);
        }).fail(function () {
            actionFail();
        })
    });
}

function deleteGenericQuestionnaireType(genericQuestionnaireTypeId) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/deleteGenericQuestionnaireType',
            context: document.body,
            type: 'post',
            data: {
                'questionnaireTypeClassName': questionnaireTypeClassName,
                'genericQuestionnaireTypeId': genericQuestionnaireTypeId
            }
        }).done(function () {
            actionSuccess();
            reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, genericQuestionnaireTypeId);
        }).fail(function () {
            actionFail();
        })
    });
}

function updateGenericQuestionnaireTypeOrderNum(button, genericQuestionnaireTypeId) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    var span = button.parent();
    var divInputGroup = span.parent();
    var orderNum = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateGenericQuestionnaireTypeOrderNum',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'orderNum': orderNum
        }
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function setSettingForGenericQuestionnaireType(genericQuestionnaireTypeId, setting, value) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();

    $.ajax({
        url: '/' + appLocation + 'admin/setSettingForGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'setting': setting,
            'value': value
        }
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
        } else {
            actionFail(response.result);
        }
    }).fail(function () {
        actionFail();
    })
}

function setSettingForGenericQuestionnaireTypeFromInputGroup(button, genericQuestionnaireTypeId, setting) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/setSettingForGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'setting': setting,
            'value': value
        }
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            unhighlightSavedValue('genericQuestionnaireTypeSetting-' + setting + '-' + genericQuestionnaireTypeId);
        } else {
            actionFail(response.result);
        }
    }).fail(function () {
        actionFail();
    });
}

function updateSelectedVitalTypeFor(evt, requirementId, vitalTypeId) {
    if (requirementId && vitalTypeId) {
        $.ajax({
            url: '/' + appLocation + 'admin/updateSelectedVitalTypeFor/' + requirementId + '/' + vitalTypeId,
            context: document.body,
            type: 'put',
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail();
        });
    }
}

function removeVitalTypePartListItem(evt) {
    $(evt).parents('.vitalTypePartDTOSLine').remove();
    vitalTypeLines = $('.vitalTypePartDTOSLine').length;
}

function removeVitalTypePart(evt, requirementId, vitalTypeId) {
    if (vitalTypeId) {
        $.ajax({
            url: '/' + appLocation + 'admin/deleteVitalTypePart/' + vitalTypeId,
            context: document.body,
            type: 'delete',
        }).done(function () {
            removeVitalTypePartListItem(evt);
            actionSuccess();
        }).fail(function () {
            actionFail();
        });
    } else {
        removeVitalTypePartListItem(evt);
    }
}

function saveVitalType(evt, requirementId, vitalTypeId) {
    var text = $('#vitalTypeNameId').val();
    if (text && text.trim().length <= 0) {
        $('#vitalTypeNameId').addClass('unsavedValue');
    } else {
        $('#vitalTypeNameId').removeClass('unsavedValue');
        var formEncoded = $('#newVitalTypeForm' + requirementId + vitalTypeId).serialize();
        $.ajax({
            url: '/' + appLocation + 'admin/addNewVitalType',
            context: document.body,
            type: 'post',
            data: formEncoded
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail();
        });
        return false;
    }
}

function saveVitalTypeFor(requirementId) {
    var formEncoded = $('#newVitalTypeForm' + requirementId).serialize();
    $.ajax({
        url: '/' + appLocation + 'admin/addVitalTypeToRequirement',
        context: document.body,
        type: 'post',
        data: formEncoded
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });
    return false;
}

var vitalTypeLines = 0;

function appendVitalTypesInput(requirementId, vitalType, id) {
    vitalTypeLines = $('.vitalTypePartDTOSLine').length + 1;
    var idPostFix = requirementId + vitalTypeLines;

    var newLineTemplate = document.getElementById('vitalTypePartTemplate');
    var newLine = $(newLineTemplate.content.cloneNode(true));

    newLine.children().each(function () {
        $(this).html($(this).html().replace(/xxx/g, '' + vitalTypeLines));
    });

    newLine.children().each(function () {
        $(this).html($(this).html().replace(/{{vitalType}}/g, '' + vitalType));
    });

    var vitalTypePartDeleteButton = newLine.children('#vitalTypePartDeleteButton');
    vitalTypePartDeleteButton.on('click', removeVitalTypePart.bind(vitalTypePartDeleteButton));

    $('#newVitalsTypeInputList' + requirementId + id).append(newLine);

}

function createNewRequirementForGenericQuestionnaireType(requirementType, genericQuestionnaireTypeId, questionnaireTabId) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    $.ajax({
        url: '/' + appLocation + 'admin/createNewRequirementForGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'questionnaireTabId': questionnaireTabId,
            'requirementType': requirementType
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, genericQuestionnaireTypeId);
    }).fail(function () {
        actionFail();
    })
}

var commonNames = ['Anamnese', 'Generisch', 'Zeug'];

function getTabTitle(questionnaireTabs) {
    var title = $('#newTabTitle').val();

    //no title set by user
    if (!title || title.trim() === '') {
        try {
            title = 'Tab-' + questionnaireTabs.length + 1;
        } catch (err) {
            title = 'Tab-' + Math.floor(Math.random() * 1000);
        }

        if (questionnaireTabs) {
            var uniqueArray = commonNames.filter(function (elem, pos, arr) {
                return questionnaireTabs.indexOf(elem) < 0;
            });

            if (uniqueArray && uniqueArray.length > 0) {
                title = uniqueArray[0]
            }
        }
    }
    return title;
}


function createNewTabForGenericQuestionnaireType(title, genericQuestionnaireTypeId, questionnaireTabs) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    var tabTitle = getTabTitle(questionnaireTabs);

    $.ajax({
        url: '/' + appLocation + 'admin/createNewTabForGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'title': tabTitle,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, genericQuestionnaireTypeId);
    }).fail(function () {
        actionFail();
    })
}

function deleteTabForGenericQuestionnaireType(genericQuestionnaireTypeId, questionnaireTabId, keepAbstractRequirements) {
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();
    $.ajax({
        url: '/' + appLocation + 'admin/deleteTabForGenericQuestionnaireType',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'questionnaireTabId': questionnaireTabId,
            'keepAbstractRequirements': keepAbstractRequirements
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, genericQuestionnaireTypeId);
    }).fail(function () {
        actionFail();
    })
}

function addAbstractRequirementToGenericQuestionnaireTypeById(button, genericQuestionnaireTypeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var abstractRequirementId = $(divInputGroup.find(':input')).val();
    var questionnaireTypeClassName = $('#questionnaireTypeClassName').val();

    $.ajax({
        url: '/' + appLocation + 'admin/addAbstractRequirementToGenericQuestionnaireTypeById',
        context: document.body,
        type: 'post',
        data: {
            'questionnaireTypeClassName': questionnaireTypeClassName,
            'genericQuestionnaireTypeId': genericQuestionnaireTypeId,
            'abstractRequirementId': abstractRequirementId
        }
    }).done(function () {
        actionSuccess();
        reloadAbstractRequirementAfterUpdateForContext(questionnaireTypeClassName, genericQuestionnaireTypeId);
    }).fail(function () {
        actionFail();
    })
}

function showGenericQuestionnaireTypePreview(questionnaireTypeClassName, genericQuestionnaireTypeId) {
    $('#ModalReplace').load('/' + appLocation + 'getGenericQuestionnaireTypePreview?questionnaireTypeClassName=' + questionnaireTypeClassName
        + '&genericQuestionnaireTypeId=' + genericQuestionnaireTypeId, function () {
        $('#genericQuestionnaireTypePreviewModal').modal({backdrop: 'static', keyboard: false});
    });
}

function importGenericQuestionnaireType(questionnaireTypeClassName) {
    var files = $('#importGenericQuestionnaireTypeFile')[0].files;
    var healthCareProviderId = $('#healthCareProviderSelect').val();
    var doctorTypeId = $('#doctorTypeSelect').val();

    if (files.length > 0) {
        $('#importGenericQuestionnaireTypeButton').prop('disabled', true);
        var data = new FormData();
        data.append('file', files[0]);
        if (doctorTypeId)
            data.append('doctorTypeId', doctorTypeId);
        if (healthCareProviderId)
            data.append('healthCareProviderId', healthCareProviderId);
        $('#importGenericQuestionnaireTypeFile').val('');
        $.ajax({
            url: '/' + appLocation + 'admin/genericQuestionnaireTypeImport/' + questionnaireTypeClassName,
            type: 'post',
            data: data,
            processData: false,
            contentType: false,
        }).done(function (response) {
            if (response.status == 'SUCCESS') {
                actionSuccess();
                loadGenericQuestionnaireTypesIntoBlock('.' + questionnaireTypeClassName + 'sBlock', questionnaireTypeClassName);
            } else {
                actionFail();
            }
        }).fail(function () {
            actionFail();
        });
    }
    return false;
}

/**
 * Anamnesis
 * */

function createNewAnamnesisQuestion(button) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var name = $(divInputGroup.find(':input')).val();

    $.ajax({
        url: '/' + appLocation + 'admin/createNewAnamnesisQuestion',
        context: document.body,
        type: 'post',
        data: {'name': name}
    }).done(function () {
        loadAdmin('AnamnesisQuestions');
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updateAnamnesisQuestionName(button, anamnesisQuestionId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var name = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updateAnamnesisQuestionName',
        context: document.body,
        type: 'post',
        data: {'anamnesisQuestionId': anamnesisQuestionId, 'name': name}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue('anamnesisQuestionName' + anamnesisQuestionId);
    }).fail(function () {
        actionFail();
    })
}

function updatePackageTypeOrderNum(button, packageTypeId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var orderNum = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updatePackageTypeOrderNum',
        context: document.body,
        type: 'post',
        data: {'packageTypeId': packageTypeId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function updatePackageTypeEntryOrderNum(button, packageTypeEntryId) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var orderNum = $(divInputGroup.find(':input')).val();
    $.ajax({
        url: '/' + appLocation + 'admin/updatePackageTypeEntryOrderNum',
        context: document.body,
        type: 'post',
        data: {'packageTypeEntryId': packageTypeEntryId, 'orderNum': orderNum}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}


/**
 * reimbursement and insurance
 * */


//save a reimbursementContract
function saveReimbursementContract(reimbursementContractId) {
    if (!reimbursementContractId) {
        reimbursementContractId = 0;
    }
    $('#ModalReplace').load('/' + appLocation + 'admin/getReimbursementContractModal/' + reimbursementContractId, function () {
        $('#reimbursementContractModal').modal({backdrop: 'static', keyboard: false});

        //submit new reimbursementContract in Modal
        $('#reimbursementContractForm').submit(function (e) {
            $('.error-msg').remove();
            $('#error-msg-reimbursementContract-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                showAjaxResponse(response, 'reimbursementContract');
                if (response.status == 'SUCCESS') {
                    $('#reimbursementContractModal').modal('hide');
                    location.reload();
                }
            }).fail(function (error) {
                showAjaxError(error, 'reimbursementContract');
            });
            $('#error-msg-reimbursementContract-form').show('fast');
            return false;
        });
    });
}

//save a reimbursement item
function saveReimbursementItem(reimbursementItemId) {
    if (!reimbursementItemId) {
        reimbursementItemId = 0;
    }
    $('#ModalReplace').load('/' + appLocation + 'admin/getReimbursementItemModal/' + reimbursementItemId, function () {
        $('#reimbursementItemModal').modal({backdrop: 'static', keyboard: false});

        //Submit new Doctor in Modal
        $('#reimbursementItemForm').submit(function (e) {
            $('.error-msg').remove();
            $('#error-msg-reimbursementItem-form').hide();

            $.ajax({
                url: $(this).attr('action'),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                showAjaxResponse(response, 'reimbursementItem');
                if (response.status == 'SUCCESS') {
                    $('#reimbursementItemModal').modal('hide');
                    actionSuccess();
                    /*loadAdmin("ReimbursementItems");*/

                }
            }).fail(function (error) {
                showAjaxError(error, 'reimbursementItem');
            });
            $('#error-msg-reimbursementItem-form').show('fast');
            return false;
        });
    });
}

//save a reimbursement item
function duplicateReimbursementItem(reimbursementItemId) {
    $.ajax({
        url: '/' + appLocation + 'admin/duplicateReimbursementItem',
        context: document.body,
        type: 'post',
        data: {'reimbursementItemId': reimbursementItemId}
    }).done(function () {
        actionSuccess();
        /*loadToMainContentDivAdmin("ReimbursementModule");*/
    }).fail(function () {
        actionFail();
    })
}

function registerReimbursementItemsCSVFormButton() {
    $('#reimbursementItemsCSVForm').submit(function (e) {
        $('#error-msg-documentHeader-form').hide();
        $('#submitReimbursementItemsCSVFormButton').prop('disabled', true);
        $('#submitReimbursementItemsCSVFormButton').prop('value', 'Bitte warten ...');

        // alert($(this).attr("action"));
        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'reimbursementItemsCSV');
            if (response.status == 'SUCCESS') {
                loadAdmin('ReimbursementItems');
            }

            $('#submitReimbursementItemsCSVFormButton').prop('disabled', false);
            $('#submitReimbursementItemsCSVFormButton').prop('value', 'Import');

            $('#error-msg-reimbursementItemsCSV-form').show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'reimbursementItemsCSV');
            $('#submitReimbursementItemsCSVFormButton').prop('disabled', false);
            $('#submitReimbursementItemsCSVFormButton').prop('value', 'Import');

            $('#error-msg-reimbursementItemsCSV-form').show('fast');
        });

        return false;
    });
}

/* CRUD dashboardType + widgetType */

/* default method to set a dashboardType setting */
function setDashboardTypeSetting(dashboardTypeId, setting, value, successCallback) {
    $.ajax({
        url: '/' + appLocation + 'admin/setDashboardTypeSetting',
        context: document.body,
        type: 'post',
        data: {'dashboardTypeId': dashboardTypeId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === 'function')
            successCallback();
    }).fail(function () {
        actionFail();
    })
}

function toggleDashboardTypeCheckbox(input, dashboardTypeId, setting) {
    var value = input.prop('checked');
    input.parent().parent().toggleClass('success error');

    setDashboardTypeSetting(dashboardTypeId, setting, value);
}


function addDashboardType() {
    $.ajax({
        url: '/' + appLocation + 'admin/addDashboardType',
        context: document.body,
        type: 'post'
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            router.navigate(appLocation + 'Admin/DashboardAdministration/dashboardType' + response.result, {trigger: true});
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}


function deleteDashboardType(dashboardTypeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/deleteDashboardType',
        context: document.body,
        type: 'post',
        data: {'dashboardTypeId': dashboardTypeId}
    }).done(function () {
        actionSuccess();
        router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
    }).fail(function () {
        actionFail();
    })
}

/* default method to set a widgetType setting */
function setWidgetTypeSetting(widgetTypeId, setting, value, successCallback) {
    $.ajax({
        url: '/' + appLocation + 'admin/setWidgetTypeSetting',
        context: document.body,
        type: 'post',
        data: {'widgetTypeId': widgetTypeId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === 'function')
            successCallback();
    }).fail(function () {
        actionFail();
    })
}

function toggleWidgetTypeCheckbox(input, widgetTypeId, setting) {
    var value = input.prop('checked');
    input.parent().parent().toggleClass('success error');

    setWidgetTypeSetting(widgetTypeId, setting, value);
}

/* default method to set a widgetType setting */
function addWidgetTypeToDashboardType(dashboardTypeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/addWidgetTypeToDashboardType',
        context: document.body,
        type: 'post',
        data: {'dashboardTypeId': dashboardTypeId}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            router.navigate(appLocation + 'Admin/DashboardAdministration/dashboardType' + dashboardTypeId + '/widgetType' + response.result, {trigger: true});
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function deleteWidgetType(widgetTypeId, dashboardTypeId) {
    router.navigate(appLocation + 'Admin/DashboardAdministration/dashboardType' + dashboardTypeId + '/widgetType' + widgetTypeId);
    $.ajax({
        url: '/' + appLocation + 'admin/deleteWidgetType',
        context: document.body,
        type: 'post',
        data: {'widgetTypeId': widgetTypeId}
    }).done(function () {
        actionSuccess();
        router.navigate(appLocation + 'Admin/DashboardAdministration/dashboardType' + dashboardTypeId, {trigger: true});
    }).fail(function () {
        actionFail();
    })
}

// load admin/get<text>/<id> in element with className <text>Block
function loadDashboardByIdAdmin(id) {
    loadInBlock('Dashboard',
        '.DashboardBlock',
        '/' + appLocation + 'admin/getDashboard/' + id);
}


function createNewDashboard(accountId, dashboardTypeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/createNewDashboard',
        context: document.body,
        type: 'post',
        data: {'accountId': accountId, 'dashboardTypeId': dashboardTypeId}
    }).done(function () {
        actionSuccess();
        router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
    }).fail(function () {
        actionFail();
    })
}

function createNewWidget(accountId, widgetTypeId) {
    $.ajax({
        url: '/' + appLocation + 'admin/createNewWidget',
        context: document.body,
        type: 'post',
        data: {'accountId': accountId, 'widgetTypeId': widgetTypeId}
    }).done(function () {
        actionSuccess();
        router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
    }).fail(function () {
        actionFail();
    })
}


function deleteDashboard(dashboardId) {
    $.ajax({
        url: '/' + appLocation + 'admin/deleteDashboard',
        context: document.body,
        type: 'post',
        data: {'dashboardId': dashboardId}
    }).done(function () {
        actionSuccess();
        router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
    }).fail(function () {
        actionFail();
    })
}

function adminDeleteWidget(widgetId) {
    $.ajax({
        url: '/' + appLocation + 'admin/deleteWidget',
        context: document.body,
        type: 'post',
        data: {'widgetId': widgetId}
    }).done(function () {
        actionSuccess();
        router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
    }).fail(function () {
        actionFail();
    })
}

function addDashboardToAllPatients(dashboardTypeId) {
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/addDashboardToAllPatients',
            context: document.body,
            type: 'post',
            data: {'dashboardTypeId': dashboardTypeId}
        }).done(function () {
            actionSuccess();
            router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
        }).fail(function () {
            actionFail();
        })
    });
}

function addDashboardToAllDoctors(dashboardTypeId) {
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/addDashboardToAllDoctors',
            context: document.body,
            type: 'post',
            data: {'dashboardTypeId': dashboardTypeId}
        }).done(function () {
            actionSuccess();
            router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
        }).fail(function () {
            actionFail();
        })
    });
}

function addDashboardToAllAssistants(dashboardTypeId) {
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/addDashboardToAllAssistants',
            context: document.body,
            type: 'post',
            data: {'dashboardTypeId': dashboardTypeId}
        }).done(function () {
            actionSuccess();
            router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
        }).fail(function () {
            actionFail();
        })
    });
}

function addWidgetToAllDashboardsWithWidgetTypesDashboardType(widgetTypeId) {
    customConfirm('Bestätigen', 'Sind Sie sicher?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/addWidgetToAllDashboardsWithWidgetTypesDashboardType',
            context: document.body,
            type: 'post',
            data: {'widgetTypeId': widgetTypeId}
        }).done(function () {
            actionSuccess();
            router.navigate(appLocation + 'Admin/DashboardAdministration', {trigger: true});
        }).fail(function () {
            actionFail();
        })
    });
}

function updateAllDrugTypesFromDiagnosiaAPI() {
    $('#updateAllDrugTypesFromDiagnosiaAPIButton').prop('disabled', true);
    $('#updateAllDrugTypesFromDiagnosiaAPIButton').html('Bitte warten ...');

    $.ajax({
        url: '/' + appLocation + 'admin/updateAllDrugTypesFromDiagnosiaAPI',
        context: document.body,
        type: 'post'
    }).done(function () {
        alert('Der Import war erfolgreich.');
        $('#updateAllDrugTypesFromDiagnosiaAPIButton').prop('disabled', false);
        $('#updateAllDrugTypesFromDiagnosiaAPIButton').html('Index automatisch über API aktualisieren');
    }).fail(function (data) {
        alert(data.responseText);
        $('#updateAllDrugTypesFromDiagnosiaAPIButton').prop('disabled', false);
        $('#updateAllDrugTypesFromDiagnosiaAPIButton').html('Index automatisch über API aktualisieren');

    })
}

function registerDrugTypesIndexDumpFormButton() {
    $('#drugTypesIndexDumpForm').submit(function (e) {
        $('#error-msg-drugTypesIndexDump-form').hide();
        $('#submitDrugTypesIndexDumpFormButton').prop('disabled', true);
        $('#submitDrugTypesIndexDumpFormButton').prop('value', 'Bitte warten ...');

        // alert($(this).attr("action"));
        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'drugTypesIndexDump');
            if (response.status == 'SUCCESS') {
                actionSuccess();
            } else {
                actionFail();
            }

            $('#submitDrugTypesIndexDumpFormButton').prop('disabled', false);
            $('#submitDrugTypesIndexDumpFormButton').prop('value', 'Import');

            $('#error-msg-drugTypesIndexDump-form').show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'drugTypesIndexDump');
            $('#submitDrugTypesIndexDumpFormButton').prop('disabled', false);
            $('#submitDrugTypesIndexDumpFormButton').prop('value', 'Import');

            $('#error-msg-drugTypesIndexDump-form').show('fast');
        });

        return false;
    });
}


function registerPharTxtFormButton() {
    $('#pharTxtForm').submit(function (e) {
        $('#error-msg-pharTxt-form').hide();
        $('#submitPharTxtFormButton').prop('disabled', true);
        $('#submitPharTxtFormButton').prop('value', 'Bitte warten ...');

        // alert($(this).attr("action"));
        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'pharTxt');
            if (response.status == 'SUCCESS') {
                actionSuccess();
            } else {
                actionFail();
            }

            $('#submitPharTxtFormButton').prop('disabled', false);
            $('#submitPharTxtFormButton').prop('value', 'Import');

            $('#error-msg-pharTxt-form').show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'pharTxt');
            $('#submitPharTxtFormButton').prop('disabled', false);
            $('#submitPharTxtFormButton').prop('value', 'Import');

            $('#error-msg-pharTxt-form').show('fast');
        });

        return false;
    });
}

function registerApoverlagDataFormButton() {
    $('#apoverlagDataForm').submit(function (e) {
        $('#error-msg-apoverlagData-form').hide();
        const submitApoverlagDataFormButton = $("#submitApoverlagDataFormButton");
        submitApoverlagDataFormButton.prop('disabled', true);
        submitApoverlagDataFormButton.prop('value', 'Bitte warten ...');

        // alert($(this).attr("action"));
        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'apoverlagData');
            if (response.status == 'SUCCESS') {
                actionSuccess();
            } else {
                actionFail();
            }

            submitApoverlagDataFormButton.prop('disabled', false);
            submitApoverlagDataFormButton.prop('value', 'Import');

            $('#error-msg-apoverlagData-form').show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'apoverlagData');
            submitApoverlagDataFormButton.prop('disabled', false);
            submitApoverlagDataFormButton.prop('value', 'Import');

            $('#error-msg-apoverlagData-form').show('fast');
        });

        return false;
    });
}


function registerASPListDataFormButton() {
    $('#aspDataForm').submit(function (e) {
        $('#error-msg-asp-list-form').hide();
        const submitASPListDataFormButton = $('#submitASPListDataFormButton');
        submitASPListDataFormButton.prop('disabled', true);
        submitASPListDataFormButton.prop('value', 'Bitte warten ...');

        // alert($(this).attr("action"));
        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr('action'),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'error-msg-asp-list-form');
            if (response.status == 'SUCCESS') {
                actionSuccess();
            } else {
                actionFail();
            }

            submitASPListDataFormButton.prop('disabled', false);
            submitASPListDataFormButton.prop('value', 'Import');

            $('#error-msg-asp-list-form').show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'error-msg-asp-list-form');
            submitASPListDataFormButton.prop('disabled', false);
            submitASPListDataFormButton.prop('value', 'Import');

            $('#error-msg-asp-list-form').show('fast');
        });

        return false;
    });
}

function registerRefreshPZNMappingFormButton() {
    $('#error-msg-asp-list-form').hide();
    const refreshPZNMappingFormButton = $('#refreshPZNMappingFormButton');
    refreshPZNMappingFormButton.on('click', () => {
        refreshPZNMappingFormButton.prop('disabled', true);
        refreshPZNMappingFormButton.prop('value', 'Bitte warten ...');
        $.ajax({
            url: '/app/admin/refreshPZNMapping',
            context: document.body,
            type: 'patch',
        }).done(function (response) {
            showAjaxResponse(response, 'error-msg-asp-list-form');
            if (response.status == 'SUCCESS') {
                actionSuccess();
            } else {
                actionFail();
            }

            refreshPZNMappingFormButton.prop('disabled', false);
            refreshPZNMappingFormButton.prop('value', 'Import');

            $('#error-msg-asp-list-form').show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'error-msg-asp-list-form');
            refreshPZNMappingFormButton.prop('disabled', false);
            refreshPZNMappingFormButton.prop('value', 'Import');

            $('#error-msg-asp-list-form').show('fast');
        });
    })
}


function createNewDoctorType(button) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var professionalGroup = $(divInputGroup.find('#newDoctorGroupProfessionalGroup')).val();
    var name = $(divInputGroup.find('#newDoctorGroupName')).val();
    var svCode = $(divInputGroup.find('#newDoctorGroupSVCode')).val();

    $.ajax({
        url: '/' + appLocation + 'admin/createNewDoctorType',
        context: document.body,
        type: 'post',
        data: {'professionalGroup': professionalGroup, 'name': name, 'svCode': svCode}
    }).done(function () {
        actionSuccess();
        location.reload();
    }).fail(function () {
        actionFail();
    })
}

function deleteDoctorType(doctorTypeId) {
    customConfirm('Bestätigen', 'Diesen DoctorType löschen?', function () {
        $.ajax({
            url: '/' + appLocation + 'admin/deleteDoctorType',
            context: document.body,
            type: 'post',
            data: {'doctorTypeId': doctorTypeId}
        }).done(function () {
            actionSuccess();
            location.reload();
        }).fail(function () {
            actionFail();
        })
    });
}


// approves the document with timestamp now and doctor logged in
function toggleInvoicePayed(invoiceId) {
    customConfirm('Zahlungsstatus ändern',
        'Sie ändern den Zahlungsstatus dieser Rechnung.\n\n Sind Sie sicher?',
        function () {

            $.ajax({
                url: '/' + appLocation + 'admin/toggleInvoicePayed',
                context: document.body,
                type: 'post',
                data: {'invoiceId': invoiceId}
            }).done(function () {
                actionSuccess();
                filterInvoices();
            }).fail(function () {
                actionFail('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.');
            })
        });
}


function processQueue() {
    $.ajax({
        url: '/' + appLocation + 'admin/mail/processQueue',
        type: 'get',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('Es ist ein Fehler aufgetreten!');
    })
}

function resetSendAttempts() {
    $.ajax({
        url: '/' + appLocation + 'admin/mail/resetMailSendAttempts',
        type: 'get',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('Es ist ein Fehler aufgetreten!');
    })
}

function toggleAdminDebugLog() {
    $.ajax({
        url: '/' + appLocation + 'admin/toggleAdminDebugLog',
        type: 'get',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('Es ist ein Fehler aufgetreten!');
    })
}
function toggleABSPolling() {
    $.ajax({
        url: '/' + appLocation + 'admin/toggleABSPolling',
        type: 'get',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('Es ist ein Fehler aufgetreten!');
    })
}

function changeBatchSize() {
    var value = parseInt($('#changeMailBatchSize').val());
    if (value > 0)
        $.ajax({
            url: '/' + appLocation + 'admin/mail/changeBatchSize',
            data: {batchSize: value},
            type: 'post',
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail('Es ist ein Fehler aufgetreten!');
        });
}

function garbageCollect() {
    $.ajax({
        url: '/' + appLocation + 'admin/garbage-collector',
        type: 'GET',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('Es ist ein Fehler aufgetreten!');
    });
}


function toggleDisabledMail() {
    $.ajax({
        url: '/' + appLocation + 'admin/mail/toggleDisabledMail',
        type: 'post',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('Es ist ein Fehler aufgetreten!');
    });
}


function filterInvoices() {
    showLoaderIn('.InvoicesBlock');
    $('.InvoicesBlock').load('/' + appLocation + 'admin/getFilteredInvoices', $('#invoiceFilterForm').serialize());
}


function findAllAnswersForQuestion(questionnaireTypeClassName, genericQuestionnaireTypeId, patientId, abstractQuestionId) {
    var parameters = {};
    parameters.questionnaireTypeClassName = questionnaireTypeClassName;
    parameters.genericQuestionnaireTypeId = genericQuestionnaireTypeId;
    parameters.patientId = patientId;
    parameters.abstractQuestionId = abstractQuestionId;
    var parametersString = '?' + $.param(parameters);

    var divId = '#abstractQuestionHistory' + abstractQuestionId;
    showLoaderIn(divId);
    $(divId).load('/' + appLocation + 'findAllAnswersForQuestion' + parametersString);

}


// mail admin tools

//resend email token to user with ID id
function resendPatientInvitationMail(username, id) {
    customConfirm('Bestätigen', 'Sind Sie sicher, dass Sie das Einladungs-Mail-Token an: ' + username + ' erneut senden möchten?', function () {
        $.ajax({
            url: '/' + appLocation + '/admin/mail/resetMaxTries',
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
        }).fail(function () {
            actionFail('E-Mail konnte nicht gesendet werden!');
        })
    });
}

function downloadXSD() {
    $.ajax({
        headers: {
            'Authorization': "AD4E78168ED838672426534EE13F3"
        },
        url: '/' + appLocation + 'austrian/xsd-schema/v1',
        type: 'get',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('E-Mail konnte nicht gesendet werden!');
    })
}

function downloadXMLTestData() {
    $.ajax({
        headers: {
            'Authorization': "AD4E78168ED838672426534EE13F3"
        },
        url: '/' + appLocation + 'austrian/testdata',
        type: 'get',
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('E-Mail konnte nicht gesendet werden!');
    });
}


function postImportData() {
    let fuckJSForm = document.getElementById("importAustrianPatients");
    $.ajax({
        headers: {
            'Authorization': "AD4E78168ED838672426534EE13F3"
        },
        data: {},//new FormData(fuckJSForm),
        url: $(fuckJSForm).attr("action"),
        type: "POST",
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail('E-Mail konnte nicht gesendet werden!');
    });
    return false;
}

function deletePatient(userId) {
    if (confirm("Sind Sie sicher, dass der User unwiderruflich gelöscht werden soll?")) {
        showLoaderIn('#UserAdministrationList');
        $.get('/' + appLocation + 'admin/delete-patient/' + userId).done(function () {
            actionSuccess();
            loadUsers();
        }).fail(function () {
            actionFail();
        });
    }
}

function executeDataExportAction(providerId, action) {
    $.ajax({
        url: "/" + appLocation + "admin/executeExportAction",
        context: document.body,
        type: "post",
        data: {providerId, action}
    }).done(function () {
        actionSuccess();
        location.reload();
    }).fail(function (e) {
        actionFail();
    });
}

// -- Question Meaning -- \\
function loadQuestionMeaningAdministration(page = 0) {
    $("#questionMeaningAdministration").load("/" + appLocation + "questionMeaning/administation/" + page)
}

function deleteQuestionMeaning(id, page) {
    customConfirm("Wirklich löschen?", "Diese Aktion kann nicht rückgängig gemacht werden, und löscht diese Bedeutung von allen Fragen und Widgets.", function () {
        $.ajax({
            url: "/" + appLocation + "questionMeaning/delete/" + id,
            context: document.body,
            type: "post"
        }).done(function (response) {
            loadQuestionMeaningAdministration(page || 0);
        })
    });
}

function appendEntryToChartScale(id, text, scale) {
    $("#chartScale" + scale + type).append(`
        <div class="input-group">
            <span class="form-control">${text}</span>
            <span class="input-group-addon" onclick="removeEntryFromChartScale(this, ${scale}, ${id})">
                <span class="glyphicon glyphicon-trash"></span>
            </span>
        </div>
    `);
}

function manageQuestionMeaning(id, page = null) {
    $.ajax("/" + appLocation + `questionMeaning/manage/${id || ''}`)
        .done((response) => {
            let box = bootbox.dialog({
                message: response,
                title: "Bedeutung Bearbeiten"
            })
            box.bind('shown.bs.modal', function () {

                const bloodhound = new Bloodhound({
                    datumTokenizer: function (entry) {
                        return Bloodhound.tokenizers.whitespace(entry.name);
                    },
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    limit: 100,
                    identify: function (entry) {
                        return entry.id
                    },
                    valueKey: 'name',
                    prefetch: {
                        url: "/" + appLocation + 'questionMeaning/getAvailableQuestions',
                        filter: function (list) {
                            return $.map(list, function (entry) {
                                return {id: entry.first, name: entry.second};
                            });
                        },
                        ttl: 10000
                    }
                });

                // kicks off the loading/processing of `local` and `prefetch`
                bloodhound.initialize();

                $('#addQuestionToMeaning').typeahead({
                    hint: true,
                    highlight: true,
                    minLength: 0
                }, {
                    name: 'availableQuestions',
                    display: 'name',
                    source: bloodhound.ttAdapter()
                }).on('typeahead:selected', function (e, datum) {
                    e.preventDefault();
                    $("#questionMeaningQuestions").append(`
                        <div class="input-group">
                            <span class="form-control">${datum.name}</span>
                            <input type="hidden" name="questions" value="${datum.id}">
                            <span class="input-group-addon" onclick="this.parentNode.remove()">
                                <span class="glyphicon glyphicon-trash"></span>
                            </span>
                        </div>
                    `);
                    e.target.value = "";
                    return false;
                });

                $("#questionMeaningSettings").submit(function (e) {
                    e.preventDefault();
                    $.ajax({
                        url: $(this).attr('action'),
                        context: document.body,
                        type: 'post',
                        data: $(this).serialize()
                    }).done(function (response) {
                        actionSuccess();
                        box.remove(); // remove so it does not stay in dom and break the next invocation
                        loadQuestionMeaningAdministration(page == null ? -1 : page); // load the current or the last page
                    }).fail(function (error) {
                        actionFail();
                    });
                });
                return false;
            })
        }).fail((error) => {
        actionFail(error)
    })
}

function setQuestionMeaning(questionId, meaningId = null) {
    $.ajax({
        url: "/" + appLocation + "questionMeaning/setMeaning",
        context: document.body,
        type: 'post',
        data: meaningId ? {questionId, meaningId} : {questionId}
    }).done(function (response) {
        actionSuccess()
    }).fail(function (error) {
        actionFail(error)
    })
}

function updateAbstractQuestionAnswerHistoryButton(input, questionId) {
    $.ajax({
        url: '/' + appLocation + 'admin/updateAbstractQuestion',
        context: document.body,
        type: 'post',
        data: {'questionId': questionId, 'historyButtonLocation': input.value}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    })
}

function clearQuestionMeaning(button) {
    const input = $(button).parent().siblings("input");
    setQuestionMeaning(input.attr("data-id"))
    input.val("")
}

function initializeQuestionMeaningTypeahead(input) {
    input = $(input)
    input.attr("onclick", null)

    const bloodhound = new Bloodhound({
        datumTokenizer: function (entry) {
            return Bloodhound.tokenizers.whitespace(entry.name);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 100,
        identify: function (entry) {
            return entry.id
        },
        valueKey: 'name',
        prefetch: {
            url: "/" + appLocation + 'questionMeaning/getAvailableMeanings',
            filter: function (list) {
                return $.map(list, function (entry) {
                    return {id: entry.first, name: entry.second};
                });
            },
            ttl: 10000
        }
    });

    // kicks off the loading/processing of `local` and `prefetch`
    bloodhound.initialize();

    input.typeahead({
        hint: true,
        highlight: true,
        minLength: 0
    }, {
        name: 'availableMeanings',
        display: 'name',
        source: bloodhound.ttAdapter()
    }).on('typeahead:selected', function (e, datum) {
        setQuestionMeaning(input.attr("data-id"), datum.id)
    });
}

function initializeQuestionHistoryTypeahead(input, id) {
    input = $(input);
    input.attr("onclick", null);

    const bloodhound = new Bloodhound({
        datumTokenizer: function (entry) {
            return Bloodhound.tokenizers.whitespace(entry.name);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace, limit: 100, valueKey: 'name',
        identify: function (entry) {
            return entry.id
        },
        prefetch: {
            url: "/" + appLocation + "chart/scales/getAvailableMeanings",
            filter: function (list) {
                return $.map(list, function (entry) {
                    return {id: entry.first, name: entry.second};
                });
            },
            ttl: 100000
        }
    });
    bloodhound.initialize();
    input.typeahead({hint: true, highlight: true, minLength: 0}, {
        name: "meaning", display: 'name', source: bloodhound.ttAdapter()
    }).on('typeahead:selected', function (e, datum) {
        $.ajax({
            url: "/" + appLocation + "chart/scales/addMeaning",
            type: "post",
            context: document.body,
            data: {scaleId: id, id: datum.id}
        }).done(function (res) {
            actionSuccess();
            appendEntryToChartScale(datum.id, datum.name, id);
        }).fail(function (err) {
            actionFail()
        })
    });
}

function removeEntryFromChartScale(input, scale, entry) {
    $.ajax({
        url: "/" + appLocation + "chart/scales/removeMeaning",
        type: "post",
        context: document.body,
        data: {scaleId: scale, id: entry}
    }).done(function (res) {
        actionSuccess();
        input.parentNode.remove();
    }).fail(function (err) {
        actionFail();
    })
}

function createFee(accountId) {
    $.ajax({
        url: "/" + appLocation + "fee",
        type: "post", context: document.body,
        data: {accountId}
    }).done(() => {
        adminShowAccountAdministration(accountId, 'accountSettingsFeeNew');
    }).fail(() => actionFail())
}

function deleteFee(id, accountId) {
    customConfirm("Wirklich löschen?", "Diese Aktion kann nicht rückgängig gemacht werden", function () {
        $.ajax({
            url: "/" + appLocation + "fee/" + id,
            type: "delete"
        }).done(() => {
            adminShowAccountAdministration(accountId, 'accountSettingsFeeNew');
        }).fail(() => actionFail())
    });
}

function setFeeSetting(feeId, setting, value, accountId) {
    $.ajax({
        url: "/" + appLocation + "fee/setSetting",
        type: "patch", context: document.body,
        data: {feeId, setting, value}
    })
        .done(() => {
            actionSuccess();
            if (setting === 'oneTimeFee' || setting === 'price')
                adminShowAccountAdministration(accountId, 'accountSettingsFeeNew');
        }).fail(() => actionFail())
}

function resetDeviceIntegrationScript() {
    customConfirm("Sicher?", "Dies löscht die aktuelle Version des Scriptes und setzt sie auf die letzte release-version zurück.", function() {
        $.ajax({
            url: "/" + appLocation + "admin/deviceIntegration/reset",
            type: "post", context: document.body
        }).done(function() {
            setTimeout(function () { location.reload(); }, 500)
        })
    });
}

function uploadDeviceIntegrationScript() {
    $.ajax({
        url: "/" + appLocation + "admin/deviceIntegration/uploadModal",
        type: "get", context: document.body
    }).done(function (content) {
        let box = bootbox.dialog({
            title: "Script(e) Hochladen",
            message: content,
            buttons: {
                confirm: {
                    label: "Hochladen & Überschreiben",
                    className: "btn-danger",

                    callback: function () {
                        this.find(".btn-danger").attr("disabled", true);
                        this.find(".btn-danger").html("Bitte warten .....");

                        const data = new FormData(document.forms.deviceIntegrationUploadForm);
                        $.ajax({
                            url: "/" + appLocation + "admin/deviceIntegration/upload",
                            type: "post", context: document.body, data,
                            processData: false,
                            contentType: false
                        }).done(function () {
                            loadToMainContentDiv("admin/deviceIntegration")
                            actionSuccess()
                        });
                        setTimeout(function () {
                            loadDeviceIntegrationTable(0)
                        }, 500)
                    },
                },
            },
        });
    })
}

function createDeviceIntegration() {
    const box = bootbox.dialog({
        title: "Integration Erstellen",
        message: "<label>Wen soll diese Integration representieren?</label>" +
            "<input class='mt-2 form-control' id='deviceIntegrationCreateHCP' placeholder='Suche...'>" +
            "<input type='hidden' id='deviceIntegrationCreateHCP-value'/>" +
            "<div class='mt-2 input-group'><span class='input-group-addon'>Wieviele?</span><input class='form-control' type='number' id='deviceIntegrationCreateCount' value='1'/></div>",
        buttons: {
            cancel: {
                label: "Abbrechen",
                className: "btn-danger"
            },
            confirm: {
                label: "Erstellen",
                className: "btn-success",

                callback: function () {
                    this.find(".btn-success").attr("disabled", true);
                    this.find(".btn-success").html("Bitte warten .....");
                    $.ajax({
                        url: `/${appLocation}admin/deviceIntegration/create`,
                        context: document.body, method: "POST", data: {
                            id: $('#deviceIntegrationCreateHCP-value').val(),
                            count: $('#deviceIntegrationCreateCount').val()
                        }
                    }).done(function () {
                        actionSuccess()
                        loadDeviceIntegrationTable()
                    }).fail(function (err) {
                        actionFail(err)
                    });
                },
            },
        }
    });
    box.bind('shown.bs.modal', function() {
        var accounts = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            limit: 100,

            prefetch: {
                url: `/${appLocation}admin/deviceIntegration/getAccounts`,
                filter: function (list) {
                    return $.map(list, function (name, id) {
                        return {name, id};
                    });
                },
                ttl: 10000
            }
        });

        // kicks off the loading/processing of `local` and `prefetch`
        accounts.initialize();

        $('#deviceIntegrationCreateHCP').typeahead({
            hint: true,
            highlight: true,
            minLength: 1
        }, {
            name: 'accounts',
            display: 'name',
            source: accounts.ttAdapter()
        }).on('typeahead:selected', function (event, data) {
            $('#deviceIntegrationCreateHCP-value').val(data.id)
        });
    });
}

function loadDeviceIntegrationTable(offset = null) {
    showLoaderIn("#deviceIntegrationAdministrationTable");
    const query = $('#deviceIntegrationAdministrationSearch').val() || '';
    // whether to only show disconnected configurations
    const page = $('#deviceIntegrationAdministrationPage');
    if (offset == 0) page.val(0)
    else if (offset) page.val((Number(page.val()) || 0) + offset);

    $('#deviceIntegrationAdministrationTable')
        .load('/' + appLocation + `admin/deviceIntegration/table?query=${query}&page=${page.val() || 0}`)
}

function doDeviceIntegrationAction(id, action, confirm = false) {
    if (!confirm && (action === "delete" || action === "regenerate")) {
        customConfirm(
            "Sind sie sicher?",
            `Das ${action === "delete" ? "Löschen" : "Neugenerieren des Tokens"} kann nicht rückgängig gemacht werden!\n` +
            "Damit wird die Integration unbrauchbar und muss beim Kunden neu konfiguriert werden.",
            function () { doDeviceIntegrationAction(id, action, true) },
            null
        )
        return
    }

    $.ajax({
        url: "/" + appLocation + "admin/deviceIntegration/action",
        context: document.body, method: 'POST', data: { id, action }
    }).done(function () {
        actionSuccess();
        loadDeviceIntegrationTable();
    }).fail(function (message) {
        actionFail(message)
    })
}

function saveDeviceIntegration(form) {
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    $.ajax({
        url: "/" + appLocation + "admin/deviceIntegration/save",
        context: document.body, method: 'POST', data
    }).done(function () {
        actionSuccess();
        editDeviceIntegration(null, true);
        loadDeviceIntegrationTable();
    }).fail(function (message) {
        actionFail(message)
    })
}

function editDeviceIntegration(id = null, force = false) {
    const config = $('#deviceIntegrationAdministrationConfig')
    if (!force && config.html()) {
        customConfirm(
            "Sicher?", "Ungespeicherte Änderungen gehen verloren!",
            function() { editDeviceIntegration(id, true); }
        )
    }
    else if (id) config.load('/' + appLocation + 'admin/deviceIntegration/edit/' + id, function() {
        config[0].scrollIntoView(true)
    });
    else config.html("");
}
