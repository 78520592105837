function newAbstractTextSuggestion(type){
    var value = $('#new'+type).val();
    saveAbstractTextSuggestion(type,null,value);
}

function updateAbstractTextSuggestion(type,id){
    var value = $('#entryId'+id).val();
    saveAbstractTextSuggestion(type,id,value);
}

function deleteAbstractTextSuggestion(type,id){
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?",function() {
        scrollTop = $('#main').scrollTop();
        $.ajax({
            url: "/" + appLocation + "delete" + type,
            context: document.body,
            type: 'post',
            data: {'id': id}
        }).done(function () {
            actionSuccess();
            load('AutoSuggestionValues',function(){$("#main").scrollTop(scrollTop);});
        }).fail(function () {
            actionFail();
        })
    });
}

function saveAbstractTextSuggestion(type,id,value) {
    scrollTop = $('#main').scrollTop();
    $.ajax({
        url: "/" + appLocation + "save" + type,
        context: document.body,
        type: 'post',
        data: {'id': id,'value':value}
    }).done(function () {
        actionSuccess();
        load('AutoSuggestionValues',function(){$("#main").scrollTop(scrollTop);});

    }).fail(function () {
        actionFail();
    })
}