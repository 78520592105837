function drawGoogleMap(targetDivId, address, infotext) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': address}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            var position = results[0].geometry.location;
            var mapOptions = {
                zoom: 15,
                center: position,
                scrollwheel: false
            };
            var map = new google.maps.Map(document.getElementById(targetDivId), mapOptions);
            var marker = new google.maps.Marker({
                map: map,
                position: position
            });
            var infowindow = new google.maps.InfoWindow({
                content: infotext
            });
            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
            infowindow.open(map, marker);
        } else {
            console.log('Geocode was not successful for the following reason: ' + status);
        }
    });
}

function parseOpeningHours(openingHours, flexible) {
    var show = false;
    if (flexible)
        show = true;
    var id = 1;
    var items = new vis.DataSet();

    if (openingHours != null) {

        for (var day in openingHours) {
            if (!openingHours.hasOwnProperty(day))
                continue;

            show = true;
            var daytime = openingHours[day];

            var openFromSplit = daytime.openFrom.split(":");
            var openFromHour = openFromSplit[0];
            var openFromMinute = openFromSplit[1];
            var openTillSplit = daytime.openTill.split(":");
            var openTillHour = openTillSplit[0];
            var openTillMinute = openTillSplit[1];

            var start = new Date();
            start.setHours(Number(openFromHour));
            start.setMinutes(Number(openFromMinute));
            start.setSeconds(0);
            start.setMilliseconds(0);
            var end = new Date();
            end.setHours(Number(openTillHour));
            end.setMinutes(Number(openFromMinute));
            end.setSeconds(0);
            end.setMilliseconds(0);

            // console.log('daytime.day:', daytime.day);

            items.add({
                    group: daytime.day,
                    id: id,
                    content: openFromHour + ':' + openFromMinute + ' - ' + openTillHour + ':' + openTillMinute,
                    start: start,
                    end: end,
                    style: "background-color: #5cb85c; color: #444444; border: 1px solid white; box-shadow:3px 3px 3px 0px #BBB;"
                }
            );
            id++;
        }
    }

    return {items: items, show: show};
}

/* show opening hours with vis.js timeline */
function drawOpeningHours(items, targetDivId) {
    $('#' + targetDivId).html('');
    var container = document.getElementById(targetDivId);

    var groups = [
        {
            id: 'MONDAY',
            content: 'Montag',
            style: "text-align: right;"
        },
        {
            id: 'TUESDAY',
            content: 'Dienstag',
            style: "text-align: right;"
        },
        {
            id: 'WEDNESDAY',
            content: 'Mittwoch',
            style: "text-align: right;"
        },
        {
            id: 'THURSDAY',
            content: 'Donnerstag',
            style: "text-align: right;"
        },
        {
            id: 'FRIDAY',
            content: 'Freitag',
            style: "text-align: right;"
        },
        {
            id: 'SATURDAY',
            content: 'Samstag',
            style: "text-align: right;"
        },
        {
            id: 'SUNDAY',
            content: 'Sonntag',
            style: "text-align: right;"
        }
    ];

    var start = new Date();
    start.setHours(7);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    var end = new Date();
    end.setHours(21);
    end.setMinutes(0);
    end.setSeconds(0);
    end.setMilliseconds(0);

    // Configuration for the Timeline
    var options = {
        autoResize: false,
        start: start,
        end: end,
        moveable: false,
        zoomable: false,
        orientation: 'both',
        showMajorLabels: false,
        timeAxis: {
            scale: 'hour',
            step: 2
        },
        format: {
            minorLabels: {
                hour: 'HH [h]'
            }
        }
    };

    // Create a Timeline
    var timeline = new vis.Timeline(container, items, groups, options);

    //Show current time
    timeline.addCustomTime(new Date());
}

function drawOpeningHoursForMobileDevices(data, targetDivId) {
    //retrieve formatted items
    var items = data.get({
        fields: ['group', 'content'], // output the specified fields only
        type: {
            group: 'String', // convert the group fields to Strings
            content: 'String'
        }
    });

    $('#' + targetDivId).html('');

    function translateDay(day){
        switch(day) {
            case "MONDAY": return "Montag";
            case "TUESDAY": return "Dienstag";
            case "WEDNESDAY": return "Mittwoch";
            case "THURSDAY": return "Donnerstag";
            case "FRIDAY": return "Freitag";
            case "SATURDAY": return "Samstag";
            case "SUNDAY": return "Sonntag";
            default: return "unexpected day";
        }
    }

    items.forEach(function (item) {
        $('#' + targetDivId).append('<span class="col-xs-6">' + translateDay(item.group) + ':</span>' +
        '<span class="col-xs-6">' + item.content + '</span><br/>');
    });
}
