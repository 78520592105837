function loadAnamnesisQuestionnaire(typeId, healthCareProviderId, patientId, anamnesisQuestionnaireId) {
    if (!anamnesisQuestionnaireId)
        anamnesisQuestionnaireId = -1;

    $('#newAnamnesisQuestionnaireDiv').show();
    showLoaderIn('#anamnesisQuestionnaireFormReplace');
    $('#anamnesisQuestionnaireFormReplace').load("/" + appLocation + "loadAnamnesisQuestionnaire/" + typeId + "/" + healthCareProviderId + "/" + patientId + "/" + anamnesisQuestionnaireId, function () {
        //scroll into view
        window.location.hash = '#newAnamnesisQuestionnaireDiv';
        //autofocus first input element
        focusOnFirstInputInForm('anamnesisQuestionnaireForm');
    });
}

//reindex arrays - avoid gaps
function prepareAnamnesisQuestionnairForm(formId, update, currenUserIsPatient) {
    //reindex question arrays (gaps due to unique id generation method for dynamic loaded questions)
    $('#' + formId + " input[id^=questionIds]").each(function (index) {
        var elementId = $(this).attr('id');
        //the initial starting point of the substring is based on "questionIds["
        var tmpIndex = elementId.substring(12, elementId.length - 1);

        $(this).attr('name', 'questionIds[' + index + ']');

        $("#questionAnswerValues\\[" + tmpIndex + "\\]").attr('name', 'questionAnswerValues[' + index + ']');
    });

    //reindex anamnesis arrays (gaps due to unique id generation method for dynamic loaded questions)
    $('#' + formId + " input[id^=anamnesisQuestionIds]").each(function (index) {
        var elementId = $(this).attr('id');
        //the initial starting point of the substring is based on "questionIds["
        var tmpIndex = elementId.substring(21, elementId.length - 1);

        $(this).attr('name', 'anamnesisQuestionIds[' + index + ']');

        $("#anamnesisQuestionAnswerValues\\[" + tmpIndex + "\\]").attr('name', 'anamnesisQuestionAnswerValues[' + index + ']');
    });

    $('#' + formId + " table").each(function (index, table) {
        // $(table).DataTable().search('').draw(false);
        // $(table).DataTable().rows('.selected').data().toArray().map(function (entry, index) {
        //     table.$('input[type="checkbox"]').each(function () {
        //         if (!$.contains(document, this)) {
        //             if (this.checked) {
        //                 form.append(
        //                     $('<input>')
        //                         .attr('type', 'hidden')
        //                         .attr('name', this.name)
        //                         .val(this.value)
        //                 );
        //             }
        //         }
        //     });
        // });
    })

}

function serializeFormDataAsJSON(formId) {
    var data = [];
    $('#' + formId + " table").each(function (index, table) {
        data.push($(table).DataTable().$('input, select').serializeArray());
    });
    return data;
}

function serializeGenericQuestionnaireFormDataFor(formId, update, currenUserIsPatient) {
    prepareAnamnesisQuestionnairForm(formId, update, currenUserIsPatient);
    var formData = $('#' + formId).serialize();
    // formData += "&" + serializeFormData(formId);
    return formData;
}

function serializeGenericQuestionnaireFormDataForAsJson(formId, update, currenUserIsPatient) {
    prepareAnamnesisQuestionnairForm(formId, update, currenUserIsPatient);
    var formData = $('#' + formId).serializeArray();
    try {
        formData = formData.concat(serializeFormDataAsJSON(formId)[0]);
    } catch (e) {

    }
    return formData;
}

function clearLocalDatatableStorage(formId) {
    $('#' + formId + " table").each(function (index, table) {
        clearSelectedRowsFor($(table).attr("id"));
    })
}

function submitAnamnesisQuestionnaireForm(update, currenUserIsPatient) {
    //prevent double submitting
    var submitButtonId = update ? 'updateAnamnesisQuestionnaireButton' : 'createAnamnesisQuestionnaireButton';
    var oldButtonText = setButtonInWaitingMode(submitButtonId);
    //remove old error messages after resubmit
    $(".error-msg").remove();
    $("#error-msg-anamnesisQuestionnaireForm-form").hide();

    var formData = serializeGenericQuestionnaireFormDataFor('anamnesisQuestionnaireForm', update, currenUserIsPatient);
    $.ajax({
        url: "/" + appLocation + (update ? "updateAnamnesisQuestionnaire" : "createAnamnesisQuestionnaire"),
        context: document.body,
        type: 'post',
        data: formData
    }).done(function (response) {
        showAjaxResponse(response, 'anamnesisQuestionnaireForm');
        clearLocalDatatableStorage("anamnesisQuestionnaireForm");
        if (response.status == 'SUCCESS') {
            if (currenUserIsPatient) {
                location.reload();
            } else {
                var currentPatientId = $('#currentPatientId').attr('value');
                //last part of url is just random nonsense - with that it will still reload if user is already on this page and saves another questionnaire. Wouldn't refresh the page otherwise.
                loadById('PatientViewAnamnesisQuestionnaires', currentPatientId);
            }
        } else {
            showAjaxError(response, 'anamnesisQuestionnaireForm');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        }
    }).fail(function (error) {
        showAjaxError(error, 'anamnesisQuestionnaireForm');
        wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
    });
    return false;
}

