/*
sections:
- abstract message operations
- messages and broadcasts
- concerns
- prescription requests
- appointment booking
- treating doctor requests
*/

/* ------------------------------------ */
/* section: abstract message operations */
/* ------------------------------------ */

//Toggle Confirm State from abstract message with ID id
function confirmMessage(receiverName, id) {

    $('#confirmMessageButton' + id).prop("disabled", true);

    $.ajax({
        url: "/" + appLocation + "confirmMessage",
        context: document.body,
        type: 'post',
        data: {'id': id}
    }).done(function () {
        actionSuccess();
        $('#confirmationState' + id).addClass('confirmed');
        $('#confirmationState' + id).attr('title', 'Von ' + receiverName + ' gelesen und bestätigt');

        $('#confirmMessageButton' + id).hide();
        $('#newMessageBadge' + id).hide();

        refreshMenuInfoBadges();
    }).fail(function () {
        actionFail();
        $('#confirmMessageButton' + id).prop("disabled", false);
    })
}

function loadNewMessageForm(){
    //disable button to prevent double submitting
    //prevent double submitting
    var submitButtonId = "sendNewMessageButton";
    var oldButtonText = setButtonInWaitingMode(submitButtonId);

    $('#newMessageDiv').load("/" + appLocation + "getMessagesNewMessage", function(){
        $("#newMessage").slideDown(400);
        wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
    });
}

/* -------------------------------- */
/* section: messages and broadcasts */

/* -------------------------------- */

function registerMessageFormSubmitButton() {
    $("#newMessageForm").submit(function (e) {
        //disable button to prevent double submitting
        //prevent double submitting
        var submitButtonId = "messageCommitButton";
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        $(".error-msg").remove();
        $("#error-msg-message-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $("#error-msg-message-form").show('fast');
            showAjaxResponse(response, 'message');
            if (response.status == 'SUCCESS') {
                if (currentContent.startsWith('NewPatientMessage/')) {
                    router.navigate(appLocation + 'Home', {trigger: true});
                } else {
                    reloadCurrentContent();
                }

                //reset attachment count
                attachmentCount = 0;
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $("#error-msg-message-form").show('fast');
            showAjaxError(error, 'message');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function registerBroadcastFormSubmitButton() {
    $("#newBroadcastForm").submit(function (e) {
        //disable button to prevent double submitting
        $("#broadcastCommitButton").prop("disabled", true);
        $("#broadcastCommitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-broadcast-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            //enable button if done and failure
            $("#broadcastCommitButton").prop("disabled", false);
            $("#broadcastCommitButton").prop('value', "Senden");
            showAjaxResponse(response, 'broadcast');
            if (response.status == 'SUCCESS') {
                reloadCurrentContent();
            }

        }).fail(function (error) {
            showAjaxError(error, 'broadcast');

            //enable button after fail
            $("#broadcastCommitButton").prop("disabled", false);
            $("#broadcastCommitButton").prop('value', "Senden");
        });
        $("#error-msg-broadcast-form").show('fast');
        return false;
    });
}


function loadExternalCallRequest(doctorID, noPackage) {
    if (noPackage) {
        customConfirm(
            "Sie besitzen kein aktives Betreuungspaket",
            "Um einen Rückruf beantragen zu können benötigen Sie ein aktives Betreuungspaket, dass die Beantwortung von Rückruf-Anfragen beinhaltet. Wollen Sie jetzt ein neues Betreuungspaket erwerben?",
            function () {
                router.navigate(appLocation + 'PackageStore', {trigger: true});
            });
    } else {
        router.navigate(appLocation + 'NewExternalCallRequest/' + doctorID, {trigger: true});
    }
}


function loadReferralRequest(doctorID, noPackage) {
    if (noPackage) {
        customConfirm(
            "Sie besitzen kein aktives Betreuungspaket",
            "Um eine Überweisung beantragen zu können, benötigen Sie ein aktives Betreuungspaket, dass die Beantwortung von Überweisungs-Anfragen beinhaltet. Wollen Sie jetzt ein neues Betreuungspaket erwerben?",
            function () {
                router.navigate(appLocation + 'PackageStore', {trigger: true});
            });
    } else {
        router.navigate(appLocation + 'NewReferralRequest/' + doctorID, {trigger: true});
    }
}

function loadOnlineConsultation(doctorID, noPackage) {
    if (noPackage) {
        customConfirm(
            "Sie besitzen kein aktives Betreuungspaket",
            "Um eine Online-Beratung bzw. Befundbesprechung beantragen zu können, benötigen Sie ein aktives Betreuungspaket dafür. Wollen Sie jetzt ein neues Betreuungspaket erwerben?",
            function () {
                router.navigate(appLocation + 'PackageStore', {trigger: true});
            });
    } else {
        router.navigate(appLocation + 'NewOnlineConsultation/' + doctorID, {trigger: true});
    }
}

/* ----------------- */
/* section: concerns */

/* ----------------- */

function loadConcern(doctorID, noPackage) {
    if (noPackage) {
        customConfirm(
            "Sie besitzen kein aktives Betreuungspaket",
            "Um eine medizinische Frage stellen zu können benötigen Sie ein aktives Betreuungspaket, dass die Beantwortung Medizinische Fragen beinhaltet. Wollen Sie jetzt ein neues Betreuungspaket erwerben?",
            function () {
                router.navigate(appLocation + 'PackageStore', {trigger: true});
            });
    } else {
        if (doctorID == -1 || doctorID == 0) {
            router.navigate(appLocation + 'ApproachDoctorModule', {trigger: true});
        } else {
            router.navigate(appLocation + 'NewConcern/' + doctorID, {trigger: true});
        }
    }
}

function packageChosen(packageId) {
    if (packageId == -1) {
        closeModals();
        router.navigate(appLocation + 'PackageStore', {trigger: true});
    } else {
        $('#selectPackage').removeClass('error');
    }
}

//Submit new Concern in Dialog
function registerConcernFormSubmitButton() {
    $("#newConcernForm").submit(function (e) {
        //prevent double submitting
        var submitButtonId = 'concernCommitButton';
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-concern-form").hide();

        var formData = serializeGenericQuestionnaireFormDataFor('newConcernForm', false, false);

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: formData
        }).done(function (response) {
            $("#error-msg-concern-form").show('fast');
            showAjaxResponse(response, 'concern');
            if (response.status == 'SUCCESS') {
                //show dismissible information to user what happens now
                loadMainDashboard(function () {
                    $('#infoMessageDashboardAlert').show();
                    $('#infoMessageDashboardText').text(response.result);
                });
                router.navigate(appLocation + 'Home', {trigger: false});
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $("#error-msg-concern-form").show('fast');
            showAjaxError(error, 'concern');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

//Post chosen item in Drop down to load Requirements
function loadRequirements(item) {
    showLoaderIn('#RequirementsBlock');
    $('#RequirementsBlock').load("/" + appLocation + "loadRequirements/" + item.value);
    $('#selectConcernType').removeClass('error');
}

function toggleHiddenFieldsForSelect(evt, questionStat) {
    var selected = $(evt).find(":selected");
    var optionId = selected.attr("optionid");
    //show this options alert and hide others
    $('.radioOptionAlertsForQuestion' + questionStat).hide();
    $('#radioOptionAlert' + optionId).show();

    //show this options questions and hide others
    $('.radioOptionAddQuestionsForQuestion' + questionStat).hide();
    $('#radioOptionAddQuestions' + optionId).show();
}


function toggleHiddenFieldsForOption(optionId, questionStat, allowMultiSelect) {
    //show this options alert and hide others
    $('.radioOptionAlertsForQuestion' + questionStat).hide();
    $('#radioOptionAlert' + optionId).show();

    //show this options questions and hide others
    $('.radioOptionAddQuestionsForQuestion' + questionStat).hide();
    $('#radioOptionAddQuestions' + optionId).show();
}

function resetComplexQuestionType(input, index, doIt) {
    //complex questions are not reset in case of multiSelect allowed
    if(doIt == true){
        var index = input.attr("questionid");
        //find all input elements for this complexQuestionType
        var elementsNew = $("input[name=questionAnswerValues\\[" + index + "\\]], textarea[name=questionAnswerValues\\[" + index + "\\]]");
        var elementsOld = $("input[id=questionAnswerValues_" + index + "], textarea[id=questionAnswerValues_" + index + "]");
        var elements = $.merge(elementsNew, elementsOld);

        elements.each(function (index, element) {
            element = $(element);
            //don't reset the element triggering this function but all others
            if (element.get(0) != input.get(0)) {
                //checkbox and radio get deselected
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    element.prop('checked', false);
                } else {            //everything else gets value reset
                    element.val('');
                }
                //close options alert and addQuestions if this get called from not radio
                if ($(input).attr('type') != 'radio') {
                    $('.radioOptionAlertsForQuestion' + index).hide();
                    $('.radioOptionAddQuestionsForQuestion' + index).hide();
                }
            }
        });

        //find all input elements for this complexQuestionType
        $("input[id=anamnesisQuestionAnswerValues\\[" + index + "\\]], textarea[id=anamnesisQuestionAnswerValues\\[" + index + "\\]]").each(function () {
            //don't reset the element triggering this function but all others
            if ($(this).get(0) != input.get(0)) {
                //checkbox and radio get deselected
                if ($(this).attr('type') == 'radio' || $(this).attr('type') == 'checkbox') {
                    $(this).prop('checked', false);
                }

                //everything else gets value reset
                else
                    $(this).val('');

                //close options alert and addQuestions if this get called from not radio
                if ($(input).attr('type') != 'radio') {
                    $('.radioOptionAlertsForQuestion' + index).hide();
                    $('.radioOptionAddQuestionsForQuestion' + index).hide();
                }
            }
        });
    }
}

function calculateCylinderConversion(vitalTypeId, requirementId) {
    var sphField = $(".conversionValuesSph" + vitalTypeId + requirementId);
    var cylField = $(".conversionValuesCyl" + vitalTypeId + requirementId);
    var axeField = $(".conversionValuesAxe" + vitalTypeId + requirementId);
    var sph = parseFloat(sphField.val().replace(',', '.').replace(' ', ''));
    var cyl = parseFloat(cylField.val().replace(',', '.').replace(' ', ''));
    var axe = parseFloat(axeField.val().replace(',', '.').replace(' ', ''));

    if (isNaN(sph) || isNaN(cyl) || isNaN(axe)) {

    } else {
        sph += cyl;
        cyl *= -1;
        ((axe += 90) < 1 || axe > 180) && (axe -= 180);
        sph = +sph.toFixed(2);
        cyl = +cyl.toFixed(2);
        // sph = sph > 0 ? "+" + sph : sph;
        // cyl = cyl > 0 ? "+" + cyl : cyl;

        sphField.val(sph);
        cylField.val(cyl);
        axeField.val(axe);
    }
}

function setValueForVitals(vitalTypeForm) {
    if (vitalTypeForm) {
        _.each(vitalTypeForm, function (vitalType, key) {
            var vitalTypeId = vitalType.vitalTypeId;

            var idField = $("input[name=\'vitalTypes[" + vitalTypeId + "].vitalId");
            idField.val(vitalType.vitalId);

            _.each(vitalType.vitalTypePartDTOS, function (entry, key) {
                try {
                    dateTime = new Date(entry.year, entry.monthValue, entry.dayOfMonth, entry.hour, entry.minute, entry.second)
                } catch (e) {
                    dateTime = new Date();
                }
                $("#vitalTypes_" + vitalTypeId + "_vitalTypePartDTOS_" + entry.id + "_time").datepicker("setDate", dateTime);

                var idField = $("input[name=\'vitalTypes[" + vitalTypeId + "].vitalTypePartDTOS[" + entry.id + "].vitalPartId");
                idField.val(entry.vitalPartId);

                var inputField = $("input[name=\'vitalTypes[" + vitalTypeId + "].vitalTypePartDTOS[" + entry.id + "].value");
                inputField.val(entry.value);
            });
        });
    }
}

function populateFromQuestionAnswerWith(type, $questionAnswer, questionId, questionType) {
    var input = $("#" + type + "_" + questionId);

    var optionFound = false;

    if (input && input.length === 0 || questionType == "complexquestion") {//radio buttons
        input = $("." + type + "_" + questionId);

        if (input.length > 0) {
            input.each(function (index, entry) {
                entry = $(entry);
                if ($questionAnswer == entry.attr('value')) {
                    if (entry.prop('tagName') == "OPTION") {
                        entry.prop('selected', true);
                        entry.change();
                        optionFound = true;
                    }
                    if ((entry.attr('type') == "radio" || entry.attr('type') == "checkbox") && $questionAnswer == entry.attr('value')) {
                        entry.prop('checked', true);
                        entry.change();
                        optionFound = true;
                    }
                }
            });
        }
    }

    input = $("#" + type + "_" + questionId);
    if (input && input.length == 1 && optionFound == false) {//simple input
        if (input.attr("type") === "checkbox") {
            input.prop('checked', true);
        }
        if (input.attr("type") === "number") {
            $questionAnswer = parseFloat($questionAnswer);
        }
        if ($(input).is('textarea')) {
            input.summernote("code", $questionAnswer);
        } else {
            input.val($questionAnswer);
        }
    }
}

//Submit referralRequest
function registerReferralRequestFormSubmitButton() {

    $("#newReferralRequestForm").submit(function (e) {
        //disable button to prevent double submitting
        $("#referralRequestCommitButton").prop("disabled", true);
        $("#referralRequestCommitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-referralRequest-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            //enable button if done and failure
            $("#referralRequestCommitButton").prop("disabled", false);
            $("#referralRequestCommitButton").prop('value', "Senden");
            showAjaxResponse(response, 'referralRequest');
            if (response.status == 'SUCCESS') {
                //show dismissible information to user what happens now
                loadMainDashboard(function () {
                    $('#infoMessageDashboardAlert').show();
                    $('#infoMessageDashboardText').text(response.result);
                });
                router.navigate(appLocation + 'Home', {trigger: false});
            }

        }).fail(function (error) {
            showAjaxError(error, 'referralRequest');

            //enable button after fail
            $("#referralRequestCommitButton").prop("disabled", false);
            $("#referralRequestCommitButton").prop('value', "Senden");
        });
        $("#error-msg-referralRequest-form").show('fast');
        return false;
    });
}


//Submit onlineConsultation
function registerOnlineConsultationFormSubmitButton() {

    $("#newOnlineConsultationForm").submit(function (e) {
        //disable button to prevent double submitting
        $("#onlineConsultationCommitButton").prop("disabled", true);
        $("#onlineConsultationCommitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-onlineConsultation-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            //enable button if done and failure
            $("#onlineConsultationCommitButton").prop("disabled", false);
            $("#onlineConsultationCommitButton").prop('value', "Senden");
            showAjaxResponse(response, 'onlineConsultation');
            if (response.status == 'SUCCESS') {
                //show dismissible information to user what happens now
                loadMainDashboard(function () {
                    $('#infoMessageDashboardAlert').show();
                    $('#infoMessageDashboardText').text(response.result);
                });
                router.navigate(appLocation + 'Home', {trigger: false});
            }

        }).fail(function (error) {
            showAjaxError(error, 'onlineConsultation');

            //enable button after fail
            $("#onlineConsultationCommitButton").prop("disabled", false);
            $("#onlineConsultationCommitButton").prop('value', "Senden");
        });
        $("#error-msg-onlineConsultation-form").show('fast');
        return false;
    });
}

//Submit externalCallRequest
function registerExternalCallRequestFormSubmitButton() {

    $("#newExternalCallRequestForm").submit(function (e) {
        //disable button to prevent double submitting
        $("#externalCallRequestCommitButton").prop("disabled", true);
        $("#externalCallRequestCommitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-externalCallRequest-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            //enable button if done and failure
            $("#externalCallRequestCommitButton").prop("disabled", false);
            $("#externalCallRequestCommitButton").prop('value', "Senden");
            showAjaxResponse(response, 'externalCallRequest');
            if (response.status == 'SUCCESS') {
                //show dismissible information to user what happens now
                loadMainDashboard(function () {
                    $('#infoMessageDashboardAlert').show();
                    $('#infoMessageDashboardText').text(response.result);
                });
                router.navigate(appLocation + 'Home', {trigger: false});
            }

        }).fail(function (error) {
            showAjaxError(error, 'externalCallRequest');

            //enable button after fail
            $("#externalCallRequestCommitButton").prop("disabled", false);
            $("#externalCallRequestCommitButton").prop('value', "Senden");
        });
        $("#error-msg-externalCallRequest-form").show('fast');
        return false;
    });
}


//Submit sendMedia
function registerSendMediaFormSubmitButton() {

    $("#newSendMediaForm").submit(function (e) {
        //disable button to prevent double submitting
        $("#sendMediaCommitButton").prop("disabled", true);
        $("#sendMediaCommitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-sendMedia-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            //enable button if done and failure
            $("#sendMediaCommitButton").prop("disabled", false);
            $("#sendMediaCommitButton").prop('value', "Senden");
            showAjaxResponse(response, 'sendMedia');
            if (response.status == 'SUCCESS') {
                //show dismissible information to user what happens now
                loadMainDashboard(function () {
                    $('#infoMessageDashboardAlert').show();
                    $('#infoMessageDashboardText').text(response.result);
                });
                router.navigate(appLocation + 'Home', {trigger: false});
            }

        }).fail(function (error) {
            showAjaxError(error, 'sendMedia');

            //enable button after fail
            $("#sendMediaCommitButton").prop("disabled", false);
            $("#sendMediaCommitButton").prop('value', "Senden");
        });
        $("#error-msg-sendMedia-form").show('fast');
        return false;
    });
}

//process Concern
function processConcern(id) {

    $("#ConcernModalReplace").load("/" + appLocation + "getConcernModal/" + id, function () {
        $("#concernModal").modal({backdrop: 'static', keyboard: false});
        registerConcernModalSubmitButton();
    });
}

function registerConcernModalSubmitButton() {

    $("#processConcernForm").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-processConcern-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'processConcern');
            if (response.status == 'SUCCESS') {
                $('#concernModal').modal("hide");
                //a new reply is sent and the message moves to first page
                currentPage = 0;
                reloadCurrentContent();
            }
        }).fail(function (error) {
            showAjaxError(error, 'processConcern');
        });
        $("#error-msg-processConcern-form").show('fast');
        return false;

    });

}

//modal for feedback
function showNewFeedbackModal(feedbackAbout, messageId) {
    $("#NewFeedbackModalReplace").load("/" + appLocation + "getNewFeedbackModal", function () {
        $("#NewFeedbackModal").modal({backdrop: 'static', keyboard: false});

        /*    if(feedbackAbout == 'concern' || feedbackAbout == 'prescriptionrequest') */

        $('#feedbackAboutMessageId').val(messageId);

        $('#feedbackAbout').val(feedbackAbout);

        //register submit button
        $("#feedbackForm").submit(function (e) {
            $.ajax({
                url: $(this).attr("action"),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function () {

                $('#feedbackFormThankYouMessage').show();
                setTimeout(function () {
                    closeModals();
                }, 2000);

            }).fail(function () {
                actionFail();
            });
            return false;
        });

    });
}

/* ----------------------------- */
/* section: prescriptionRequests */

/* ----------------------------- */

function loadPrescription(noPackage) {
    if (noPackage) {
        customConfirm(
            "Sie besitzen kein aktives Betreuungspaket",
            "Um ein Rezept bestellen zu können benötigen Sie ein aktives Betreuungspaket, dass die Beantwortung von Rezept-Anfragen beinhaltet. Wollen Sie jetzt ein neues Betreuungspaket erwerben?",
            function () {
                router.navigate(appLocation + 'PackageStore', {trigger: true});
            });
    } else {
        router.navigate(appLocation + 'PrescriptionRequest', {trigger: true});
    }
}


//open PrescriptionModal
function getPrescription(drugId) {
    $("#PrescriptionRequestModalReplace").load("/" + appLocation + "getPrescriptionRequestModal/" + drugId, function () {
        $("#PrescriptionRequestModal").modal({backdrop: 'static', keyboard: false});
        // Register Submit Button
        $("#newPrescriptionRequestModal").submit(function (e) {
            //prevent double submitting
            $("#prescriptionRequestSubmitButton").prop("disabled", true);
            $("#prescriptionRequestSubmitButton").prop('value', "Bitte warten ...");

            $(".error-msg").remove();
            $("#error-msg-prescriptionRequest-form").hide();
            $.ajax({
                url: $(this).attr("action"),
                context: document.body,
                type: 'post',
                data: $(this).serialize()
            }).done(function (response) {
                //re-enable submit button if failed
                $("#prescriptionRequestSubmitButton").prop("disabled", false);
                $("#prescriptionRequestSubmitButton").prop('value', "Senden");
                showAjaxResponse(response, 'prescriptionRequest');
                if (response.status == 'SUCCESS') {
                    $('#PrescriptionRequestModal').modal("hide");
                    if (currentContent == 'Home')
                        reloadCurrentContent();
                    else
                        router.navigate(appLocation + 'Home', {trigger: true});
                }
            }).fail(function (error) {
                //re-enable submit button if failed
                $("#prescriptionRequestSubmitButton").prop("disabled", false);
                $("#prescriptionRequestSubmitButton").prop('value', "Senden");
                showAjaxError(error, 'prescriptionRequest');
            });
            $("#error-msg-prescriptionRequest-form").show('fast');
            return false;
        });
    });
}

function loadPackagesForDoctor(doctorId) {
    showLoaderIn('#prescriptionRequestModalPackageReplace');
    $("#prescriptionRequestModalPackageReplace").load("/" + appLocation + "getPrescriptionRequestPackagesForDoctor/" + doctorId);
}

function showPrescriptionModal(select) {
    if (select.value == -1) {
        router.navigate(appLocation + "NewDrug", {trigger: true});
    } else {
        getPrescription(select.value);
    }
}

function processPrescriptionRequest(id, patientId) {
    //initialize patient context, if not in patientView;
    if (patientId) {
        tempCurrentPatientContext = patientId;
    }

    $("#DrugsModalReplace").load("/" + appLocation + "getPrescriptionRequestAnswerModal/" + id, function () {
        $("#DrugsModal").modal({backdrop: 'static', keyboard: false});

        //Submit new Drug in Modal
        initializeDrugForm(
            function () {
                //not available here
            },
            function (response) {
                // response.result = helper class CreatedDocumentResponse
                // document was created with signature block --> sign it
                if (response.result.withSignaturePlaceholder) {
                    loadDocumentToSign(response.result.documentId,
                        //Callback what should be done when document is signed (finish button clicked)
                        function () {
                            customConfirm("Erfolg", "Das Dokument wurde erfolgreich signiert. Möchten Sie es jetzt versenden?",
                                //confirm
                                function () {
                                    // load documents to get necessary new created data (glyphicon and correct link)
                                    loadById("Documents", response.result.patientId, function () {
                                        router.navigate(appLocation + 'SendDocument/' + response.result.documentId + '/' + response.result.documentName
                                            + '/' + response.result.createdAt + '/' + response.result.patientId, {trigger: true});
                                    });
                                },
                                //cancel
                                function () {
                                    refreshDocumentsForPatient(patientId);
                                    maybePrint('printPrescriptionNow', response.result.createdDocumentUrl);
                                    reloadCurrentContent();
                                }
                            );
                        },
                        //callback when user canceled action
                        function () {
                            refreshDocumentsForPatient(patientId);
                            maybePrint('printPrescriptionNow', response.result.createdDocumentUrl);
                            reloadCurrentContent();
                        }
                    );
                }
                else {
                    maybePrint('printPrescriptionNow', response.result.createdDocumentUrl);
                    reloadCurrentContent();
                }
            }
        );
    });
}


/* ---------------------------- */
/* section: appointment booking */

/* ---------------------------- */

function loadBooking(doctorID) {
    //-1 say there is no doctor yet in this list - load all doctors to connect with one of them
    if (doctorID == -1) {
        router.navigate(appLocation + 'Doctors', {trigger: true});
    } else {
        router.navigate(appLocation + 'Booking/' + doctorID, {trigger: true});
    }
}

function showBookingFrame(doctorID) {
    if (doctorID == -1) {
        loadToMainContentDiv("Doctors");
    } else {
        showLoaderIn('#bookingFrame');
        $('#bookingFrame').load("/" + appLocation + "onlineBookingFrame/" + doctorID);
    }
}

function registerAppointmentRequestFormSubmitButton() {
    $("#newAppointmentRequestForm").submit(function (e) {
        //disable button to prevent double submitting
        $("#appointmentRequestCommitButton").prop("disabled", true);
        $("#appointmentRequestCommitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-appointmentRequest-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            //enable button if done and failure
            $("#appointmentRequestCommitButton").prop("disabled", false);
            $("#appointmentRequestCommitButton").prop('value', "Senden");
            showAjaxResponse(response, 'appointmentRequest');
            if (response.status == 'SUCCESS') {
                router.navigate(appLocation + 'Home', {trigger: true});
            }
        }).fail(function (error) {
            showAjaxError(error, 'appointmentRequest');

            //enable button after fail
            $("#appointmentRequestCommitButton").prop("disabled", false);
            $("#appointmentRequestCommitButton").prop('value', "Senden");
        });
        $("#error-msg-appointmentRequest-form").show('fast');
        return false;
    });
}

/* ----------------------------------------------------------------- */
/* section: process to connect with a doctor (treatingDoctorRequest) */

/* ----------------------------------------------------------------- */

function connectWithDoctor(doctorId) {
    customConfirm("Bitte bestätigen", "Eine Verbindung ist nur zu Ärzten möglich, bei denen Sie im Moment in Behandlung sind. " +
        "Sind Sie sicher, dass Sie sich mit diesem Arzt verbinden möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "connect",
            context: document.body,
            type: 'post',
            data: {'doctorId': doctorId}
        }).done(function () {
            //reloadCurrentContent();
            router.navigate(appLocation + "Home", {trigger: true});

        }).fail(function () {
            alert("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.");
        })
    });
}

function patientConfirmTreatingDoctor(doctorId) {
    customConfirm("Bitte bestätigen", "Möchten Sie die Verbindung mit dieser ÄrztIn bestätigen?", function () {
        $.ajax({
            url: "/" + appLocation + "patientConfirmTreatingDoctor",
            context: document.body,
            type: 'post',
            data: {'doctorId': doctorId}
        }).done(function () {
            //reloadCurrentContent();
            router.navigate(appLocation + "Home", {trigger: true});

        }).fail(function () {
            alert("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es nochmal. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.");
        })
    });
}

function processTreatingDoctorRequest(treatingDoctorId, action) {
    $("#TreatingDoctorAnswerModalReplace").load("/" + appLocation + "getTreatingDoctorAnswerModal/" + action + "/" + treatingDoctorId, function () {
        $("#TreatingDoctorAnswerModal").modal({backdrop: 'static', keyboard: false});
        registerTreatingDoctorAnswerModalSubmitButton();
    });
}

function registerTreatingDoctorAnswerModalSubmitButton() {
    $("#newTreatingDoctorAnswerModal").submit(function (e) {
        //disable button to prevent double submitting
        $("#treatingDoctorAnswerSubmitButton").prop("disabled", true);
        $("#treatingDoctorAnswerSubmitButton").prop('value', "Bitte warten ...");

        $(".error-msg").remove();
        $("#error-msg-treatingDoctorAnswer-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'treatingDoctorAnswer');
            if (response.status == 'SUCCESS') {
                $('#treatingDoctorAnswerModal').modal("hide");
                location.reload();
            }
            $("#treatingDoctorAnswerSubmitButton").prop("disabled", false);
            $("#treatingDoctorAnswerSubmitButton").prop('value', "Bestätigen");
        }).fail(function (error) {
            showAjaxError(error, 'treatingDoctorAnswer');
            $("#treatingDoctorAnswerSubmitButton").prop("disabled", false);
            $("#treatingDoctorAnswerSubmitButton").prop('value', "Bestätigen");
        });
        $("#error-msg-treatingDoctorAnswer-form").show('fast');
        return false;

    });
}

// not technically belonging to treating doctor but highly coupled to above
function registerPatientWithEmail(treatingDoctorId, patientId) {
    $("#RegisterPatientEmailModalReplace").load("/" + appLocation + "getRegisterPatientEmailModal/" + treatingDoctorId + "/" + patientId, function () {
        $("#RegisterPatientEmailModal").modal({backdrop: 'static', keyboard: false});
        registerRegisterPatientEmailModalSubmitButton();
    });
}

function registerRegisterPatientEmailModalSubmitButton() {
    $("#newRegisterPatientEmailModal").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-registerPatientForDoctor-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'registerPatientForDoctor');
            if (response.status == 'SUCCESS') {
                $('#registerPatientEmailModal').modal("hide");
                location.reload();
            }
        }).fail(function (error) {
            showAjaxError(error, 'registerPatientForDoctor');
        });
        $("#error-msg-registerPatientForDoctor-form").show('fast');
        return false;

    });
}

/*
* clearing-process
* */
function dontDebitPackage(messageId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diese Anfrage nicht verrechnen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "dontDebitPackage",
            context: document.body,
            type: 'post',
            data: {'messageId': messageId}
        }).done(function () {
            actionSuccess();
            reloadCurrentContent();
        }).fail(function () {
            actionFail();
        })
    });
}

function forceDebitPackage(messageId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie trotz der Unzufriedenheit des Patienten, diese Anfrage verrechnen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "forceDebitPackage",
            context: document.body,
            type: 'post',
            data: {'messageId': messageId}
        }).done(function () {
            actionSuccess();
            reloadCurrentContent();
        }).fail(function () {
            actionFail();
        })
    });
}


function cancelConcern(messageId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diese Anfrage nicht beantworten und abbrechen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "cancelConcern",
            context: document.body,
            type: 'post',
            data: {'messageId': messageId}
        }).done(function () {
            actionSuccess();
            reloadCurrentContent();
        }).fail(function () {
            actionFail();
        })
    });
}

//patient can close the concern
//is satisfied
function closeConcernSatisfied(messageType, messageId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass die Anfrage zu Ihrer Zufriedenheit beantwortet ist? Sie können nachher keine weiteren Kommentare mehr dazu schreiben!", function () {
        $.ajax({
            url: "/" + appLocation + "closeConcernSatisfied",
            context: document.body,
            type: 'post',
            data: {'messageId': messageId}
        }).done(function () {
            reloadCurrentContent();
            showNewFeedbackModal(messageType, messageId);
            //TODO: we cannot show a feedback modal if patient is
            // not satisfied, because the doctor closes the concern
        }).fail(function () {
            actionFail();
        })
    });
}

//is not satisfied
function patientIsNotSatisfied(messageId, confirmMessage) {
    customConfirm("Bitte bestätigen", confirmMessage, function () {
        $.ajax({
            url: "/" + appLocation + "patientIsNotSatisfied",
            context: document.body,
            type: 'post',
            data: {'messageId': messageId}
        }).done(function () {
            reloadCurrentContent();
        }).fail(function () {
            actionFail();
        })
    });
}


/*
* externalCall CRUD
* */

function createExternalCall(patientId) {
    var submitButtonId = "createExternalCallButton";
    var oldButtonText = setButtonInWaitingMode(submitButtonId);

    $.ajax({
        url: "/" + appLocation + "createExternalCall",
        context: document.body,
        type: 'post',
        data: {'patientId': patientId}
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            openExternalCall(response.result);
        } else {
            actionFail();
        }
        wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
    }).fail(function () {
        actionFail();
        wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
    })
}

function createExternalCallForRequest(patientId, externalCallRequestId) {
    $.ajax({
        url: "/" + appLocation + "createExternalCallForRequest",
        context: document.body,
        type: 'post',
        data: {'externalCallRequestId': externalCallRequestId}
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            openExternalCall(response.result);
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}

function deleteExternalCall(externalCallId, patientId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diese Online-Sprechstunde löschen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "deleteExternalCall",
            context: document.body,
            type: 'post',
            data: {'externalCallId': externalCallId}
        }).done(function () {
            actionSuccess();
            reloadCurrentContent();
        }).fail(function (data) {
            actionFail(data.responseText);
        })
    });

}


function confirmExternalCall(externalCallId) {
    $.ajax({
        url: "/" + appLocation + "confirmExternalCall",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId}
    }).done(function () {
        actionSuccess();
        reloadCurrentContent();
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}

function toggleExternalCallPatientReady(externalCallId, currentState) {
    if (currentState == false) {
        customConfirm("Bitte bestätigen", "Klicken Sie auf Einverstanden, wenn Mikrofon, Lautsprecher und evtl. Kamera vorhanden und funktionsfähig sind und Sie bereit für die Video-Sprechstunde sind.", function () {
            reallyToggleExternalCallPatientReady(externalCallId);
        });
    } else {
        reallyToggleExternalCallPatientReady(externalCallId);
    }
}

function reallyToggleExternalCallPatientReady(externalCallId) {
    $.ajax({
        url: "/" + appLocation + "toggleExternalCallPatientReady",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId}
    }).done(function () {
        reloadCurrentContent();
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}


function toggleExternalCallVideoMeeting(externalCallId) {
    $.ajax({
        url: "/" + appLocation + "toggleExternalCallVideoMeeting",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId}
    }).done(function () {
        openExternalCall(externalCallId);
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}

function toggleExternalCallArchived(externalCallId) {
    $.ajax({
        url: "/" + appLocation + "toggleExternalCallArchived",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId}
    }).done(function () {
        location.reload();
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}

function sendConfirmationMessageForExternalCall(externalCallId) {
    var submitButtonId = "sendConfirmationMessageForExternalCallButton";
    $("#" + submitButtonId).prop("disabled", true);
    $("#" + submitButtonId).removeClass('btn-success');
    $("#" + submitButtonId).addClass('btn-default');
    $("#" + submitButtonId).html("Bitte warten ...");

    //prevent button click event fired before onblur event fired
    setExternalCallSetting(externalCallId, 'confirmationMessage', $('#externalCall' + externalCallId + 'confirmationMessage').val(), function () {
        $.ajax({
            url: "/" + appLocation + "sendConfirmationMessageForExternalCall",
            context: document.body,
            type: 'post',
            data: {'externalCallId': externalCallId}
        }).done(function () {
            /*$("#" + submitButtonId).prop("disabled", false);*/
            $("#" + submitButtonId).addClass('btn-success');
            $("#" + submitButtonId).removeClass('btn-default');
            $("#" + submitButtonId).html("Einladung gesendet!");
        }).fail(function (data) {
            actionFail(data.responseText);
        })
    });
}


function sendInformationForPatient(externalCallId, submitButtonId) {
    $("#" + submitButtonId).prop("disabled", true);
    $("#" + submitButtonId).removeClass('btn-success');
    $("#" + submitButtonId).addClass('btn-default');
    $("#" + submitButtonId).html("Bitte warten ...");

    //prevent button click event fired before onblur event fired
    setExternalCallSetting(externalCallId, 'informationForPatient', $('#externalCall' + externalCallId + 'informationForPatient').val(), function () {
        $.ajax({
            url: "/" + appLocation + "sendInformationForPatient",
            context: document.body,
            type: 'post',
            data: {'externalCallId': externalCallId}
        }).done(function () {
            /*$("#" + submitButtonId).prop("disabled", false);*/
            $("#" + submitButtonId).addClass('btn-success');
            $("#" + submitButtonId).removeClass('btn-default');
            $("#" + submitButtonId).html("Nachricht gesendet!");
        }).fail(function (data) {
            actionFail(data.responseText);
        })

    });
}

function startVideoChat(externalCallId) {
    $.ajax({
        url: "/" + appLocation + "startVideoChat",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId}
    }).done(function () {
        openExternalCall(externalCallId);
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}

function stopVideoChat(externalCallId) {
    /*alert("use docsy hangup button in modal");*/
    $.ajax({
        url: "/" + appLocation + "stopVideoChat",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId}
    }).done(function () {
        openExternalCall(externalCallId);
    }).fail(function (data) {
        actionFail(data.responseText);
    })
}

/* default method to set a externalCall setting */
function setExternalCallSetting(externalCallId, setting, value, successCallback) {
    if ($('#error-msg-externalCall' + externalCallId + '-' + setting).text()) {
        $('#error-msg-externalCall' + externalCallId + '-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setExternalCallSetting",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId, 'setting': setting, 'value': value}
    }).done(function () {
        if (typeof successCallback === "function")
            successCallback();
        else {
            actionSuccess();
        }
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-externalCall' + externalCallId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('externalCall' + externalCallId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

function createToDoListEntryFromExternalCall(externalCallId) {
    var submitButtonId = "createToDoListEntryFromExternalCallButton";
    $("#" + submitButtonId).prop("disabled", true);
    $("#" + submitButtonId).removeClass('btn-success');
    $("#" + submitButtonId).addClass('btn-default');
    $("#" + submitButtonId).html("Bitte warten ...");

    $.ajax({
        url: "/" + appLocation + "createToDoListEntryFromExternalCall",
        context: document.body,
        type: 'post',
        data: {'externalCallId': externalCallId}
    }).done(function () {
        /*$("#" + submitButtonId).prop("disabled", false);*/
        $("#" + submitButtonId).addClass('btn-success');
        $("#" + submitButtonId).removeClass('btn-default');
        $("#" + submitButtonId).html("To-Do-Eintrag erstellt!");
    }).fail(function () {
        actionFail();
    })
}

function debitPackageWithExternalCall(externalCallId, packageId, patientId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie dieses Gespräch mit dem gewählten Paket verrechnen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "debitPackageWithExternalCall",
            context: document.body,
            type: 'post',
            data: {'externalCallId': externalCallId, 'packageId': packageId}
        }).done(function (response) {
            if (response.status == 'SUCCESS') {
                openExternalCall(externalCallId);
            } else {
                actionFail(response.result);
            }
        }).fail(function () {
            actionFail();
        })
    });
}


function releasePackageWithExternalCall(externalCallId, patientId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie die Verrechnung stornieren möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "releasePackageWithExternalCall",
            context: document.body,
            type: 'post',
            data: {'externalCallId': externalCallId}
        }).done(function (response) {
            if (response.status == 'SUCCESS') {
                openExternalCall(externalCallId);
            } else {
                actionFail(response.result);
            }
        }).fail(function () {
            actionFail();
        })
    });
}

function toggleAll(ctx, checkBoxClass) {
    var checked = $(ctx).prop("checked");
    $("." + checkBoxClass).each(function (index, checkbox) {
        $(checkbox).prop("checked", checked);
    });
}

function getExternalCallPanelHight(externalCallId) {
    var panelId = "ExternalCall-" + externalCallId;
    return $('.jsPanel-content').height() - 20;
}

function isIE() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}

// cave: now working in safari (https://jitsi.github.io/handbook/docs/user-guide/supported-browsers/)
function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

function showVideoChatIn(selector, externalCallId, roomName, jitsiDomain, token) {
    if (isIE()) {
        $("#" + selector + "-ie-warning").show();
    } else {
        if (!JitsiMeetExternalAPI) return;
        var frame = document.querySelector('#' + selector);
        var height = getExternalCallPanelHight(externalCallId) + 'px';
        var api = new JitsiMeetExternalAPI(jitsiDomain, {
            roomName: roomName,
            height: height,
            interfaceConfigOverwrite: {"LANG_DETECTION": true, "SHOW_JITSI_WATERMARK": false},
            jwt: token,
            parentNode: frame
        });
        api.on("readyToClose", function () {
            /*alert("use hangup button in video-call");*/
            api.dispose();
            var event = new CustomEvent('readyToClose');
            if (document.getElementById("jitsipatientframe")) {
                document.getElementById("jitsipatientframe").dispatchEvent(event);

                //close panel for patient if used hang-up button in jitsi
                var panelId = "ExternalCall-" + externalCallId;
                if (jsPanels[panelId])
                    jsPanels[panelId].close();
                delete jsPanels[panelId];
            }
            if (document.getElementById("jitsiframe")) {
                stopVideoChat(externalCallId);
                document.getElementById("jitsiframe").dispatchEvent(event);
            }
        });

        document.getElementById(selector).addEventListener('onbeforeclose', function (elem) {
            /*alert("close modal");*/
            api.executeCommand('hangup');
            api.dispose();
        }, false);
    }
}

function openExternalCall(externalCallId, startVideoChatNow) {
    if (!startVideoChatNow)
        startVideoChatNow = false;

    var title = "Online-Sprechstunde";
    var panelId = "ExternalCall-" + externalCallId;

    if (jsPanels[panelId])
        jsPanels[panelId].close();
    delete jsPanels[panelId];

    openExternalCallPanel(panelId, title, function () {
        $(this.content).load("/" + appLocation + "getExternalCall/" + externalCallId + "/" + startVideoChatNow, function () {
        });
    });
}

function openExternalCallPanel(panelId, title, contentFN) {
    if (jsPanels[panelId]) {
        jsPanels[panelId].front();
    } else {
        var options = {
            footerToolbar: '<span class="pull-right">' + title + '</span>',
            headerLogo: '<span style="margin:10px"></span>',
            contentSize: {width: '100%', height: '80%'},
            onwindowresize: true,
            borderRadius: 3,
            closeOnEscape: true,
            content: contentFN,
            onbeforeclose: function (panel) {
                var event = new CustomEvent('onbeforeclose');
                if (document.getElementById("jitsipatientframe")) document.getElementById("jitsipatientframe").dispatchEvent(event);
                if (document.getElementById("jitsiframe")) document.getElementById("jitsiframe").dispatchEvent(event);

                delete jsPanels[panelId];
                reloadCurrentContent();
                return true;
            },
            headerTitle: title,
            theme: "#f2f2f2",
        };

        jsPanels[panelId] = jsPanel.create(options);
        if ($(window).width() <= 1120) {
            jsPanels[panelId].maximize();
        }
    }
}

function getMessageFilter() {
    const form = $("#messageFilterForm");
    if (form.length != 1) return "";
    return form.serialize();
}
function applyMessageFilter(location = 'Messages', page = 0) {
    loadToMainContentDivPage(location, page, null, null, "&" + getMessageFilter());
}
function loadMessagesToWidget(widgetName) {
    return (dashboardName, page) => loadWidgetDashboard({
        dashboardName, page, widgetName,
        patientId: null,
        widgetTypeId: null,
        callback: null,
        filter: getMessageFilter()
    });
}
function loadMessagesToNewsfeed(location, page) {
    loadWidgetDashboard({
        dashboardName: location,
        page: page,
        widgetName: "newsfeed",
        patientId: null,
        widgetTypeId: null,
        callback: null,
        filter: getMessageFilter()
    })
}