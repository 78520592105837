var doctorStartTour;
var patientStartTour;
var packageCodeTour;


/*general tour settings*/
function createNewTour(onEnd) {
    var tourObject = new Tour({
        template: '<div class="popover" role="tooltip"> ' +
        '<div class="arrow"></div> ' +
        '<h3 class="popover-title"></h3> ' +
        '<div class="popover-content"></div> ' +
        '<div class="popover-navigation"> ' +
        '<div class="btn-group"> ' +
        '<button class="btn btn-sm btn-default" data-role="prev">&laquo; Zurück</button> ' +
        '<button class="btn btn-sm btn-default" data-role="next">Weiter &raquo;</button> ' +
        '<button class="btn btn-sm btn-default" data-role="pause-resume" data-pause-text="Pause" data-resume-text="Resume">Pause</button> ' +
        '</div> ' +
        '<div class="btn-group pull-right">' +
        '<button class="btn btn-sm btn-default" onclick="$(\'.popover.tour-tour\').hide();">Später</button> ' +
        '<button class="btn btn-sm btn-default" data-role="end">Beenden</button> ' +
        '</div> ' +
        '</div> ' +
        '</div>',
        storage: false,
        onEnd: onEnd
    });
    return tourObject;
}

/*tour: patient onboarding*/
function createPatientStartTour() {
    var onEnd = function () {
        $.ajax({
            url: "/" + appLocation + "tourDone",
            context: document.body,
            type: 'post'
        }).done(function () {
        }).fail(function () {
            actionFail();
        });
    };

    patientStartTour = createNewTour(onEnd);

    return patientStartTour.addSteps([
        {
            element: '#startTour-welcome',
            content: '<strong>Herzlich Willkommen bei MeinArztOnline.at!</strong> <br/><br/> ' +
            'Wenn Sie möchten, zeigen wir ihnen jetzt in dieser <b>kurzen Tour</b>' +
            ' die wichtigsten Funktionen dieser Seite. <br/><br/> ' +
            '<strong>Dauert nur 3 Minuten!</strong> <br/> <br/> ' +
            '<i>(Sie können auch die <strong>Pfeiltasten</strong> verwenden. Später können Sie die Tour im Hilfe-Bereich erneut starten!</i><br/> <br/>' +
            'Los geht\'s!',
            placement: 'right'
        },
        {
            element: '#startTour-profile',
            content: "In Ihren Einstellungen verwalten Sie z.B. Ihre Kontaktdaten und können Ihr Passwort ändern.",
            placement: 'left'
        },
        {
            element: '#patientStartTour-phr',
            content: "Hier können Sie alle Ihre <strong>medizinischen Informationen</strong>, wie " +
            "Erkrankungen, Medikamente, Allergien oder Impfungen verwalten.",
            placement: 'right'
        },
        {
            element: '#patientStartTour-documents',
            content: "Hier können Sie alle Ihre <strong>Dokumente und Medien</strong> verwalten, wie z.B. " +
            "Befunde, Rechnungen, Ultraschallbilder oder Rezepte.",
            placement: 'right'
        },
        {
            element: '#patientStartTour-patientsDoctors',
            content: "Hier können Sie Ihre <strong>Ärzte</strong> verwalten, die MeinArztOnline benutzen. Dazu müssen Sie Ihrem Arzt " +
            "als erstes eine <strong>Verbindungsanfrage</strong> senden! Erst dann können Sie mit ihm kommunizieren.",
            placement: 'right'
        },
        {
            element: '#patientStartTour-packages',
            content: "Manche Ärzte bieten MeinArztOnline völlig kostenlos an, manche verkaufen sogenannte <strong>Betreuungspakete</strong>. " +
            "Wenn Ihr Arzt das Service kostenlos anbietet, erhalten Sie nach der Verbindungsanfrage automatisch ein Paket geschenkt. Wenn Ihr Arzt nur kostenpflichtige Pakete anbietet, müssen " +
            "Sie hier vorher <strong>Pakete kaufen</strong>. Unter <strong>Betreuungspakete</strong> finden Sie einen Überblick über Ihre Pakete.",
            placement: 'right'
        },
        {
            element: '#patientStartTour-communication',
            content: "Hier finden Sie die wichtigsten Funktionen von MeinArztOnline. Unter <strong>Nachrichten</strong> " +
            "finden Sie Ihr persönliches Postfach - hier können Sie Fragen bzw. Nachrichten an Ihre Ärzte senden. Weiters können Sie hier <strong>Rezepte</strong> bestellen und <strong>Termine</strong> vereinbaren.",
            placement: 'right'
        },
        {
            element: '#patientStartTour-apps',
            content: "Hier gibt\'s später hilfreiche <strong>Erweiterungen</strong> für Sie. Klicken Sie es nachher einfach mal an, um zu sehen was Sie bald erwartet...",
            placement: 'right'
        },
        {
            element: '#patientStartTour-managedPatients',
            content: "Hier können Sie Profile für Ihre Kinder und Angehörigen anlegen und verwalten. Es ist wichtig, dass Sie " +
            "für jede Person ein eigenes Profil anlegen!",
            placement: 'right'
        },
        {
            element: '#dashboard-col-2',
            content: "Hier auf Ihrem Dashboard finden Sie die sogenannten <strong>Widgets</strong>, welche Sie rechts unten bei <strong>Dashboard konfigurieren</strong> anpassen können. <br/> <br/> " +
            "Häufig benötigte Aktionen können Sie hier mit nur einem Klick erledigen, ... ",
            placement: 'top'
        },
        {
            element: '#newConcernWidget',
            content: "... schnell eine <strong>Frage</strong> an Ihren Arzt stellen ...",
            placement: 'left'
        },
        {
            element: '#newPrescriptionRequestWidget',
            content: "... schnell ein <strong>Rezept</strong> bestellen ...",
            placement: 'left'
        },
        {
            element: '#bookingWidget',
            content: "... und schnell einen <strong>Termin</strong> vereinbaren!",
            placement: 'left'
        },
        {
            element: '#activityLogWidget',
            content: "Hier finden Sie das <strong>Aktivitätenprotokoll</strong>. Sie können hier alle Aktionen, die in Ihrem Profil geschehen, kontrollieren.",
            placement: 'left'
        },
        {
            element: '#newsfeed',
            content: "Und zu guter Letzt .. <br/> <br/> Hier in Ihrem persönlichen <strong>Newsfeed</strong> finden Sie alle Nachrichten und Informationen auf einen Blick!" +
            " <br/> <br/> Viel Freude mit MeinArztOnline!",
            placement: 'top'
        }
        ]
    );
}

function createAndStartPatientStartTour() {
    var mobileView = $('#visible-xs').is(":visible");

    if (!mobileView) {
        patientStartTour = createPatientStartTour();
        patientStartTour.init();
        patientStartTour.start();
    }
}

function startPatientStartTour() {
    var mobileView = $('#visible-xs').is(":visible");

    if (!mobileView) {
        patientStartTour.init();
        patientStartTour.start();
    }
}

function restartPatientStartTour() {
    router.navigate(appLocation + 'Home');
    loadMainDashboard(function () {
        startPatientStartTour();
    });
}

/*tour: doctor onboarding*/
function createDoctorStartTour(activatedModules) {
    var onEnd = function () {
        $.ajax({
            url: "/" + appLocation + "tourDone",
            context: document.body,
            type: 'post'
        }).done(function () {
        }).fail(function () {
            actionFail();
        });
    };

    doctorStartTour = createNewTour(onEnd);

    doctorStartTour.addSteps([
        {
            element: '#startTour-welcome',
            content: '<strong>Herzlich Willkommen bei docsy.at!</strong> <br/><br/> ' +
            'Wenn Sie möchten, zeigen wir Ihnen jetzt in dieser <b>kurzen Tour</b>' +
            ' die wichtigsten Funktionen dieser Seite. <br/><br/> ' +
            '<strong>Dauert nur 3 Minuten!</strong> <br/> <br/> ' +
            '<i>(Sie können auch die <strong>Pfeiltasten</strong> verwenden. Später können Sie die Tour im Hilfe-Bereich erneut starten!</i><br/> <br/>' +
            'Los geht\'s!',
            placement: 'right'
        },
        {
            element: '#startTour-settings',
            content: "In den Einstellungen verwalten Sie alle Konfigurationen und Personalisierungen für Ihren docsy Account.",
            placement: 'left'
        }]
    );
    if(activatedModules.appointmentsModule || activatedModules.informationSystem || activatedModules.patientCommunication || activatedModules.findingsModule){
        doctorStartTour.addSteps([
        {
            element: '#doctorStartTour-patients',
            content: "Hier können Sie neue Patienten anlegen oder mittels deren Connect-ID importieren, sowie Ihre bestehenden <strong>Patienten verwalten</strong>.",
            placement: 'right'
        },
        {
            element: '#doctorStartTour-referrers',
            content: "Hier können Sie Ihre zuweisenden <strong>ÄrztInnen und TherapeutInnen</strong> suchen bzw. ein docsy Profil für Sie anlegen, um Ihnen mit der Anwendung <strong>Befund-Austausch</strong> (docsy postman) mit einem Klick Befunde " +
            "Ihrer PatientInnen oder sonstige Nachrichten zu übermitteln.",
            placement: 'right'
        }]
    );}
    doctorStartTour.addSteps([
        {
            element: '#doctorStartTour-communication',
            content: "Hier finden Sie Ihr persönliches sicheres Postfach - Sie können hier neue Nachrichten verfassen und finden alle erhaltenen Nachrichten und Anfragen chronologisch sortiert.",
            placement: 'right'
        },
        {
            element: '#doctorStartTour-apps',
            content: "Hier finden Sie die verfügbaren <strong>Anwendungen</strong> von docsy, wie z.B. die Terminverwaltung oder die Verwaltung der Honorarnoten. " +
            "Unter <i>Einstellungen > Aktivierte Anwendungen</i> können Sie sehen welche Anwendungen für Sie aktiviert sind. Wenn Sie weitere Anwendungen verwenden möchten, können Sie sich jederzeit an uns wenden.",
            placement: 'right'
        },
        {
            element: '#doctorStartTour-documents',
            content: "In dieser Anwendung erhalten Sie einen Überblick über all Ihre erstellen und empfangenen <strong>Dokumente und Medien</strong>, wie z.B. " +
            "Befunde, Rechnungen, Ultraschallbilder oder Rezepte.",
            placement: 'right'
        }
        ]
    );
    if(activatedModules.appointmentsModule){
        doctorStartTour.addSteps([
            {
                element: '#doctorStartTour-appointmentsModule',
                content: "Hier gelangen Sie zur <strong>Terminverwaltung</strong> ... ",
                placement: 'right',
                onNext: function (tour) {
                    router.navigate(appLocation + 'AppointmentsModule');
                    loadToMainContentDiv('AppointmentsModule', null, function (){
                        doctorStartTour.goTo(tour.getCurrentStep()+1);
                    });
                    return (new jQuery.Deferred()).promise();
                }
            },
            {
                element: '#doctorStartTour-appointmentsModuleSettings',
                content: "Hier können Sie Ihre Terminverwaltung konfigurieren. <br/><br/> Damit Patienten online einen Termin buchen können, müssen Sie zumindest " +
                "einen Kalender und eine Terminart anlegen, bei denen die Einstellung <i>Online buchbar</i> aktiviert ist. <br/><br/>Sie finden den Link um " +
                "die Online-Terminbuchung zu testen in der ersten Zeile der allgemeinen Einstellungen zur Terminverwaltung.",
                placement: 'left',
                onPrev: function (tour) {
                    router.navigate(appLocation + 'Home');
                    loadMainDashboard(function () {
                        doctorStartTour.goTo(tour.getCurrentStep()-1);
                    });
                    return (new jQuery.Deferred()).promise();
                },
                onNext: function (tour) {
                    router.navigate(appLocation + 'Home');
                    loadMainDashboard(function () {
                        doctorStartTour.goTo(tour.getCurrentStep()+1);
                    });
                    return (new jQuery.Deferred()).promise();
                }
            }
            ]
        );
    }

    doctorStartTour.addSteps([
        {
            element: '.dashboard',
            content: "Hier auf Ihrem Dashboard finden Sie die sogenannten <strong>Widgets</strong>, welche Sie rechts unten bei <strong>Dashboard konfigurieren</strong> anpassen können. <br/> <br/> " +
            "Die wichtigsten Informationen werden hier auf einen Blick für Sie gesammelt, wie z.B. die Warteliste oder ein Suchfeld für Ihre Patienten. Häufig benötigte Aktionen können Sie hier mit nur einem Klick erledigen, wie z.B. Rezept-Anfragen beantworten, Termine bestätigen, ... "+
            " <br/> <br/>Das war's! Wir wünschen Ihnen viel Freude mit docsy!",
            placement: 'top'
        }
        ]
    );

    return doctorStartTour;
}

function createAndStartDoctorStartTour(activatedModules) {
    var mobileView = $('#visible-xs').is(":visible");

    if (!mobileView) {
        doctorStartTour = createDoctorStartTour(activatedModules);
        doctorStartTour.init();
        doctorStartTour.start();
    }
}

function startDoctorStartTour() {
    var mobileView = $('#visible-xs').is(":visible");

    if (!mobileView) {
        doctorStartTour.init();
        doctorStartTour.start();
    }
}

function restartDoctorStartTour() {
    router.navigate(appLocation + 'Home');
    loadMainDashboard(function () {
        startDoctorStartTour();
    });
}


/*tour: create new package code (doctor)*/
function createPackageCodeTour() {
    packageCodeTour = createNewTour();
    packageCodeTour.addSteps([
        {
            element: '#packageCode-tour-menu',
            content: "Über den Menüeintrag \"Betreuungspakete\" gelangen Sie zur Übersicht Ihrer Pakete.",
            onNext: function () {
                router.navigate(appLocation + 'DoctorsPackagesModule');
                loadToMainContentDiv('DoctorsPackagesModule', null, function () {
                    load('VoucherPackagesModule', function () {
                        packageCodeTour.goTo(1);
                    });
                });
            }
        },
        {
            element: '#tour-packageCode-createButton',
            content: "Nach einem Klick auf den Button \"Neuer Paket-Code\" können Sie das gewünschte Paket mit einem weiteren " +
            "Mausklick auswählen. Neue Pakete müssen vom Administrator angelegt werden.",
            placement: 'left',
            onPrev: function () {
                router.navigate(appLocation + 'Home');
                loadMainDashboard(function () {
                    packageCodeTour.goTo(0);
                });
            }
        },
        {
            element: '#tour-packageCode-voucherList',
            content: "Hier sehen Sie anschließend eine Liste, in welcher der neu erstelle Paket-Code grün markiert an oberster Stelle " +
            "erscheint. Mit einem Klick auf \"Paket-Code drucken\" öffnet sich ein PDF in Ihrem Browser, welches Sie anschließend " +
            "wie gewohnt ausdrucken können.",
            placement: 'top'
        }
    ]);
    return packageCodeTour;
}

function createAndStartPackageCodeTour() {
    packageCodeTour = createPackageCodeTour();
    packageCodeTour.init();
    router.navigate(appLocation + 'Home');
    loadMainDashboard(function () {
        packageCodeTour.start();
    });
}
