function filterReimbursementBills(){
    showLoaderIn('.ReimbursementBillsBlock');
    $('.ReimbursementBillsBlock').load("/" + appLocation + "getFilteredReimbursementBills", $("#reimbursementBillFilterForm").serialize());
}

//load and show the modal to create
function saveReimbursementBill(patientId, reimbursementBillId) {
    if(!patientId){
        patientId = -1;
    }
    if(!reimbursementBillId){
        reimbursementBillId = -1;
    }

    $("#ReimbursementBillModalReplace").load("/" + appLocation + "getReimbursementBillModal/" + patientId + "/" + reimbursementBillId, function () {
        setComboBoxValue('reimbursementBillSubject', patientId);
        $("#ReimbursementBillModal").modal({backdrop: 'static', keyboard: false});
        registerReimbursementBillModalSubmitButton(patientId);
    });
}


//what happens when user clicks on create
function registerReimbursementBillModalSubmitButton(patientId) {
    $("#reimbursementBillForm").submit(function (e) {
        //prevent double submitting
        var submitButtonId = "reimbursementBillFormSubmitButton";
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-reimbursementBill-form").hide();

        $.ajax({
            url:  $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $("#error-msg-reimbursementBill-form").show('fast');
            showAjaxResponse(response, 'reimbursementBill','ReimbursementBillModal');
            if (response.status == 'SUCCESS') {
                filterReimbursementBills();
                $('#ReimbursementBillModal').modal("hide");
            }
            else{
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $("#error-msg-reimbursementBill-form").show('fast');
            showAjaxError(error, 'reimbursementBill');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}



/*START OF APPENDING REIMBURSEMENT ITEMS*/

//append an reimbursementItem to the reimbursementItem list (div id=reimbursementItems)
function appendReimbursementBillItem(nextItemStat, reimbursementItemId, reimbursementItemCode, reimbursementItemDescription){

    var reimbursementBillItemId = nextItemStat;
    reimbursementItemId = reimbursementItemId ? reimbursementItemId : "";
    reimbursementItemCode = reimbursementItemCode ? reimbursementItemCode : "";
    reimbursementItemDescription = reimbursementItemDescription ? reimbursementItemDescription : "";

    $('#reimbursementBillItems').append(
        '<div class="form-group" id="appendedReimbursementBillItem' + reimbursementBillItemId + '">\n' +
        '                            <input style="display: none;" value="-1" type="text" class="form-control" name="itemIds"/>\n' +
        '                            <input style="display: none;" value="' + reimbursementItemId + '" type="text" class="form-control" name="itemReimbursementItemIds"/>\n' +
        '                            <div class="col-sm-3 col-xs-12">\n' +
        '                                <div class="input-group date datetimepicker-item' + reimbursementBillItemId + '-date"\n' +
        '                                     id="datetimepicker-item' + reimbursementBillItemId + '-date">\n' +
        '                                    <input type="text" class="form-control" name="itemDates"/>\n' +
        '                                    <span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>\n' +
        '                                </div>\n' +
        '                                <div id="error-msg-item' + reimbursementBillItemId + '-date"></div>\n' +
        '                            </div>\n' +
        '                            <div class="col-sm-1 col-xs-12">\n' +
        '                                <div>\n' +
        '                                    <input type=\'text\' class="form-control" pattern="[-]*[0-9]+([\\.,][0-9]+)?" title="Zahl mit max. 2 Dezimalstellen" onchange="this.value = this.value.replace(/,/g, \'.\')" name="itemQuantities" value="1"/>\n' +
        '                                </div>\n' +
        '                                <div id="\'error-msg-item' + reimbursementBillItemId + '-quantity\'"></div>\n' +
        '                            </div>\n' +
        '                            <div class="col-sm-1 col-xs-12">\n' +
        '                                <div>\n' +
        '                                    <input type=\'text\' class="form-control" name="itemCodes" value="' + reimbursementItemCode + '"/>\n' +
        '                                </div>\n' +
        '                                <div id="\'error-msg-item' + reimbursementBillItemId + '-code\'"></div>\n' +
        '                            </div>\n' +
        '                            <div class="col-sm-6 col-xs-12">\n' +
        '                                <div>\n' +
        '                                    <input type=\'text\' autofocus="autofocus" class="form-control reimbursementBillItemDescriptionInputField" name="itemDescriptions" value="' + reimbursementItemDescription + '"/>\n' +
        '                                </div>\n' +
        '                                <div id="\'error-msg-item' + reimbursementBillItemId + '-description\'"></div>\n' +
        '                            </div>\n' +
        '                            <div class="col-sm-1 col-xs-12">' +
        '                               <button type="button" class="btn btn-link" onclick="removeReimbursementBillItem(' + reimbursementBillItemId + ');">'+
        '                                   <span style="color:dimgrey;" class="glyphicon glyphicon-remove"></span>'+
        '                               </button>'+
        '                                   <input style="display: none;" name="itemDeletes" value="false"/>'+
        '                            </div>\n' +
        '                        </div>'
    );

    $('.datetimepicker-item' + reimbursementBillItemId + '-date').datetimepicker({
        format: 'DD.MM.YYYY',
        showTodayButton: true, showClose: true,
        locale: 'de',
        defaultDate: new Date
    });

    //initializeReimbursementBillItemDescriptions(reimbursementBillItemId);

    if($('#ReimbursementBillTemplateModal')){
        $('#ReimbursementBillTemplateModal').find('[autofocus]').focus();
        $('#ReimbursementBillTemplateModal').modal('handleUpdate');
    }
    if($('#ReimbursementBillModal')){
        $('#ReimbursementBillModal').modal('handleUpdate');
        $('#ReimbursementBillModal').find('[autofocus]').focus();
    }

    $('.reimbursementBillItemDescriptionInputField').keypress(function (e) {
        if (e.which == 13) { //when user clicks enter
            addReimbursementBillCustomItem();
            return false;
        }
    });

}


function removeReimbursementBillItem(reimbursementBillItemToRemoveId){
    $('#appendedReimbursementBillItem' + reimbursementBillItemToRemoveId).remove();
}

function addReimbursementBillCustomItem(){
    //append it to html container
    var nextItemStat = Number($('#nextItemStat').val());
    $('#nextItemStat').val(nextItemStat+1);

    appendReimbursementBillItem(nextItemStat);
}


function addReimbursementBillReimbursementItem(){
    var selectElement = $('#reimbursementItemComboBox')[0];
    var nextItemStat = Number($('#nextItemStat').val());
    $('#nextItemStat').val(nextItemStat+1);
    appendReimbursementBillItem(nextItemStat,
        selectElement.value,
        selectElement.options[selectElement.selectedIndex].getAttribute('code'),
        selectElement.options[selectElement.selectedIndex].getAttribute('description'));

    $('#' + selectElement.id).val(null).trigger('change');
}

function initializeReimbursementBillItemDescriptions(theClass){
    var customReimbursementBillItemDescriptions = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 100,

        prefetch: {
            url: "/" + appLocation + 'getCustomBillItemDescriptions',
            filter: function (list) {
                return $.map(list, function (entry) {
                    return {name: entry};
                });
            },
            ttl: 10000
        }
    });

    // kicks off the loading/processing of `local` and `prefetch`
    customReimbursementBillItemDescriptions.initialize();

    $('.prefetch-customReimbursementBillItemDescriptions' + theClass + ' .typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 3
    }, {
        name: 'customReimbursementBillItemDescriptions',
        display: 'name',
        source: customReimbursementBillItemDescriptions.ttAdapter()
    });
}
