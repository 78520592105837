function togglePatientsVaccinationTypes(vaccinationTypeId) {
    $.ajax({
        url: "/" + appLocation + "togglePatientsVaccinationType",
        context: document.body,
        type: 'post',
        data: {'vaccinationTypeId': vaccinationTypeId}
    }).done(function () {
        //load('MyVaccinationPlan');
        drawVaccinationChartInDiv('MyVaccinationChart');
    }).fail(function () {
        alert("Undefined Error!");
        $("#msg").html("<span class=\"formFieldError\">Server failed to process request</span>");
    })
}

//DropDown Menu for Vaccinations
function loadVaccinationParts(htmlelement, id) {
    $('#' + htmlelement).load("/" + appLocation+"getVaccinationParts/" + id);
}

//draw vaccination-overview in vis.js timeline
function drawVaccinationChartInDiv(divID) {

    $('#' + divID).html("");//delete previous chart
    var container = document.getElementById(divID);

    var jsonItems = $.ajax({
        url: "/" + appLocation +  "drawVaccinationChartItems",
        dataType: "json",
        async: false
    }).responseText;

    var jsonGroups = $.ajax({
        url: "/" + appLocation +  "drawVaccinationChartGroups",
        dataType: "json",
        async: false
    }).responseText;

    var items = new vis.DataSet(eval(jsonItems));
    var groups = new vis.DataSet(eval(jsonGroups));

    // Configuration for the Timeline
    var today = new Date();
    var startdate = new Date().setFullYear(today.getFullYear() - 2);
    var enddate = new Date().setFullYear(today.getFullYear() + 4);
    var options = {
        autoResize: false,
        start: startdate,
        end: enddate,
        orientation: 'both',
        zoomMax: 1261440000000, //40 years
        margin: {
            item: {
                horizontal: 0
            }
        }
    };

    // Create the Timeline
    var timeline = new vis.Timeline(
        container,
        items,
        groups,
        options
    );
}

function wiki_auth(login, pass, ref) {
    $.post('http://wikimed.stonebird-it.com/mediawiki/api.php?action=login&lgname=' + login +
    '&lgpassword=' + pass + '&format=json', function (data) {
        if (data.login.result == 'NeedToken') {
            $.post('http://wikimed.stonebird-it.com/mediawiki/api.php?action=login&lgname=' + login +
                '&lgpassword=' + pass + '&lgtoken=' + data.login.token + '&format=json',
                function (data) {
                    if (!data.error) {
                        if (data.login.result == "Success") {
                            loggedInWiki = true;
                            document.location.href = ref;
                        } else {
                            // console.log('Result: ' + data.login.result);
                        }
                    } else {
                        loggedInWiki = false;
                        // console.log('Error: ' + data.error);
                    }
                });
        } else {
            loggedInWiki = true;
            // console.log('Result: ' + data.login.result);
        }
        if (data.error) {
            loggedInWiki = false;
            // console.log('Error: ' + data.error);
        }
    });
}

function loadWikiContent(id) {

    showLoaderIn('.WikiContentBlock' + id);

    if (loggedInWiki == false) {
        wiki_auth('admin', 'zpawG)/h23r', 'http://www.stonebird-it.com/mediawiki/api.php');
    }

    $('.WikiContentBlock' + id).load("/" + appLocation+"getWikiContent/" + id);
}