var desktopNotificationAllowed;

//function to set settings - called automatically when home is loaded
function setSettings(setting) {
    desktopNotificationAllowed = setting.desktopNotifications;
}

function secureToggleSettings(setting, customSuccessCallback, reloadSettingPage) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diese Einstellung ändern möchten?", function () {
        toggleSettings(setting, customSuccessCallback, reloadSettingPage);
    }, function () {
        $("#autoPermissionsSetting").prop("checked", !$("#autoPermissionsSetting").prop("checked"));
    });
}

function getFileSizes(userId) {
    showLoaderIn('.filesizeblock');
    var url = "/" + appLocation + "getFileSizes";
    if (userId) {
        url = url + "/" + userId;
    }
    $.get(url, function (response) {
        if (response) {
            response = JSON.parse(response);
            actionSuccess();
            var newLineTemplate = document.getElementById("filesize-template");
            var newLine = $(newLineTemplate.content.cloneNode(true));
            newLine.children().each(function () {
                var usedPercent = 0;
                if (response.usedPercent >= 90) {
                    usedPercent = "<span style='color:red'>" + response.usedPercent + "<span>";
                } else if (response.usedPercent >= 50 && response.usedPercent < 90) {
                    usedPercent = "<span style='color:orange'>" + response.usedPercent + "<span>";
                } else {
                    usedPercent = "<span style='color:green'>" + response.usedPercent + "<span>";
                }
                var text = $(this).html().replace(/{{usedDiskspace}}/g, "" + response.usedSpace + " GB (" + usedPercent + "%)");
                text = text.replace(/{{availableDiskspace}}/g, response.availableSpace);
                text = text.replace(/{{diskSpaceLeft}}/g, response.diskSpaceLeft);
                text = text.replace(/{{docCount}}/g, "" + response.count);
                $(this).html(text);
            });
            $(".filesizeblock").html(newLine);
        }
    }).fail(function () {
        actionFail();
    });

}


function getPatientContactCount(userId) {
    showLoaderIn('.patientContactCountBlock');
    var url = "/" + appLocation + "getPatientContactCount";
    if (userId) {
        url = url + "/" + userId;
    }

    $('.patientContactCountBlock').load(url, function () {actionSuccess();
    });

}


function toggleSettings(setting, customSuccessCallback, reloadSettingPage) {
    showLoaderIn('.SettingsBlock');
    $.ajax({
        url: "/" + appLocation + "toggleSetting",
        context: document.body,
        type: 'post',
        data: {'setting': setting}
    }).done(function () {
        actionSuccess();
        if (customSuccessCallback != null && typeof customSuccessCallback === "function") {
            customSuccessCallback();
        } else if (reloadSettingPage) {
            load(reloadSettingPage);
        }
    }).fail(function () {
        actionFail();
    });

    if (setting == "desktopNotifications") {
        desktopNotificationAllowed = !desktopNotificationAllowed;
    }
}

/* default method to set a setting */
function setSetting(setting, value, customSuccessCallback) {
    showLoaderIn('.SettingsBlock');
    if ($('#error-msg-' + setting).text()) {
        $('#error-msg-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setSetting",
        context: document.body,
        type: 'post',
        data: {'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();

        if (customSuccessCallback != null && typeof customSuccessCallback === "function") {
            customSuccessCallback();
        } else {
            load('Settings');
        }
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-' + setting)) {
                actionFail();
                showResponseEntityMessage(setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

/* method to update a setting from a button group (button as param) */
function updateSetting(button, setting) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(":input")).val();
    $.ajax({
        url: "/" + appLocation + "setSetting",
        context: document.body,
        type: 'post',
        data: {'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        unhighlightSavedValue(setting);
    }).fail(function () {
        actionFail();
    })
}

//desktop notifications
function notifyMe() {
    // if (desktopNotificationAllowed && document['hidden']) { //only notify if tab is not active
    //     if (!("Notification" in window)) {
    //         //alert("This browser does not support desktop notification");
    //     }
    //     else if (Notification.permission === "granted") {
    //         var options = {
    //             body: "Neue Benachrichtigung",
    //             dir: "ltr"
    //         };
    //         var notification = new Notification("MeinArztOnline.at", options);
    //         //notification.onclick = function () {window.open("http://localhost:8080/", "http://localhost:8080/");};
    //     }
    //     else if (Notification.permission !== 'denied') {
    //         Notification.requestPermission(function (permission) {
    //             if (!('permission' in Notification)) {
    //                 Notification.permission = permission;
    //             }
    //
    //             if (permission === "granted") {
    //                 var options = {
    //                     body: "Neue Benachrichtigung",
    //                     dir: "ltr"
    //                 };
    //                 var notification = new Notification("MeinArztOnline.at", options);
    //                 //notification.onclick = function () {window.open("http://localhost:8080/", "http://localhost:8080/");};
    //             }
    //         });
    //     }
    // }
}


function registerAnamnesisFormButton() {
    $("#anamnesisForm").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-anamnesis-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'anamnesis');
        }).fail(function (error) {
            showAjaxError(error, 'anamnesis');
        });
        $("#error-msg-anamnesis-form").show('fast');
        return false;
    });
}

function registerGenericAnamnesisFormSubmitButton() {
    $("#anamnesisForm").submit(function (e) {
        $("#anamnesisFormSubmitButton").prop('disabled', true);
        $("#anamnesisFormSubmitButton").prop('value', "Bitte warten ...");

        //reindex anamnesis arrays (gaps due to unique id generation method for dynamic loaded questions)
        $("input[id^=anamnesisQuestionIds]").each(function (index) {
            var elementId = $(this).attr('id');
            //the initial starting point of the substring is based on "anamnesisQuestionIds["
            var tmpIndex = elementId.substring(21, elementId.length - 1);

            $(this).attr('name', 'anamnesisQuestionIds[' + index + ']');

            $("#anamnesisQuestionAnswerValues\\[" + tmpIndex + "\\]").attr('name', 'anamnesisQuestionAnswerValues[' + index + ']');

        });

        $(".error-msg").remove();
        $("#error-msg-anamnesis-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'anamnesis');
            $("#anamnesisFormSubmitButton").prop('disabled', false);
            $("#anamnesisFormSubmitButton").prop('value', "Aktualisieren");
        }).fail(function (error) {
            showAjaxError(error, 'anamnesis');
            $("#anamnesisFormSubmitButton").prop('disabled', false);
            $("#anamnesisFormSubmitButton").prop('value', "Aktualisieren");
            $("#error-msg-anamnesis-form").show('fast');
        });
        $("#error-msg-anamnesis-form").show('fast');
        return false;
    });
}

function registerContactDataFormButton() {
    $("#contactDataForm").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-contact-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'contact');
        }).fail(function (error) {
            showAjaxError(error, 'contact');
        });
        $("#error-msg-contact-form").show('fast');
        return false;
    });
}


function registerMedicalPracticeFormButtons() {
    $(".medicalPracticeForms").submit(function (e) {
        var formId = $(this).attr("id");
        $(".error-msg").remove();
        $("#error-msg-" + formId + "-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            if (response.status == "SUCCESS")
                actionSuccess();
            showAjaxResponse(response, formId);
        }).fail(function (error) {
            showAjaxError(error, formId);
        });
        $("#error-msg-" + formId + "-form").show('fast');
        return false;
    });
}

function addMedicalPractice() {
    $.ajax({
        url: "/" + appLocation + "addMedicalPractice",
        context: document.body,
        type: 'post'
    }).done(function (response) {
        if (response.status == "SUCCESS") {
            actionSuccess();
            location.reload();
            /*router.navigate(appLocation + 'MedicalPractices' ,{trigger:true});*/
        } else {
            actionFail();
        }
    }).fail(function () {
        actionFail();
    })
}


function deleteMedicalPractice(medicalPracticeId) {
    customConfirm("Bitte bestätigen", "Sind Sie sicher, dass Sie diese Ordination löschen/archivieren möchten? Sie können diese Ordination anschließend nicht mehr aktiv nutzen.", function () {
        $.ajax({
            url: "/" + appLocation + "deleteMedicalPractice",
            context: document.body,
            type: 'post',
            data: {'medicalPracticeId': medicalPracticeId}
        }).done(function () {
            actionSuccess();
            location.reload();
        }).fail(function () {
            actionFail();
        })
    });
}

function registerChangePasswordButton() {
    $("#changePasswordForm").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-password-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, "password");
            //Load something after submit
        }).fail(function (error) {
            showAjaxError(error, "password");
        });
        $("#error-msg-password-form").show('fast');
        return false;
    });
}

function registerPersonalizationFormSubmitButton() {
    $("#personalizationForm").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-personalization-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {

            if (response.status == 'SUCCESS') {
                actionSuccess();
            } else {
                actionFail(response.result);
            }

            showAjaxResponse(response, 'personalization');
        }).fail(function (error) {
            showAjaxError(error, 'personalization');
        });
        $("#error-msg-personalization-form").show('fast');
        return false;
    });
}


function registerDocumentStyleInformationFormSubmitButton() {
    $("#documentStyleInformationForm").submit(function (e) {
        $(".error-msg").remove();
        $("#error-msg-documentStyleInformation-form").hide();
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            showAjaxResponse(response, 'documentStyleInformation');
        }).fail(function (error) {
            showAjaxError(error, 'documentStyleInformation');
        });
        $("#error-msg-documentStyleInformation-form").show('fast');
        return false;
    });
}

function registerProfilePictureFormButton() {
    $("#profilePictureForm").submit(function (e) {
        $("#error-msg-profile-picture-form").hide();
        $("#submitProfilePictureButton").prop('disabled', true);
        $("#submitProfilePictureButton").prop('value', "Bitte warten ...");

        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'profile-picture');
            if (response.status == 'SUCCESS') {
                updateProfilePic(response.result);
            } else if (response.status == 'RESET') {
                updateProfilePic(response.result);
            }

            $("#submitProfilePictureButton").prop('disabled', false);
            $("#submitProfilePictureButton").prop('value', "Senden");

            $("#error-msg-profile-picture-form").show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'profile-picture');
            $("#submitProfilePictureButton").prop('disabled', false);
            $("#submitProfilePictureButton").prop('value', "Senden");

            $("#error-msg-profile-picture-form").show('fast');
        });

        return false;
    });
}


function updateProfilePic(url) {
    if (url.startsWith('http')) {
        $(".profile-pic").css("background-image", "url('" + url + "')");
        $(".profile-pic-doctorMenu").css("background-image", "url('" + url + "')");
    } else {
        $(".profile-pic").css("background-image", "url('/" + appLocation + url + "')");
        $(".profile-pic-doctorMenu").css("background-image", "url('/" + appLocation + url + "')");
    }
}


function registerGroupProfilePictureFormButton(accountId) {
    $("#groupProfilePictureForm").submit(function (e) {
        $("#error-msg-groupProfile-picture-form").hide();
        $("#submitGroupProfilePictureButton").prop('disabled', true);
        $("#submitGroupProfilePictureButton").prop('value', "Bitte warten ...");

        var formData = new FormData(this);
        formData.append('accountId', accountId);
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'groupProfile-picture');
            if (response.status == 'SUCCESS') {
                updateGroupProfilePic(response.result);
            } else if (response.status == 'RESET') {
                updateGroupProfilePic(response.result);
            }

            $("#submitGroupProfilePictureButton").prop('disabled', false);
            $("#submitGroupProfilePictureButton").prop('value', "Profilbild aktualisieren");

            $("#error-msg-groupProfile-picture-form").show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'profile-picture');
            $("#submitGroupProfilePictureButton").prop('disabled', false);
            $("#submitGroupProfilePictureButton").prop('value', "Profilbild aktualisieren");

            $("#error-msg-groupProfile-picture-form").show('fast');
        });

        return false;
    });
}

function updateGroupProfilePic(url) {
    if (url.startsWith('http')) {
        $("groupProfile-pic").css("background-image", "url('" + url + "')");
    } else {
        $(".groupProfile-pic").css("background-image", "url('/" + appLocation + url + "')");
    }
}


function uploadCustomImage(event, htmlForm, dsiId) {
    event.preventDefault();
    var form = $(htmlForm);
    var submitButton = form.find(':submit');
    var htmlError = form.find(".error-msg-customImage-form");
    htmlError.hide();
    submitButton.prop('disabled', true);
    submitButton.prop('value', "Bitte warten ...");
    var formData = new FormData(htmlForm);
    $.ajax({
        url: form.attr("action"),
        context: document.body,
        type: 'post',
        data: formData,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        showAjaxResponseWithSelector(response, htmlError);
        submitButton.prop('disabled', false);
        submitButton.prop('value', "Aktualisieren");
        htmlError.show('fast');
        var data = form.data("content");
        var url = response.result;
        if (url != null) {
            loadToMainContentDiv("Profile", null, function () {
                $('#profileTabList a[href="#documentStyleInformation"]').tab("show");
                    window.setTimeout(function () {
                        $('#dsiMedicalPracticeTabList a[href="#medicalPracticeDSI' + dsiId + '"]').on('shown.bs.tab', function (e) {
                            document.querySelector("." + data + "_" + dsiId + "_LIST").scrollIntoView({ behavior: 'auto', block: 'center' });
                        });
                        $('#dsiMedicalPracticeTabList a[href="#medicalPracticeDSI' + dsiId + '"]').tab("show");
                    }, 500);
            });
        }
    }).fail(function (error) {
        showAjaxErrorOn(error, htmlError);
        submitButton.prop('disabled', false);
        submitButton.prop('value', "Aktualisieren");
        htmlError.show('fast');
    });
    return false;
}

function toggleCustomImageActive(dsiId, customImageId) {
    var formData = {
        customImageId: customImageId,
        dsiId: dsiId
    };
    $.ajax({
        url: "/" + appLocation + "toggleImageActive",
        type: 'POST',
        data: formData
    }).done(function (response) {
        showAjaxResponse(response, 'dsi' + dsiId);
    }).fail(function (error) {
        showAjaxError(error, 'dsi' + dsiId);
    });
    return false;
}

function deleteImage(button, dsiId, customImageId) {
    var formData = {
        customImageId: customImageId,
        dsiId: dsiId
    };
    $.ajax({
        url: "/" + appLocation + "deleteCustomImage",
        type: 'POST',
        data: formData
    }).done(function (response) {
        showAjaxResponse(response, 'dsi' + dsiId);
        $("#imagerow" + customImageId).remove();
    }).fail(function (error) {
        showAjaxError(error, 'dsi' + dsiId);
    });
    return false;
}

function updateDocumentHeader(url, dsiId) {
    if (url == null) {
        $("#document-header-pic" + dsiId).attr("src", "");
        $("#document-header-pic" + dsiId).attr("alt", "Kein Bild vorhanden. Standard Briefkopf (Name + Adresse) wird erstellt.");
    } else {
        $("#document-header-pic" + dsiId).attr("src", "/" + appLocation + url);
        $("#document-header-pic" + dsiId).attr("alt", "");
    }
}

function registerLogoFormButton(dsiId) {
    $("#logoForm" + dsiId).submit(function (e) {
        $("#error-msg-logo" + dsiId + "-form").hide();
        $("#submitLogoButton" + dsiId).prop('disabled', true);
        $("#submitLogoButton" + dsiId).prop('value', "Bitte warten ...");

        // alert($(this).attr("action"));
        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'logo' + dsiId);
            if (response.status == 'SUCCESS') {
                updateLogoImage(response.result, dsiId);
            } else if (response.status == 'RESET') {
                updateLogoImage(response.result, dsiId);
            }

            $("#submitLogoButton" + dsiId).prop('disabled', false);
            $("#submitLogoButton" + dsiId).prop('value', "Aktualisieren");

            $("#error-msg-logo" + dsiId + "-form").show('fast');
            $("#logos" + dsiId + " .images-list").load("/" + appLocation + "listImages/" + dsiId + "/LOGO");
        }).fail(function (error) {
            showAjaxError(error, 'logo' + dsiId);
            $("#submitLogoButton" + dsiId).prop('disabled', false);
            $("#submitLogoButton" + dsiId).prop('value', "Aktualisieren");

            $("#error-msg-logo" + dsiId + "-form").show('fast');
        });

        return false;
    });
}

function updateLogoImage(url, dsiId) {
    if (url == null) {
        $("#logo-pic" + dsiId).attr("src", "");
        $("#logo-pic" + dsiId).attr("alt", "Kein Bild vorhanden");
    } else {
        $("#logo-pic" + dsiId).attr("src", "/" + appLocation + url);
        $("#logo-pic" + dsiId).attr("alt", "");
    }
}

function registerStampFormButton(dsiId) {
    $("#stampForm" + dsiId).submit(function (e) {
        $("#error-msg-stamp" + dsiId + "-form").hide();
        $("#submitStampButton" + dsiId).prop('disabled', true);
        $("#submitStampButton" + dsiId).prop('value', "Bitte warten ...");

        // alert($(this).attr("action"));
        var formData = new FormData(this);
        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            showAjaxResponse(response, 'stamp' + dsiId);
            if (response.status == 'SUCCESS') {
                updateStamp(response.result, dsiId);
            } else if (response.status == 'RESET') {
                updateStamp(response.result, dsiId);
            }

            $("#submitStampButton" + dsiId).prop('disabled', false);
            $("#submitStampButton" + dsiId).prop('value', "Aktualisieren");
            $("#stamps" + dsiId + " .images-list").load("/" + appLocation + "listImages/" + dsiId + "/STAMP");

            $("#error-msg-stamp" + dsiId + "-form").show('fast');
        }).fail(function (error) {
            showAjaxError(error, 'stamp' + dsiId);
            $("#submitStampButton" + dsiId).prop('disabled', false);
            $("#submitStampButton" + dsiId).prop('value', "Aktualisieren");

            $("#error-msg-stamp" + dsiId + "-form").show('fast');
        });

        return false;
    });
}


function updateStamp(url, dsiId) {
    if (url == null) {
        $("#stamp-pic" + dsiId).attr("src", "");
        $("#stamp-pic" + dsiId).attr("alt", "Kein Bild vorhanden. Standard Stempel (Name + Adresse) wird erstellt.");
    } else {
        $("#stamp-pic" + dsiId).attr("src", "/" + appLocation + url);
        $("#stamp-pic" + dsiId).attr("alt", "");
    }
}


/* default method to set a calendar setting */
function setSettingForDSI(dsiId, setting, value, successCallback) {
    if ($('#error-msg-dsi' + dsiId + '-' + setting).text()) {
        $('#error-msg-dsi' + dsiId + '-' + setting).html('');
    }
    $.ajax({
        url: "/" + appLocation + "setSettingForDSI",
        context: document.body,
        type: 'post',
        data: {'dsiId': dsiId, 'setting': setting, 'value': value}
    }).done(function () {
        actionSuccess();
        if (typeof successCallback === "function")
            successCallback();
    }).fail(function (e) {
        if (e && e.responseText) {
            if ($('#error-msg-dsi' + dsiId + '-' + setting)) {
                actionFail();
                showResponseEntityMessage('dsi' + dsiId + '-' + setting, e.responseText);
            } else {
                actionFail(e.responseText);
            }
        } else {
            actionFail();
        }
    })
}

function toggleBtnGroup(btnGroupToggle) {
    var btnGroup = btnGroupToggle;

    btnGroup.find('.btn').toggleClass('active');

    if (btnGroup.find('.btn-primary').length > 0) {
        btnGroup.find('.btn').toggleClass('btn-primary');
    }
    if (btnGroup.find('.btn-danger').length > 0) {
        btnGroup.find('.btn').toggleClass('btn-danger');
    }
    if (btnGroup.find('.btn-success').length > 0) {
        btnGroup.find('.btn').toggleClass('btn-success');
    }
    if (btnGroup.find('.btn-info').length > 0) {
        btnGroup.find('.btn').toggleClass('btn-info');
    }

    btnGroup.find('.btn').toggleClass('btn-default');
}

function setSettingAndReload(setting, value, btnGroupToggle) {

    if (btnGroupToggle)
        toggleBtnGroup(btnGroupToggle);

    $.ajax({
        url: "/" + appLocation + "setSetting",
        context: document.body,
        type: 'post',
        data: {'setting': setting, 'value': value}
    }).done(function () {
        location.reload();
    }).fail(function (e) {
        actionFail(e);
    })
}


/**
 * text blocks
 * */

function newTextBlockWithContent(content) {
    $("#TextBlockModalReplace").load("/" + appLocation + "getTextBlockModal/-1?content=" + encodeURI(content), function () {
        $("#TextBlockModal").modal({backdrop: 'static', keyboard: false});
        registerTextBlockModalSubmitButton();
    });
}


//load and show the modal to create or update
function saveTextBlock(textBlockId) {
    if (!textBlockId) {
        textBlockId = -1;
    }

    $("#TextBlockModalReplace").load("/" + appLocation + "getTextBlockModal/" + textBlockId, function () {
        $("#TextBlockModal").modal({backdrop: 'static', keyboard: false});
        registerTextBlockModalSubmitButton();
    });
}

//what happens when user clicks on create
function registerTextBlockModalSubmitButton() {
    $("#textBlockForm").submit(function (e) {
        //prevent double submitting
        var submitButtonId = "textBlockModalSubmitButton";
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-textBlockModal-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $("#error-msg-textBlockModal-form").show('fast');
            showAjaxResponse(response, 'textBlockModal');
            $("#textBlockModalSubmitButton").prop("disabled", false);
            $("#textBlockModalSubmitButton").prop('value', "Speichern");

            if (response.status == 'SUCCESS') {
                $('#TextBlockModal').modal("hide");
                load("TextBlocks");
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $("#error-msg-textBlockModal-form").show('fast');
            showAjaxError(error, 'textBlockModal');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function copyTextBlock(textBlockId) {
    $.ajax({
        url: "/" + appLocation + "copyTextBlock",
        context: document.body,
        type: 'post',
        data: {'textBlockId': textBlockId}
    }).done(function () {
        actionSuccess();
        load("TextBlocks");
    }).fail(function () {
        actionFail();
    })
}

function deleteTextBlock(textBlockId) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diesen Textbaustein löschen möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteTextBlock",
                context: document.body,
                type: 'post',
                data: {'textBlockId': textBlockId}
            }).done(function () {
                actionSuccess();
                load("TextBlocks");
            }).fail(function () {
                actionFail();
            })
        }
    );
}

function appendTextToTextarea(e) {
    var text = $(e).attr("value");
    var textareaId = $(e).attr("name");
    appendTextToTextareaWithId(textareaId,decodeHtml(text))
}

function appendTextToTextareaWithId(textareaId, text){
    var currentText = $("#" + textareaId).val();
    $("#" + textareaId).val((currentText == '' ? '' : currentText + ' ') + text);
}

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function replaceTextInTextarea(text, textareaId) {
    var currentText = $("#" + textareaId).val();
    if (currentText) {
        if (confirm("Wollen Sie den aktuellen Inhalt im Textfeld wirklich überschreiben?")) {
            $("#" + textareaId).val(text);
        }
    } else {
        $("#" + textareaId).val(text);
    }
    $(".complexTypeSelectorClass").hide();
}

function replaceTextInTextareas(text1, textarea1Id, text2, textarea2Id) {
    var currentText1 = $("#" + textarea1Id).val();
    var currentText2 = $("#" + textarea2Id).val();
    if (currentText1 || currentText2) {
        if (confirm("Wollen Sie den aktuellen Inhalt in den Textfeldern wirklich überschreiben?")) {
            $("#" + textarea1Id).val(text1);
            $("#" + textarea2Id).val(text2);
        }
    } else {
        $("#" + textarea1Id).val(text1);
        $("#" + textarea2Id).val(text2);
    }
    $(".complexTypeSelectorClass").hide();
}

function replaceTextInTextareaComplexType(text, textareaId, complexTypeSelector) {
    var currentText = $("#" + textareaId).val();
    if (currentText) {
        if (confirm("Wollen Sie den aktuellen Inhalt im Textfeld wirklich überschreiben?")) {
            $("#" + textareaId).val(text);

        }
    } else {
        $("#" + textareaId).val(text);
    }
    $(".complexTypeSelectorClass").hide();
    $("#" + complexTypeSelector).show();
}

function addTextFromTextBlockToInput(patientId, textBlockId, textareaId) {
    var currentText = $("#" + textareaId).val();

    if (patientId == null) {
        patientId = $('#messageReceiverValue').val();
        if (!patientId)
            patientId = -1;
    }

    $.ajax({
        url: "/" + appLocation + "getTextForTextBlock/" + textBlockId + "/" + patientId,
        context: document.body,
        type: 'get'
    }).done(function (textForTextBlock) {
        var newText = (currentText == '' ? '' : currentText + ' ') + textForTextBlock;
        $("#" + textareaId).val(newText).change(); // trigger the onChange event
    }).fail(function () {
        actionFail();
    })
}

function addTextFromTextBlock(patientId, textBlockId, textareaId) {
    var currentText = $("#" + textareaId).val();

    if (patientId == null) {
        patientId = $('#messageReceiverValue').val();
        if (!patientId)
            patientId = -1;
    }

    $.ajax({
        url: "/" + appLocation + "getTextForTextBlock/" + textBlockId + "/" + patientId,
        context: document.body,
        type: 'get'
    }).done(function (textForTextBlock) {
        var newText = (currentText == '' ? '' : currentText + ' ') + textForTextBlock;
        $("#" + textareaId).val(newText);
        $("#" + textareaId).summernote('code', newText);
        $("#" + textareaId).summernote('fontName', 'Helvetica');

    }).fail(function () {
        actionFail();
    })

}

function deleteEntry(ctx) {
    $(ctx).closest("li").remove();
}

function editEntry(ctx) {
    var itemInput = $(ctx).closest('li').find("input[type='text']");
    var itemDisplay = $(ctx).closest('li').find(".valuedisplay");

    var itemSpan = $(ctx).find('span');

    if (itemInput.is(":visible")) {
        itemDisplay.html(itemInput.val());
        itemSpan.removeClass("glyphicon-ok");
        itemSpan.addClass("glyphicon-edit");
        $(ctx).removeClass("btn-success");
        $(ctx).addClass("btn-link");
    } else {
        itemSpan.removeClass("glyphicon-edit");
        itemSpan.addClass("glyphicon-ok");
        $(ctx).addClass("btn-success");
        $(ctx).removeClass("btn-link");
    }

    itemInput.toggle();
    itemDisplay.toggle();
}


function initDraggable(id, name) {

    var selector = $(id);
    selector.sortable({
        appendTo: selector,
        // placeholder: "glyphicon glyphicon-ok",
        update: function (event, ui) {
            $(id + " li").each(function (index, element) {
                $(element).find("input").attr("name", name + '[' + index + ']');
            });
        }
    });
}

function addToList(evt, id, name, edit) {
    var value = "";
    var displayValue = "";
    if (evt.tagName == "SELECT") {
        value = $(evt).val();
        displayValue = $(evt).find(":selected").text();
    } else if (evt.tagName == 'BUTTON' || event.key === "Enter") {
        displayValue = value = $("#datalistItemsValue" + id).val().trim();
        $("#datalistItemsValue" + id).val("");
    } else {
        return;
    }

    name = name !== undefined ? name.trim() : "";

    if (value === "" || name == "") return;

    var length = $('ul#list' + id + ' li').length;

    var item =
        '<li class="list-group-item"  style="border-left: 15px solid #666666">' +
        '   <div class="row">' +
        '   <div class="col-md-10">' +
        '       <span style="line-height: 2" class="valuedisplay">' + displayValue + '</span>' +
        '       <input class="form-control" type="text" style="display: none" name="' + name + '[' + length + ']" placeholder="Zusätzliche Fachrichtungen oder Fortbildungen" value="' + value + '"/>' +
        '   </div>' +
        '   <div class="col-md-2">' +
        '       <button type="button" style="margin-left: 4px" class="btn btn-link pull-right" onclick="deleteEntry(this)"><span class="glyphicon glyphicon-trash"></span></button>';
    if (edit) {
        item += '<button type="button" class="btn btn-link pull-right" onclick="editEntry(this)"><span class="glyphicon glyphicon-edit"></span></button>';
    }
    item += '</div>' +
        '</div>' +
        '</li>';

    $("#list" + id).append(item);
}


//load and show the modal to create or update
function saveDocumentType(documentTypeId) {
    if (!documentTypeId) {
        documentTypeId = -1;
    }

    $("#DocumentTypeModalReplace").load("/" + appLocation + "getDocumentTypeModal/" + documentTypeId, function () {
        $("#DocumentTypeModal").modal({backdrop: 'static', keyboard: false});
        registerDocumentTypeModalSubmitButton();
    });
}

//what happens when user clicks on create
function registerDocumentTypeModalSubmitButton() {
    $("#documentTypeForm").submit(function (e) {
        //prevent double submitting
        var submitButtonId = "documentTypeModalSubmitButton";
        var oldButtonText = setButtonInWaitingMode(submitButtonId);

        //remove old error messages after resubmit
        $(".error-msg").remove();
        $("#error-msg-documentTypeModal-form").hide();

        $.ajax({
            url: $(this).attr("action"),
            context: document.body,
            type: 'post',
            data: $(this).serialize()
        }).done(function (response) {
            $("#error-msg-documentTypeModal-form").show('fast');
            showAjaxResponse(response, 'documentTypeModal');
            $("#documentTypeModalSubmitButton").prop("disabled", false);
            $("#documentTypeModalSubmitButton").prop('value', "Speichern");

            if (response.status == 'SUCCESS') {
                $('#DocumentTypeModal').modal("hide");
                load("DocumentTypes");
            } else {
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }
        }).fail(function (error) {
            $("#error-msg-documentTypeModal-form").show('fast');
            showAjaxError(error, 'documentTypeModal');
            wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
        });
        return false;
    });
}

function deleteDocumentType(documentTypeId) {
    customConfirm("Bestätigen", "Sind Sie sicher, dass Sie diesen Dokument-Typ löschen möchten?",
        function () {
            $.ajax({
                url: "/" + appLocation + "deleteDocumentType",
                context: document.body,
                type: 'post',
                data: {'documentTypeId': documentTypeId}
            }).done(function () {
                actionSuccess();
                load("DocumentTypes");
            }).fail(function (e) {
                if (e && e.responseText) {
                    actionFail(e.responseText);
                } else {
                    actionFail();
                }
            })
        }
    );
}


function removeExternalAccount(account) {
    customConfirm("Bestätigen", "Sind sie sicher, dass sie diesen Account" +
        (account.getAttribute("subscriptioncount") > 0 ? " (und " + account.getAttribute("subscriptioncount") + " verbundene Kalender)" : "") +
        " löschen möchten?", function () {
        $.ajax({
            url: "/" + appLocation + "oauth/unauth",
            context: document.body,
            type: "post",
            data: {"accountID": account.getAttribute("accID")}
        }).done(function () {
            actionSuccess();
            location.reload();
        }).fail(function(e) {
            actionFail();
        });
    });
}

function addICloudAccount(shared) {
    var submitButtonId = shared ? "addICloudAccountButtonPublic": "addICloudAccountButtonPrivate";
    var oldButtonText = setButtonInWaitingMode(submitButtonId);

    var appleID = $("#apple-login-appleID").val();
    var password = $("#apple-login-password").val();

    var ok = true;
    if (appleID === "") {
        $("#error-msg-appleID").html(
            "<div class='alert alert-danger alert-dismissible' role='alert'>" +
            "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
            "Dieses Feld darf nicht leer sein." +
            "</div>"
        );
        ok = false;
    }
    if (password === "") {
        $("#error-msg-applePassword").html(
            "<div class='alert alert-danger alert-dismissible' role='alert'>" +
            "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
            "Dieses Feld darf nicht leer sein." +
            "</div>"
        );
        ok = false;
    }

    if (ok === false) return;

    $.ajax({
        url: "/" + appLocation + "auth/apple",
        context: document.body,
        type: "post",
        data: { 
            "shared": shared,
            "appleID": appleID,
            "password": password
        }
    }).done(function() {
        actionSuccess();
        location.reload();
    }).fail(function(e) {
        actionFail();
        wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
    })
}

function setMPAPISetting(setting, value) {
    $.ajax({
        url: "/" + appLocation + "mp/api-setting",
        context: document.body,
        type: "post",
        data: { 
            "setting": setting,
            "value": value
        }
    }).done(function() {
        actionSuccess()
    }).fail(function(e) {
        actionFail();
    })
}


function toggleAllowBroadcastMessages(treatingDoctorId){
    $.ajax({
        url: "/" + appLocation + "toggleAllowBroadcastMessages",
        context: document.body,
        type: 'post',
        data: {'treatingDoctorId': treatingDoctorId}
    }).done(function () {
        actionSuccess();
    }).fail(function () {
        actionFail();
    });
}