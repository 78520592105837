/**
 * Zeigt ein benutzerdefiniertes Bestätigungsdialogfeld im Bootstrap-Stil an.
 *
 * @param {string} title - Der Titel des Dialogfelds.
 * @param {string} message - Die Nachricht, die im Dialogfeld angezeigt wird.
 * @param {Function} successFunction - Die Funktion, die ausgeführt wird, wenn der Benutzer bestätigt.
 * @param {Function} [deniedFunction] - Die Funktion, die ausgeführt wird, wenn der Benutzer ablehnt.
 * @param {string} [confirmLabel="Einverstanden"] - Der Text für die Bestätigungsschaltfläche.
 * @param {string} [deniedLabel="Ablehnen"] - Der Text für die Ablehnungsschaltfläche.
 */
function customConfirm(title, message, successFunction, deniedFunction, confirmLabel = "Einverstanden", deniedLabel = "Ablehnen") {
    bootbox.dialog({
        message: message,
        title: title,
        buttons: {
            success: {
                label: confirmLabel,
                className: "btn-success",
                callback: function (e) {
                    //prevent double submitting
                    this.find(".btn-success").attr("disabled", true);
                    this.find(".btn-success").html("Bitte warten .....");

                    if (successFunction == null) {
                        //do nothing
                    } else if (typeof successFunction === "function") successFunction();
                    else alert("Success, but invalid callback");
                },
            },
            danger: {
                label: deniedLabel,
                className: "btn-danger",
                callback: function () {
                    //prevent double submitting
                    this.find(".btn-danger").attr("disabled", true);
                    this.find(".btn-danger").html("Bitte warten .....");

                    if (deniedFunction == null) {
                        //do nothing
                    } else if (typeof deniedFunction === "function") deniedFunction();
                    else alert("Denied, but invalid callback");
                },
            },
        },
    });
}

/**
 * Zeigt ein benutzerdefiniertes Informationsdialogfeld im Bootstrap-Stil an.
 *
 * @param {string} title - Der Titel des Dialogfelds.
 * @param {string} message - Die Nachricht, die im Dialogfeld angezeigt wird.
 */
function customModalInfo(title, message) {
    bootbox.dialog({
        message: message,
        title: title,
        buttons: {
            close: {
                label: "Schließen",
                className: "btn-primary",
            }
        },
    });
}

/**
 * Zeigt eine Erfolgsmeldung an und stoppt die Ladeanimation.
 * @param {number} [timeOut=3000] - Die Zeit in Millisekunden, nach der die Erfolgsmeldung ausgeblendet wird.
 */
function actionSuccess(timeOut = 3000) {
    $("#actionSuccessElement").toggle("highlight");
    actionStopLoading(); //always stop loading on success
    setTimeout(function () {
        $("#actionSuccessElement").toggle("highlight");
    }, timeOut);
}

/**
 * Startet die Ladeanimation und setzt ein Timeout, um sie nach 60 Sekunden zu stoppen.
 */
function actionStartLoading() {
    $("#nav-bar-loading-spinner").show();
    // hide after 60 seconds to avoid showing an infitly spinning loader
    setTimeout(function () {
        //only hide + failMessage if still visible
        if ($("#nav-bar-loading-spinner").is(":visible")) {
            actionStopLoading("Timeout: Bitte aktualisieren Sie die Seite und versuchen Sie es erneut!")
        }
    }, 60000);
}

/**
 * Stoppt die Ladeanimation und zeigt optional eine Fehlermeldung an.
 *
 * @param {string} [message] - Die Fehlermeldung, die angezeigt werden soll.
 */
function actionStopLoading(message) {
    if (message) {
        actionFail(message)
    }
    $("#nav-bar-loading-spinner").hide();
}

/**
 * Zeigt eine Fehlermeldung an und stoppt die Ladeanimation.
 *
 * @param {string} [message] - Die Fehlermeldung, die angezeigt werden soll.
 */
function actionFail(message) {
    $("#actionFailElement").toggle("highlight");
    actionStopLoading(); //always stop loading on failure
    if (message != null && message != "") {
        $("#actionFailText").html("Fehler: " + message);
    }
    setTimeout(function () {
        $("#actionFailElement").toggle("highlight");
    }, 10000);
}

/**
 * Hebt ein unsaved-Wert-Element visuell hervor.
 *
 * @param {string} id - Die ID des Elements, das hervorgehoben werden soll.
 */
function highlightUnsavedValue(id) {
    $("#" + id).addClass("unsavedValue");
}

/**
 * Entfernt die Hervorhebung eines unsaved-Wert-Elements.
 *
 * @param {string} id - Die ID des Elements, dessen Hervorhebung entfernt werden soll.
 */
function unhighlightSavedValue(id) {
    $("#" + id).removeClass("unsavedValue");
}


/**
 * Wechselt zwischen Vollbild und normalem Bildschirmmodus.
 */
function toggleFullscreen() {
    const elem = document.documentElement;
    if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
    ) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
}


/**
 * Schließt alle geöffneten Modals.
 */
function closeModals() {
    $(".modal").modal("hide");
}

/**
 * Kopiert den angegebenen Text in die Zwischenablage.
 *
 * Diese Hilfsfunktion kapselt die Logik zur Nutzung von `document.execCommand("copy")`
 * sowie der Unterstützung für Internet Explorer. Sie sollte intern verwendet werden.
 *
 * @param {string} text - Der zu kopierende Text.
 * @returns {boolean} Gibt `true` zurück, wenn das Kopieren erfolgreich war, andernfalls `false`.
 */
function copyText(text) {
    if (!text) {
        console.warn("copyText: Kein Text übergeben.");
        return false;
    }

    // Spezieller Code für Internet Explorer (IE10+)
    if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData("Text", text);
    }

    // Moderne Browser
    if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
    ) {
        const textarea = document.createElement("textarea");
        textarea.value = text;

        // Verhindert Scroll-Jumping in Edge / Safari
        textarea.style.position = "fixed";
        textarea.style.top = "-9999px";
        textarea.style.left = "-9999px";

        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        let successful = false;

        try {
            successful = document.execCommand("copy");
            if (!successful) {
                console.warn("copyText: Kopierbefehl fehlgeschlagen.");
            }
        } catch (err) {
            console.error("copyText: Ausnahme beim Kopieren.", err);
            successful = false;
        } finally {
            document.body.removeChild(textarea);
        }

        return successful;
    }

    console.warn("copyText: Kopieren wird in diesem Browser nicht unterstützt.");
    return false;
}

/**
 * Kopiert den Wert eines Input-Feldes in die Zwischenablage.
 *
 * Nach erfolgreichem Kopieren wird der zugehörige HelpBlock-Text aktualisiert.
 *
 * @param {jQuery} input - Das jQuery-Input-Element, dessen Wert kopiert werden soll.
 */
function copyToClipboard(input) {
    if (!input || input.length === 0) {
        console.warn("copyToClipboard: Ungültiges Input-Element übergeben.");
        return;
    }

    const value = input.val();

    const successful = copyText(value);

    if (successful) {
        const helpBlockId = `${input.attr("id")}HelpBlock`;
        $(`#${helpBlockId}`).text(
            "Der Link wurde in die Zwischenablage kopiert (Zum Einfügen: Strg + V)"
        );
        actionSuccess();
    } else {
        console.warn("copyToClipboard: Kopiervorgang war nicht erfolgreich.");
    }
}

/**
 * Kopiert einen übergebenen Textstring in die Zwischenablage.
 *
 * Nach erfolgreichem Kopieren wird `actionSuccess()` aufgerufen.
 *
 * @param {string} text - Der zu kopierende Text.
 * @returns {boolean} Gibt `true` zurück, wenn das Kopieren erfolgreich war, andernfalls `false`.
 */
function copyTextToClipboard(text) {
    const successful = copyText(text);

    if (successful) {
        actionSuccess();
    }

    return successful;
}


function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector(selector));
            }
        });

        // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

/**
 * Zeigt einen Ladeindikator (Loader) in einem angegebenen HTML-Container an.
 *
 * @param {string|HTMLElement|jQuery} block - Das Ziel-Element oder der Selektor,
 *                                             in dem der Ladeindikator angezeigt werden soll.
 */
function showLoaderIn(block) {
    $(block).html(
        "<div>" +
        "<img src='/" +
        appLocation +
        "resources/images/loader.gif' alt='Lade Daten...'/>" +
        "</div>"
    );
}

/**
 * Entfernt den Ladeindikator (Loader) aus dem angegebenen HTML-Container.
 *
 * @param {string|HTMLElement|jQuery} block - Das Ziel-Element oder der Selektor,
 *                                             aus dem der Ladeindikator entfernt werden soll.
 */
function hideLoaderIn(block) {
    $(block).html("");
}

/**
 * Enthält die Zuordnung von Checkbox-IDs zu den zugehörigen Button- und Text-Elementen.
 * Jede Checkbox aktiviert/deaktiviert einen Button und manipuliert ein Text-Element.
 */
const agreementMappings = {
    newConcern: {
        buttonSelector: "#concernCommitButton",
        textSelector: "#agreeText"
    },
    submitOnlineBookingRequestCheckbox: {
        buttonSelector: "#onlineBookingRequestFormSubmitButton",
        textSelector: "#submitOnlineBookingRequestAgreeText"
    },
    externalCallRequestCheckbox: {
        buttonSelector: "#externalCallRequestCommitButton",
        textSelector: "#externalCallRequestAgreeText"
    },
    sendMediaCheckbox: {
        buttonSelector: "#sendMediaCommitButton",
        textSelector: "#sendMediaAgreeText"
    },
    newPrescriptionRequest: {
        buttonSelector: "#prescriptionRequestSubmitButton",
        textSelector: "#prescriptionRequestAgreeText"
    },
    referralRequestCheckbox: {
        buttonSelector: "#referralRequestCommitButton",
        textSelector: "#referralRequestAgreeText"
    },
    onlineConsultationCheckbox: {
        buttonSelector: "#onlineConsultationCommitButton",
        textSelector: "#onlineConsultationAgreeText"
    },
    signup: {
        buttonSelector: "#signupCommitButton",
        textSelector: "#signupAgreeText"
    },
    signupDoctor: {
        buttonSelector: "#signupDoctorCommitButton",
        textSelector: "#signupDoctorAgreeText"
    }
};

/**
 * Aktiviert oder deaktiviert Schaltflächen und kennzeichnet Pflichtfelder,
 * abhängig vom Status einer Checkbox.
 *
 * Zusätzlich werden Sonderfälle behandelt, wie z.B. die Abwesenheitsbenachrichtigung.
 *
 * @param {HTMLInputElement} checkbox - Die Checkbox, deren Status überprüft wird.
 */
function agree(checkbox) {
    const mapping = agreementMappings[checkbox.id];

    if (mapping) {
        const {buttonSelector, textSelector} = mapping;

        if (checkbox.checked) {
            $(buttonSelector).removeAttr("disabled");
            $(textSelector).removeClass("mandatoryField");
        } else {
            $(buttonSelector).attr("disabled", "disabled");
            $(textSelector).addClass("mandatoryField");
        }
    } else if (checkbox.id === "absenceAutoReply") {
        handleAbsenceAutoReply(checkbox.checked);
    } else {
        console.warn(`agree(): Keine Mapping-Konfiguration für Checkbox-ID "${checkbox.id}" gefunden.`);
    }
}

/**
 * Behandelt den Sonderfall für die Abwesenheitsbenachrichtigung (Auto-Reply).
 *
 * @param {boolean} isChecked - Gibt an, ob die Checkbox aktiviert wurde.
 */
function handleAbsenceAutoReply(isChecked) {
    const absenceText = $("#absenceText");
    const startOfAbsence = $("#startOfAbsence");
    const endOfAbsence = $("#endOfAbsence");

    if (isChecked) {
        absenceText.addClass("mandatoryField").html("Aktiviert");
        startOfAbsence.removeAttr("readonly");
        endOfAbsence.removeAttr("readonly");
    } else {
        absenceText.removeClass("mandatoryField").html("Nicht Aktiviert");
        startOfAbsence.attr("readonly", "readonly").val("");
        endOfAbsence.attr("readonly", "readonly").val("");
    }
}


/**
 * Blendet optionale Formularfelder eines bestimmten Typs ein oder aus
 * und aktualisiert den Text und das Icon entsprechend.
 *
 * @param {string} type - Ein Suffix zur Identifikation der Felder und Textelemente.
 * @param {string} [showText] - Der Text, der angezeigt werden soll, wenn die Felder eingeblendet werden.
 * @param {string} [hideText] - Der Text, der angezeigt werden soll, wenn die Felder ausgeblendet werden.
 * @param {string} [modalId] - Die ID eines Modals, dessen Layout nach dem Umschalten aktualisiert werden soll.
 */
function toggleOptionalFields(type, showText, hideText, modalId) {
    let fields = $(".optionalFields" + type);
    let text = $("#toggleTextForOptionalFields" + type);
    let icon = $("#chevronForOptionalFields" + type);

    fields.slideToggle(100, function () {
        if (modalId) {
            $("#" + modalId).modal("handleUpdate");
        }
    });

    if (text.text() === hideText || text.text() === "Weniger anzeigen") {
        text.text(showText || "Mehr anzeigen");
    } else {
        text.text(hideText || "Weniger anzeigen");
    }

    icon.toggleClass("glyphicon-chevron-down glyphicon-chevron-up");
}

/**
 * Blendet ein Collapse-Div ein oder aus und aktualisiert das Chevron-Icon.
 * Optional wird ein Modal-Layout aktualisiert, um das korrekte Layout beizubehalten.
 *
 * @param {string} collapseDivId - Die ID des Collapse-Containers.
 * @param {string} chevronId - Die ID des Chevron-Icons, das den Status anzeigt.
 * @param {string} [modalId] - Die ID eines Modals, dessen Layout nach dem Umschalten aktualisiert werden soll.
 */
function toggleCollapse(collapseDivId, chevronId, modalId) {
    if (modalId) {
        $("#" + collapseDivId).off("shown.bs.collapse");
        $("#" + collapseDivId).on("shown.bs.collapse", function () {
            $("#" + modalId).modal("handleUpdate");
        });
    }

    $("#" + collapseDivId).collapse("toggle");
    $("#" + chevronId).toggleClass("glyphicon-chevron-down glyphicon-chevron-up");
}

/**
 * Blendet ein Div-Element ein oder aus und aktualisiert das Chevron-Icon.
 *
 * @param {string} collapseDivId - Die ID des zu toggelnden Div-Containers.
 * @param {string} chevronId - Die ID des Chevron-Icons, das den Status anzeigt.
 */
function toggleDiv(collapseDivId, chevronId) {
    $("#" + collapseDivId).slideToggle();
    $("#" + chevronId).toggleClass("glyphicon-chevron-down glyphicon-chevron-up");
}

/**
 * Blendet ein Div-Element ein und setzt das Chevron-Icon auf "geöffnet".
 *
 * @param {string} collapseDivId - Die ID des einzublendenden Div-Containers.
 * @param {string} chevronId - Die ID des Chevron-Icons, das auf "geöffnet" gesetzt wird.
 */
function showDiv(collapseDivId, chevronId) {
    $("#" + collapseDivId).show();
    $("#" + chevronId).attr("class", "glyphicon glyphicon-chevron-up");
}

/**
 * Blendet ein Div-Element aus und setzt das Chevron-Icon auf "geschlossen".
 *
 * @param {string} collapseDivId - Die ID des auszublendenden Div-Containers.
 * @param {string} chevronId - Die ID des Chevron-Icons, das auf "geschlossen" gesetzt wird.
 */
function hideDiv(collapseDivId, chevronId) {
    $("#" + collapseDivId).hide();
    $("#" + chevronId).attr("class", "glyphicon glyphicon-chevron-down");
}


/**
 * Zeigt eine Fehlermeldung innerhalb eines bestimmten Containers an.
 *
 * Diese Funktion erzeugt eine Bootstrap-Fehlermeldung (alert-danger) in einem
 * Element mit der ID "error-msg-" + divClassName. Die Nachricht wird innerhalb
 * eines schließbaren Alert-Containers angezeigt.
 *
 * @param {string} divClassName - Der Klassenname (Suffix), der genutzt wird,
 *                                um das Ziel-Element anhand der ID zu identifizieren.
 * @param {string} message - Der Nachrichtentext, der im Fehler-Alert angezeigt wird.
 */
function showResponseEntityMessage(divClassName, message) {
    $("#error-msg-" + divClassName).html(
        "<div class='error-msg'>" +
        "<div class='alert alert-danger alert-dismissible' role='alert'>" +
        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
        "<span aria-hidden='true'>&times;</span></button>" +
        message +
        "</div></div>"
    );
}

/**
 * Zeigt eine AJAX-Response-Meldung in einem bestimmten HTML-Element an.
 *
 * Diese Funktion ist ein Wrapper für `showAjaxResponse()` und leitet das
 * gegebene `selector`-Element als Anzeigeziel weiter.
 *
 * @param {Object} response - Das JSON-Objekt, das den Status und die Resultate der Antwort enthält.
 * @param {jQuery} selector - Ein jQuery-Objekt oder DOM-Element, in dem die Nachricht angezeigt wird.
 * @param {string} [modalId] - Optional: Die ID eines geöffneten Modals, dessen Layout
 *                             nach der Anzeige aktualisiert werden soll.
 * @param {string} [customSuccessMessage] - Optional: Ein benutzerdefinierter Erfolgstext.
 */
function showAjaxResponseWithSelector(response, selector, modalId, customSuccessMessage) {
    showAjaxResponse(response, undefined, modalId, customSuccessMessage, selector);
}

/**
 * Verarbeitet die Antwort eines AJAX-Requests und zeigt eine entsprechende
 * Bootstrap-Alert-Meldung an.
 *
 * Unterstützt sowohl Erfolgsmeldungen als auch Fehlermeldungen. Der angezeigte Text
 * richtet sich nach dem Status der Response. Es können zusätzliche Details angezeigt
 * werden, wenn `response.result` enthalten ist.
 *
 * @param {Object} response - Die Antwort des AJAX-Requests. Muss ein `status`-Feld enthalten.
 * @param {string} [divClassName] - Optional: Der Klassenname zur Identifikation des Fehler-Containers.
 *                                  Falls nicht angegeben, wird stattdessen der `selector` verwendet.
 * @param {string} [modalId] - Optional: Die ID eines geöffneten Modals, das nach dem Anzeigen der Nachricht
 *                             ein Layout-Update benötigt.
 * @param {string} [customSuccessMessage] - Optional: Benutzerdefinierte Erfolgsmeldung, falls `status === 'SUCCESS'`.
 * @param {jQuery} [selector] - Optional: Ein jQuery-Element, das die Nachricht aufnehmen soll.
 */
function showAjaxResponse(response, divClassName, modalId, customSuccessMessage, selector) {
    let message = "";

    switch (response.status) {
        case "FAIL":
            if (response.result != null) {
                for (let i = 0; i < response.result.length; i++) {
                    $("#error-msg-" + divClassName + "-" + response.result[i].field).html(
                        "<div class='error-msg'>" +
                        "<div class='alert alert-danger alert-dismissible' role='alert'>" +
                        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
                        "<span aria-hidden='true'>&times;</span></button>" +
                        response.result[i].defaultMessage +
                        "</div></div>"
                    );
                }
            }
            message =
                "<div class='alert alert-danger alert-dismissible' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
                "<span aria-hidden='true'>&times;</span></button>" +
                "Das Formular enthält Fehler. Bitte überprüfen Sie Ihre Eingaben." +
                (typeof (response.result) === 'string' ? " Details: " + response.result : "") +
                "</div>";
            break;

        case "PASSWORD-FAIL":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Falsches Passwort. Bitte überprüfen Sie Ihre Eingaben." +
                "</div>";

            break;
        case "EMPTY-FILE-FAIL":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Kein Dokument ausgewählt. Bitte überprüfen Sie Ihre Eingaben." +
                "</div>";

            break;
        case "UPLOAD-DOCUMENT-FAIL":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Das Dokument konnte leider nicht hochgeladen werden. Bitte versuchen Sie es nochmal." +
                "</div>";
            break;
        case "IBAN-EMPTY-FAIL":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Sie haben die Option <strong>QR-Code mit Zahlungsinformationen auf Honorarnote</strong> drucken aktiviert aber keinen IBAN eingegeben! Details: <span>" +
                response.result +
                " </span>" +
                "</div>";
            break;
        case "UNKNOWN-CHAR-FAIL":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Sie haben ein Sonderzeichen oder einen Buchstaben verwendet, der von docsy leider nicht gedruckt werden kann. Meist treten solche Probleme bei kopierten Inhalten auf. Bitte überprüfen Sie Ihre eingegebenen Daten und bereinigen Sie etwaige Sonderzeichen. Details: <span>" +
                response.result +
                " </span>" +
                "</div>";

            break;
        case "DOCUMENT-WRONG-MIMETYPE-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Ungültiger Dateityp. Bitte nur PDF, PNG, JPEG, Video, Audio, oder Office-Dateien hochladen." +
                "</div>";

            break;
        case "PICTURE-WRONG-MIMETYPE-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Ungültiger Dateityp. Bitte nur PNG- oder JPEG-Dateien hochladen." +
                "</div>";
            break;
        case "MAX-FILE-SIZE-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Die Datei ist leider zu groß! Max. Datei Größe: 50 MB" +
                "</div>";
            break;
        case "RESET":
            message =
                "<div class='alert alert-success alert-dismissible' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Keine Datei ausgewählt. Profilbild wurde zurück gesetzt." +
                "</div>";

            break;
        case "EMPTY-DOCUMENT-NAME-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Kein Name angegeben. Bitte überprüfen Sie Ihre Eingaben." +
                "</div>";

            break;
        case "NO-AUTHOR-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Kein Autor angegeben. Bitte wählen Sie einen Autor für das Dokument aus." +
                "</div>";

            break;
        case "DUPLICATE-PUBLICPAGELINK-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Leider ist dieser Link für die öffentliche Seite bereits vergeben." +
                "</div>";

            break;
        case "EMAIL-NOT-FOUND-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Es wurde leider kein Account mit dieser E-Mail-Adresse gefunden." +
                "</div>";

            break;
        case "EMPTY-NAME":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Kein Name angegeben." +
                "</div>";

            break;
        case "NO-PACKAGE-ERROR":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Kein Paket angegeben. Bitte wählen Sie ein aufgeladenes Paket oder kaufen Sie ein neues." +
                "</div>";

            break;
        case "NO-CONCERN-TYPE-FAIL":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Bitte wählen Sie eine Fragekategorie aus." +
                "</div>";

            break;
        case "NO-RECEIVER-FAIL":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Bitte wählen Sie einen Empfänger aus." +
                "</div>";

            break;
        case "NO-ATTACHMENTS-BUT-SAID-SO":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Sie haben das Wort <strong>" +
                response.result +
                "</strong> verwendet - es wurde aber kein Anhang gefunden. Wollen Sie die Nachricht trotzdem senden?" +
                "</div>";

            $("#overruleMissingAttachmentWarning").val("true");
            break;
        case "ATTACHMENTS-WITHOUT-SIGNATURE":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Sie haben ein klinisches Dokument angehängt, das nicht vidiert oder digital signiert ist. Wollen Sie die Nachricht trotzdem senden?" +
                "</div>";

            $("#overruleUnsignedClinicalAttachmentWarning").val("true");
            break;
        case "APPOINTMENT-UNAVAILABLE":
            message =
                "<div class='alert alert-danger alert-dismissible ' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Dieser Termin ist leider nicht mehr verfügbar. Bitte wählen Sie einen neuen Termin aus." +
                "</div>";
            break;
        case "SUCCESS-FEEDBACK":
            message =
                "<div class='alert alert-success alert-dismissible' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                "Vielen Dank für Ihre Anfrage. Wir melden uns in Kürze bei Ihnen!" +
                "</div>";

            break;
        case "SUCCESS":
            message =
                "<div class='alert alert-success alert-dismissible' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" +
                (customSuccessMessage
                    ? customSuccessMessage
                    : "Ihre Angaben wurden gespeichert.") +
                "</div>";
            break;
    }

    if (divClassName) {
        $("#error-msg-" + divClassName + "-form").html(message);
    } else if (selector) {
        selector.html(message);
    }

    if (modalId) {
        $("#" + modalId).modal("handleUpdate");
    }
}

/**
 * Zeigt eine allgemeine Fehlermeldung bei AJAX-Anfragen in einem bestimmten Container an.
 *
 * Diese Funktion wird genutzt, wenn eine AJAX-Anfrage durch einen technischen Fehler
 * oder einen Serverfehler fehlgeschlagen ist.
 *
 * @param {string} error - Der Fehlertext oder die Fehlermeldung, die angezeigt werden soll.
 * @param {string} divClassName - Der Klassenname zur Identifikation des Fehler-Containers,
 *                                in dem die Meldung angezeigt wird.
 */
function showAjaxError(error, divClassName) {
    $("#error-msg-" + divClassName + "-form").html(
        "<div class='alert alert-danger alert-dismissible' role='alert'>" +
        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
        "<span aria-hidden='true'>&times;</span></button>" +
        "Server konnte Anfrage nicht bearbeiten!" +
        "<br/><br/>" +
        error +
        "</div>"
    );
}


/**
 * Zeigt eine allgemeine Fehlermeldung bei AJAX-Anfragen innerhalb eines beliebigen Containers an.
 *
 * Diese Funktion ist eine Variante von `showAjaxError()` und nimmt einen jQuery-Selektor als Ziel entgegen.
 *
 * @param {string} error - Der Fehlertext oder die Fehlermeldung, die angezeigt werden soll.
 * @param {jQuery} selector - Das Ziel-Element (jQuery-Objekt), in dem die Meldung angezeigt wird.
 */
function showAjaxErrorOn(error, selector) {
    selector.html(
        "<div class='alert alert-success alert-dismissible' role='alert'>" +
        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
        "<span aria-hidden='true'>&times;</span></button>" +
        "Server konnte Anfrage nicht bearbeiten!" +
        error +
        "</div>"
    );
}

