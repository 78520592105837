/**
 * Führt eine API-Anfrage aus.
 *
 * @param {string} method - Die HTTP-Methode (GET, POST, PUT, DELETE).
 * @param {string} url - Die URL der API.
 * @param {Object} [data] - Die Daten, die im Body der Anfrage gesendet werden sollen (für POST, PUT).
 * @param {Object} [options] - Zusätzliche Optionen wie headers und contentType.
 * @returns {Promise} - Ein Promise, das die Antwort der API enthält.
 */
function callApi(method, url, data = null, options = {}) {
    const {headers = {}, contentType = 'application/json; charset=utf-8', success = true} = options;

    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: method,
            context: document.body,
            contentType: contentType,
            data: data ? (contentType.includes('application/json') ? JSON.stringify(data) : data) : null,
            headers: headers,
            success: (response) => {
                if (success) actionSuccess();
                resolve(response)
            },
            error: (xhr, status, error) => reject({
                status: xhr.status,
                message: error || xhr.responseText
            })
        });
    });
}

/**
 * Führt eine GET-Anfrage aus.
 *
 * @param {string} url - Die URL der API.
 * @param {Object} [options] - Zusätzliche Optionen wie headers und contentType.
 * @returns {Promise} - Ein Promise, das die Antwort der API enthält.
 */
function get(url, options = {}) {
    return callApi('GET', url, null, options);
}

/**
 * Führt eine POST-Anfrage aus.
 *
 * @param {string} url - Die URL der API.
 * @param {Object} data - Die Daten, die im Body der Anfrage gesendet werden sollen.
 * @param {Object} [options] - Zusätzliche Optionen wie headers und contentType.
 * @returns {Promise} - Ein Promise, das die Antwort der API enthält.
 */
function post(url, data, options = {}) {
    return callApi('POST', url, data, options);
}

/**
 * Führt eine PATCH-Anfrage aus.
 *
 * @param {string} url - Die URL der API.
 * @param {Object} data - Die Daten, die im Body der Anfrage gesendet werden sollen.
 * @param {Object} [options] - Zusätzliche Optionen wie headers und contentType.
 * @returns {Promise} - Ein Promise, das die Antwort der API enthält.
 */
function patch(url, data, options = {}) {
    return callApi('PATCH', url, data, options);
}

/**
 * Führt eine DELETE-Anfrage aus.
 *
 * @param {string} url - Die URL der API.
 * @param {Object} [options] - Zusätzliche Optionen wie headers und contentType.
 * @returns {Promise} - Ein Promise, das die Antwort der API enthält.
 */
function del(url, options = {}) {
    return callApi('DELETE', url, null, options);
}

// Exportiere die Funktionen, falls du ein Modulsystem verwendest
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {callApi, get, post, patch, del};
}


/*
Beispielaufruf:


   try {
        const result = await post(`/${appLocation}startECardConnection/${patientId}`, eCard.cardData);
        actionSuccess();
        $("#currentPatientCardToken").val(result.cardToken);
        $(".requires-card-token").show();
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }

       const response = await post(`/${appLocation}upload`, formData, {
            contentType: 'multipart/form-data', // Benutzerdefinierter Content-Type
            headers: {
                'X-Custom-Header': 'value'
            }
        });


     const data = await get(`/${appLocation}patient/${patientId}`, {
            headers: {
                'Authorization': 'Bearer token'
            }
        });
 */