function createCashBox() {
    var accountId = $('#cashBoxModule #selectAccount').val();
    $.ajax({
        url: "/" + appLocation + "createCashBox",
        context: document.body,
        type: 'post',
        data: {'accountId': accountId}
    }).done(function () {
        actionSuccess();
        loadById('CashBoxes', accountId);
    }).fail(function () {
        actionFail();
    })
}

function resetCashBox(cashBoxId) {
    customConfirm("Bitte bestätigen", "Möchten Sie diese Registrierkasse unwiderruflich zurücksetzen? Alle Belege gehen verloren!",
        function () {
            var accountId = $('#cashBoxModule #selectAccount').val();
            $.ajax({
                url: "/" + appLocation + "resetCashBox",
                context: document.body,
                type: 'post',
                data: {'cashBoxId': cashBoxId}
            }).done(function () {
                actionSuccess();
                loadById('CashBoxes', accountId);
            }).fail(function () {
                actionFail();
            })
        });
}

function deleteCashBox(cashBoxId) {
    customConfirm("Bitte bestätigen", "Möchten Sie diese Registrierkasse unwiderruflich löschen?",
        function () {
            var accountId = $('#cashBoxModule #selectAccount').val();
            $.ajax({
                url: "/" + appLocation + "deleteCashBox",
                context: document.body,
                type: 'post',
                data: {'cashBoxId': cashBoxId}
            }).done(function () {
                actionSuccess();
                loadById('CashBoxes', accountId);
            }).fail(function () {
                actionFail();
            })
        });
}

function setSettingForCashBox(cashBoxId, setting, value) {
    $.ajax({
        url: "/" + appLocation + "setSettingForCashBox",
        context: document.body,
        type: 'post',
        data: {'cashBoxId': cashBoxId, 'setting': setting, 'value': value}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
        } else {
            actionFail(response.result);
        }
    }).fail(function () {
        actionFail();
    })
}

function setSettingForCashBoxFromInputGroup(button, cashBoxId, setting) {
    var span = button.parent();
    var divInputGroup = span.parent();
    var value = $(divInputGroup.find(":input")).val();

    $.ajax({
        url: "/" + appLocation + "setSettingForCashBox",
        context: document.body,
        type: 'post',
        data: {'cashBoxId': cashBoxId, 'setting': setting, 'value': value}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
            unhighlightSavedValue(setting + cashBoxId);
        } else {
            actionFail(response.result);
        }
    }).fail(function () {
        actionFail();
    })
}

function createCertificateAndInitCashBox(cashBoxId) {
    customConfirm("Bitte bestätigen", "Sind die Daten korrekt?",
        function () {
            var submitButtonId = "createCertificateAndInitCashBoxButton" + cashBoxId;
            var oldButtonText = setButtonInWaitingMode(submitButtonId);

            var accountId = $('#cashBoxModule #selectAccount').val();
            $.ajax({
                url: "/" + appLocation + "createCertificateAndInitCashBox",
                context: document.body,
                type: 'post',
                data: {'cashBoxId': cashBoxId}
            }).done(function () {
                actionSuccess();
                loadById('CashBoxes', accountId);
            }).fail(function (e) {
                actionFail(e.responseText);
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            })
        });
}

function createStartReceipt(cashBoxId) {
    customConfirm("Bitte bestätigen", "Möchten Sie jetzt einen Startbeleg erstellen?",

        function () {
            var submitButtonId = "createStartReceiptButton" + cashBoxId;
            var oldButtonText = setButtonInWaitingMode(submitButtonId);

            var accountId = $('#cashBoxModule #selectAccount').val();
            $.ajax({
                url: "/" + appLocation + "createStartReceipt",
                context: document.body,
                type: 'post',
                data: {'cashBoxId': cashBoxId}
            }).done(function () {
                actionSuccess();
                loadById('CashBoxes', accountId);
            }).fail(function () {
                actionFail();
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            })
        });
}

function createNullReceipt(cashBoxId) {
    customConfirm("Bitte bestätigen", "Möchten Sie jetzt einen neuen Nullbeleg erstellen?",
        function () {
            var submitButtonId = "createNullReceiptButton" + cashBoxId;
            var oldButtonText = setButtonInWaitingMode(submitButtonId);

            var accountId = $('#cashBoxModule #selectAccount').val();
            $.ajax({
                url: "/" + appLocation + "createNullReceipt",
                context: document.body,
                type: 'post',
                data: {'cashBoxId': cashBoxId}
            }).done(function () {
                actionSuccess();
                loadById('NullReceiptPackages', cashBoxId);
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            }).fail(function () {
                actionFail();
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            })
        });
}

function setCashBoxReadyToUse(cashBoxId) {
    customConfirm("Bitte bestätigen", "Wurde die Registrierkasse in FinanzOnline registriert und der Startbeleg erfolgreich geprüft?",
        function () {
            var submitButtonId = "setCashBoxReadyToUseButton" + cashBoxId;
            var oldButtonText = setButtonInWaitingMode(submitButtonId);

            var accountId = $('#cashBoxModule #selectAccount').val();
            $.ajax({
                url: "/" + appLocation + "setCashBoxReadyToUse",
                context: document.body,
                type: 'post',
                data: {'cashBoxId': cashBoxId}
            }).done(function () {
                actionSuccess();
                loadById('CashBoxes', accountId);
            }).fail(function () {
                actionFail();
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            })
        });
}


function printReceipt(cashBoxId, receiptId) {
    $.ajax({
        url: "/" + appLocation + "printReceipt",
        context: document.body,
        type: 'post',
        data: {'cashBoxId': cashBoxId, 'receiptId': receiptId}
    }).done(function (response) {
        if (response.status == 'SUCCESS') {
            actionSuccess();
        } else {
            actionFail(response.result);
        }
    }).fail(function () {
        actionFail();
    })
}