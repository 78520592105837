const { STPadServerLibCommons, STPadServerLibDefault } = STPadServerLib;

const SignaturePad = {
    serverAddress: "localhost:49494", connected: false, padInfo: null, showPreview: false,
    connect: async function() {
        if (SignaturePad.connected) await SignaturePad.disconnect();
        return new Promise((resolve, reject) => {
            const onOpen = async function()  {
                SignaturePad.connected = true;
                const searchPadParams = new STPadServerLibDefault.Params.searchForPads();
                searchPadParams.setPadSubset("HID");
                const pads = await STPadServerLibDefault.searchForPads(searchPadParams);
                if (pads.foundPads.length === 0) return reject("Es wurde kein verbundenes SignoTec-Pad gefunden.");
                else actionSuccess();
                const openPadParams = new STPadServerLibDefault.Params.openPad(0);
                const padDetails = await STPadServerLibDefault.openPad(openPadParams)
                SignaturePad.padInfo = padDetails.padInfo;
                return resolve();
            };
            const onClose = function() {
                SignaturePad.connected = false;
                SignaturePad.disconnect();
                SignaturePad.clearCallbacks();
                return reject();
            }

            STPadServerLibCommons.createConnection("wss://" + SignaturePad.serverAddress, onOpen, onClose, onClose);
        });
    },
    disconnect: async function() {
        if (SignaturePad.padInfo != null) await STPadServerLibDefault.closePad(
            new STPadServerLibDefault.Params.closePad(0)
        );
        await STPadServerLibCommons.destroyConnection();
        SignaturePad.connected = false;
        SignaturePad.padInfo = null;
        SignaturePad.clearCallbacks();
    },
    clearCallbacks: function() {
        STPadServerLibDefault.handleRetrySignature = function() {};
        STPadServerLibDefault.handleConfirmSignature = function() {};
        STPadServerLibDefault.handleCancelSignature = function() {};
        STPadServerLibDefault.handleConfirmSelection = function() {};
        STPadServerLibDefault.handleCancelSelection = function() {};
        STPadServerLibDefault.handleSelectionChange = function() {};
        STPadServerLibDefault.handleError = function() {};
    },
    signature: async function(title = "Unterschrift", customText = "") {
        return new Promise(async (resolve, reject) => {
            let error;
            if (! SignaturePad.connected) await SignaturePad.connect().catch(it => error = it);
            if (error) {
                reject(error)
                return
            }
            const startSignatureParams = new STPadServerLibDefault.Params.startSignature();
            startSignatureParams.setFieldName(title);
            customText && startSignatureParams.setCustomText(customText);
            // register the callbacks
            STPadServerLibDefault.handleRetrySignature = STPadServerLibDefault.retrySignature;
            STPadServerLibDefault.handleConfirmSignature = async function () {
                const signature = await STPadServerLibDefault.confirmSignature();
                if (signature.countedPoints < 50) {
                    reject("Unterschrift zu kurz. Bitte erneut versuchen.");
                    return await STPadServerLibDefault.retrySignature();
                }
                const imageParams = new STPadServerLibDefault.Params.getSignatureImage();
                imageParams.setFileType(STPadServerLibDefault.FileType.PNG);
                imageParams.setPenWidth(5);
                const signatureImage = await STPadServerLibDefault.getSignatureImage(imageParams);

                const result = { image: signatureImage.file, width: SignaturePad.padInfo?.xResolution, height: SignaturePad.padInfo?.yResolution};
                if (SignaturePad.showPreview) bootbox.alert(`<h3>Signature:</h3><img src="data:image/png;base64,${result.image}" width="500" alt="signature">`);
                SignaturePad.clearCallbacks();
                resolve(result);
            };
            STPadServerLibDefault.handleCancelSignature = async function () {
                await STPadServerLibDefault.cancelSignature();
                SignaturePad.clearCallbacks();
                reject("Vorgang durch Nutzer abgebrochen.");
            };
            STPadServerLibDefault.startSignature(startSignatureParams);
        });
    },
    selection: async function(checkboxes = {}) {
        if (! SignaturePad.connected) await SignaturePad.connect();
        const dialogParams = new STPadServerLibDefault.Params.startSelectionDialog();
        dialogParams.addCheckboxInformation(Object.entries(checkboxes)
            .map(it => { return {id: it[0], ...it[1]}}));
        return new Promise((resolve, reject) => {
            // register the callbacks
            STPadServerLibDefault.handleConfirmSelection = async function () {
                await STPadServerLibDefault.cancelSignature();
                SignaturePad.clearCallbacks();
                var code = "<h3>Selection:</h3>";
                Object.entries(checkboxes)
                    .map(it => it[1])
                    .map(it => `<label style="display: block"><input type="checkbox" disabled ${it.checked ? 'checked' : ''}>&nbsp;&nbsp;${it.text}</label>`)
                    .forEach(it => code += it)
                if (SignaturePad.showPreview) bootbox.alert(code);
                resolve(checkboxes);
            };
            STPadServerLibDefault.handleCancelSelection = async function () {
                await STPadServerLibDefault.cancelSignature();
                SignaturePad.clearCallbacks();
                reject("Vorgang durch Nutzer abgebrochen.");
            };
            STPadServerLibDefault.handleSelectionChange = function(id, checked) {
                // why would anyone send a boolean as "TRUE" or "FALSE"???
                checkboxes[id].checked = checked === "TRUE";
            }
            STPadServerLibDefault.startSelectionDialog(dialogParams);
        });
    },
    signDocument: async function (documentId) {
        const box = bootbox.dialog({
            title: "Bitte auf dem Signatur-Pad fortfahren.",
            message: `<img src="/${appLocation}resources/images/loader.gif" alt="loading">`
        });
        await new Promise((resolve, reject) => box.bind('shown.bs.modal', resolve));
        let error;
        await SignaturePad.connect().catch(function (reason) { error = reason });
        if (error) return box.find('.bootbox-body').html(`<div class="alert alert-danger">${error}</div>`);
        // check (with backend) that user may sign the supplied document
        const selection = await post("/" + appLocation + "signDocumentWithSignoTec/" + documentId);
        // (optional) show received checkboxes on pad
        // SignaturePad.selection edits the original object!!
        if (Object.keys(selection).length > 0) {
            box.find('.bootbox-body').text("Bitte wählen Sie die Optionen auf dem Signatur-Pad.");
            await SignaturePad.selection(selection).catch(function (reason) { error = reason });
            if (error) {
                box.find('.bootbox-body').html(`<div class="alert alert-danger">${error}</div>`);
                return await SignaturePad.disconnect();
            }
        }
        // get signature from pad
        box.find('.bootbox-body').text("Bitte unterschreiben Sie auf dem Signatur-Pad.")
        const signatureImageInfo = await SignaturePad.signature().catch(function (reason) { error = reason });
        if (error) return box.find('.bootbox-body').html(`<div class="alert alert-danger">${error}</div>`);
        await SignaturePad.disconnect();
        // send signature to backend
        await post("/" + appLocation + "signDocumentWithSignoTec/" + documentId + "/complete", { selection, signatureImageInfo });
        const showButton = $('<button class="btn btn-default pull-right mr-5">Dokument öffnen</button>').bind("click", function() {
            window.open(`/${appLocation}document/${documentId}`)
        });
        const closeButton = $('<button class="btn btn-primary pull-right">Schließen</button>').bind("click", function() { box.modal("hide") });
        box.find('.bootbox-body').html(`
            <div class="alert alert-success">Unterschrift erfolgreich gespeichert.</div>
            Bitte beachten Sie: 
            <ul>
                <li>Sollten sie das Dokument bearbeiten, wird die Unterschrift unwiderruflich entfernt.</li>
                <li>Eine solche Unterschrift ist keine digitale Signatur, sondern nur ein Bild.</li>
            </ul>
        `).append(
            $("<div class='row'></div>").append(closeButton).append(showButton)
        );
    }
};

